import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, searchDateTypeOption, searchStatusOption, searchTypeOption } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseRadio, BaseTable, BaseTooltip } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepickerWithSelect from "src/components/RangeDatepickerWithSelect";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ViewYmdFormat, YmdFormat, qsParse } from "src/utils/common-util";
import { columns } from "./columns/Columns";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const ContractList = () => {
  const { isAuthority } = usePartnerAuthority();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const { openErrorModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [building, setBuilding] = useState<any>({ buildingName: "" });
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [org, setOrg] = useState<any>({ name: "" });
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);
  const orgColumns: any = [
    {
      Header: "선택",
      accessor: "isSelected",
      width: 80,
      Cell: ({ row, setSelectedOrg }: any) => {
        const changeTrigger = useCallback(() => {
          setSelectedOrg(row.original);
        }, [row.original, setSelectedOrg]);

        return <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} value={row.original.isSeleted} />;
      },
    },
    {
      Header: "id",
      accessor: "id",
      width: 120,
    },
    {
      Header: "법인명/상호",
      accessor: "name",
      width: 740,
      Cell: ({ value }: any) => {
        return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
      },
    },
  ];

  // console.log(location.search, "location.search");
  const queryParams: any = useMemo(() => {
    const _queryParams: any = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "contractManageId", direction: "DESC" }],
      };
    }
    if (_queryParams?.id) {
      delete _queryParams?.id;
    }
    if (!_queryParams?.searchType) {
      _queryParams.searchType = "";
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.searchStartTime !== undefined && _queryParams?.searchStartTime !== "") {
      console.log("_queryParams?.searchStartTime", _queryParams?.searchStartTime);
      _queryParams.searchStartTime = moment(_queryParams?.searchStartTime).toDate();

      dump[0] = moment(_queryParams?.searchStartTime).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.searchEndTime !== undefined && _queryParams?.searchEndTime !== "") {
      console.log("_queryParams?.searchEndTime", moment(_queryParams?.searchEndTime).format(ViewYmdFormat.YYYY_MM_DD));
      _queryParams.searchEndTime = moment(moment(_queryParams?.searchEndTime).format(ViewYmdFormat.YYYY_MM_DD)).toDate();

      dump[1] = moment(_queryParams?.searchEndTime).toDate();
    } else {
      dump[1] = null;
    }
    console.log("setQueryParams", dump);
    setDateRange(dump);

    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    if (_queryParams.buildingName === undefined || _queryParams.buildingName === "") {
      _queryParams.buildingName = "";
      delete _queryParams.buildingId;
    }
    if (_queryParams.orgName === undefined || _queryParams.orgName === "") {
      _queryParams.orgName = "";
      delete _queryParams.mbOrganizationId;
    }

    return _queryParams;
  }, [location]);
  const [params, setParams] = useState<any>({ ...queryParams });
  const [data, setData] = useState<ContractManageList[] | undefined>(undefined);
  const [pagination, setPagination] = useState<PageMeta>();

  //호출부
  const { executeAsync } = useApiOperation(getContractApply);

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: any) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      console.log("navigateWithQueryParams", newQueryParams);
      setParams(newQueryParams);
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );

  const settingBuilding = useCallback(
    (bd: any) => {
      setIsBuildingOpen(false);
      setBuilding(bd);

      navigateWithQueryParams({ ...params, ...{ buildingId: bd.id, buildingName: bd.buildingName } });
    },
    [params],
  );

  const settingOrg = useCallback(
    (org: any) => {
      setIsOrgOpen(false);
      setOrg(org);

      navigateWithQueryParams({ ...params, ...{ mbOrganizationId: org.id, orgName: org.name } });
    },
    [params],
  );

  const callList = useCallback(
    async (param: any) => {
      console.log("originalparam", param);
      if (param.buildingName === undefined || param.buildingName === "") {
        delete param.buildingId;
      }
      if (param.orgName === undefined || param.orgName === "") {
        delete param.mbOrganizationId;
      }
      if (dateRange[0]) {
        let startTime = moment(dateRange[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        param.searchStartTime = startTime;
        setParams({ ...param, ...{ searchStartTime: startTime } });
      }
      if (dateRange[1]) {
        let endTime = moment(dateRange[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

        console.log("endTime", endTime);
        param.searchEndTime = endTime;
        setParams({ ...param, ...{ searchEndTime: endTime } });
      }
      console.log("callList", param);
      param.searchValue = param.searchValue ? decodeURIComponent(param.searchValue) : "";
      setParams({ ...queryParams, ...param });
      let response: any = await executeAsync(param);

      if (response && response.status >= 200 && response.status <= 299) {
        setData(response.data.data.content);
        setPagination(response.data.meta.pageMeta);
      }
    },
    [dateRange, queryParams, executeAsync, location.search],
  );

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let startTime = "";
    let endTime = "";
    if (dateRange[0]) {
      startTime = moment(moment(dateRange[0]).format(ViewYmdFormat.YYYY_MM_DD) + "T00:00:00.000Z", "YYYY.MM.DDTHH:mm:ss.SSSZ").format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      );

      setParams({ ...params, ...{ searchStartTime: startTime } });
    }
    if (dateRange[1]) {
      endTime = moment(moment(dateRange[1]).format(ViewYmdFormat.YYYY_MM_DD) + "T23:59:59.999Z", "YYYY.MM.DDTHH:mm:ss.SSSZ").format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      );

      navigateWithQueryParams({ ...params, ...{ searchStartTime: startTime, searchEndTime: endTime, page: 0 } });
    }
  };

  const returnContractSteps = useMemo(() => {
    let rtn: any = [];
    // console.log("params.contractStep", params.contractStep);
    if (params.contractStep !== undefined && params.contractStep !== "") {
      rtn = params.contractStep.split(",");
    }

    return rtn;
  }, [params.contractStep]);

  useEffect(() => {
    callList(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // console.log("pagination", pagination);
  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={decodeURIComponent(params?.buildingName) || ""}
                    placeholder="건물 선택"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsBuildingOpen(true);
                    }}
                    onClearClick={() => {
                      let cl = _.cloneDeep(params);
                      cl.buildingName = "";
                      delete cl.buildingId;
                      navigateWithQueryParams({ ...params, ...cl });
                    }}
                  />
                  <SearchBuildingPopup
                    isOpen={isBuildingOpen}
                    partnerId={params.partnerId}
                    buildingName={params?.buildingName}
                    onClick={settingBuilding}
                    onClose={() => setIsBuildingOpen(false)}
                  />
                </div>
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={decodeURIComponent(params?.orgName || "")}
                    placeholder="사업자 선택"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsOrgOpen(true);
                    }}
                    onClearClick={() => {
                      let cl = _.cloneDeep(params);
                      cl.orgName = "";
                      delete cl.mbOrganizationId;
                      navigateWithQueryParams({ ...params, ...cl });
                    }}
                  />
                  <SearchOrgPopup
                    isOpen={isOrgOpen}
                    partnerId={params.partnerId}
                    name={params?.orgName}
                    columns={orgColumns}
                    placeholder={"법인명/상호를 입력해 주세요"}
                    onClick={settingOrg}
                    onClose={() => setIsOrgOpen(false)}
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="">
                  {/* <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} /> */}
                  <RangeDatepickerWithSelect
                    stateOptions={searchDateTypeOption}
                    selectValue={params.searchTimeType || ""}
                    setStateValue={(searchTimeType: string) => {
                      if (params.searchStartTime && params.searchEndTime) {
                        navigateWithQueryParams({ ...params, searchTimeType });
                      } else {
                        console.log("params", params);
                        setParams({ ...params, searchTimeType });
                      }
                    }}
                    selectWidth={120}
                    dateRange={dateRange}
                    onChange={handleOnDateRangeChange}
                  />
                </div>
                <div className="minmax140 ">
                  <BaseMultiSelect
                    placeholder="계약상태"
                    stateOptions={searchStatusOption}
                    value={returnContractSteps || []}
                    setStateValue={(options: Array<{ value: string; label: string }>) => {
                      let status = "";
                      options.map((obj: { value: string; label: string }) => {
                        if (status === "") {
                          status = obj.value;
                        } else {
                          status += "," + obj.value;
                        }
                      });
                      let dump = _.cloneDeep(params);
                      dump.page = 0;
                      dump.contractStep = status;
                      navigateWithQueryParams(dump);
                    }}
                  />
                  {/* <BaseSelect
                    value={params.contractStep}
                    placeholder="상태"
                    stateOptions={searchStatusOption}
                    setStateValue={(contractStep: string) => {
                      navigateWithQueryParams({ ...params, ...{ contractStep, page: 0 } });
                    }}
                  /> */}
                </div>

                <div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={params.searchType}
                    inputValue={decodeURIComponent(String(params?.searchValue) || "")}
                    stateOptions={searchTypeOption}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keyword: string) => setParams({ ...params, ...{ searchValue: keyword } })}
                    onKeyUp={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onSearchClick={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onClearClick={() => navigateWithQueryParams({ ...params, ...{ searchValue: "" } })}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">{isAuthority("CONTRACT", "w") && <BaseButton title="+ 신청/계약 생성" onClick={() => navigate("form")} />}</div>
        </div>

        {/* 샘플 */}
        {data !== undefined && (
          <BaseTable
            data={data}
            columns={columns}
            pageIndex={Number(params?.page) || 0}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            totalPages={Number(pagination?.totalPages) || 0}
            totalElements={Number(pagination?.totalElements) || 0}
            goPage={(page: number) => {
              // setParams({ ...params, ...{ page } });
              navigateWithQueryParams({ ...params, ...{ page, size: queryParams.size } });
            }}
            disabledSortHeaders={["mbOrganizationName", "useNums"]}
            orders={params?.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                // setParams({ ...params, ...{ sort: { orders } } });
                navigateWithQueryParams({ ...params, ...{ sort: { orders } } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ContractList;
