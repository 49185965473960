
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getContractNumbers } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseEmail from "src/components/BaseEmail";
import BaseScroll from "src/components/BaseScroll";
import useApiLoading from "src/hooks/useApiLoading";
import { formatPhoneNumber } from "src/utils/common-util";

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    // sticky: "left",
    width: 140,
    Cell: ({ rows, row, setSelectedMember }: any) => {
      const changeTrigger = useCallback(() => {
        console.log("row.original.id", row.original);
        setSelectedMember(row.original);
      }, [row.original, setSelectedMember]);

      return (
        <div>
          <BaseRadio id={`selector_${row.original.index}`} name={"isSelected"} onChange={changeTrigger} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "contractMemberId",
    // sticky: "left",
    width: 140,
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 220,
  },
  {
    Header: "휴대폰번호",
    accessor: "inviteMobileNumber",
    width: 220,
    Cell: ({ value }: any) => {
          return <p>{formatPhoneNumber(value)}</p>;
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <div>
          <BaseEmail value={value} />
        </div>
      );
    },
  },
];
type Props = {
  contractManageId?: number;
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchContractMemberPopup = (props: Props) => {
  const { isApiLoading } = useApiLoading();
  const [data, setData] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [pageMeta, setPageMeta] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    contractManageId: props.contractManageId,
    searchValue: "",
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: data,
      setSelectedMember,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getContractNumbers);

  const onClickData = useCallback(() => {
    if (props.onClick) {
      props.onClick(selectedMember);
    }
  }, [props, selectedMember]);

  const closeData = useCallback(async () => {
    if (props.onClose) props.onClose(selectedMember);
  }, [props]);

  const callContractUsers = useCallback(
    async (params: any) => {
      console.log("params", params);
      const response: any = await executeAsyncAll(params);

      if (response.status > 199 && response.status < 300) {
        if (response?.data?.data && response?.data?.data.length > 0) {
          for (let i = 0; i < response?.data?.data?.length; i++) {
            response.data.data[i] = Object.assign(response.data.data[i], {
              isSelected: false,
              index: i,
            });
          }
        }
        setData(response?.data?.data);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [executeAsyncAll],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      setSearchValue("");
      prs.contractManageId = props.contractManageId;
      prs.searchValue = "";
      prs.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
      callContractUsers(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={props.isOpen}>
      <>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>계약자 선택</h1>

            <div className="minmax260 text-left">
              <BaseInput
                type="text"
                value={searchValue || ""}
                placeholder="회원번호 또는 휴대폰 번호 검색"
                onChange={(value: string) => {
                  setSearchValue(value);

                  setParams({ ...params, ...{ searchValue: value } });
                }}
                onKeyUp={() => {
                  setParams({ ...params, ...{ searchValue }, ...{ page: 0 } });
                  callContractUsers({ ...params, ...{ searchValue }, ...{ page: 0 } });
                }}
                onSearchClick={() => {
                  setParams({ ...params, ...{ searchValue }, ...{ page: 0 } });
                  callContractUsers({ ...params, ...{ searchValue }, ...{ page: 0 } });
                }}
                onClearClick={() => {
                  setSearchValue("");
                  setParams({ ...params, ...{ searchValue: "" }, ...{ page: 0 } });
                }}
              />
            </div>
          </div>
        </section>
        {/* table */}
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table sticky  view-data-table px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            pageIndex={params.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={Number(params.size) || 20}
            sizeOption={(sizeValue) => {
              setParams({ ...params, size: sizeValue });
              callContractUsers({ ...params, size: sizeValue });
            }}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              callContractUsers({ ...params, ...{ page } });
            }}
            children={
              <div className="flex-center">
                <button className="base-btn color-white mr10" onClick={() => closeData()}>
                  취소
                </button>
                <button className="base-btn" onClick={() => onClickData()}>
                  선택
                </button>
              </div>
            }
          />
        </section>
      </>
    </BaseAbstractModal>
  );
};

export default SearchContractMemberPopup;
