import { useCallback, useEffect, useState } from "react";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getBuildingsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { Modal, UnionCmdType } from "src/api/public-types";
import { getCsServiceItemsAsync, getVocListAsync, submitVocItemAsync } from "src/api/voc/voc-api";
import { CsItemModel, CsServiceItemsParams, VocModelUpdate, VocQueryParams } from "src/api/voc/voc-types";
import { BaseButton, BaseConfirmModal } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import Link from "src/components/PartnerLink";
import RegistrationInformation from "src/components/RegistrationInformation";
import { ArticleSection } from "src/components/layout/ArticleSection";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";

type Props = {
  vocId: string;
};

const BasicInfoDetail = ({ vocId }: Props) => {
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [vocDetail, setVocDetail] = useState<CsItemModel>();
  const [buildings, setBuildings] = useState<{ id: string; buildingName: string }[]>([]);

  const { isAuthority } = usePartnerAuthority();

  // 등록/수정
  const { executeAsync: submitVocItem } = useApiOperation(submitVocItemAsync);

  // voc 항목 상세
  const { executeAsync: getVocItems } = useApiOperation(getVocListAsync);

  // csService 목록 api (건물 > voc탭 목록)
  const { executeAsync: getCsService } = useApiOperation(getCsServiceItemsAsync);

  // 건물 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  const fetchBuildings = useCallback(
    async (ids: string[]) => {
      const buldingData = {
        page: 0,
        size: 999,
        id: ids.join(","),
      };
      const { data, status } = await getBuildings(buldingData);

      if (status >= 200 && status <= 299) {
        const buildingList = data.data.content.map((buildng) => {
          return {
            id: String(buildng.id),
            buildingName: buildng.buildingName,
          };
        });

        setBuildings(buildingList);
      }
    },
    [getBuildings],
  );

  // voc항목이 등록된 건물의 ids 가져오기
  const fetchCsService = useCallback(async () => {
    const param: CsServiceItemsParams = {
      searchType: "CS_ITEM_ID",
      keywordList: vocId,
      serviceType: ServiceTypes.SERVICE_BUILDING,
      sort: {
        orders: [{ property: "id", direction: "DESC" }],
      },
    };

    const { data, status } = await getCsService(param);

    if (status >= 200 && status <= 299) {
      const vocServiceIds = data.data.content.map((item) => item.serviceId);
      if (vocServiceIds.length > 0) {
        await fetchBuildings(vocServiceIds);
      }
    }
  }, [getCsService]);

  // 1. voc 항목 상세 호출
  // 2. voc항목이 등록된 건물의 voc 호출
  useEffect(() => {
    const data = {
      searchType: "id",
      keywordList: vocId,
    };
    const fetchVocList = async (params: VocQueryParams) => {
      const { data, status } = await getVocItems(params);

      if (status >= 200 && status <= 299) {
        await fetchCsService();
        setVocDetail(data.data.content[0]);
      }
    };

    fetchVocList(data);
  }, [getVocItems]);

  const handleSubmitVocItems = async (vocData: VocModelUpdate) => {
    const { status } = await submitVocItem(vocData);
    if (status >= 200 && status <= 299) {
      navigate(PagePath.voc.list);
    }
  };

  return (
    <div>
      {true && (
        <div className="contents-container__scroll">
          <div className="contents-container__wrap ">
            <ArticleSection title="VoC 정보">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{vocDetail?.csTypeName || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>항목</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{vocDetail?.csItemName || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>적용된 건물</p>
                </div>
                <div className="contents-container__grid-contents flex-center ">
                  <div className="flex-row flex-center-start flex-wrap ">
                    {buildings.length > 0 ? (
                      buildings.map((building, index) => (
                        <div className="mr15 h-36 flex-center " key={building.id}>
                          <span>{`${building.buildingName} (id:`} </span>
                          <Link className="text-underline" target="_blank" to={PagePath.building.detail.replaceAll(":id", building.id)}>
                            {building.id}
                          </Link>
                          <span className="ic-target-blank"></span>
                          <span>{`)${index < buildings.length - 1 ? "," : ""}`}</span>
                        </div>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </section>
            </ArticleSection>

            <RegistrationInformation data={vocDetail} />

            {vocId && <AdminMemoV3 serviceId={Number(vocId)} serviceType={ServiceTypes.SERVICE_CS_ITEM} />}
          </div>
        </div>
      )}

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
          {isAuthority("BASIC", "d") && (
            <BaseButton
              title="삭제"
              className="no-outline color-white size-large text-red900 ml10"
              onClick={() => {
                if (buildings.length > 0) {
                  setAlertModal({ isOpen: true, message: "VoC항목이 적용된 건물이 있어 삭제할 수 없습니다." });
                } else {
                  setConfirmModal({
                    isOpen: true,
                    message: "정말 삭제 처리하시겠습니까?",
                    type: "BUILDING_DELETE",
                  });
                }
              }}
            />
          )}
        </div>

        <div className="right-area">
          {isAuthority("BASIC", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.voc.form}?id=${vocId}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
      <>
        {confirmModal.isOpen && (
          <BaseConfirmModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title={confirmModal.message}
            onClick={() => {
              const data = { ...vocDetail, cmdType: "D" as UnionCmdType };
              handleSubmitVocItems(data);
            }}
            onClose={() => setConfirmModal({ isOpen: false })}
          ></BaseConfirmModal>
        )}

        {alertModal.isOpen && (
          <BaseConfirmModal
            isOpen={true}
            btnRightTitle="확인"
            title={alertModal.message}
            onClick={() => setAlertModal({ isOpen: false })}
          ></BaseConfirmModal>
        )}
      </>
    </div>
  );
};

export default BasicInfoDetail;
