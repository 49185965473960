import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { BuildingModel, MgmtOfficerType } from "src/api/building/building-types";
import { MediaServiceType, Modal } from "src/api/public-types";
import { ConnectedInfo } from "src/api/question/question-type";
import { WorkOrderDetailModel, WorkOrderSheetCostType, WorkOrderStatus } from "src/api/work-order/workorder-types";
import { BaseButton, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import Link from "src/components/PartnerLink";
import PublicImageUpdate from "src/components/PublicImageUpdate";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { RoleType } from "src/pages/building/building-types";
import useWorkOrder from "src/pages/workOrder/hooks/useWorkOrder";
import { formatPhoneNumber, numberToStringWithComma, onChangeKoreanDays } from "src/utils/common-util";
import WorkOrderGroupListSection from "./workOrderGroup/WorkOrderGroupListSection";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
export interface BasicProps {
  setSummary: any;
  workOrderDetail?: WorkOrderDetailModel;
}

const BasicInfoDetail = ({ setSummary, workOrderDetail }: BasicProps) => {
  const navigate = useNavigate();

  // 로딩바

  const params = useParams();
  const workOrderId = useMemo(() => {
    return params.id;
  }, [params]);

  // 컨트롤룸 baseUrl
  const ctrlUrl = useMemo(() => process.env.REACT_APP_BASEURL, []);

  // 경고창 팝업
  const [cancelModal, setCancelModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 관리처 상세 / 건물 상세에서 가져온 건물/관리처  value
  const [mgmtBuildingName, setMgmtBuildingName] = useState("");

  // 담당자 text Value
  const [officerNamePhone, setOfficerNamePhone] = useState({ value: "", deleted: false });

  const [connectedCs, setConnectedCs] = useState<ConnectedInfo>();

  const { isAuthority } = usePartnerAuthority();

  const { getWorkOrderDetail, workOrderCancel, getProviderDetail, getBuilding, getBuildingManagement, getConnectedInfoList } = useWorkOrder();

  useEffect(() => {
    const fetchConnectedCs = async () => {
      const { data, status } = await getConnectedInfoList({ connectedId: workOrderDetail?.id, connectedType: "CS_CONNECTED_WORKORDER" });

      if (status >= 200 && status <= 299) {
        setConnectedCs(data.data.content[0]);
      }
    };
    fetchConnectedCs();
  }, [workOrderDetail, getConnectedInfoList]);
  //회원 상세 조회 API

  // 담당자 input value 노출
  const officerInfoText = useCallback((officerId: string, officerList: MgmtOfficerType[]) => {
    //

    // 담당자 목록 && 팝업에서 선택된 담당자ID 가 있으면
    if (officerId && officerList) {
      const officer = officerList.find((item) => item.id === officerId);

      let officerValue = "";

      if (officer?.isPrimary) {
        officerValue = `대표매니저 / ${officer.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.MANAGER) {
        officerValue = `매니저 / ${officer.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.USER) {
        officerValue = `${officer?.name} / ${formatPhoneNumber(officer?.phone || "")}`;
      }
      setOfficerNamePhone({ value: officerValue, deleted: officer?.isDeleted || false });
    }
  }, []);

  // 관리처 관리 상세 / 건물 상세 api
  const getBuildingManagementApi = useCallback(
    async (workOrder?: WorkOrderDetailModel) => {
      // 관리처 상세 조회 response
      const mgmtBuildingResponse = await getBuildingManagement({ id: workOrder?.mgmtOfficeId });

      if (mgmtBuildingResponse.status >= 200 && mgmtBuildingResponse.status <= 299) {
        const managementBuilding = mgmtBuildingResponse.data.data.content;

        // 관리처 상세에 있는 buildingId
        const buildingId = managementBuilding.buildingId;

        // 관리처 상세에 있는 정산정보 ID
        const providerId = managementBuilding.providerId;

        // buildingId로 건물 상세 조회
        if (buildingId) {
          const buildingResponse = await getBuilding({ buildingId: Number(buildingId) });
          const building: BuildingModel = buildingResponse.data?.data?.content?.building;

          let providerName = "없음";

          // 정산정보 상세에서 providerName 가져와서 표시 (관리처명)

          if (providerId !== null) {
            const { data } = await getProviderDetail({ providerId: String(providerId) });
            if (data) {
              providerName = String(data.data.provider?.providerName);
              // 건물/관리처 input value setState
            }
          }
          const mgmtBuildingName = `${building.buildingName} / ${providerName}`;
          setMgmtBuildingName(mgmtBuildingName);

          // 관리처 담당자 정보 setTate
          officerInfoText(String(workOrder?.mgmtOfficerId), managementBuilding.officerList || []);
        }
      }
    },
    [getBuilding, getBuildingManagement, getProviderDetail, officerInfoText],
  );

  // 워크오더 취소 api
  const workOrderCancelApi = useCallback(
    async (id: string, type: string) => {
      // 그룹삭제
      if (id && type === "group") {
        const response = await workOrderCancel({ groupIds: String(id) });

        if (response.status >= 200 && response.status <= 299) {
          setAlertModal({ isOpen: true, message: "취소 상태로 변경되었습니다." });
          setCancelModal({ isOpen: false });
        }
      }
      // 단건 삭제
      else {
        const response = await workOrderCancel({ ids: String(id) });

        if (response.status >= 200 && response.status <= 299) {
          setAlertModal({ isOpen: true, message: "취소 상태로 변경되었습니다." });
          setCancelModal({ isOpen: false });
        }
      }
    },
    [workOrderCancel],
  );

  useEffect(() => {
    if (workOrderDetail) {
      getBuildingManagementApi(workOrderDetail);
    }
  }, [workOrderDetail]);

  // 워크오더 이미지 부분
  const workOrderMediasList = useCallback(
    (mediaType: MediaServiceType) => {
      if (workOrderDetail?.mediaList && workOrderDetail.mediaList.length > 0) {
        const mediaList = workOrderDetail.mediaList;
        switch (mediaType) {
          // 업무내용 이미지 노출
          case "MEDIA_WORK_ORDER_SHEET":
            const workSheetMedias = mediaList.filter((media) => media.mediaServiceType === mediaType);
            return workSheetMedias.length > 0 ? (
              // <ImagesDetail images={workSheetMedias} />
              <PublicImageUpdate mediaFiles={workSheetMedias} isOnlyView />
            ) : (
              <div>
                <span>-</span>
              </div>
            );

          // 처리내용 이미지 노출
          case "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT":
            const workCompletedMedias = mediaList.filter((media) => media.mediaServiceType === mediaType);
            return workCompletedMedias.length > 0 ? (
              <PublicImageUpdate mediaFiles={workCompletedMedias} isOnlyView />
            ) : (
              // <ImagesDetail images={workCompletedMedias} />
              <div>
                <span>-</span>
              </div>
            );

          // 처리비용 이미지가 있으면 노출
          case "MEDIA_WORK_ORDER_SHEET_COST":
            const workCostMedias = mediaList.filter((media) => media.mediaServiceType === mediaType);

            return workCostMedias.length > 0 ? (
              <PublicImageUpdate mediaFiles={workCostMedias} isOnlyView />
            ) : (
              // <ImagesDetail images={workCostMedias} />
              <div>
                <span>-</span>
              </div>
            );
        }
      } else {
        return (
          <div>
            <span>-</span>
          </div>
        );
      }
    },
    [workOrderDetail],
  );
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {workOrderDetail && (
            <>
              <article className="contents-container__wrap-article">
                <BaseSectionTitle title={"업무 정보"} />
                <div className="flex-center-between">
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>업무 유형</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="minmax240">
                        <span>{workOrderDetail?.workOrderSheetGroupId ? "정기" : "비정기"}</span>
                      </div>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>건물/관리처</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <Link
                          className="text-underline"
                          target={"_blank"}
                          to={PagePath.building.detail.replace(":id", String(workOrderDetail.buildingId))}
                        >
                          {mgmtBuildingName || "-"}
                        </Link>
                        <span className="ic-target-blank"></span>
                      </div>
                    </div>
                  </section>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>업무 제목</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400 flex-row flex-center-start">
                      <span>{workOrderDetail?.summary}</span>
                      {/* 지연여부 노출 - 오늘 날짜, endDate 비교 */}
                      {moment(moment().format("YYYY-MM-DD")).isAfter(workOrderDetail.endDate) &&
                        (workOrderDetail.status === WorkOrderStatus.OPEN ||
                          workOrderDetail.status === WorkOrderStatus.WAITING ||
                          workOrderDetail.status === WorkOrderStatus.PROGRESS) && ( //
                          <span className="font12 status G ml10">지연</span>
                        )}
                      {/* 재처리 비용 노출 */}
                      {workOrderDetail.isReopened && <span className="chip red ml10">재요청</span>}
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>업무 내용</p>
                  </div>
                  <div className="contents-container__grid-contents contents-container__1200">
                    <div>
                      <p className="font14 pre-formatted">{workOrderDetail?.description || "-"}</p>
                    </div>
                  </div>
                </section>

                {/* 업무 내용 이미지  */}

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>이미지</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div>{workOrderMediasList("MEDIA_WORK_ORDER_SHEET")}</div>
                  </div>
                </section>

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>작업자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    {officerNamePhone?.value && (
                      <div className="flex-row flex-center-start">
                        <span>{officerNamePhone?.value}</span>
                        {officerNamePhone.deleted && (
                          <ul className="list-marker-red">
                            <li>
                              <span className="contents-container__sub-title-info_r icon-red text-red600 ml10 font12">
                                관리처에서 삭제된 담당자입니다.
                              </span>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                    {workOrderDetail.isSendAlarm && (
                      <ul className="list-marker ml10 text-gray600 ">
                        <li>
                          <span className="font12"> 업무 알림톡 발송 ON</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>업무 기간</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400 d-flex">
                      <span>
                        {`${moment(workOrderDetail?.startDate).format("YYYY.MM.DD") || "-"} (${onChangeKoreanDays(workOrderDetail?.startDate)}) -
                  ${moment(workOrderDetail?.endDate).format("YYYY.MM.DD") || "-"} (${onChangeKoreanDays(workOrderDetail?.endDate)}) `}
                      </span>
                    </div>
                  </div>
                </section>
              </article>
              {/* 그룹 워크오더시 리스트 노출 */}
              {workOrderDetail.workOrderSheetGroupId && (
                <article className="contents-container__wrap-article">
                  <BaseSectionTitle title={"정기 스케줄"} />
                  <section className="contents-container__grid ">
                    <div className="contents-container__grid-index">
                      <p>그룹 id</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{workOrderDetail.workOrderSheetGroupId}</p>
                    </div>
                  </section>
                  <section className="contents-container__grid ">
                    <div className="contents-container__grid-index">
                      <p>업무 목록</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <WorkOrderGroupListSection workOrder={workOrderDetail as WorkOrderDetailModel} />
                    </div>
                  </section>
                </article>
              )}
              <>
                {/* 상태 - 처리중 / 처리완료 / 처리종료 일시 노출 */}
                {(workOrderDetail.status === WorkOrderStatus.PROGRESS ||
                  workOrderDetail.status === WorkOrderStatus.COMPLETED ||
                  workOrderDetail.status === WorkOrderStatus.CLOSED) && (
                  <article className="contents-container__wrap-article">
                    <BaseSectionTitle title={"업무 처리 내역"} />
                    {/* 처리내용 */}
                    <section className="contents-container__grid ">
                      <div className="contents-container__grid-index">
                        <p>특이 사항</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax400 pt7">
                          <p className="font14 pre-formatted">{workOrderDetail?.completionReport || "-"}</p>
                        </div>
                      </div>
                    </section>
                    {/* 처리내용 증빙자료 */}
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p>이미지</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div>{workOrderMediasList("MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT")}</div>{" "}
                      </div>
                    </section>

                    {/* 처리비용 */}
                    <section className="contents-container__grid ">
                      <div className="contents-container__grid-index">
                        <p>처리 비용</p>
                      </div>
                      <table className="inner-table" width="940">
                        <thead>
                          <tr className="border-y">
                            <th className="bg-white">
                              <div>
                                <span>지출 내용</span>
                              </div>
                            </th>
                            <th className="bg-white">
                              <span>처리 금액</span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {workOrderDetail?.workOrderSheetCostList && workOrderDetail?.workOrderSheetCostList.length > 0 ? (
                            workOrderDetail.workOrderSheetCostList
                              .sort((a, b) => Number(a?.id) - Number(b?.id))
                              .map((cost: WorkOrderSheetCostType) => (
                                <tr key={cost.id}>
                                  <td>
                                    <div className="flex-center-start ml20">{cost.description || "-"}</div>
                                  </td>
                                  <td width={280}>
                                    {/* <div className="flex-center-center"> */}
                                    <div className="flex-center-end mr20">
                                      {Number(cost.cost) === 0 ? "-" : numberToStringWithComma(cost.cost) || "-"} 원{/* {} */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={2}>
                                <div className="flex-center-center">데이터가 없습니다</div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </section>

                    {/* 처리비용 이미지 */}
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        {" "}
                        <p>증빙 자료</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div>{workOrderMediasList("MEDIA_WORK_ORDER_SHEET_COST")}</div>
                      </div>
                    </section>
                    {/* 고객답변 */}
                    {(workOrderDetail.status === WorkOrderStatus.COMPLETED || workOrderDetail.status === WorkOrderStatus.CLOSED) && (
                      <section className="contents-container__grid ">
                        <div className="contents-container__grid-index">
                          <p className="pre-fomatter">접수 고객답변</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="minmax400 pt7">
                            <span className="font14 pre-formatted">{workOrderDetail?.customerNotification || "-"}</span>
                          </div>
                        </div>
                      </section>
                    )}
                  </article>
                )}
              </>
            </>
          )}

          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"등록 정보"} />
            <section className="contents-container__grid ">
              <div className="contents-container__grid-index">
                <p>연동 CS</p>
              </div>
              <div className="contents-container__grid-contents">
                {connectedCs?.questionId ? (
                  <div className="flex-row flex-center-start">
                    <Link className="text-underline" target="_blank" to={PagePath.question.detail.replace(":id", String(connectedCs?.questionId))}>
                      {connectedCs?.questionId || "-"}
                    </Link>
                    <span className="ic-target-blank"></span>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </section>
            <section className="contents-container__grid ">
              <div className="contents-container__grid-index">
                <p>모바일웹 URL</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-center-start flex-row">
                  <span className="font14 pre-formatted">
                    <a
                      className="text-underline "
                      href={`${ctrlUrl}/mng/ctrl/public/workOrder/${workOrderDetail?.workOrderSheetNo}`}
                      target="_blank"
                      rel="noreferrer"
                    >{`${ctrlUrl}/mng/ctrl/public/workOrder/${workOrderDetail?.workOrderSheetNo}`}</a>
                  </span>
                  <span className="ic-target-blank"></span>
                </div>
              </div>
            </section>
            <div className="flex-center-between">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최초 등록일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{moment(workOrderDetail?.createdDate).format("YYYY.MM.DD HH:mm:ss") || "-"}</span>

                    {/* <span className="font14">{moment(workOrderDetail?.createdDate).format(YmdFormat.FULL) || "-"}</span> */}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최초 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{workOrderDetail?.createdBy || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center-between">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{moment(workOrderDetail?.modifiedDate).format("YYYY.MM.DD HH:mm:ss") || "-"}</span>{" "}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{workOrderDetail?.modifiedBy === "product" ? "모바일웹에서 변경됨" : workOrderDetail?.modifiedBy || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
          </article>

          <AdminMemoV3 serviceId={Number(workOrderId)} serviceType={ServiceTypes.SERVICE_WORKORDER} />
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
          {/* <GoBackListButton /> */}
        </div>
        {workOrderDetail?.status === "WORK_ORDER_CANCELED" ? (
          <p className="text-red900 font16">취소된 워크시트 입니다.</p>
        ) : workOrderDetail?.status === "WORK_ORDER_CLOSED" ? (
          <p className="text-red900 font16">종료된 워크시트 입니다.</p>
        ) : (
          <div className="right-area">
            {isAuthority("WORKORDER", "w") && (
              <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const formPath = `${PagePath.workOrder.authenticated.form}?id=${params.id}`;
                  navigate(formPath);
                }}
              />
            )}
          </div>
        )}
      </div>
      <BaseModal
        isOpen={cancelModal.isOpen}
        onClose={() => setCancelModal({ isOpen: false })}
        onClick={() => {
          workOrderCancelApi(String(cancelModal.payload), cancelModal.type || "");
        }}
        btnLeftTitle="취소"
        btnRightTitle="확인"
      >
        <p className="pre-formatted"> {cancelModal.message}</p>
      </BaseModal>

      <BaseModal
        isOpen={alertModal.isOpen}
        onClick={() => {
          getWorkOrderDetail({ id: Number(workOrderDetail?.id) });
          setAlertModal({ isOpen: false });
        }}
        btnRightTitle="확인"
      >
        <p className="pre-formatted"> {alertModal.message}</p>
      </BaseModal>
    </>
  );
};

export default BasicInfoDetail;
