import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useApiOperation } from "src/api/hooks";

import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { qsParse } from "src/utils";
import ProviderSelectModal from "../components/ProviderSelectModal";
import closedPeriodListColumn from "./columns/closedPeriodColumn";
import { getClosedPeriodRuleListAsync } from "src/api/closedPeriodRule/closedPeriodRule-api";
import { ClosedPeriodRuleModel } from "src/api/closedPeriodRule/closedPeriodRule-types";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchType?: string;
  keywordList?: string;
  providerName?: string;
  providerId?: string;
  partnerId?: string;
  partnerName?: string;
  buildingName?: string;
};

const searchTypes = [
  {
    label: "정책명",
    value: "RULE_NAME",
  },
];

/**
 * 공용공간 휴무일시 > 목록 화면
 */
const ClosedPeriodList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.providerName) {
      _queryParams.providerName = "";
    }
    if (!_queryParams?.searchType) {
      _queryParams.searchType = "RULE_NAME";
    }
    if (!_queryParams?.buildingName) {
      _queryParams.buildingName = "";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    // qs.parse에 decoder때문에 검색값 입력 검색시 한번더 decoding
    if (_queryParams.keywordList) {
      _queryParams.keywordList = decodeURIComponent(_queryParams.keywordList);
    }

    return _queryParams;
  }, [location.search]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [ClosedPeriodList, setClosedPeriodList] = useState<ClosedPeriodRuleModel[]>();
  const [isProviderModalOpen, setIsProviderModalOpen] = useState({ isOpen: false });

  const { executeAsync: getClosedPeriodRuleList } = useApiOperation(getClosedPeriodRuleListAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);

  // 쿼리파람과 함께 navigate
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  const onAddedProviderSelectModal = (
    provider: {
      providerId: string;
      providerName: string;
    }[],
  ) => {
    if (!provider) return;
    setIsProviderModalOpen({ isOpen: false });
    navigateWithQueryParams({ ...params, providerName: provider[0].providerName, providerId: String(provider[0].providerId) });
  };

  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화
    const fetchApi = async (closedPeriodParams: QueryParams) => {
      // 목록 조회
      let { providerName, ...payload } = closedPeriodParams;
      const response = await getClosedPeriodRuleList(payload);
      if (response.status >= 200 && response.status <= 299) {
        const closedPeriodData = response.data.data.content;
        let providerData: ProviderModel[] = [];

        // provider목록 조회해서 providerName 매핑
        const removeDuplicateProvider = new Set(closedPeriodData.map((closedPeriod) => closedPeriod.providerId));

        const providerListString = [...removeDuplicateProvider].filter((item) => item !== null).join(",");

        const providerResponse = await getProvider({ providerId: providerListString });
        if (providerResponse.status >= 200 && providerResponse.status <= 299) {
          providerData = providerResponse?.data?.data?.content;
        }

        const forColumnclosedPeriodData = closedPeriodData.map((closedPeriod) => {
          const findProviderName = providerData.find((provider) => provider.providerId === closedPeriod.providerId)?.providerName;

          return { ...closedPeriod, providerName: findProviderName || "" };
        });

        setClosedPeriodList(forColumnclosedPeriodData);
        setPageMeta(response.data.meta.pageMeta);
      }
    };

    fetchApi(queryParams);
  }, [queryParams, getClosedPeriodRuleList, getProvider]);

  return (
    <div className="">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={params?.providerName || ""}
                    placeholder="프로바이더"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsProviderModalOpen({ isOpen: true });
                    }}
                    onClearClick={() => {
                      let { providerName, providerId, ...rest } = params;
                      navigateWithQueryParams({ ...rest });
                    }}
                  />
                  {isProviderModalOpen.isOpen && (
                    <ProviderSelectModal
                      onCanceled={() => setIsProviderModalOpen({ isOpen: false })}
                      onAdded={onAddedProviderSelectModal}
                      defaultChecked={params.providerName ? [{ providerId: String(params.providerId), providerName: params.providerName }] : []}
                    />
                  )}
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={"RULE_NAME"}
                    inputValue={params?.keywordList || ""}
                    stateOptions={searchTypes}
                    isSelectDisabled={true}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onClearClick={() => navigateWithQueryParams({ ...params, searchType: "RULE_NAME", keywordList: "" })}
                    onChange={(keywordList: string) => setParams({ ...params, keywordList })}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">
            <div className="flex-center">
              <BaseButton
                title="+ 휴무 등록"
                onClick={() => {
                  navigate(PagePath.closedPeriod.form);
                }}
              />
            </div>
          </div>
        </div>

        {ClosedPeriodList && (
          <BaseTable
            data={ClosedPeriodList}
            columns={closedPeriodListColumn}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams?.sort?.orders}
            disabledSortHeaders={["providerName", "productId", "subject", "startDate", "endTime"]}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(queryParams.size) || 20}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ClosedPeriodList;
