import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { MgmtOfficeModel, MgmtOfficerType, MgmtPhoneType } from "src/api/building/building-types";
import { editBuildingManagementAsync, getBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import GoToListButton from "src/components/GoToListButton";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useModal } from "src/recoil/modalState/hook";
import { formatPhoneNumber } from "src/utils";
import MgmtContactForm from "../../forms/mgmtOffice/components/MgmtContactForm";
import MgmtOfficeInfoForm from "../../forms/mgmtOffice/components/MgmtOfficeInfoForm";
import MgmtOfficerForm from "../../forms/mgmtOffice/components/MgmtOfficerForm";
import { FrontMgmtOfficeModel } from "../../forms/mgmtOffice/management-type";
import MgmtContactSection from "./components/MgmtContactSection";
import MgmtOfficeInfoDetail from "./components/MgmtOfficeInfoDetail";
import MgmtOfficerSection from "./components/MgmtOfficerSection";

export type ChangeSection = {
  info: boolean;
  contact: boolean;
  officer: boolean;
};

export type MgmtSectionType = "info" | "contact" | "officer";

type Props = { buildingId: string };

const MgmtOfficeDetail = ({ buildingId }: Props) => {
  const [mgmtInfoOpen, setMgmtInfoOpen] = useState<Modal>({ isOpen: false });

  // 정산정보 상세
  const [providerDetail, setProviderDetail] = useState<ProviderModel>();

  const [providerName, setProviderName] = useState("");

  // 관리처 상세
  const [managementDetail, setManagementDetail] = useState<MgmtOfficeModel>();

  const [frontOriginalData, setFrontOriginalData] = useState<FrontMgmtOfficeModel>();

  const { isAuthority } = usePartnerAuthority();

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync, { noHandleError: true });

  // 정산정보 상세 api
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync);

  const { setAbstractModalZ2 } = useModal();

  // 정산정보 상세 불러오기
  const getProviderDetailApi = async (providerId: string) => {
    if (providerId) {
      const response = await getProviderDetail({ providerId: providerId });
      if (response.status >= 200 && response.status <= 299) {
        setProviderDetail(response.data.data.provider);
        setProviderName(response.data.data.provider?.providerName || "");
      }
    }
  };

  // 관리처 관리 상세정보 api
  const getBuildingManagementApi = useCallback(
    async (id: string) => {
      //

      const response = await getBuildingManagement({ buildingId: String(id) });

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setManagementDetail({
          ...result,
          officePhoneList: result.officePhoneList?.map((phone) => ({
            ...phone,
            number: formatPhoneNumber(String(phone.number))?.replaceAll("-", ""),
          })),
        });
        if (result.providerId) {
          await getProviderDetailApi(result.providerId);
        }

        if (!!result.id) {
          const officePhoneList = result?.officePhoneList?.map((item: MgmtPhoneType) => {
            return { ...item, apiId: item.id };
          });

          const officerList = result.officerList?.map((item: MgmtOfficerType) => {
            return { ...item, apiId: item.id };
          });

          const dataForCompare: FrontMgmtOfficeModel = {
            id: result.id,
            buildingId: result.buildingId,
            providerId: String(result.providerId),
            officePhoneList,
            isHoliday: result.isHoliday,
            officerList,
            startTime: moment(result.startTime, "hh:mm").toDate(),
            endTime: moment(result.endTime, "hh:mm").toDate(),
          };

          setFrontOriginalData(dataForCompare);
        }
      }

      //
    },
    [getBuildingManagement],
  );

  useEffect(() => {
    getBuildingManagementApi(String(buildingId));
  }, [buildingId, getBuildingManagementApi]);

  // 연락처 삭제
  const handleDeleteTableRow = async (type: MgmtSectionType, body: any) => {
    if (type === "contact") {
      const { data, status } = await editBuildingManagement({ id: String(managementDetail?.id || ""), office: { officePhoneList: body } });
      if (status >= 200 && status <= 299) {
        await getBuildingManagementApi(String(buildingId));
      }
    } else {
      const { data, status } = await editBuildingManagement({ id: String(managementDetail?.id || ""), office: { officerList: body } });
      if (status >= 200 && status <= 299) {
        await getBuildingManagementApi(String(buildingId));
      }
    }
  };
  // 등록/수정 폼 오픈
  const handleOpenSectionForm = (type: MgmtSectionType, detail?: any) => {
    if (type === "contact") {
      setAbstractModalZ2({
        isOpen: true,
        size: "medium",
        children: (
          <MgmtContactForm
            managementDetail={managementDetail}
            buildingId={buildingId}
            contactDetail={detail || undefined}
            getBuildingManagement={getBuildingManagementApi}
          />
        ),
      });
    } else if (type === "officer") {
      setAbstractModalZ2({
        isOpen: true,
        size: "large",
        children: (
          <MgmtOfficerForm
            managementDetail={managementDetail}
            buildingId={buildingId}
            officerDetail={detail}
            frontOriginalData={frontOriginalData}
            getBuildingManagement={getBuildingManagementApi}
          />
        ),
      });
    }
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <>
            {/* 관리처 상세*/}
            <MgmtOfficeInfoDetail
              providerName={providerName}
              managementDetail={managementDetail}
              setMgmtInfoOpen={setMgmtInfoOpen}
              isAuthority={isAuthority("BUILDING", "w")}
            />
            {mgmtInfoOpen.isOpen && (
              <BaseAbstractModal isOpen={true} size="large">
                <MgmtOfficeInfoForm
                  buildingId={buildingId}
                  managementDetail={managementDetail}
                  frontOriginalData={frontOriginalData}
                  settlementCompany={providerDetail}
                  setSettlementCompany={setProviderDetail}
                  getBuildingManagement={getBuildingManagementApi}
                  setMgmtInfoOpen={setMgmtInfoOpen}
                  setProviderName={setProviderName}
                  providerName={providerName}
                />
              </BaseAbstractModal>
            )}

            {/* 연락처 */}
            <MgmtContactSection
              managementDetail={managementDetail || {}}
              handleDeleteTableRow={handleDeleteTableRow}
              handleOpenSectionForm={handleOpenSectionForm}
              isAuthority={{ editable: isAuthority("BUILDING", "w"), deletable: isAuthority("BUILDING", "d") }}
            />

            {/* 담당자 */}
            <MgmtOfficerSection
              managementDetail={managementDetail || {}}
              handleOpenSectionForm={handleOpenSectionForm}
              handleDeleteTableRow={handleDeleteTableRow}
              isAuthority={{ editable: isAuthority("BUILDING", "w"), deletable: isAuthority("BUILDING", "d") }}
            />
          </>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default MgmtOfficeDetail;
