import { useState } from "react";
import { ProductBuildingCommonFacilitySaveListReq } from "src/api/product/product-types";
import { SpaceTypeT } from "src/pages/product/product-types";

import { decommaizeNumber } from "@toss/utils";
import { CommonFacilityModel } from "src/api/building/building-types";
import usePostCommonFacility from "src/hooks/product/usePostCommonFacility";
import { useQueryParams } from "src/hooks/useQueryParams";
import LimitFrom from "./LimitFrom";
import { useParams } from "react-router-dom";

export interface EditObj {
  isUnLimitReservation: boolean;
  isMonthlyUnLimit: boolean;
  monthlyTime: string | number;
  isDailyUnLimit: boolean;
  dailyTime: string | number;
  isAllowOverReservation: boolean;
}

type Props = {
  space: CommonFacilityModel;
  spaceType: SpaceTypeT;
  onClose: () => void;
};

const SpaceUsageLimitModal = ({ space, spaceType, onClose }: Props) => {
  const { queryParams } = useQueryParams<{ id?: string }>();
  const params = useParams();
  const productId = queryParams.id ? queryParams.id : params.id;

  const { mutate } = usePostCommonFacility();

  const [editObj, setEditObj] = useState<EditObj>({
    //무제한 여부
    isUnLimitReservation: space.isUnlimitedReservation ?? false,
    //월 이용한도 체크여부
    isMonthlyUnLimit: space.isLimitedMonthlyReservation ?? false,
    monthlyTime: space.monthlyServiceMinutes === -1 ? "" : space.monthlyServiceMinutes ?? "",
    //일 이용한도 체크여부
    isDailyUnLimit: space.isLimitedDailyReservation ?? false,
    dailyTime: space.dailyServiceMinutes === -1 ? "" : space.dailyServiceMinutes ?? "",
    //초과 예약 여부
    isAllowOverReservation: space.isAllowedOverReservation ?? false,
  });

  if (!productId) {
    return null;
  }

  const handleSubmit = async () => {
    const data: ProductBuildingCommonFacilitySaveListReq = [
      {
        id: space.productBuildingCommonFacilityId, // 상품 건물-공용공간ID (수정/삭제 시 필수)
        cmdType: "U",
        buildingCommonFacilityId: space.id?.toString(), // 건물-공용공간IDspace.id?.toString()

        isUnlimitedReservation: editObj.isUnLimitReservation, // 예약무제한여부
        isAllowedOverReservation: editObj.isAllowOverReservation, // 초과예약허용여부
        isLimitedMonthlyReservation: editObj.isMonthlyUnLimit, // 월별예약제한사용여부
        monthlyServiceMinutes: editObj.monthlyTime.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime.toString()), // 월별제공시간(분)
        isLimitedDailyReservation: editObj.isDailyUnLimit, // 일별예약제한사용여부
        dailyServiceMinutes: editObj.dailyTime.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime.toString()), // 일별예약제한시간(분)
      },
    ];

    mutate({ productId: productId, productBuildingCommonFacilitySaveList: data }, { onSuccess: () => onClose() });
  };

  return <LimitFrom spaceType={spaceType} editObj={editObj} setEditObj={setEditObj} onClose={onClose} handleSubmit={handleSubmit} />;
};

export default SpaceUsageLimitModal;
