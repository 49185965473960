// 방문자 Table Columns
import { Cell } from "react-table";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import { BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { formatVisitorMobileNumber } from "../../utils";
import { VisitorDateCell } from "./VisitorDateCell";
import { VisitorLinkCell } from "./VisitorLinkCell";
import BaseEmail from "src/components/BaseEmail";
import { VISIT_PURPOSE_MAP, VISIT_TYPE_MAP } from "../../constants";
import BaseNewTabLink from "src/components/BaseNewTabLink";

export const columns = [
  {
    Header: "방문신청번호",
    accessor: "visitApplyNumber",
    width: 130,
    sticky: "left",
    Cell: (props: Cell<VisitorListModel>) => <VisitorLinkCell {...props} />,
  },
  {
    Header: "상태",
    accessor: "visitStatus",
    sticky: "left",
    width: 90,
    Cell: ({ value }: Cell<VisitorListModel>) => {
      return (
        <div
          className={`${
            (value === "VISIT_USE" && "status A") ||
            (value === "VISIT_PAUSE" && "status B") ||
            (value === "VISIT_CLOSE" && "status D") ||
            (value === "VISIT_DELETE" && "status D")
          }`}
        >
          {(value === "VISIT_USE" && "사용") ||
            (value === "VISIT_PAUSE" && "일시정지") ||
            (value === "VISIT_CLOSE" && "종료") ||
            (value === "VISIT_DELETE" && "삭제")}
        </div>
      );
    },
  },

  {
    Header: "방문자 명",
    accessor: "visitorName",
    width: 130,
    Cell: (props: Cell<VisitorListModel>) => {
      return (
        <div className="flex-center-start w-100">
          {props.row.original.visitType === "SELF_REGIST" ? "-" : <BaseTooltip contents={props.value} isSingleLine={true} />}
        </div>
      );
    },
  },
  {
    Header: "방문자 휴대폰",
    accessor: "visitorMobileNumber",
    width: 160,
    Cell: ({ value }: Cell<VisitorListModel>) => {
      const phoneNumber = formatVisitorMobileNumber(value);
      return <div className="flex-center-start w-100">{phoneNumber}</div>;
    },
  },
  {
    Header: "방문자 이메일",
    accessor: "visitorEmail",
    width: 160,
    Cell: ({ row }: Cell<VisitorListModel>) => {
      if (row.original.visitorEmail) {
        return (
          <div className="flex-center-start w-100">
            {row.original.visitorEmail} {row.original.visitorEmailLang === "en" ? "(영문)" : "(국문)"}
          </div>
        );
      }

      return <div className="flex-center-start w-100">{"-"}</div>;
    },
  },
  {
    Header: "방문시작일시",
    accessor: "visitStartTime",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },
  {
    Header: "방문종료일시",
    accessor: "visitEndTime",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },

  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
    Cell: (props: Cell<VisitorListModel>) => {
      return <p>{props.value}</p>;
    },
  },
  {
    Header: "공간 상품 Id",
    accessor: "spaceProductId",
    width: 120,
    Cell: ({ value }: Cell<VisitorListModel>) => {
      const path = PagePath.product.detail.replace(":id", value);
      return <BaseNewTabLink path={path} value={value} className="w-100 text-left" />;
    },
  },
  {
    Header: "공간 상품명",
    accessor: "spaceProductName",
    width: 180,
    Cell: ({ value }: Cell<VisitorListModel>) => (
      <div className="flex-center-start w-100">
        <BaseTooltip contents={value} />
      </div>
    ),
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },
  {
    Header: "등록 ID",
    accessor: "createdBy",
    width: 110,
    Cell: ({ value }: Cell<VisitorListModel>) => (
      <div className="flex-center-start w-100">
        <BaseEmail value={value ? value : "-"} />
      </div>
    ),
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 110,
    Cell: (props: Cell<VisitorListModel>) => <VisitorDateCell {...props} />,
  },
];
