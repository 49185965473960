import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { buildingMediaServiceType } from "../../building-types";
import ImageCategoryDetail from "./components/ImageCategoryDetail";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const buildingMediaTypes: MediaServiceType[] = buildingMediaServiceType;

const BuildingImagesDetail = () => {
  const params = useParams();
  const buildingId: number | undefined = Number(params.id);

  const navigate = useNavigate();
  const location = useLocation();
  //========= Building ============
  const [images, setImages] = useState<Array<MediaFile>>([]);

  const { isAuthority } = usePartnerAuthority();

  const { executeAsync: getBuildingImages } = useApiOperation(getPrImageAsync);

  // 이미지 불러오기
  const getProductImagesApi = useCallback(
    async (serviceId: number) => {
      const data = {
        serviceId,
        mediaServiceTypes: buildingMediaServiceType,
      };

      const response = await getBuildingImages(data);
      if (response.status >= 200 || response.status < 300) {
        const result = response.data.data.content;

        setImages(result || []);
      }
    },
    [getBuildingImages],
  );

  useEffect(() => {
    if (buildingId) {
      getProductImagesApi(buildingId);
    }
  }, [getProductImagesApi, buildingId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"이미지"} />
            {buildingMediaTypes && buildingMediaTypes.map((type) => <ImageCategoryDetail key={type} type={type} images={images} />)}
          </article>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("BUILDING", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.building.form}?id=${buildingId}${location.search.replace("?", "&")}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BuildingImagesDetail;
