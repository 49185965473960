import _ from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { addGroup, editGroup, getChargeItemsGroupDetail } from "src/api/chargeItems/chargeItems-api";
import { ChargeItems } from "src/api/chargeItems/chargeItems-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";

interface Props {
  setTitle: Function;
}
const BasicInfo = ({ setTitle }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openErrorModal } = useErrorModal();
  const { openToast } = useToast();

  const { executeAsync: getGroupDetail } = useApiOperation(getChargeItemsGroupDetail);
  const { executeAsync: addGroupItem } = useApiOperation(addGroup);
  const { executeAsync: editGroupItem } = useApiOperation(editGroup);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  let id = queryParams.id ? queryParams.id : useParams.prototype.id;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const [ctMntGroup, setCtMntGroup] = useState<ChargeItems>({
    buildingId: "",
    buildingName: "",
    ctMntGroup: {
      isUse: false,
      isDeleted: false,
    },
  });

  const onChangeBuilding = useCallback(
    (building: any) => {
      let temp = _.cloneDeep(ctMntGroup);
      temp.buildingId = building.id;
      temp.buildingName = building.buildingName;

      setCtMntGroup(temp);

      setIsOpen(false);
    },
    [ctMntGroup],
  );
  const onChangeValue = useCallback(
    (groupName: string) => {
      console.log("groupName", groupName);
      let temp = _.cloneDeep(ctMntGroup);
      temp.ctMntGroup.groupName = groupName;
      console.log("temp", temp);
      setCtMntGroup(temp);
    },
    [ctMntGroup],
  );
  const moveDetail = useCallback(() => {
    const path =
      PagePath.chargeItems.detail.replace(":id", id) + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [id, navigate, queryParams]);

  const groupDetail = useCallback(
    async (groupId: number) => {
      const response: any = await getGroupDetail({ groupId });
      console.log("form detail", response);
      if (response.status >= 200 && response.status <= 299) {
        setCtMntGroup(response.data.data.content);
        setTitle(response.data.data.content.ctMntGroup.groupName);
      }
    },
    [getGroupDetail, setTitle],
  );

  const addGI = useCallback(async () => {
    ctMntGroup.ctMntGroup.buildingId = ctMntGroup.buildingId;
    ctMntGroup.ctMntGroup.buildingName = ctMntGroup.buildingName;
    console.log("addGi request ctMntGroup", ctMntGroup);
    const response: any = await addGroupItem(ctMntGroup);
    console.log("addGi", response);
    if (response.status >= 200 && response.status < 300) {
      const path = PagePath.chargeItems.detail.replace(":id", response.data.data.ctMntGroup.groupId);
      navigate(path);
      openToast({ content: "정상적으로 저장 되었습니다." });
    }
  }, [addGroupItem, ctMntGroup, navigate, openToast]);

  const editGI = useCallback(async () => {
    const response: any = await editGroupItem(ctMntGroup);
    console.log("editGi", response);
    if (response.status >= 200 && response.status < 300) {
      const path = PagePath.chargeItems.detail.replace(":id", response.data.data.ctMntGroup.groupId);
      navigate(path);
      openToast({ content: "정상적으로 저장 되었습니다." });
    }
  }, [ctMntGroup, editGroupItem, navigate, openToast]);

  const onSave = useCallback(async () => {
    setIsOpen3(false);

    if (ctMntGroup.buildingId && ctMntGroup.buildingName) {
      if (ctMntGroup.ctMntGroup.groupId) {
        editGI();
      } else {
        addGI();
      }
    } else {
      setIsOpen2(true);
    }
  }, [addGI, ctMntGroup.ctMntGroup.groupId, editGI]);

  useEffect(() => {
    if (id) {
      groupDetail(Number(id));
    }
  }, [id, groupDetail]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 건물 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  {ctMntGroup.buildingId && (
                    <div className="base-chip flex-center mr8">
                      <>
                        <span className="ml6">
                          [{ctMntGroup?.buildingId}]{ctMntGroup?.buildingName}
                        </span>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="base-remove bg-gray ml2"
                        />
                      </>
                    </div>
                  )}
                  <BaseButton title="선택" className="color-white" onClick={() => setIsOpen(true)} />
                  <SearchBuildingPopup buildingName={""} isOpen={isOpen} onClick={onChangeBuilding} onClose={() => setIsOpen(false)} />
                </div>
              </div>
            </section>
            {/* 부과항목 그룹 명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">부과항목 그룹 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <BaseInput
                    className="mr8"
                    type="text"
                    name="buildingName"
                    value={ctMntGroup?.ctMntGroup.groupName || ""}
                    onChange={onChangeValue}
                  />
                </div>
              </div>
            </section>
          </article>
        </div>
        <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => onSave()} onClose={() => setIsOpen3(false)}>
          <p>저장 하시겠습니까?</p>
        </BaseModal>
        <BaseModal isOpen={isOpen2} btnRightTitle="확인" onClick={() => setIsOpen2(false)}>
          <p>건물은 필수 선택 요소입니다.</p>
        </BaseModal>
        {/* <BaseModal
        isOpen={isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={(e?: any) => {}}
        onClose={() => setIsOpen(false)}
      >
        <p>"사용안함"으로 전환하시겠습니까?</p>
                    </BaseModal> */}
        <BaseModal isOpen={isOpen4} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => moveDetail()} onClose={() => setIsOpen4(false)}>
          <p>상세페이지로 이동하시겠습니까?</p>
        </BaseModal>
      </div>

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeItems.list)} />
          {id && <BaseButton title="상세" className="color-white size-large ml10" onClick={() => setIsOpen4(true)} />}
        </div>
        <div className="right-area">
          <BaseButton title="저장" className=" size-large" onClick={() => setIsOpen3(true)} />
        </div>
      </div>
    </>
  );
};
export default BasicInfo;
