import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Contractor, DetailTotalData } from "src/api/contract/contract-types";
import { BaseButton, BaseInput } from "src/components";
import SearchMemberPopup from "src/pages/commonPopup/SearchMemberPopup";
import { formatPhoneNumber } from "src/utils/common-util";
import BaseSection from "../../../../components/layout/BaseSection";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  saveData?: any;
  setSaveData?: any;
  productDetail?: any;
}

const ContractorInfoEdit = ({ detailData, saveData, setSaveData }: ContractBasicProp) => {
  const [mflag, setMflag] = useState(false);

  const [member, setMemeber] = useState<Contractor>();

  const onCheckMemberInfo = () => {
    setMflag(true);
  };

  const onChangeMember = useCallback(
    (member: any) => {
      console.log("member", member);
      setMemeber(member);
      const temp = _.cloneDeep(saveData);
      temp.applicantMobile = member.phoneNumber;
      temp.email = member.email;
      temp.applicantMemberNo = member.memberNo;
      temp.applicantName = member.nickname;

      setSaveData(temp);

      setMflag(false);
    },
    [saveData, setSaveData],
  );

  useEffect(() => {
    if (saveData) {
      console.log("saveData", saveData);
      const temp = _.cloneDeep(saveData);
      let mem = {
        phoneNumber: temp.applicantMobile,
        memberNo: temp.applicantMemberNo,
        nickname: temp.applicantName,
        email: temp.email,
      };
      setMemeber(mem);
    }
  }, [saveData]);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <h2>신청자 정보</h2>
      </div>
      <div className="flex-center">
        <BaseSection
          title="회원번호"
          required={true}
          children={
            <div className="flex-row flex-center-start">
              {member?.memberNo && (
                <div className="minmax140 mr10">
                  <p>{member?.memberNo}</p>
                </div>
              )}
              <BaseButton
                title={member?.memberNo ? "변경" : "조회"}
                onClick={() => onCheckMemberInfo()}
                className="color-white"
                disabled={saveData.productId === undefined || saveData.productId === 0}
              />
              {mflag && <SearchMemberPopup isOpen={mflag} memberInfo={member} onClick={onChangeMember} onClose={() => setMflag(false)} />}

              {/* 컨트롤 룸은 memberNo로 체크한다. */}
              <p className="text-red600 font12">
                {/* {memberInfo?.id === undefined && errors?.contractMember?.memberNo && errors?.contractMember?.memberNo.message} */}
              </p>
            </div>
          }
        />

        <BaseSection
          title="휴대폰"
          value={member?.phoneNumber !== undefined ? formatPhoneNumber(decodeURIComponent(String(member?.phoneNumber))) : "-"}
        />
      </div>
      <div className="flex-center">
        <BaseSection title="이메일" value={member?.email !== undefined ? decodeURIComponent(String(member?.email)) : "-"} />
        <BaseSection
          title="신청자명"
          children={
            <div className="minmax160">
              {member?.memberNo !== undefined ? (
                <BaseInput
                  name={"applicantName"}
                  value={saveData.applicantName}
                  onChange={(value: string) => {
                    const temp = _.cloneDeep(saveData);
                    temp.applicantName = value;
                    setSaveData(temp);
                  }}
                />
              ) : (
                "-"
              )}
            </div>
          }
        />
      </div>
    </article>
  );
};

export default ContractorInfoEdit;
