import React, { useEffect, useState } from "react";
import { ProductModel } from "src/api/product/product-types";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { MaxReservTimeDayMap, MaxReservTimeMonthMap, renderTime } from "../libs";
import AllSpaceUsageLimitModal, { SpaceTypeReqDataMap } from "../modal/AllSpaceUsageLimitModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { BaseButton } from "src/components";
import { useModal } from "src/recoil/modalState/hook";

type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
};
const UsageLimitView = ({ product, spaceType }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const { isAuthority } = usePartnerAuthority();

  return (
    <div>
      <div className="flex-center-between mt20">
        <p className="font15 font-weight-semibold">{SpaceTypeLabel[spaceType]} 전체 이용한도</p>
        <div className="flex-center">
          {isAuthority("PRODUCT", "w") && (
            <BaseButton
              title={"전체 이용한도 설정"}
              className="color-white"
              onClick={() =>
                setAbstractModalZ1({
                  size: "medium",
                  isOpen: true,
                  children: (
                    <>
                      <section className="base-abstract-modal__title border-bottom">
                        <div>
                          <h1>{SpaceTypeLabel[spaceType]}전체 이용한도 설정</h1>
                          <ul className="base-list mt20">
                            <li className="font14">전체 {SpaceTypeLabel[spaceType]}의 예약 이용한도를 설정해 주세요.</li>
                          </ul>
                        </div>
                      </section>
                      <AllSpaceUsageLimitModal product={product} spaceType={spaceType} onClose={() => setAbstractModalZ1({ isOpen: false })} />
                    </>
                  ),
                })
              }
            />
          )}
        </div>
      </div>
      <section className="reservation-wrap__gray-board">
        <div>
          <p className="index">월 이용한도</p>
          <div className="flex-center value">
            {renderTime("USED", product[MaxReservTimeMonthMap[spaceType]], product[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit])}
          </div>
        </div>
        <div>
          <p className="index">일 이용한도</p>
          <div className="flex-center value">
            {renderTime("USED", product[MaxReservTimeDayMap[spaceType]], product[SpaceTypeReqDataMap[spaceType].isDailyUnLimit])}
          </div>
        </div>
      </section>
    </div>
  );
};

export default UsageLimitView;
