/* eslint-disable array-callback-return */
import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { BaseDatePicker, BaseInput, BaseSelect, BaseTooltip } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat, numberToStringWithComma } from "src/utils";
const lastDetail: any = {
  adjustAmount: 0,
  baseAmount: 0,
  billId: "0",
  dtlId: "0",
  dtlOrder: 0,
  isDeleted: false,
  itemName: "",
  remark: "",
  supplyAmount: 0,
  taxAmount: 0,
  totalAmount: 0,
};
const pgCodes: any = [
  { label: "신용카드", value: "creditcard" },
  { label: "가상계좌", value: "virtualaccount" },
  { label: "계좌이체", value: "banktransfer" },
];
const Accordion = ({ data, setData, viewOnly, spaceProductType }: any) => {
  const { openToast } = useToast();
  // const [isAllExpanded, setIsAllExpanded] = useState(false);
  const addDetailLine = useCallback(
    (billId: string) => {
      const temp = _.cloneDeep(data);

      temp.forEach((bill: any) => {
        if (bill.billId === billId) {
          const sort: any = _.sortBy(bill.billDetailList, "dtlOrder");
          const find = sort[sort.length - 1];
          if (find) {
            lastDetail.billId = find.billId;
            lastDetail.dtlOrder = find.dtlOrder + 1;
            bill.billDetailList.push(lastDetail);
          } else {
            lastDetail.billId = bill.billId;
            lastDetail.dtlOrder = 1;
            bill.billDetailList.push(lastDetail);
          }
        }
      });
      setData(temp);
    },
    [data, setData],
  );
  const delDetailLine = useCallback(
    (billId: string, detail: any) => {
      const temp = _.cloneDeep(data);

      temp.forEach((bill: any) => {
        if (bill.billId === billId) {
          if (bill.billDetailList.length > 1) {
            if (detail.dtlId !== "0") {
              bill.billDetailList.forEach((dl: any) => {
                if (_.isEqual(detail, dl)) {
                  dl.isDeleted = true;
                }
              });
            } else {
              const filterList = bill.billDetailList.filter((dl: any) => !_.isEqual(detail, dl));
              bill.billDetailList = filterList;
            }
          } else {
            openToast({ content: "세부 목록은 1개 이상이어야 합니다.", isSystemToast: true });
            return;
          }
        }
      });
      setData(temp);
    },
    [data, setData],
  );

  const totalDetailadjustAmount = useCallback((detailList: any, type: string) => {
    let sum = 0;
    detailList.forEach((dl: any) => {
      sum += Number(dl[type]);
    });
    return sum;
  }, []);

  const checkBillDueDate = (dueDate: string) => {
    if (moment().isBefore(moment(dueDate))) {
      return true;
    } else {
      return false;
    }
  };
  const changeText = (pgcode: string) => {
    const find = pgCodes.find((code: any) => code.value === pgcode);
    if (find) {
      return find.label;
    } else {
      return "";
    }
  };
  const checkPgCodes = useMemo(() => {
    return spaceProductType !== "TIME_COURT"
      ? [
          { label: "신용카드", value: "creditcard", isDisabled: false },
          { label: "계좌이체", value: "banktransfer", isDisabled: false },
        ]
      : [
          { label: "신용카드", value: "creditcard", isDisabled: false },
          {
            label: "현금(가상계좌)",
            value: "virtualaccount",
            isDisabled: true,
          },
        ];
  }, [spaceProductType]);

  return (
    <>
      <section className="base-abstract-modal__title">
        <div className="flex-row flex-center-between w-100">
          <h1>청구 금액 {"확인"}</h1>
          {/* <p
            className="font14 ml20 text-underline cursor-pointer"
            onClick={() => {
              const temp = _.cloneDeep(data);
              temp.forEach((bi: any) => {
                bi.isView = !isAllExpanded;
              });
              setIsAllExpanded(!isAllExpanded);
              setData(temp);
            }}
          >
            전부 {isAllExpanded ? "숨기기" : "보기"}
          </p> */}
        </div>
      </section>
      <section className="base-abstract-modal__contents px30">
        <div className="base-table sticky">
          <div className="header">
            <div className="base-table__tr flex-row flex-center-between">
              <div className="base-table__th px0 minmax160">항목명</div>
              {/* <div className="base-table__th px0 minmax70">상세</div> */}
              <div className="base-table__th px0 minmax200">결제수단</div>
              {spaceProductType !== "TIME_COURT" ? (
                <>
                  <div className="base-table__th px0 minmax100">
                    <div className="flex-center font14">
                      청구일자
                      <BaseTooltip
                        contents={""}
                        tooltip={"주말 또는 공휴일인 경우 다음 영업일로 적용됩니다."}
                        type={"normal"}
                        className={"tooltip__trigger-icon"}
                      />
                    </div>
                  </div>
                  <div className="base-table__th px0 minmax120">
                    <div className="flex-center font14">
                      납부기한
                      {/* <BaseTooltip
                    contents={""}
                    tooltip={"주말 또는 공휴일인 경우 다음 영업일로 적용됩니다."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  /> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="base-table__th px0 minmax220">
                  <div className="flex-center font14">
                    납부기한
                    {/* <BaseTooltip
                    contents={""}
                    tooltip={"주말 또는 공휴일인 경우 다음 영업일로 적용됩니다."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  /> */}
                  </div>
                </div>
              )}
              <div className="base-table__th px0 minmax120">
                <div className="flex-center font14">
                  총 판매가(원)
                  <BaseTooltip contents={""} tooltip={"부가세 포함 금액입니다."} type={"normal"} className={"tooltip__trigger-icon"} />
                </div>
              </div>
              <div className="base-table__th px0 minmax120">총 할인/할증 금액(원)</div>
              <div className="base-table__th px0 minmax120">총 청구금액(원)</div>
            </div>
          </div>

          <div className="body">
            {data.map((bill: any, index: number) => {
              return (
                <div key={index}>
                  <div className={`base-table__tr flex-row flex-center-between`}>
                    <div className="base-table__td minmax160">
                      <p className="ml20 ">
                        {bill.billTitle}{" "}
                        {spaceProductType !== "TIME_COURT" && "(회차: " + bill.billOrder + "/" + data[data.length - 1].billOrder + ")"}
                      </p>
                    </div>
                    {/* <div className="base-table__td minmax70">
                      <p
                        className="text-underline cursor-pointer  text-center"
                        onClick={() => {
                          const temp = _.cloneDeep(data);
                          temp.forEach((bi: any) => {
                            if (bi.billId === bill.billId) {
                              bi.isView = !bi.isView;
                            }
                          });
                          setData(temp);
                        }}
                      >
                        {bill.isView ? "숨기기" : "보기"}
                      </p>
                    </div> */}

                    {spaceProductType !== "TIME_COURT" ? (
                      <>
                        <div className="base-table__td minmax200">
                          {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                            <BaseSelect
                              menuPosition="fixed"
                              menuPortalTarget={document.body}
                              stateOptions={checkPgCodes}
                              setStateValue={(value: string) => {
                                const temp = _.cloneDeep(data);
                                temp.forEach((bi: any) => {
                                  if (bi.billId === bill.billId) {
                                    bi.pgcode = value;
                                  }
                                });
                                setData(temp);
                              }}
                              value={bill.pgcode}
                              isDisabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                            />
                          ) : (
                            <p>{changeText(bill.pgcode)}</p>
                          )}
                        </div>
                        <div className="base-table__td minmax100">
                          <p>{moment(bill.billIssueDate).format(ViewYmdFormat.YYYY_MM_DD)}</p>
                        </div>
                        <div className="base-table__td minmax120">
                          {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                            <BaseDatePicker
                              name={"billDueDate"}
                              type={"date"}
                              selectedDate={bill.billDueDate ? moment(bill.billDueDate).toDate() : moment().toDate()}
                              setDate={(value: Date) => {
                                const dump = _.cloneDeep(data);
                                dump.forEach((dp: any) => {
                                  if (dp.billId === bill.billId) {
                                    dp.billDueDate = moment(value).format(YmdFormat.WITH_TIME_ZONE);
                                  }
                                });
                                setData(dump);
                              }}
                              disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                              minDate={moment(bill.billIssueDate).add(1, "days").toDate()}
                              maxDate={moment(bill.billIssueDate).add(1, "months").subtract(1, "days").toDate()}
                            />
                          ) : (
                            <p>{moment(bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD)}</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="base-table__td minmax170">
                          {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                            <BaseSelect
                              menuPosition="fixed"
                              menuPortalTarget={document.body}
                              stateOptions={checkPgCodes}
                              setStateValue={(value: string) => {
                                const temp = _.cloneDeep(data);
                                temp.forEach((bi: any) => {
                                  if (bi.billId === bill.billId) {
                                    bi.pgcode = value;
                                  }
                                });
                                setData(temp);
                              }}
                              value={bill.pgcode}
                              isDisabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                            />
                          ) : (
                            <p>{changeText(bill.pgcode)}</p>
                          )}
                        </div>
                        <div className="base-table__td minmax180">
                          {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                            <BaseDatePicker
                              name={"billDueDate"}
                              type={"full"}
                              selectedDate={bill.billDueDate ? moment(bill.billDueDate).toDate() : moment().toDate()}
                              setDate={(value: Date) => {
                                const dump = _.cloneDeep(data);
                                dump.forEach((dp: any) => {
                                  if (dp.billId === bill.billId) {
                                    dp.billDueDate = moment(value).format(YmdFormat.WITH_TIME_ZONE);
                                  }
                                });
                                setData(dump);
                              }}
                              disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                              minDate={moment(bill.billIssueDate).add(1, "days").toDate()}
                              maxDate={moment(bill.billIssueDate).add(1, "months").subtract(1, "days").toDate()}
                            />
                          ) : (
                            <p>{moment(bill.billDueDate).format(ViewYmdFormat.FULL)}</p>
                          )}
                        </div>
                      </>
                    )}
                    <div className="base-table__td minmax120">
                      {numberToStringWithComma(totalDetailadjustAmount(bill.billDetailList, "baseAmount"))}
                    </div>
                    <div className="base-table__td minmax120">
                      {numberToStringWithComma(totalDetailadjustAmount(bill.billDetailList, "adjustAmount"))}
                    </div>
                    <div className="base-table__td minmax120">
                      {numberToStringWithComma(
                        totalDetailadjustAmount(bill.billDetailList, "baseAmount") + totalDetailadjustAmount(bill.billDetailList, "adjustAmount"),
                      )}
                    </div>
                  </div>
                  {bill.isView && (
                    <div className={`base-table__tr flex-row flex-center-between mb30`}>
                      <div className="base-table__innerTd text-center">
                        <div className="base-table nested-table">
                          <div className="base-table__tr flex-row flex-center-between">
                            <div className="base-table__th minmax80">
                              <div className="ic_ho"></div>
                              {viewOnly === false && checkBillDueDate(bill.billDueDate) && bill.billPayStatus !== "PAYMENT_SUCCESS" && (
                                <button
                                  className="base-add-btn mr10"
                                  onClick={() => addDetailLine(bill.billId)}
                                  disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                                />
                              )}
                            </div>
                            <div className="base-table__th px0 minmax200">항목명</div>
                            <div className="base-table__th minmax200">
                              <div className="flex-center font14">판매가(원)</div>
                            </div>
                            <div className="base-table__th minmax200">할인/할증 금액(원)</div>
                            <div className="base-table__th minmax200">청구금액(원)</div>
                          </div>

                          <div>
                            {bill.billDetailList.map((detail: any, idx: number) => {
                              if (!detail.isDeleted) {
                                return (
                                  <div key={idx}>
                                    <div className={`base-table__tr flex-row flex-center-between`}>
                                      <div className="base-table__td minmax80">
                                        <div className="ic_ho"></div>
                                        {viewOnly === false && checkBillDueDate(bill.billDueDate) && bill.billPayStatus !== "PAYMENT_SUCCESS" && (
                                          <button
                                            onClick={() => delDetailLine(bill.billId, detail)}
                                            disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                                            className="base-erase-btn size-large"
                                          />
                                        )}
                                      </div>

                                      <div className="base-table__td minmax200">
                                        {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                                          <BaseInput
                                            name="itemName"
                                            value={String(detail.itemName) || undefined}
                                            onChange={(value: string) => {
                                              const temp = _.cloneDeep(data);

                                              temp.forEach((bl: any) => {
                                                if (bill.billId === bl.billId) {
                                                  bl.billDetailList.forEach((bdl: any) => {
                                                    if (_.isEqual(detail, bdl)) {
                                                      bdl.itemName = value;
                                                    }
                                                  });
                                                }
                                              });
                                              setData(temp);
                                            }}
                                            disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                                          />
                                        ) : (
                                          <p>{detail.itemName}</p>
                                        )}
                                      </div>

                                      <div className="base-table__td minmax200">
                                        {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                                          <BaseInput
                                            name="baseAmount"
                                            className="text-right"
                                            value={String(detail.baseAmount) || undefined}
                                            onChange={(value: string) => {
                                              value = _.replace(value, new RegExp(" ", "g"), "");

                                              if (value === "") {
                                                value = "0";
                                              } else {
                                                if (value.charAt(0) === "0") {
                                                  value = value.substring(1, value.length - 1);
                                                }
                                              }
                                              if (value.indexOf("-") > -1) {
                                                openToast({ content: "0보다 큰 값만 입력 가능합니다.", isSystemToast: true });
                                                return;
                                              }
                                              const temp = _.cloneDeep(data);

                                              temp.forEach((bl: any) => {
                                                if (bill.billId === bl.billId) {
                                                  bl.billDetailList.forEach((bdl: any) => {
                                                    if (_.isEqual(detail, bdl)) {
                                                      bdl.baseAmount = value;
                                                    }
                                                  });
                                                }
                                              });
                                              setData(temp);
                                            }}
                                            disabled={Number(detail.dtlId) !== 0 || bill.billPayStatus === "PAYMENT_SUCCESS"}
                                          />
                                        ) : (
                                          <p>{numberToStringWithComma(detail.baseAmount)}</p>
                                        )}
                                      </div>

                                      <div className="base-table__td minmax200">
                                        {viewOnly === false && checkBillDueDate(bill.billDueDate) ? (
                                          <BaseInput
                                            name="adjustAmount"
                                            className="text-right"
                                            value={String(detail.adjustAmount) || undefined}
                                            onChange={(value: string) => {
                                              if (value.match(/^(-?)[0-9]+$/) || value === "" || value === "-") {
                                                const temp = _.cloneDeep(data);

                                                temp.forEach((bl: any) => {
                                                  if (bill.billId === bl.billId) {
                                                    bl.billDetailList.forEach((bdl: any) => {
                                                      if (_.isEqual(detail, bdl)) {
                                                        bdl.adjustAmount = value;
                                                      }
                                                    });
                                                  }
                                                });
                                                setData(temp);
                                              }
                                            }}
                                            onBlur={() => {
                                              const temp = _.cloneDeep(data);

                                              temp.forEach((bl: any) => {
                                                if (bill.billId === bl.billId) {
                                                  bl.billDetailList.forEach((bdl: any) => {
                                                    if (bdl.adjustAmount === "" || bdl.adjustAmount === "-") {
                                                      bdl.adjustAmount = "0";
                                                    }
                                                  });
                                                }
                                              });
                                              setData(temp);
                                            }}
                                            disabled={bill.billPayStatus === "PAYMENT_SUCCESS"}
                                          />
                                        ) : (
                                          <p>{numberToStringWithComma(detail.adjustAmount)}</p>
                                        )}
                                      </div>
                                      <div className="base-table__td minmax200">
                                        {numberToStringWithComma(Number(detail.baseAmount) + Number(detail.adjustAmount))}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordion;
