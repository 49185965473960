/* eslint-disable array-callback-return */

import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { editTaxInvoiceNumber, retryPayment } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, BaseTooltip } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import StopModal from "../modal/StopModal";
import StorageProcessingModal from "../modal/StorageProcessing/StorageProcessingModal";
import PaymentStatusView from "../components/PaymentStatusView";

const ScheduleInfoColumns: any = [
  {
    Header: "회차",
    accessor: "bill.billOrder",
    sticky: "left",
    width: 70,
    Cell: ({ value, row }: any) => {
      return <div>{value ? value : "-"} </div>;
    },
  },

  {
    Header: "청구서 명",
    accessor: "bill.billTitle",
    sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "사용기간",
    accessor: "bill.billStartTime",
    sticky: "left",
    width: 190,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const start = moment(row.original.bill.billStartTime).format(ViewYmdFormat.YYYY_MM_DD);
      const end = moment(row.original.bill.billEndTime).format(ViewYmdFormat.YYYY_MM_DD);
      return <p>{start + " - " + end}</p>;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  {
    Header: "수납확인",
    accessor: "recieve",
    sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, scheduleId, showBtn }: any) => {
      // const [prevStatus, setPrevStatus] = useState<any>("");
      const [isChange, setIsChange] = useState(false);

      const pDatas = useMemo(() => {
        const datas = {
          row,
          rows,
          callList,
          contractBaseInfo,
          scheduleId,
          showBtn,
          isChange,
          setIsChange,
          type: "schedule",
        };
        return datas;
      }, [callList, contractBaseInfo, isChange, row, rows, scheduleId, showBtn]);
      // console.info("mainRow", row);
      const checkShowPayBtn = useMemo(() => {
        return moment(moment(row.original.bill.billDueDate).format(ViewYmdFormat.FULL)).isAfter(moment().format(ViewYmdFormat.FULL));
      }, [row.original.bill]);
      return (
        <div>
          <div>
            {showBtn && row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ? (
              <BaseButton
                title={row.original.receiptBundle.receiptList.length > 0 ? "수정" : "수납처리"}
                className="color-white size-small"
                disabled={
                  (Number(row.original.bill.totalAmount) > 0 || row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true) || checkShowPayBtn
                }
                onClick={() => setIsChange(true)}
              />
            ) : (
              <BaseButton
                title={"수납확인"}
                className="color-white size-small"
                disabled={
                  (row.original.billReceipt !== null ? false : row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true) || checkShowPayBtn
                }
                onClick={() => setIsChange(true)}
              />
            )}
            {isChange && <StorageProcessingModal processData={pDatas} />}
          </div>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          기준금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.baseAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.baseAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          할인/할증
          <br />
          금액(원)
        </p>
      );
    },
    accessor: "bill.adjustAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.adjustAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.totalAmount",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.totalAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },

  {
    Header: "청구일자",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부기한",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      let payLimitDay = row.original.bill.billDueDate;
      return <div>{payLimitDay ? moment(payLimitDay).format(ViewYmdFormat.FULL) : "-"} </div>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          승인번호
        </p>
      );
    },
    accessor: "TAX_PLUS",
    width: 220,
    Cell: ({ value, row, scheduleId, callList, showBtn }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const { id } = useParams();
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);
          setTaxInvoice(sorted[0]);
          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_PLUS";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          await callList();
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <div className="w-100 text-left mr10">
              {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== "" ? (
                <div>
                  <BaseTooltip contents={taxInvoice.invoiceNumber} isSingleLine={true} />
                  {showBtn && (
                    <BaseButton
                      title="수정"
                      disabled={Number(row.original.bill.totalAmount) === 0}
                      className="color-white size-small"
                      onClick={() => setView(true)}
                    />
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>

          <BaseModal
            className="dialog-modal small-size"
            isOpen={view}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => {
              setView(false);

              setTaxInvoice(original);
            }}
            onClick={() => saveTaxInvoice()}
          >
            <>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>세금계산서 발행 정보 수정</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left font12 text-gray500">
                        <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                        <p>* 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록 해주세요.</p>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-gray600">국세청 승인번호</span>
                        </div>
                      </td>
                      <td>
                        <div className="py10">
                          <div className="minmax300 mb8">
                            <BaseInput
                              name={"taxInvoiceNumber"}
                              value={String(taxInvoice?.invoiceNumber)}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(taxInvoice);
                                temp.invoiceNumber = value;
                                setTaxInvoice(temp);
                              }}
                              maxLength={24}
                              // disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          전송일자
        </p>
      );
    },
    accessor: "taxInvoiceIssueDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      const [original, setOriginal] = useState<any>();
      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);

          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);
      return <p>{original && original?.createdDate !== "" ? moment(original?.createdDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          계산서
          <br />
          승인번호
        </p>
      );
    },
    accessor: "TAX_NONE",
    width: 220,
    Cell: ({ value, row, scheduleId, callList, showBtn }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const { id } = useParams();
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType !== "TAX_PLUS");

          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);
          setTaxInvoice(sorted[0]);
          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_NONE";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          await callList();
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <div className="w-100 text-left mr10">
              {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== "" ? (
                <>
                  <BaseTooltip contents={taxInvoice.invoiceNumber} isSingleLine={true} />
                  {showBtn && (
                    <BaseButton
                      title="수정"
                      disabled={Number(row.original.bill.totalAmount) === 0}
                      className="color-white size-small"
                      onClick={() => setView(true)}
                    />
                  )}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>

          <BaseModal
            className="dialog-modal small-size"
            isOpen={view}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => {
              setView(false);

              setTaxInvoice(original);
            }}
            onClick={() => saveTaxInvoice()}
          >
            <>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>계산서 발행 정보 수정</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left font12 text-gray500">
                        <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                        <p>* 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록 해주세요.</p>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-gray600">국세청 승인번호</span>
                        </div>
                      </td>
                      <td>
                        <div className="py10">
                          <div className="minmax300 mb8">
                            <BaseInput
                              name={"taxInvoiceNumber"}
                              value={String(taxInvoice?.invoiceNumber)}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(taxInvoice);
                                temp.invoiceNumber = value;
                                setTaxInvoice(temp);
                              }}
                              maxLength={24}
                              // disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          계산서
          <br />
          전송일자
        </p>
      );
    },
    accessor: "cashBillIssueDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, showBtn }: any) => {
      const [original, setOriginal] = useState<any>();
      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType !== "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);

          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);
      return <p>{original && original?.createdDate !== "" ? moment(original?.createdDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: "tid",
    accessor: "payment.tid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowTId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];

        billShowTId = payment.tid !== "" ? payment.tid : "-";

        if (payment.paymentCancel !== null) {
          billShowTId = payment.paymentCancel.tid;
        }
      }

      return <p>{billShowTId}</p>;
    },
  },
  {
    Header: "cid",
    accessor: "payment.cid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowCId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];

        billShowCId = payment.cid !== "" ? payment.cid : "-";

        if (payment.paymentCancel != null) {
          billShowCId = payment.paymentCancel.cid;
        }
      }

      return <p>{billShowCId}</p>;
    },
  },
  {
    Header: "즉시결제",
    accessor: "instantPayment",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, rows, showBtn, scheduleId, callList, contractBaseInfo, basicInfo }: any) => {
      const [confirmFlag, setConfirmFlag] = useState(false);
      const [prevStatus, setPrevStatus] = useState<any>("");
      const { id } = useParams();
      const { openToast } = useToast();

      const { executeAsync: rtp } = useApiOperation(retryPayment);

      const retryPay = useCallback(async () => {
        setConfirmFlag(false);
        const askReturn: any = await rtp({ billId: Number(row.original.bill.billId) });

        if (askReturn.status >= 200 && askReturn.status <= 299 && String(askReturn.data.data.content.result) === "true") {
          await callList();
          openToast({ content: "즉시결제가 정상처리 되었습니다.", isSystemToast: true });
        } else {
          openToast({ content: "즉시결제가 실패하였습니다. 관리자에게 문의해주세요.", isSystemToast: true });
        }
      }, [callList, openToast, row.original.bill.billId, rtp]);
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);

      const checkPrevStatus = useMemo(() => {
        let temp = _.cloneDeep(prevStatus);
        const finded = rows.find((r: any) => Number(r.id) === Number(row.id) - 1);

        if (finded) {
          //console.log(finded.original.bill.billPayStatus);
          temp = finded.original.bill.billPayStatus;
          setPrevStatus(finded.original.bill.billPayStatus);
        }
        return temp;
      }, [prevStatus, row.id, rows]);
      const getBillKey = useMemo(() => {
        let billkey: string | undefined = undefined;
        const find = basicInfo.scheduleList.find((obj: any) => obj.supplyType === "RENTAL");

        if (find) {
          return find.billkey;
        }

        return billkey;
      }, [basicInfo]);

      return (
        <>
          {showBtn ? (
            <>
              <BaseButton
                title={"즉시결제"}
                className="color-white size-small"
                disabled={
                  row.original.bill.pgcode !== "creditcard" ||
                  getBillKey === undefined ||
                  getBillKey === "" ||
                  row.original.receiptBundle.receiptList.length > 0 ||
                  row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ||
                  moment(today).isSameOrBefore(moment(billDate)) ||
                  (row.original.bill.billPayStatus === "PAYMENT_READY" && (checkPrevStatus === "" || checkPrevStatus === "PAYMENT_SUCCESS")
                    ? false
                    : true)
                }
                onClick={() => setConfirmFlag(true)}
              />
              <BaseModal
                title={"즉시 결제하시겠습니까?"}
                isOpen={confirmFlag}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => retryPay()}
                onClose={() => setConfirmFlag(false)}
              ></BaseModal>
            </>
          ) : (
            "-"
          )}
        </>
      );
    },
  },

  {
    Header: "거래일시",
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(ViewYmdFormat.FULL) : "-";
    },
  },
  {
    Header: "연체 기간(일)",
    accessor: "receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{value ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      // 연체 표시 : -
      let billIssuDay = moment(row.original.bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD);
      let today = moment().format(ViewYmdFormat.YYYY_MM_DD);

      if (row.original.bill.billPayStatus === "PAYMENT_READY" && moment(today).isAfter(moment(billIssuDay))) {
        value = "PAYMENT_DELAY";
      }

      return <PaymentStatusView value={value} />;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
];
export default ScheduleInfoColumns;
