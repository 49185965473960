import qs from "qs";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsTitle } from "src/components";

import PagePath from "src/pagePath.json";

import FormBasicInfo from "./components/BasicInfo";
import ChargeItemsDetailForm from "./components/ChargeItemsDetailForm";

const ChargeItemsForm = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [title, setTitle] = useState("");

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "detailCost", label: "세부 부과항목" },
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  let id = queryParams.id ? queryParams.id : useParams.prototype.id;
  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      // if (items && items?.id) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
      // }
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (id) {
        if (newQueryParams?.tab) {
          delete newQueryParams.tab;
        }
        newQueryParams.tab = tab.value;

        // query parameter 에 tab 추가
        const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
        navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
      }
    },
    [navigate, location, queryParams, id],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      console.log("clickBreadCrumb", crumb);
      if (crumb.value === "chargeItem") {
        navigate(PagePath.chargeItems.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "chargeItem", label: "관리비" }];

  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <div>
      <ContentsTitle
        title="관리비 - 부과항목관리"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[{ title: "id", value: String(queryParams.id) || "" }]}
      />

      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <FormBasicInfo setTitle={setTitle} />}
        {activeTab?.value === "detailCost" && <ChargeItemsDetailForm />}
      </div>
    </div>
  );
};
export default ChargeItemsForm;
