import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getContractDetailBasicInfo } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { postVisitors } from "src/api/visitor/visitor-api";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import { parsedPhoneNumber } from "src/utils";
import { getVisitorListPath, getVistorDetailPath } from "src/utils/route-util";
import InvitationInfo from "../components/InvitationInfo";
import VisitorInfo from "../components/VisitorInfo";
import { INVITEABLE_CONTRACT_STEP } from "../constants";
import useVisitor from "../hooks/useVisitor";
import { VisitorAddForm } from "../visitor-types";
import { useQueryParams } from "src/hooks/useQueryParams";

function VisitorAdd() {
  const { setBaseModal } = useModal();
  const { fetchAuthorityReadonly, isAuthority } = usePartnerAuthority();
  const { contractManageId, contractId } = useParams();

  const location = useLocation();
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "상세정보",
      value: "basic",
      disabled: false,
    },
  ];

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0], [queryParams]);
  // 탭 클릭시 callback 함수

  const scrollContainer = useRef<HTMLDivElement>(null);
  const partner = useRecoilValue(globalPartnerState);
  const { invitationInfo, visitorInfoList } = useVisitor({ contractApplyNumber: contractManageId });
  const formRef = useRef<HTMLFormElement>(null);
  const useFormReturn = useForm<VisitorAddForm>({
    defaultValues: {
      visitorInfoList: [{ contractVisitorId: 0, invitationType: "phone", visitorEmailLang: "en" }],
    },
  });
  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useFormReturn;

  const {
    fields: formVisitorInfoList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visitorInfoList",
  });
  const { openToast } = useToast();
  const [modal, setModal] = useState({
    atLeastOneAlert: {
      isOpen: false,
      title: "1명 이상의 방문자 등록이 필요합니다.",
    },
    duplicateAlert: {
      isOpen: false,
      title: "중복으로 등록된 연락처가 있습니다.",
    },
  });

  const openModal = (modalName: keyof typeof modal) => {
    setModal((prev) => ({ ...prev, [modalName]: { ...prev[modalName], isOpen: true } }));
  };

  const closeModal = (modalName: keyof typeof modal) => {
    setModal((prev) => ({ ...prev, [modalName]: { ...prev[modalName], isOpen: false } }));
  };

  const { executeAsync: postVisitorsAsync } = useApiOperation(postVisitors);
  const { executeAsync: getContractDetailBasicInfoAsync } = useApiOperation(getContractDetailBasicInfo);

  const onSubmit = async (data: VisitorAddForm) => {
    if (data.visitorInfoList.length === 0) {
      openModal("atLeastOneAlert");
      return;
    }

    const contractVisitorList = data.visitorInfoList.map((visitorInfo) => {
      return {
        visitorName: visitorInfo.name || "",
        visitorEmail: visitorInfo.visitorEmail || undefined,
        visitorEmailLang: visitorInfo.visitorEmailLang || undefined,
        visitorMobileNumber: visitorInfo.phoneNumber ? parsedPhoneNumber(visitorInfo.phoneNumber!) : undefined,
        visitorCarNumber: visitorInfo.carNumber,
        visitStartTime: moment(data.invitationInfo.visitStartTime).format(),
        visitEndTime: moment(data.invitationInfo.visitEndTime).format(),
        meetingPlace: data.invitationInfo.meetingPlace,
        reservationId: data.invitationInfo.reservationId ? Number(data.invitationInfo.reservationId) : undefined,
        visitType: "INVITE_REGIST",
      };
    });

    console.log("contractVisitorList :>> ", contractVisitorList);

    if (partner?.id === undefined) throw new Error("partner id is undefined");
    if (invitationInfo.contractManageId === undefined) throw new Error("contractManageId is undefined");

    const result = await postVisitorsAsync({
      contractManageId: Number(invitationInfo.contractManageId),
      partnerId: Number(partner.id),
      contractVisitorList,
      // buildingId: invitationInfo.buildingId ? Number(invitationInfo.buildingId) : undefined, // be 에서 buildingId 제거됨
      // 추후 건물을 선택해야 할 떄 buildingId 다시 추가 예정
    });

    if (result.status === 200) {
      openToast({
        type: "SUCCESS",
        content: "방문자 초대가 완료되었습니다.",
      });
      navigate(
        getVistorDetailPath({
          visitApplyNumber: result.data.data.visitApplyNumber,
          contractApplyNumber: contractManageId || "",
        }),
      );
    } else {
      openToast({
        type: "FAIL",
        content: "방문자 초대에 실패하였습니다.",
      });
    }
  };

  useEffect(() => {
    setValue("invitationInfo", invitationInfo);
  }, [invitationInfo, setValue, visitorInfoList]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getContractDetailBasicInfoAsync({ id: Number(contractId) });

        const _contractApplyNumber = result.data.data.contractApplyNumber;
        const contractStep = result.data.data.contractStep as ContractStep;

        if (_contractApplyNumber !== contractManageId) {
          openToast({
            type: "FAIL",
            content: "잘못된 접근입니다.",
          });

          navigate(getVisitorListPath());
        }

        if (!INVITEABLE_CONTRACT_STEP.includes(contractStep)) {
          openToast({
            type: "FAIL",
            content: "초대할 수 없는 계약 상태입니다.",
          });

          navigate(getVisitorListPath() + `?contractApplyNumber=${contractManageId}&contractId=${contractId}`);
        }
      } catch (error) {
        openToast({
          type: "FAIL",
          content: "계약 정보를 불러오는데 실패하였습니다.",
        });

        navigate(getVisitorListPath());
      }
    })();
  }, [contractId]);

  const handleClickSaveButton = () => formRef.current?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));

  return (
    <div>
      <MetaTag isCreatePage />
      <ContentsTitle title="방문자 초대" tabs={tabs} activeTab={activeTab} />
      <div className="contents-container__scroll" ref={scrollContainer}>
        <form className="" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className="contents-container__wrap page-visitor">
            <InvitationInfo useFormReturn={useFormReturn} pageType="add" invitationInfo={invitationInfo} />
            <VisitorInfo
              pageType="add"
              append={append}
              remove={remove}
              control={control}
              formVisitorInfoList={formVisitorInfoList}
              scrollContainer={scrollContainer}
              useFormReturn={useFormReturn}
            />
          </div>
        </form>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton
            title="저장"
            className="size-large"
            onClick={async () => {
              (await trigger()) &&
                setBaseModal({
                  isOpen: true,
                  title: "저장하시겠습니까?",
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  onClick: () => {
                    handleClickSaveButton();
                    setBaseModal({ isOpen: false });
                  },
                });
            }}
          />
        </div>
      </div>
      {modal.atLeastOneAlert.isOpen && (
        <BaseModal isOpen={true} title={modal.atLeastOneAlert.title} onClick={() => closeModal("atLeastOneAlert")} btnRightTitle="확인" />
      )}
    </div>
  );
}
export default VisitorAdd;
