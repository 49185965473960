import { ContractStep } from "src/api/contract/contract-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { BaseButton } from "../BaseButton";
import { BaseModal } from "../BaseModal";
import BaseSectionTitle from "./BaseSectionTitle";
const NoEditStateNoS2: Array<string> = [ContractStep.APPLY_CANCEL, ContractStep.TERMINATE_RECEIVED, ContractStep.TERMINATE_COMPLETE];
const NoEditStateS2: Array<string> = NoEditStateNoS2.concat([
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.USE_COMPLETE,
]);

type props = {
  title: string;
  className?: string;
  children?: React.ReactNode;
  isEditable?: boolean;
  setIsEditable?: Function;
  onClickSave?: Function;
  commonModals?: any;
  popupState?: boolean;
  setPopupState?: any;
  editConfrim?: boolean;
  setEditConfrim?: any;
  disabledState?: [status: string, productType?: string];
  noHashLink?: boolean;
};

export const ArticleSection = ({
  title,
  className,
  children,
  isEditable,
  setIsEditable,
  onClickSave,
  commonModals,
  popupState,
  setPopupState,
  editConfrim,
  setEditConfrim,
  disabledState,
  noHashLink,
}: props) => {
  if (disabledState === undefined) {
    disabledState = ["", ""];
  }
  const { isAuthority } = usePartnerAuthority();

  return (
    <article className={className ? `${className} contents-container__wrap-article full-width` : "contents-container__wrap-article full-width"}>
      {isAuthority("CONTRACT", "w") && onClickSave && setIsEditable && (
        <div className="contents-container__edit-area">
          {isEditable ? (
            <div className="flex-center">
              <BaseButton type="button" className="size-small color-white mr10" title={"취소"} onClick={() => setIsEditable(false)} />
              <BaseButton type="button" className="size-small" title={"저장"} onClick={() => setEditConfrim(true)} />
            </div>
          ) : (
            <button
              className="base-edit-btn"
              disabled={
                disabledState && disabledState[1] !== "TIME_COURT"
                  ? NoEditStateNoS2.includes(disabledState && disabledState[0])
                    ? true
                    : false
                  : !NoEditStateS2.includes(disabledState && disabledState[0])
                  ? true
                  : false
              }
              onClick={() => setIsEditable(true)}
            />
          )}
        </div>
      )}
      <BaseSectionTitle title={title} noHashLink={noHashLink} />
      <section className="contents-container__1070">{children}</section>
      {popupState && (
        <BaseModal isOpen={popupState} btnRightTitle="확인" onClick={() => setPopupState(false)} title={commonModals.title}>
          {commonModals.subTitle && <p>{commonModals.subTitle}</p>}
          <p>{commonModals.message}</p>
        </BaseModal>
      )}
      {editConfrim && (
        <BaseModal
          isOpen={editConfrim}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            if (onClickSave) {
              onClickSave();
            }
          }}
          onClose={() => setEditConfrim(false)}
          title={title}
        >
          <p>저장하시겠습니까?</p>
        </BaseModal>
      )}
    </article>
  );
};
function openToast(arg0: { content: string; isSystemToast: boolean }) {
  throw new Error("Function not implemented.");
}
