import { Cell } from "react-table";
import { BizType, MbOrg } from "src/api/organization/org-types";
import BaseEmail from "src/components/BaseEmail";
import { BaseTooltip } from "src/components/BaseTooltip";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { companyRegistrationHypen, corpHypen } from "src/utils";

const OrgColumns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    sticky: "left",
    Cell: (props: Cell<MbOrg>) => {
      const path = `${PagePath.organization.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <DetailLink to={path} className="text-underline">
          {props.value}
        </DetailLink>
      );
    },
  },
  {
    Header: "구분",
    accessor: "classifications",
    width: 130,
    sticky: "left",
    Cell: ({ row, value }: Cell<MbOrg>) => {
      const changeKorean = value.map((item: string) => {
        return item === "GENERAL_BIZ" ? "일반 사업자" : "소유주";
      });

      return changeKorean.join(", ") || "-";
    },
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 180,
    sticky: "left",
    Cell: (props: Cell<MbOrg>) => {
      return <BaseTooltip className="text-left w-100" contents={props.value ? props.value : "-"}></BaseTooltip>;
    },
  },
  {
    Header: "사업 형태",
    accessor: "bizType",
    width: 140,
    sticky: "left",
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <div>{props.value === BizType.CORP ? "법인사업자" : "개인사업자"}</div>
        </>
      );
    },
  },
  {
    Header: "사업자 등록번호",
    accessor: "bizRegNo",
    width: 140,
    Cell: (props: Cell<MbOrg>) => {
      return <div className="w-100 text-left">{companyRegistrationHypen(props.value) || "-"}</div>;
    },
  },
  {
    Header: "법인 등록번호",
    accessor: "corRegNo",
    width: 140,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <div className="w-100 text-left">
          <span>{corpHypen(props.value) || "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 120,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 120,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 작성자",
    accessor: "lastModifiedBy",
    width: 180,
    Cell: (props: Cell<MbOrg>) => {
      return (
        <div className="w-100 text-left email">
          <BaseEmail value={props.value} />
        </div>
      );
    },
  },
];

export default OrgColumns;
