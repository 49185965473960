import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ImageRequestModal } from "src/types/product";
import { ApiResponse, MediaFile } from "../public-types";
import {
  ProductAddModel,
  ProductBuildingCommonFacilitySaveListReq,
  ProductBuildingCommonFacilitySaveListRes,
  ProductDetailType,
  ProductEditModel,
  ProductListData,
  ProductListParams,
  ProductMediaFileList,
  ProductUpdateResponseData,
} from "./product-types";
import { ProductStatusT } from "src/pages/product/product-types";
import { BuildingModel } from "../building/building-types";

// RPP01. 공간상품 기본정보 목록
export async function getProductListAsync(axios: AxiosInstance, params?: ProductListParams): Promise<AxiosResponse<ApiResponse<ProductListData>>> {
  return await axios.get<ApiResponse<ProductListData>>(ApiPath.product.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// RPP02. 공간상품 기본정보 상태 변경

export async function productStatusAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    status: ProductStatusT;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.status.replace("{id}", String(params?.productId));
  return await axios.patch(path, { product: { status: params?.status } });
}

/* 
  RPP03. 공간상품 기본정보 등록
*/
export async function createProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductAddModel;
  },
): Promise<AxiosResponse<ApiResponse<{ content?: string }>>> {
  return await axios.post(ApiPath.product.add.basicInfo, params);
}

/* 
  RPP04. 공간상품 정보 수정
*/
export async function updateProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductEditModel;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  if (!params?.product.id) {
    throw Error("id is not found.");
  }
  const path = ApiPath.product.edit.replace("{id}", params.product.id.toString());
  return await axios.put(path, params);
}

/* 
  RPP05. 공간상품 기본정보 상세
*/
export async function getProductDetailAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductDetailType>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  const path = ApiPath.product.detail.replace("{id}", params.productId.toString());
  return await axios.get(path);
}

// RPP06. 공간상품 기본정보 삭제
export async function deleteProductsAsync(
  axios: AxiosInstance,
  params?: {
    productId: any;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.delete.replace("{id}", String(params?.productId));
  return await axios.delete(path);
}

// 공간상품 이미지 등록
export const postPrImageAsync = async (axios: AxiosInstance, parmas?: Array<MediaFile>): Promise<AxiosResponse<ApiResponse<Array<MediaFile>>>> => {
  return await axios.post("/api/ctrl/partners/{partnerId}/pr/mediaFile", parmas);
};

// RPM01. 공통 미디어파일 목록
export const getPrImageAsync = async (
  axios: AxiosInstance,
  params?: ImageRequestModal,
): Promise<AxiosResponse<ApiResponse<ProductMediaFileList>>> => {
  const imageParams = {
    serviceId: Number(params?.serviceId),
    mediaServiceType: String(params?.mediaServiceTypes.join()),
  };

  return axios.get(`/api/ctrl/partners/{partnerId}/pr/mediaFile`, { params: imageParams });
};

// 공간상품 이미지 삭제
export const deletePrImageAsync = async (
  axios: AxiosInstance,
  params?: {
    ids: Array<string>;
  },
) => {
  const { ids } = params || {};

  return axios.delete("/api/ctrl/partners/{partnerId}/pr/mediaFile", { data: { ids } });
};

/* 
  RPP22. 공간상품 빌딩 공용공간 등록/수정/삭제
*/
export async function mutationProductBuildingCommonFacility(
  axios: AxiosInstance,
  params?: {
    productBuildingCommonFacilitySaveList: ProductBuildingCommonFacilitySaveListReq;
    productId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<ProductBuildingCommonFacilitySaveListRes>>> {
  if (!params?.productId) {
    throw Error("id is not found.");
  }

  const path = ApiPath.product.productBuildingCommonFacility.replace("{id}", params.productId.toString());
  return await axios.post(path, { productBuildingCommonFacilitySaveList: params.productBuildingCommonFacilitySaveList });
}

/* 
  RPB12. 공간상품 빌딩 조회
*/
export async function buildingsDetails(
  axios: AxiosInstance,
  params?: {
    building: {
      ids: string[];
    };
  },
): Promise<AxiosResponse<ApiResponse<{ content?: [{ building: BuildingModel }] }>>> {
  const path = ApiPath.building.details;
  return await axios.post(path, params);
}
