import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { deletePriceRulesAsync, getPriceRulesListAsync } from "src/api/price/price-api";
import { PriceRules, PriceRulesModel } from "src/api/price/price-types";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, numberToStringWithComma } from "src/utils";
import { findPriceRuleOptionToText, findPriceRuleToText } from "../../price-types";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

/* 
  공지사항 상세
 */
const BasicInfoDetail = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { id } = useParams();
  // const { openToast } = useToast();
  const { isAuthority } = usePartnerAuthority();
  const [priceDetail, setPriceDetail] = useState<
    PriceRulesModel & { providerName?: string; buildingId?: number; buildingName?: string; facilityName?: string }
  >();
  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: getPriceRulesDetail } = useApiOperation(getPriceRulesListAsync);
  const { executeAsync: deleteRules } = useApiOperation(deletePriceRulesAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);

  useEffect(() => {
    const fetchApi = async (id: string) => {
      const response = await getPriceRulesDetail({ priceRuleIdList: id, page: 0, size: 1 });

      if (response.status >= 200 && response.status <= 299) {
        if (response.data.data.content.length > 0) {
          let priceData = response.data.data.content[0];
          let providerData: ProviderModel = {};
          let facilityData: CommonFacilityModel = {};

          const providerResponse = await getProvider({ providerId: priceData?.providerId?.toString() });
          if (providerResponse.status >= 200 && providerResponse.status <= 299) {
            if (providerResponse.data.data.content.length > 0) {
              providerData = providerResponse?.data?.data?.content[0];
            }
          }
          if (priceData.buildingCommonFacilityId) {
            const buildingFacilityResponse = await getCommonFacilityList({
              searchType: "ID",
              keywordList: priceData?.buildingCommonFacilityId?.toString(),
            });
            if (buildingFacilityResponse.status >= 200 && buildingFacilityResponse.status <= 299) {
              if (buildingFacilityResponse.data.data.content.length > 0) {
                facilityData = buildingFacilityResponse?.data?.data?.content[0];
              }
            }
          }
          console.log("facilityData", facilityData);
          const priceDetail = {
            ...priceData,
            providerName: providerData?.providerName,
            buildingId: facilityData?.buildingId,
            buildingName: facilityData?.buildingName,
            facilityName: facilityData?.facilityName,
          };

          setPriceDetail(priceDetail);
        }
      }
    };
    if (id) {
      fetchApi(id);
    }
  }, [id, getPriceRulesDetail, getProvider, getCommonFacilityList]);

  // 공지사항 삭제
  const fetchDeleteRules = async () => {
    if (id) {
      const { data } = await deleteRules({ idList: id });
      if (data.data) {
        openToast({ content: "정상적으로 삭제 되었습니다" });
        navigate(`${PagePath.price.list}`);
      }
    }
    setConfirmModal({ isOpen: false });
  };
  return (
    <>
      <MetaTag subTitle={String(priceDetail?.providerId) || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap price-page">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"기본정보"} />
            {/* 기본정보 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">프로바이더</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="font14">
                    {priceDetail?.providerId ? (
                      <BaseNewTabLink
                        path={`${PagePath.provider.detail.replace(":id", priceDetail.providerId.toString())}`}
                        value={priceDetail.providerName || "-"}
                        ellipsis
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>요금구분</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{priceDetail?.priceRuleType ? findPriceRuleToText(priceDetail?.priceRuleType) : "-"}</p>
                {priceDetail?.priceRuleType !== PriceRules.PRICE_RULE_SPECIFIC && (
                  <div className="gray-box mt20">
                    <div className="gray-box__grid">
                      <div className="gray-box__grid-index">
                        <p>기준요금 유형</p>
                      </div>
                      <div className="gray-box__grid-contents">
                        <p>{priceDetail?.priceRuleType ? findPriceRuleOptionToText(priceDetail?.priceRuleType) : "-"}</p>
                      </div>
                    </div>
                    <div className="gray-box__grid mt20">
                      <div className="gray-box__grid-index">
                        <p>공용공간</p>
                      </div>
                      <div className="gray-box__grid-contents">
                        <div>
                          {priceDetail?.buildingName && priceDetail?.facilityName ? (
                            <div className="two-depth-link">
                              <BaseNewTabLink
                                path={`${PagePath.building.detail.replace(":id", `${priceDetail.buildingId}`)}`}
                                value={priceDetail.buildingName || "-"}
                                isAddChevronPositionRight
                              />
                              <BaseNewTabLink
                                path={`${PagePath.building.detail.replace(":id", `${priceDetail.buildingId}`)}?tab=facility`}
                                value={priceDetail.facilityName || "-"}
                              />
                            </div>
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">요금정책명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14">{priceDetail?.name || "-"}</p>
                </div>
              </div>
            </section>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">적용일자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {priceDetail?.startDate
                      ? `${moment(priceDetail?.startDate).format(ViewYmdFormat.YYYY_MM_DD)} ~ ${moment(priceDetail?.endDate).format(
                          ViewYmdFormat.YYYY_MM_DD,
                        )}`
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">적용시간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-center-start flex-row minmax240">
                    <p>{priceDetail?.startTime ? `${priceDetail?.startTime} ~ ${priceDetail?.endTime}` : "-"}</p>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">원가 (원/30분)</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p> {priceDetail?.cost !== undefined ? `${numberToStringWithComma(priceDetail?.cost)}원` : "-"}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">판매가 (원/30분)</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p> {priceDetail?.cost !== undefined ? `${numberToStringWithComma(priceDetail?.price)}원` : "-"}</p>
                </div>
              </section>
            </div>
          </article>

          <RegistrationInformation data={priceDetail} />

          <AdminMemoV3 serviceId={Number(id)} serviceType={ServiceTypes.SERVICE_PRICE_RULE} />
          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.type === "DELETE" && fetchDeleteRules();
            }}
            title={confirmModal.message}
          ></BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            title={alertModal.message}
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          ></BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="left-area d-flex">
          {isAuthority("BASIC", "d") && (
            <BaseButton
              title="삭제"
              className="color-white size-large mr10"
              disabled={PriceRules.PRICE_RULE_PROVIDER ? true : false}
              onClick={() => {
                setConfirmModal({ isOpen: true, message: "삭제 하시겠습니까?", type: "DELETE" });
              }}
            />
          )}
          {isAuthority("BASIC", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.price.form}?id=${id}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;

//여기
