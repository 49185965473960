import moment from "moment";
import { Cell } from "react-table";
import DetailLink from "src/components/DetailLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat, numberToStringWithComma } from "src/utils";
import { findPriceRuleOptionToText, findPriceRuleToText } from "../../price-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";

/* 
  공용공간 예약 목록 columns
*/
const priceListColumn = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.price.detail.replace(":id", `${props.value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {props.value}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "프로바이더",
    accessor: "providerName",
    width: 270,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.provider.detail.replace(":id", `${props.row.original.providerId}`)}`;

      return (
        <BaseNewTabLink path={detailPath} value={props.value || "-"} ellipsis className="w-100 text-left"/>
      );
    },
  },
  {
    Header: "요금구분",
    accessor: "priceRuleType",
    width: 140,
    Cell: ({ value }: Cell<any>) => {
      return <p>{findPriceRuleToText(value)}</p>;
    },
  },
  {
    Header: "기준요금 유형",
    accessor: "priceRuleType2",
    width: 140,
    Cell: (props: Cell<any>) => {
      const priceRuleType = props.row.original.priceRuleType;
      return <p>{findPriceRuleOptionToText(priceRuleType)}</p>;
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 200,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || '-'}</p>
        </div>
      );
    },
  },
  {
    Header: "공용공간",
    accessor: "facilityName",
    width: 150,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left">
          <p>{value || '-'}</p>
        </div>
      );
    },
  },
  {
    Header: "요금정책명",
    accessor: "name",
    width: 200,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || '-'}</p>
        </div>
      );
    },
  },
  {
    Header: "적용일자",
    accessor: "startDate",
    width: 150,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD) : ""} - <br />
          {props.row.original.endDate ? moment(props.row.original.endDate).format(ViewYmdFormat.YYYY_MM_DD) : ""}
        </div>
      );
    },
  },
  {
    Header: "적용시간",
    accessor: "endTime",
    width: 150,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.row.original.startTime ? props.row.original.startTime : ""} -
          {props.value ? props.value : ""}
        </div>
      );
    },
  },
  {
    Header: "원가 (원/30분)",
    accessor: "cost",
    width: 130,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
  {
    Header: "판매가 (원/30분)",
    accessor: "price",
    width: 130,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
];

export default priceListColumn;
