import moment from "moment";

import { Cell } from "react-table";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { BaseTooltip } from "src/components";
import BaseEmail from "src/components/BaseEmail";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { ExternalServiceType, getStatusText, ReservationState } from "../../reservation-types";
import { ViewYmdFormat } from "src/utils";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import DetailLink from "src/components/DetailLink";
import BaseNewTabLink from "src/components/BaseNewTabLink";
/* 
  공용공간 예약 목록 columns
*/
const ReservationColumns = [
  {
    Header: "id",
    accessor: "id",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}?&groupID=${props.row.original.parentId || 0}`}>
            {props.value}
          </DetailLink>
          {/* <Link className="text-underline" to={`${detailPath}?&groupID=${props.row.original.parentId || 0}`}>
            {props.value}
          </Link> */}
        </div>
      );
    },
  },
  {
    Header: "그룹Id",
    accessor: "parentId",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div>
          {props.value && props.value !== "0" ? (
            <Link className="text-underline" to={`${detailPath}?groupID=${props.row.original.parentId || 0}`}>
              {props.value}
            </Link>
          ) : (
            <span>-</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "예약 경로",
    accessor: "inboundChannel",
    sticky: "left",
    width: 90,
    Cell: (props: Cell<ReservationListModel>) => {
      let externalServiceType = "";

      if (props.value === ExternalServiceType.TAAP) {
        externalServiceType = "TAAP";
      } else if (props.value === ExternalServiceType.GC) {
        externalServiceType = "GC";
      } else if (props.value === ExternalServiceType.OC) {
        externalServiceType = "OC";
      } else {
        externalServiceType = "알 수 없음";
      }

      return (
        <div>
          <span>{externalServiceType ? externalServiceType : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "신청번호",
    accessor: "contract.applyNumber",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.contract.detail.replace(":id", `${props.row.original.contract.id}`)}`;
      return <div>{props.value ? <BaseNewTabLink path={detailPath} value={props.value} className="w-100 text-left" /> : "-"}</div>;
    },
  },
  {
    Header: "상태",
    accessor: "status.code",
    width: 80,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const statueText = getStatusText(value);
      return (
        <div
          className={`${
            (value === ReservationState.ACCEPTED && "status A") ||
            (value === ReservationState.CANCELLED && "status D") ||
            (value === ReservationState.ACKNOWLEDGED && "status B") ||
            (value === ReservationState.INCOMPLETE && "status D")
          }`}
        >
          {statueText}
        </div>
      );
    },
  },
  {
    Header: "건물 명",
    accessor: "facility.building.name",
    width: 180,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left">
          <span>{value}</span>
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "facility.floor",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const floorText = value > 0 ? "지상" : "지하";
      return (
        <div>
          <span>
            {floorText} {Math.abs(value)}층
          </span>
        </div>
      );
    },
  },
  {
    Header: "공간타입",
    accessor: "facility.type",
    width: 90,
    Cell: (props: Cell<ReservationListModel>) => {
      if (props.value === "MEETING_ROOM") {
        return <span>회의실</span>;
      } else if (props.value === "DESK") {
        return <span>좌석</span>;
      } else {
        return <span>편의시설</span>;
      }
    },
  },
  {
    Header: "공간 명",
    accessor: "facility",
    width: 180,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value.name || ""} />
        </div>
      );
    },
  },
  {
    Header: "예약일",
    accessor: "start",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <div>
          <span>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "예약 시간",
    accessor: "end",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const startTime = moment(props.row.original.start).format("HH:mm");
      const endTime = moment(props.row.original.end).format("HH:mm");

      return (
        <div>
          <span>{startTime}</span> ~ <span>{`${endTime === "00:00" ? "24:00" : endTime}`}</span>
        </div>
      );
    },
  },
  {
    Header: "회원번호",
    accessor: "organizer.memberNo",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column">
          <span>{value || "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "휴대폰번호",
    accessor: "organizer.phoneNumber",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column">
          <PhoneNumberColumn phoneNumber={value ? value : "-"} />
        </div>
      );
    },
  },
  {
    Header: "닉네임",
    accessor: "organizer.displayName",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "이메일",
    accessor: "organizer.userEmail",
    width: 180,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column w-100 text-left">
          <BaseEmail value={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const createdDate = props.row.original.createdDate;
      return <BaseTooltip contents={createdDate} type="date" />;
    },
  },
];

export default ReservationColumns;
