import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import BuildingInfo from "../detail/components/buildingInfo/BuildingInfo";
import UseProductInfo from "../detail/components/useProductInfo/UseProductInfo";
import BasicInfoForm from "./components/basicInfo/BasicInfoForm";

const OrganizationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orgData, setOrgData] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);

  usePartnerAuthority({ checkPageAccess: true });
  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "useProduct", label: "신청/계약" },
    { value: "buildingInfo", label: "건물" },
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const orgId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      if (!queryParams.id && tab.value !== "basicInfo") {
        setModalOpen(true);
        return;
      }

      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // // title 에 건물명 바인딩
  let name;
  if (orgData && orgData?.organization?.name) {
    name = orgData?.organization?.name;
  }

  return (
    <>
      {orgId ? <MetaTag subTitle={orgData?.organization?.name} /> : <MetaTag isCreatePage />}
      <div>
        {modalOpen && (
          <BaseModal title={"기본정보가 저장되지 않았습니다"} isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)}></BaseModal>
        )}
        <ContentsTitle title="사업자" tabs={tabs} activeTab={activeTab} clickTab={clickTab} detailInfo={[{ title: "id", value: orgId || "" }]} />

        {activeTab?.value === "basicInfo" && <BasicInfoForm setOrgData={setOrgData} />}
        {activeTab?.value === "useProduct" && <UseProductInfo orgId={String(orgId)} />}
        {activeTab?.value === "buildingInfo" && <BuildingInfo orgId={String(orgId)} />}
      </div>
    </>
  );
};

export default OrganizationForm;
