import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { getQuestionDetailListAsync, getQuestionListAsync } from "src/api/question/qeustion-api";
import {
  CsCategoryTypeUnionType,
  QuestionAnswerModel,
  QuestionListModel,
  QuestionQueryParams,
  QuestionSiteUnionType,
} from "src/api/question/question-type";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepicker from "src/components/RangeDatepicker";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import { PagePath } from "src/pages/product/details";
import ExcelDownloadButton from "src/pages/visitor/list/ExcelDownloadButton";
import { useCsOptions } from "src/pages/voc/hooks/useCsOptions";
import { YmdFormat, downloadExcel, numberToStringWithComma, qsParse } from "src/utils";
import { csCategoryOptions, questionSearchTypes, siteOptions, statusOptions } from "../question-types";
import QuestionColmns from "./column/QuestionColmns";

const QuestionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const { isAuthority } = usePartnerAuthority();

  const queryParams: QuestionQueryParams = useMemo(() => {
    const _queryParams: QuestionQueryParams = qsParse(location.search);
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (!_queryParams?.csCategoryType) {
      _queryParams.csCategoryType = "";
    }

    if (!_queryParams?.csTypeId) {
      _queryParams.csTypeId = "";
    }

    if (_queryParams.statusList && typeof _queryParams.statusList === "string") {
      _queryParams.statusList = _queryParams.statusList;
    }

    if (_queryParams?.keywordList) {
      _queryParams.keywordList = decodeURIComponent(_queryParams.keywordList);
    }
    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.startDate !== undefined && _queryParams?.startDate !== "") {
      dump[0] = moment(_queryParams?.startDate).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.endDate !== undefined && _queryParams?.endDate !== "") {
      dump[1] = moment(_queryParams?.endDate).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);
    return _queryParams;
  }, [location.search]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [isBuildingModalOpen, setIsBuildingModalOpen] = useState({ isOpen: false });

  const [params, setParams] = useState<QuestionQueryParams>({ ...queryParams });

  const [questions, setQuestions] = useState<Array<QuestionListModel>>([]);

  // voc 유형
  const { options } = useCsOptions(params.csCategoryType as CsCategoryTypeUnionType | undefined);

  const csTypeOptions = [
    {
      label: "전체",
      value: "",
    },
    ...options,
  ];

  // 상담관리 기본정보 목록 api
  const { executeAsync: getQuestionList } = useApiOperation(getQuestionListAsync);

  // 상담관리 상세정보 목록 api
  const { executeAsync: getQuestionDetailList } = useApiOperation(getQuestionDetailListAsync);

  // 건물 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  /**
   * cs 기본정보 리스트에 건물명과 답변내용 바인딩하여 리턴
   * @param passParams
   * @returns { csList , meta }
   */
  const fetchQuestionList = async (passParams: QuestionQueryParams) => {
    const getQuestionListRes = await getQuestionList(passParams);
    if (getQuestionListRes.status >= 200 && getQuestionListRes.status <= 299) {
      // 상담관리 기본정보 목록
      const csList = getQuestionListRes?.data?.data?.content ?? [];

      // result 에서 bulding id 만 추출
      const buildingIds = csList.map((item) => item.buildingId).filter((id) => id);
      // bulidingId 중복없애서 호출
      const uniqueBuildingIds = [...new Set(buildingIds)];
      // console.log('object :>> ', object);

      const buildingList =
        uniqueBuildingIds && uniqueBuildingIds.length > 0
          ? await getBuildings({
              page: 0,
              size: 999999,
              id: uniqueBuildingIds.join(","),
            }).then((res) => res?.data?.data?.content ?? [])
          : [];

      const csDetailList =
        csList && csList.length > 0
          ? await getQuestionDetailList({
              page: 0,
              size: 999999,
              ids: csList.map((data) => data.id).join(","),
            }).then((res) => res?.data?.data?.content ?? [])
          : [];

      // cs 기본정보 리스트에 건물명과 답변내용 바인딩
      const newCsList = csList.map((csItem) => {
        const buildingName = buildingList.find((building) => String(csItem.buildingId) === String(building.id))?.buildingName;
        const answerList = csDetailList.find((csDetail) => String(csItem.id) === String(csDetail.id))?.answerList;

        return { ...csItem, buildingName: buildingName, answerList: answerList };
      });

      return { csList: newCsList, meta: getQuestionListRes.data.meta };
    }
  };

  useEffect(() => {
    setParams({ ...queryParams });

    fetchQuestionList(queryParams).then((data) => {
      setQuestions(data?.csList ?? []);
      setPageMeta(data?.meta.pageMeta);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 날짜 검색
  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let start = "";
    let end = "";

    if (dateRange[0] !== null) {
      start = moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
      setParams({ ...params, ...{ startDate: start } });
    }
    if (dateRange[1] !== null) {
      end = moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59.999+09:00";
      setParams({ ...params, ...{ startDate: start, endDate: end } });

      navigateWithQueryParams({ ...params, ...{ startDate: start, endDate: end } });
    }
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QuestionQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      console.log("passParams :>> ", passParams);
      console.log("newQueryParamStr :>> ", newQueryParamStr);

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params, queryParams],
  );

  // 엑셀 다운로드
  const onDownloadWorkOrderExcel = async () => {
    const newParams = { ...params };
    delete newParams.size;

    const data = await fetchQuestionList(newParams);
    if (data?.csList && data?.csList.length > 0) {
      const downloadData = data.csList?.map((item) => {
        // 상담관리 상태
        const QuestionStatusMap = {
          STATUS_UNRECOGNIZED: "정의되지 않은 상태",
          QUESTION_WAIT: "접수",
          QUESTION_PROCEEDING: "처리중",
          QUESTION_HOLD: "보류",
          QUESTION_COMPLETE: "완료",
          QUESTION_CANCELED: "취소",
        } as const;

        const SiteMap = {
          SITE_HOMEPAGE: "홈페이지",
          SITE_EXTERNAL: "외부 플랫폼",
          SITE_ETC: "기타",
          SITE_TAAP_SPACE: "TaapSpace",
          SITE_TAAP: "Taap",
        } as const;

        // 가장 최근 답변을 리턴
        const answer = item.answerList?.sort((a: QuestionAnswerModel, b: QuestionAnswerModel) => Number(b.id) - Number(a.id))?.[0].description;

        const formatData = {
          id: item.id ?? "-",
          status: QuestionStatusMap[item.status as keyof typeof QuestionStatusMap] ?? "-",
          csCategoryType: item.csCategoryType ?? "-",
          site: SiteMap[item.site as keyof typeof SiteMap] ?? "-",
          csTypeName: item.csTypeName ?? "-",
          summary: item.summary ?? "-",
          description: item.description ?? "-",
          answer: answer,
          buildingName: item.buildingName ?? "-",
          assigneeEmail: item.assigneeEmail ?? "-",
          cost: item.cost ? numberToStringWithComma(item.cost) : "-",
          expense: item.expense ? numberToStringWithComma(item.expense) : "-",
          modifiedDate: item.modifiedDate ? moment(item.modifiedDate).format(YmdFormat.YYYY_MM_DD_HH_MM) : "-",
          modifiedBy: item.modifiedBy ?? "-",
        };

        return formatData;
      });
      try {
        const fileName =
          params.startDate && params.endDate
            ? `ctrl.room_CS목록_${moment(params.startDate).format("YYYYMMDD")}~${moment(params.endDate).format("YYYYMMDD")}_${moment().format(
                "YYYYMMDDHHmmss",
              )}`
            : `ctrl.room-CS목록-전체_${moment().format("YYYYMMDDHHmmss")}`;

        await downloadExcel({
          data: downloadData || [],
          fileName,
          header: QuestionColmns.map((column) => column.Header),
        });

        setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
      }
    }
  };
  return (
    <>
      <div className="page-product-list">
        <div className="contents-container__table">
          <div className="contents-container__search-wrap">
            <div className="left-area">
              <section>
                <div className="left-area__index">
                  <span>지정검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax140">
                    <BaseInput
                      type="text"
                      value={params?.buildingName || ""}
                      placeholder="건물명"
                      readonly={true}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsBuildingModalOpen({ isOpen: true });
                      }}
                      onClearClick={() => {
                        delete params.buildingId;
                        delete params.buildingName;
                        navigateWithQueryParams({ ...params, page: 0 });
                      }}
                    />
                    {isBuildingModalOpen.isOpen && (
                      <SearchBuildingPopup
                        buildingName={""}
                        isOpen={true}
                        onClick={(building: any) => {
                          setParams({ ...params, buildingId: String(building.id), buildingName: building.buildingName });
                          navigateWithQueryParams({
                            ...params,
                            buildingId: String(building.id), //
                            buildingName: building.buildingName,
                            page: 0,
                          });
                          setIsBuildingModalOpen({ isOpen: false });
                        }}
                        onClose={() => setIsBuildingModalOpen({ isOpen: false })}
                      />
                    )}
                  </div>
                </div>
              </section>
              <section>
                <div className="left-area__index">
                  <span>조건검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax201 ">
                    <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                  </div>
                  <div className="minmax120 mr8">
                    <BaseMultiSelect
                      stateOptions={statusOptions}
                      value={(params.statusList as any as string[]) ?? []}
                      setStateValue={(statusList: Array<{ value: string; label: string }>) => {
                        setParams({ ...params, page: 0, statusList: statusList.map((item) => item.value) as any as string });
                        navigateWithQueryParams({ ...params, page: 0, statusList: statusList.map((item) => item.value) as any as string });
                      }}
                      placeholder="상태"
                    />
                  </div>
                  <div className="minmax140 mr8">
                    <BaseSelect
                      placeholder="구분"
                      stateOptions={csCategoryOptions}
                      setStateValue={(csCategoryType: QuestionSiteUnionType) => {
                        setParams({ ...params, csCategoryType });
                        navigateWithQueryParams({ ...params, page: 0, csCategoryType });
                      }}
                      value={params.csCategoryType}
                    />
                  </div>

                  <div className="minmax140 mr8">
                    <BaseSelect
                      placeholder="유입경로"
                      stateOptions={siteOptions}
                      setStateValue={(siteList: QuestionSiteUnionType) => {
                        setParams({ ...params, siteList: siteList });
                        navigateWithQueryParams({ ...params, page: 0, siteList: siteList });
                      }}
                      value={params.siteList}
                    />
                  </div>

                  <div className="minmax120 mr8">
                    <BaseSelect
                      placeholder="유형"
                      stateOptions={csTypeOptions}
                      setStateValue={(csTypeOption: string) => {
                        console.log("csTypeOption :>> ", csTypeOption);
                        setParams({ ...params, csTypeId: csTypeOption });
                        navigateWithQueryParams({ ...params, page: 0, csTypeId: csTypeOption });
                      }}
                      value={csTypeOptions?.find((data) => data.value === params.csTypeId)?.value ?? "-"}
                    />
                  </div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={params.searchType}
                    inputValue={params?.keywordList ? String(params?.keywordList) : ""}
                    stateOptions={questionSearchTypes}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keywordList: string) => {
                      setParams({ ...params, keywordList: keywordList });
                    }}
                    onClearClick={() => {
                      const editParams = { ...params };
                      delete editParams.keywordList;
                      setParams(editParams);
                      navigateWithQueryParams({ ...params, page: 0, keywordList: "" });
                    }}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                  />
                </div>
              </section>
            </div>
            <div className="right-area">
              {isAuthority("CS", "w") && <BaseButton className="ml20" title="+ CS 등록" onClick={() => navigate(PagePath.question.form)} />}
            </div>
          </div>
          <BaseTable
            data={questions}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            pageIndex={Number(params?.page || 0)}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            orders={params?.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } }, "search");
              }
            }}
            disabledSortHeaders={["buildingName", "answerCnt"]}
            columns={QuestionColmns}
            children={
              <ExcelDownloadButton
                onClick={() => {
                  onDownloadWorkOrderExcel();
                }}
              >
                엑셀받기
              </ExcelDownloadButton>
            }
          />
        </div>
        {alertModal.isOpen && (
          <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })} title={alertModal.message}></BaseModal>
        )}
      </div>
    </>
  );
};

export default QuestionList;
