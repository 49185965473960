/* eslint-disable array-callback-return */

import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { editTaxInvoiceNumber, mntGetWeekDay, mntSendNts, retryPayment } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, BaseTooltip } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import PaymentStatusView from "../components/PaymentStatusView";
import StorageProcessingModal from "../modal/StorageProcessing/StorageProcessingModal";
import useEstimateApi from "src/pages/contract/hooks/useEstimateApi";
import useContractApi from "src/pages/contract/hooks/useContractApi";
import useProductFacilityApi from "src/pages/contract/hooks/useProductFacilityApi";
import useContactApi from "src/pages/contract/hooks/useContractApi";

const S2Columns: any = [
  {
    Header: "청구서 명",
    accessor: "bill.billTitle",
    sticky: "left",
    width: 120,
    Cell: ({ value, row, contractBaseInfo }: any) => {
      const { getProductData } = useContractApi();
      const { getEstimateList } = useEstimateApi();
      const { makeSpaceList } = useProductFacilityApi();

      const [estimateData, SetEstimateData] = useState<any>({ buildingCommonFacilityName: "" });

      const initDatas = useCallback(async () => {
        if (contractBaseInfo?.contract?.quotationList) {
          let ids = "";
          contractBaseInfo?.contract?.quotationList.forEach((list: any) => {
            if (ids !== "") {
              ids = ids + "," + list.quotationId;
            } else {
              ids = list.quotationId;
            }
          });

          let tableData: any = [];
          const result = await getProductData(Number(contractBaseInfo?.contractManage?.spaceProductId));
          if (result) {
            tableData = await makeSpaceList(result);
          }
          const response: any = await getEstimateList({ id: ids });
          if (response.data.data.content) {
            response.data.data.content.forEach((item: any) => {
              const find: any = tableData.find((list: any) => list.id === item?.quotationItemList[0].buildingCommonFacilityId);
              // console.log("find", find);
              if (find) {
                item.buildingCommonFacilityId = item?.quotationItemList[0].buildingCommonFacilityId;
                item.buildingCommonFacilityName = find.facilityName;
                item.commonFacilityType =
                  find.commonFacilityType === "MEETING_ROOM" ? "회의실" : find.commonFacilityType === "REFRESH_ROOM" ? "휴게공간" : "좌석";
                item.facilityMaxPeopleNums = find.facilityMaxPeopleNums;
                item.isGround = find.isGround;
                item.floorNum = find.floorNum;
              } else {
                item.buildingCommonFacilityId = 0;
                item.buildingCommonFacilityName = "없는 공용공간입니다.";
                item.commonFacilityType = "유형이 없습니다.";
                item.facilityMaxPeopleNums = 0;
                item.isGround = false;
                item.floorNum = 0;
              }
              item.subRows = item?.quotationItemList;
            });
            SetEstimateData(response.data.data.content[0]);
          }
        }
      }, [
        contractBaseInfo?.contract?.quotationList,
        contractBaseInfo?.contractManage?.spaceProductId,
        getEstimateList,
        getProductData,
        makeSpaceList,
      ]);

      useEffect(() => {
        initDatas();
      }, [initDatas]);

      return <BaseTooltip contents={estimateData!.buildingCommonFacilityName ? estimateData!.buildingCommonFacilityName : "-"} />;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 :</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.totalAmount",
    sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.totalAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: "납부 시작일",
    accessor: "bill.billIssueDate",
    sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부 종료일",
    accessor: "bill.billDueDate",
    sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      let payLimitDay = row.original.bill.billDueDate;
      return <div>{payLimitDay ? moment(payLimitDay).format(ViewYmdFormat.FULL) : "-"} </div>;
    },
  },
  {
    Header: "수납확인",
    accessor: "recieve",
    sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, scheduleId, showBtn }: any) => {
      // const [prevStatus, setPrevStatus] = useState<any>("");
      const [isChange, setIsChange] = useState(false);

      const pDatas = useMemo(() => {
        const datas = {
          row,
          rows,
          callList,
          contractBaseInfo,
          scheduleId,
          showBtn,
          isChange,
          setIsChange,
          type: "schedule",
        };
        return datas;
      }, [callList, contractBaseInfo, isChange, row, rows, scheduleId, showBtn]);
      console.info("mainRow", row.original.bill);

      return (
        <div>
          <div>
            {row.original.bill.billPayStatus === "PAYMENT_READY" ? (
              <BaseButton
                title={row.original.receiptBundle.receiptList.length > 0 ? "수정" : "수납처리"}
                className="color-white size-small"
                disabled={Number(row.original.bill.totalAmount) === 0 || !showBtn}
                onClick={() => setIsChange(true)}
              />
            ) : (
              <BaseButton
                title={"수납확인"}
                className="color-white size-small"
                disabled={row.original.billReceipt !== null ? false : true}
                onClick={() => setIsChange(true)}
              />
            )}
            {isChange && <StorageProcessingModal processData={pDatas} />}
          </div>
        </div>
      );
    },
  },
  {
    Header: "거래일시",
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(ViewYmdFormat.FULL) : "-";
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },

  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    // sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    // sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      // 연체 표시 : -
      let billIssuDay = moment(row.original.bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD);
      let today = moment().format(ViewYmdFormat.YYYY_MM_DD);

      if (row.original.bill.billPayStatus === "PAYMENT_READY" && moment(today).isAfter(moment(billIssuDay))) {
        value = "PAYMENT_DELAY";
      }

      return <PaymentStatusView value={value} />;
    },
  },
  {
    Header: "tid",
    accessor: "payment.tid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowTId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];
        console.log("payment", payment);
        billShowTId = payment.tid !== "" ? payment.tid : "-";

        if (payment.paymentCancel !== null) {
          billShowTId = payment.paymentCancel.tid;
        }
      }

      return <p>{billShowTId}</p>;
    },
  },
  {
    Header: "cid",
    accessor: "payment.cid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowCId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];
        console.log("payment", payment);
        billShowCId = payment.cid !== "" ? payment.cid : "-";

        if (payment.paymentCancel != null) {
          billShowCId = payment.paymentCancel.cid;
        }
      }

      return <p>{billShowCId}</p>;
    },
  },
  {
    Header: "현금영수증",
    accessor: "cashbill",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, contractBaseInfo }: any) => {
      console.log("contractBaseInfo", contractBaseInfo);
      let status = "대기";
      if (row.original.bill.pgcode !== "virtualaccount" || contractBaseInfo?.expenseProofData?.expenseProofType !== "CASHBILL") {
        status = "-";
      }
      if (row.original.cashbillList.length > 0) {
        if (row.original.cashbillList[0].progressStatus === "TEMP_SAVE") {
          status = "임시저장";
        }
        if (row.original.cashbillList[0].progressStatus === "ISSUE_COMPLETE") {
          status = "발행완료";
        }
        if (row.original.cashbillList[0].progressStatus === "ISSUE_REVOKE") {
          status = "발행취소";
        }
        if (row.original.cashbillList[0].progressStatus === "TRANS_BEFORE") {
          status = "전송전";
        }
        if (row.original.cashbillList[0].progressStatus === "TRANS_READY") {
          status = "전송대기";
        }
        if (row.original.cashbillList[0].progressStatus === "TRANS_ONGOING") {
          status = "전송중";
        }
        if (row.original.cashbillList[0].progressStatus === "TRANS_SUCCESS") {
          status = "전송성공";
        }
        if (row.original.cashbillList[0].progressStatus === "TRANS_FAIL") {
          status = "전송실패";
        }
        if (row.original.cashbillList[0].progressStatus === "ISSUE_MANUAL") {
          status = "수기발행";
        }
      }

      return <p>{status}</p>;
    },
  },
  {
    Header: "계산서 발행",
    accessor: "nontax",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, showBtn, messageModal, setMessageModal, getRounds, buildingId, openToast, contractBaseInfo }: any) => {
      const { getProviderData } = useContactApi();
      const { executeAsync: sendNts } = useApiOperation(mntSendNts);
      const { executeAsync: getWeekDay } = useApiOperation(mntGetWeekDay);
      const today = moment().toDate();
      const [sendFlag, setSendFlag] = useState(false);
      const [sendFlag2, setSendFlag2] = useState(false);
      const [sendFlag3, setSendFlag3] = useState(false);
      const [sendFlag4, setSendFlag4] = useState(false);
      const [msg, setMsg] = useState("");

      // const;
      const invoiceSend = useCallback(
        (params: any) => {
          return sendNts(params);
        },
        [sendNts],
      );
      const checkIssueDate = useCallback(async () => {
        const response = await getWeekDay({ checkDate: moment(today).format("YYYYMMDD") });
        const useableDate = moment(response.data.data.weekday).format(ViewYmdFormat.FULL);

        const providerData = await getProviderData(row.original.bill.providerId);
        let type = 1;
        if (moment(row.original.bill.taxInvoiceIssueDate).isBefore(moment(useableDate))) {
          //휴무일시 적용 해야함 오늘 날짜 기준으로 휴무일시체크
          const ym = moment(row.original.bill.taxInvoiceIssueDate).format("YYYY.MM");
          const addMd = moment(ym).add(1, "months").add(10, "days");
          const check = moment(addMd).format(ViewYmdFormat.FULL);

          if (moment(useableDate).isSameOrAfter(moment(check))) {
            setMsg("세금계산서가 즉시 발행됩니다. 선택한 작성일자로 세금계산서를 발행하면 가산세가 부과될 수 있으니 참고해 주세요");
          } else {
            setMsg("세금계산서가 즉시 발행됩니다.");
          }
        }
        if (
          moment(moment(row.original.bill.taxInvoiceIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isSame(
            moment(useableDate).format(ViewYmdFormat.YYYY_MM_DD),
          )
        ) {
          setMsg("세금계산서가 즉시 발행됩니다.");
        }
        if (moment(row.original.bill.taxInvoiceIssueDate).isAfter(moment(useableDate))) {
          type = 3;
          setMsg("세금계산서가 계산서 작성일에 예약 발행됩니다.");
        }
        return { passYn: providerData.providerPopbill.isVerifiedTaxInvoice, type };
      }, [getProviderData, getWeekDay, row.original.bill.providerId, row.original.bill.taxInvoiceIssueDate, today]);

      const handleSendNts = useCallback(async () => {
        const sendParams: any = { billId: row.original.bill.billId };

        const result: any = await invoiceSend(sendParams);
        if (result.status >= 200 && result.status < 400) {
          setSendFlag(false);
          setSendFlag2(false);
          setSendFlag3(false);
          setSendFlag4(false);
          setMessageModal({ ...messageModal, message: "발행 요청되었습니다.", isOpen: true });

          await getRounds(buildingId, row.original.bill.scheduleId);
        } else {
          setSendFlag(false);
          setSendFlag2(false);
          setSendFlag3(false);
          setSendFlag4(false);
        }
      }, [row.original.bill.billId, row.original.bill.scheduleId, invoiceSend, setMessageModal, messageModal, getRounds, buildingId]);

      if (!showBtn) {
        let status = "대기";
        if (row.original.bill.pgcode !== "virtualaccount" || contractBaseInfo?.expenseProofData?.expenseProofType !== "TAX_INVOICE") {
          status = "-";
        }
        if (row.original.taxInvoiceList.length > 0) {
          if (row.original.taxInvoiceList[0].progressStatus === "TEMP_SAVE") {
            status = "임시저장";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "ISSUE_COMPLETE") {
            status = "발행완료";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "ISSUE_REVOKE") {
            status = "발행취소";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "TRANS_BEFORE") {
            status = "전송전";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "TRANS_READY") {
            status = "전송대기";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "TRANS_ONGOING") {
            status = "전송중";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "TRANS_SUCCESS") {
            status = "전송성공";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "TRANS_FAIL") {
            status = "전송실패";
          }
          if (row.original.taxInvoiceList[0].progressStatus === "ISSUE_MANUAL") {
            status = "수기발행";
          }
        }

        return <p>{status}</p>;
      } else {
        //  청구상태가 ‘저장'이고, 승인이 ‘확정’일때만 버튼 활성화
        return (
          <div>
            <BaseButton
              title={"발행"}
              className="color-white"
              disabled={
                row.original.bill.pgcode === "creditcard" ||
                contractBaseInfo?.expenseProofData?.expenseProofType !== "TAX_INVOICE" ||
                row.original.taxInvoiceList.length > 0
              }
              onClick={async () => {
                if (contractBaseInfo!.contractManage!.mbOrganizationId! && Number(contractBaseInfo.contractManage.mbOrganizationId) === 0) {
                  setSendFlag4(true);
                } else {
                  const passYn = await checkIssueDate();
                  if (passYn.passYn) {
                    passYn.type === 1 ? setSendFlag(true) : setSendFlag3(true);
                  } else {
                    setSendFlag2(true);
                  }
                }
              }}
            />
            {sendFlag4 && (
              <BaseModal
                isOpen={sendFlag4}
                btnRightTitle={"확인"}
                onClick={() => {
                  setSendFlag4(false);
                }}
                title={"계약 메뉴에서 사업자 정보를 등록해 주세요."}
              >
                <p>사업자 메뉴에서 정보를 등록한 후, 신청/계약에 해당 정보를 추가해 주세요.</p>
              </BaseModal>
            )}
            {sendFlag && (
              <BaseModal
                isOpen={sendFlag}
                btnLeftTitle={"취소"}
                btnRightTitle={"확인"}
                onClose={() => {
                  setSendFlag(false);
                }}
                onClick={handleSendNts}
                title={"발행하시겠습니까?"}
              >
                <p>{msg}</p>
              </BaseModal>
            )}
            {sendFlag2 && (
              <BaseModal
                isOpen={sendFlag2}
                btnRightTitle={"확인"}
                onClick={() => {
                  setSendFlag2(false);
                }}
                title={"해당 관리처 정보로 계산서를 발행 할 수 없습니다."}
              >
                <p>{"정산정보 메뉴에서 해당 관리처의 팝빌 인증정보 검증을 실행해 주세요."}</p>
              </BaseModal>
            )}
            {sendFlag3 && (
              <BaseModal
                isOpen={sendFlag3}
                btnRightTitle={"확인"}
                onClick={() => {
                  setSendFlag3(false);
                }}
                title={"발행하시겠습니까?"}
              >
                <p>{"세금계산서가 작성일에 예약 발행됩니다."}</p>
              </BaseModal>
            )}
          </div>
        );
      }
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          승인번호
        </p>
      );
    },
    accessor: "TAX_PLUS",
    width: 220,
    Cell: ({ value, row, scheduleId, callList, showBtn }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const { id } = useParams();
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);
          setTaxInvoice(sorted[0]);
          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_PLUS";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          await callList();
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <div className="w-100 text-left mr10">
              {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== "" ? (
                <div>
                  <BaseTooltip contents={taxInvoice.invoiceNumber} isSingleLine={true} />
                  {showBtn && (
                    <BaseButton
                      title="수정"
                      disabled={Number(row.original.bill.totalAmount) === 0}
                      className="color-white size-small"
                      onClick={() => setView(true)}
                    />
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>

          <BaseModal
            className="dialog-modal small-size"
            isOpen={view}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => {
              setView(false);

              setTaxInvoice(original);
            }}
            onClick={() => saveTaxInvoice()}
          >
            <>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>세금계산서 발행 정보 수정</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left font12 text-gray500">
                        <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                        <p>* 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록 해주세요.</p>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-gray600">국세청 승인번호</span>
                        </div>
                      </td>
                      <td>
                        <div className="py10">
                          <div className="minmax300 mb8">
                            <BaseInput
                              name={"taxInvoiceNumber"}
                              value={String(taxInvoice?.invoiceNumber)}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(taxInvoice);
                                temp.invoiceNumber = value;
                                setTaxInvoice(temp);
                              }}
                              maxLength={24}
                              // disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          전송일자
        </p>
      );
    },
    accessor: "taxInvoiceIssueDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      const [original, setOriginal] = useState<any>();
      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);

          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);
      return <p>{original && original?.createdDate !== "" ? moment(original?.createdDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: "결제취소",
    accessor: "cancel",
    sticky: "right",
    width: 150,
    Cell: ({ value, row }: any) => {
      return <p>-</p>;
    },
  },
];
export default S2Columns;
