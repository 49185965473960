import { useParams } from "react-router-dom";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import ProviderBasicDetail from "./components/ProviderBasicDetail";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
export interface Pd {
  providerDetail: ProviderAddModel;
}

const BasicInfoDetail = ({ providerDetail }: Pd) => {
  const navigate = useNavigate();
  const { id: providerId } = useParams();
  const { isAuthority } = usePartnerAuthority();

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"기본 정보"} />
            {providerDetail && (
              <>
                {/* 프로바이더 기본정보 */}
                <ProviderBasicDetail providerBasic={providerDetail.provider} contractMedia={providerDetail.contractMediaList} />
              </>
            )}
          </article>

          {/*등록정보  */}
          <RegistrationInformation data={providerDetail?.provider} />

          <AdminMemoV3 serviceType={ServiceTypes.SERVICE_PROVIDER} serviceId={Number(providerId)} />
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("BASIC", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const path = `${PagePath.provider.form}?id=${providerId}`;
                navigate(path);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
