import _ from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { addGroupItem, editGroupItem, getChargeItemList, getChargeItemsGroupDetail, getCommonCode } from "src/api/chargeItems/chargeItems-api";
import { ChargeItem } from "src/api/chargeItems/chargeItems-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { columns } from "./ColumnsProperty";

enum Level1 {
  LEVEL1 = "MNT_AREA",
  LEVEL2 = "MNT_USAGE",
  LEVEL3 = "MNT_SEPARATE",
}

enum Level3 {
  LEVEL1 = "true",
  LEVEL2 = "false",
}
enum Level4 {
  LEVEL1 = "true",
  LEVEL2 = "false",
}
enum Level8 {
  LEVEL1 = "MNT_ORIGIN",
  LEVEL2 = "MNT_THOUSAND",
  LEVEL3 = "MNT_HUNDRED",
  LEVEL4 = "MNT_TEN",
  LEVEL5 = "MNT_ONE",
}

const ChargeItemsDetailForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openErrorModal } = useErrorModal();
  const { openToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");

  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const [stateOptions1, setStateOptions1] = useState<any>([]);
  const [stateOptions2, setStateOptions2] = useState<any>([]);
  const [stateOptions8, setStateOptions8] = useState<any>([]);
  const [stateOptions9, setStateOptions9] = useState<any>([]);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  let id = queryParams.id ? queryParams.id : useParams.prototype.id;

  const { executeAsync: getGroupDetail } = useApiOperation(getChargeItemsGroupDetail);
  const { executeAsync: getGroupItems } = useApiOperation(getChargeItemList);
  const { executeAsync: addItem } = useApiOperation(addGroupItem, { noLoading: true });
  const { executeAsync: editItem } = useApiOperation(editGroupItem, { noLoading: true });
  const { executeAsync: commonCode } = useApiOperation(getCommonCode, { noLoading: true });

  const stateOptions3 = [
    { value: Level3.LEVEL1, label: "과세" },
    { value: Level3.LEVEL2, label: "비과세" },
  ];
  const stateOptions4 = [
    { value: Level4.LEVEL1, label: "적용" },
    { value: Level4.LEVEL2, label: "미적용" },
  ];
  const stateOptions5 = [
    { value: Level4.LEVEL1, label: "적용" },
    { value: Level4.LEVEL2, label: "미적용" },
  ];

  const stateOptions7 = [
    { value: Level4.LEVEL1, label: "사용중" },
    { value: Level4.LEVEL2, label: "사용안함" },
  ];

  interface Code {
    code: string;
    desc: string;
  }

  const callCode = useCallback(
    async (codeGroup: string) => {
      const response: any = await commonCode({ codeGroup });
      return response;
    },
    [commonCode],
  );
  const groupDetail = useCallback(
    async (groupId: number) => {
      const response: any = await getGroupDetail({ groupId });
      if (response.status >= 200 && response.status <= 299) {
        setTitle(response.data.data.content.ctMntGroup.groupName);
      }
    },
    [getGroupDetail],
  );

  //공통 코드 조회
  const callCommonCodes = useCallback(async () => {
    const response: any = await callCode("MNT_ROOT");
    const response2: any = await callCode("MNT_PRICE_UNIT");
    const response3: any = await callCode("MNT_ROUNDING_MODE");

    if (response.status > 199 && response.status < 300) {
      const level1: any = [];
      response.data.data.content.map((codeGroup: Code) => {
        const obj = {
          value: codeGroup.code,
          label: codeGroup.desc,
        };
        level1.push(obj);
      });
      setStateOptions1(level1);
    }
    if (response2.status > 199 && response2.status < 300) {
      const level1: any = [];
      response2.data.data.content.map((codeGroup: Code) => {
        const obj = {
          value: codeGroup.code,
          label: codeGroup.desc,
        };
        level1.push(obj);
      });
      setStateOptions8(level1);
    }
    if (response3.status > 199 && response3.status < 300) {
      const level1: any = [];
      response3.data.data.content.map((codeGroup: Code) => {
        const obj = {
          value: codeGroup.code,
          label: codeGroup.desc,
        };
        level1.push(obj);
      });
      setStateOptions9(level1);
    }
  }, [callCode]);

  const [formData, setFormData] = useState<ChargeItem>({
    groupId: Number(id),
    orderNum: 0,
    isDeleted: false,
  });
  const [tableData, setTableData] = useState<any>([]);

  const itemList = useCallback(async () => {
    const response: any = await getGroupItems({ groupId: id });
    const sorted = _.sortBy(response.data.data.ctMntItemList, "orderNum").reverse();
    setTableData(sorted);
  }, [getGroupItems, id]);

  useEffect(() => {
    if (id) {
      callCommonCodes();
      itemList();
      groupDetail(id);
    }
  }, [id, itemList, callCommonCodes, groupDetail]);

  const moveDetail = useCallback(() => {
    const path =
      PagePath.chargeItems.detail.replace(":id", id) + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [id, navigate, queryParams]);

  const clearForm = useCallback(() => {
    setStateOptions2([]);
    setFormData({
      groupId: Number(id),
      orderNum: 0,
      itemName: "",
      level1: "",
      level2: "",
      isApplyTax: undefined,
      isApplyOverdue: undefined,
      isApplyAdjust: undefined,
      priceUnit: "",
      roundingMode: "",
      isUse: false,
      isDeleted: false,
    });
  }, [id]);

  const insert = useCallback(
    async (ctMntItem: ChargeItem) => {
      const response: any = await addItem({ groupId: id, ctMntItemList: [ctMntItem] });
      if (response.status > 199 && response.status < 300) {
        await itemList();
        openToast({ content: "정상적으로 저장 되었습니다." });
        clearForm();
      }
    },
    [addItem, clearForm, id, itemList, openToast],
  );

  const update = useCallback(
    async (ctMntItem: ChargeItem) => {
      const response: any = await editItem({ groupId: id, ctMntItemList: [ctMntItem] });
      if (response.status > 199 && response.status < 300) {
        await itemList();
        openToast({ content: "정상적으로 저장 되었습니다." });
        clearForm();
      }
    },
    [clearForm, editItem, id, itemList, openToast],
  );

  const checkFormData = useCallback(
    (formData: ChargeItem) => {
      let checker = false;
      if (formData.itemName === undefined || formData.itemName === "") {
        checker = true;
        formData.itemNameError = "필수 입력 항목입니다.";
      } else {
        delete formData.itemNameError;
      }
      if (formData.itemLabel === undefined || formData.itemLabel === "") {
        checker = true;
        formData.itemLabelError = "필수 입력 항목입니다.";
      } else {
        delete formData.itemLabelError;
      }
      if (formData.level1 === undefined || formData.level1 === "") {
        checker = true;
        formData.level1Error = "필수 입력 항목입니다.";
      } else {
        delete formData.level1Error;
      }
      if ((formData.level1 !== Level1.LEVEL3 && formData.level2 === undefined) || (formData.level1 !== Level1.LEVEL3 && formData.level2 === "")) {
        checker = true;
        formData.level2Error = "필수 입력 항목입니다.";
      } else {
        delete formData.level2Error;
      }
      if (formData.isApplyTax === undefined || String(formData.isApplyTax) === "") {
        console.log("과세 기준 미작성");
        checker = true;
        formData.isApplyTaxError = "필수 입력 항목입니다.";
      } else {
        delete formData.isApplyTaxError;
      }
      if (formData.isApplyOverdue === undefined || String(formData.isApplyOverdue) === "") {
        console.log("연체료 미작성");
        checker = true;
        formData.isApplyOverdueError = "필수 입력 항목입니다.";
      } else {
        delete formData.isApplyOverdueError;
      }
      if (formData.isApplyAdjust === undefined || String(formData.isApplyAdjust) === "") {
        console.log("중간정산 미작성");
        checker = true;
        formData.isApplyAdjustError = "필수 입력 항목입니다.";
      } else {
        delete formData.isApplyAdjustError;
      }
      if (formData.priceUnit === undefined || String(formData.priceUnit) === "") {
        console.log("소수점 미작성");
        checker = true;
        formData.priceUnitError = "필수 입력 항목입니다.";
      } else {
        delete formData.priceUnitError;
      }
      console.log(formData?.priceUnit, formData.roundingMode);
      if (
        (formData?.priceUnit !== Level8.LEVEL1 && formData.roundingMode === undefined) ||
        (formData?.priceUnit !== Level8.LEVEL1 && String(formData.roundingMode) === "")
      ) {
        console.log("반올림 미작성");
        checker = true;
        formData.roundingModeError = "필수 입력 항목입니다.";
      } else {
        delete formData.roundingModeError;
      }
      if (formData.isUse === undefined || String(formData.isUse) === "") {
        console.log("사용여부 미작성");
        checker = true;
        formData.isUseError = "필수 입력 항목입니다.";
      } else {
        delete formData.isUseError;
      }
      if (formData?.priceUnit === Level8.LEVEL1) {
        formData.roundingMode = "UNNECESSARY";
      }
      if (formData.level1 === Level1.LEVEL3) {
        formData.level2 = "UNNECESSARY";
      }
      const editData = _.cloneDeep(formData);
      setFormData(editData);

      return checker;
    },
    [setFormData],
  );

  const saveItem = useCallback(() => {
    setIsOpen(false);
    if (checkFormData(formData) === true) {
      console.log("작성 안한 내용이 있다.");
    } else {
      if (formData.itemId) {
        update(formData);
      } else {
        insert(formData);
      }
    }
  }, [checkFormData, formData, insert, update]);

  const moveList = useCallback(() => {
    setIsOpen4(false);
    const path = PagePath.chargeItems.list;
    navigate(path);
  }, [navigate]);
  const setSelectOption = useCallback(async () => {
    if (formData?.level1) {
      const response: any = await callCode(String(formData?.level1));
      const level2: any = [];
      response.data.data.content.map((codeGroup: Code) => {
        const obj = {
          value: codeGroup.code,
          label: codeGroup.desc,
        };
        level2.push(obj);
      });
      setStateOptions2(level2);
    }

    if (formData?.priceUnit) {
      const response: any = await callCode(String(formData?.priceUnit));
      const level2: any = [];
      response.data.data.content.map((codeGroup: Code) => {
        const obj = {
          value: codeGroup.code,
          label: codeGroup.desc,
        };
        level2.push(obj);
      });
      setStateOptions9(level2);
    }
  }, [callCode, formData?.level1, formData?.priceUnit]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 1000 },
      setFormData,
      setTableData,
      update,
      itemList,

      clearForm,
      setSelectOption,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSelectOption();
  }, [formData, setFormData, setSelectOption, title]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 세부 부과항목 */}
            <section className="">
              {/* 첫째줄 폼 */}
              <table className="inner-table mb20" width="940">
                <thead>
                  <tr>
                    <th>
                      <div>id</div>
                    </th>
                    <th>
                      <div>항목</div>
                    </th>
                    <th>
                      <div>고지서 표기</div>
                    </th>
                    <th>
                      <div>부과 기준</div>
                    </th>
                    <th>
                      <div>과세 기준</div>
                    </th>
                    <th>
                      <div>연체료</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <BaseInput type="text" readonly value={formData?.itemId} />
                    </td>
                    <td>
                      <BaseInput
                        type="text"
                        value={formData?.itemName}
                        readonly={formData.isFreezing}
                        onChange={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { itemName: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.itemNameError}
                      />
                    </td>
                    <td>
                      <BaseInput
                        type="text"
                        value={formData?.itemLabel}
                        readonly={formData.isFreezing}
                        onChange={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { itemLabel: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.itemLabelError}
                      />
                    </td>
                    <td>
                      <div className="flex-center">
                        <BaseSelect
                          value={formData?.level1}
                          stateOptions={stateOptions1}
                          isDisabled={formData.isFreezing}
                          setStateValue={async (value: string) => {
                            const temp = _.cloneDeep(formData);
                            const nD = Object.assign(temp, { level1: value, level2: "" }, {});
                            setFormData(nD);
                            const response: any = await callCode(value);
                            const level2: any = [];
                            response.data.data.content.map((codeGroup: Code) => {
                              const obj = {
                                value: codeGroup.code,
                                label: codeGroup.desc,
                              };
                              level2.push(obj);
                            });
                            setStateOptions2(level2);
                          }}
                          className="mr2"
                          errorText={formData?.level1Error}
                        />
                        <BaseSelect
                          value={String(formData?.level2)}
                          stateOptions={stateOptions2}
                          isDisabled={formData.isFreezing}
                          setStateValue={(value: string) => {
                            const temp = _.cloneDeep(formData);
                            const nD = Object.assign(temp, { level2: value }, {});
                            console.log("nD", nD, value);
                            setFormData(nD);
                          }}
                          errorText={formData?.level2Error}
                        />
                      </div>
                    </td>
                    <td>
                      <BaseSelect
                        value={String(formData?.isApplyTax)}
                        stateOptions={stateOptions3}
                        isDisabled={formData.isFreezing}
                        setStateValue={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { isApplyTax: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.isApplyTaxError}
                      />
                    </td>
                    <td>
                      <BaseSelect
                        value={String(formData?.isApplyOverdue)}
                        stateOptions={stateOptions4}
                        isDisabled={formData.isFreezing}
                        setStateValue={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { isApplyOverdue: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.isApplyOverdueError}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* 둘째줄 폼 */}
              <table className="inner-table mb20" width="940">
                <thead>
                  <tr>
                    <th>
                      <div>중간정산</div>
                    </th>
                    <th>
                      <div>부과계산방법</div>
                    </th>
                    <th>
                      <div>사용여부</div>
                    </th>
                    <th>
                      <div>표기순서</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <BaseSelect
                        value={String(formData?.isApplyAdjust)}
                        stateOptions={stateOptions5}
                        isDisabled={formData.isFreezing}
                        setStateValue={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { isApplyAdjust: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.isApplyAdjustError}
                      />
                    </td>
                    <td>
                      <div className="flex-center">
                        <BaseSelect
                          value={formData?.priceUnit}
                          stateOptions={stateOptions8}
                          isDisabled={formData.isFreezing}
                          setStateValue={async (value: string) => {
                            const temp = _.cloneDeep(formData);
                            const nD = Object.assign(temp, { priceUnit: value, roundingMode: "" }, {});
                            setFormData(nD);
                            const response: any = await callCode(value);
                            const level2: any = [];
                            response.data.data.content.map((codeGroup: Code) => {
                              const obj = {
                                value: codeGroup.code,
                                label: codeGroup.desc,
                              };
                              level2.push(obj);
                            });
                            setStateOptions9(level2);
                          }}
                          className="mr2"
                          errorText={formData?.priceUnitError}
                        />
                        <BaseSelect
                          value={formData?.roundingMode}
                          stateOptions={stateOptions9}
                          isDisabled={formData.isFreezing}
                          setStateValue={(value: string) => {
                            const temp = _.cloneDeep(formData);
                            const nD = Object.assign(temp, { roundingMode: value }, {});
                            setFormData(nD);
                          }}
                          errorText={formData?.roundingModeError}
                        />
                      </div>
                    </td>
                    <td>
                      <BaseSelect
                        value={String(formData?.isUse)}
                        stateOptions={stateOptions7}
                        isDisabled={formData.isFreezing}
                        setStateValue={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { isUse: value }, {});
                          setFormData(nD);
                        }}
                        errorText={formData?.isUseError}
                      />
                    </td>
                    <td width="120">
                      <BaseInput
                        type="text"
                        value={String(formData?.orderNum)}
                        onChange={(value: string) => {
                          const temp = _.cloneDeep(formData);
                          const nD = Object.assign(temp, { orderNum: value }, {});
                          setFormData(nD);
                        }}
                      />
                    </td>
                    <td width="100">
                      <div className="flex-center">
                        <BaseButton title="저장" className="mr2" onClick={() => setIsOpen(true)} />
                        <BaseButton title="취소" className="color-white" onClick={() => clearForm()} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* 2depth 테이블 */}
              <div className="inner-tab-table two-depth-header">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header: any) => {
                          // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
                          // 스티키가 아닌 rowspan 헤더 케이스
                          if (!header.parent && header.depth === 0 && header.columns?.length === 1 && !header.sticky) {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                <div className="w-100 no-parent" style={{ zIndex: 2 }}>
                                  {header.render("Header")}
                                </div>
                              </div>
                            );
                          } else if (header.sticky === "left") {
                            // 스티키 상태인 rowspan 케이스
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th" style={{ ...header.getHeaderProps().style, zIndex: 4 }}>
                                <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
                                  {header.render("Header")}
                                </div>
                                {/* <div className="ic_sort"></div> */}
                              </div>
                            );
                          } else {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                <div className=" w-100 ">{header.render("Header")}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {page.map((row: any) => {
                      prepareRow(row);

                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </article>
          <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => saveItem()} onClose={() => setIsOpen(false)}>
            <p>저장 하시겠습니까?</p>
          </BaseModal>

          <BaseModal isOpen={isOpen4} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => moveList()} onClose={() => setIsOpen4(false)}>
            <p>목록으로 이동합니다. 미 저장 시 정보가 초기화 되어 저장됩니다.</p>
          </BaseModal>
          <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => moveDetail()} onClose={() => setIsOpen3(false)}>
            <p>상세페이지로 이동하시겠습니까?</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => setIsOpen4(true)} />
        </div>
        <div className="right-area">
          <BaseButton title="수정 취소" className="color-white size-large ml10" onClick={() => setIsOpen3(true)} />
        </div>
      </div>
    </>
  );
};
export default ChargeItemsDetailForm;
