import { useRecoilValue } from "recoil";
import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";
import { BaseButton } from "src/components";
import BaseRichTooltip from "src/components/BaseRichTooltip";
import { PagePath } from "src/pages/product/details";
import { globalPartnerState } from "src/recoil/partners/atom";
import { roundFloat } from "src/utils/common-util";
import { DeviceStatus, DeviceType, ExecuteResultType, MonitoringType } from "../monitoring-types";
import { getUnit, monitoringTooltipData } from "../MonitoringTooltopData";

type Props = {
  monitoring: MonitoringType[];
  handleRefresh: (id: number) => void;
  buildingId: number;
  date: string;
};

// const alertIcon = "⚠️";
export const alertIcon = <>&#x26A0;&#xFE0F;</>;

const MonitoringTable = ({ monitoring, handleRefresh, buildingId, date }: Props) => {
  /** 소숫점 특정 자릿수까지만 표시하는 함수 - 기본설정: 소숫점 첫째자리까지만 표시 */
  const partner = useRecoilValue(globalPartnerState);
  //온도 평균

  const temperatureAverage = (passTemperatures: ExecuteResultType[]) => {
    const temperatureList = passTemperatures.map((item) => ({
      id: item.location.identifier,
      internalDisplayName: item.internalDisplayName,
      locatioin: item.location,
      labelType: item.labelType,
      timestamp: item.result?.temperature?.timestamp || "",
      tempValue: (item.result?.temperature && (item.result?.temperature?.value || item.result.temperature?.current)) || 0,
    }));

    const sum: number = temperatureList.reduce((acc, current) => acc + current.tempValue, 0);
    const result = sum / temperatureList.length;

    const final = result ? roundFloat(result) : "";

    if (passTemperatures.some((item) => item.isLoading && !date)) {
      return "-";
    }
    return (
      <>
        <BaseRichTooltip
          rightLabel={<p className="font14">{final && <span>{`${final}°C`}</span>}</p> || ""}
          children={
            <div>
              <p className="popover-gray__title">평균 온도</p>
              <p>•{final ? final + "°C" : "알수없음"}</p>
              {temperatureList.map((item, idx: number) => (
                <div key={`${item.locatioin.category}-${item.labelType}-${idx}`} className="flex-center">
                  <p>{`•${roundFloat(item.tempValue) + "°C"}  ${item.internalDisplayName}`}</p>
                  {isCaution(item.timestamp) && <div className="ml3">{alertIcon}</div>}
                </div>
              ))}
            </div>
          }
        />
      </>
    );
  };

  // 기기의 timestamp가 현재 시간으로부터 6시간 초과면 old 한 데이터 - 노란색 아이콘으로 표시
  const isCaution = (timestamp: string) => {
    if (!timestamp) return;
    const sixHourMilisecond = 6 * 60 * 60 * 1000;
    // 현재 시간
    const now = new Date();

    // 타임스탬프를 Date 객체로 변환
    const dateToCompare = new Date(timestamp);

    // 6시간 이전인지 확인
    const isMoreThanSixHours = now.getTime() - dateToCompare.getTime() > sixHourMilisecond;
    return isMoreThanSixHours;
  };
  // 각 기기들의 동작 상태 확인
  const deviceStatusCheck = (type: "IOT" | "ACCESS", device: any) => {
    const iotDevice: ExecuteResultType = type === "IOT" && device;
    const accessDevice: Ac2ProductAccessGroupModel = type === "ACCESS" && device;

    // 출입기기 상태 확인
    if (type === "ACCESS") {
      switch (accessDevice.deviceStatusInfo?.deviceStatus) {
        case "ONLINE":
          return DeviceStatus.ACTIVE;

        case "OFFLINE":
          return DeviceStatus.INACTIVE;

        default:
          return DeviceStatus.CANNOT;
      }
    }
    // iot 기기 상태 확인
    else {
      // result 값이 없거나 error 데이터가 있으면 "불가 표시"

      if (!iotDevice.result || iotDevice.result.error) return DeviceStatus.CANNOT;

      // 재실
      if (iotDevice.labelType === DeviceType.SENSOR && iotDevice.result.motion) {
        if (isCaution(iotDevice.result.motion.timestamp)) {
          return DeviceStatus.CAUTION;
        }
        return iotDevice.result.motion.value === "active" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
      }

      // 실내기
      if (iotDevice.labelType === DeviceType.INDOOR_UNIT && iotDevice.platform === "ST") {
        if (iotDevice.result.switch) {
          if (isCaution(iotDevice.result.switch.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.switch.value === "on" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        } else {
          return DeviceStatus.CANNOT;
        }
      }

      if (iotDevice.labelType === DeviceType.INDOOR_UNIT && iotDevice.platform === "B_IOT") {
        if (iotDevice.result.operation) {
          if (isCaution(iotDevice.result.operation.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.operation.power === "On" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        } else {
          return DeviceStatus.CANNOT;
        }
      }

      if (iotDevice.labelType === DeviceType.LIGHT && iotDevice.result.switch) {
        if (isCaution(iotDevice.result.switch.timestamp)) {
          return DeviceStatus.CAUTION;
        }
        return iotDevice.result.switch.value === "on" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
      }

      //남자 화장실
      if ((iotDevice.labelType === DeviceType.MENS_TOILET, iotDevice.result)) {
        if (iotDevice.result.contact) {
          if (isCaution(iotDevice.result.contact.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.contact.value === "open" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        }

        if (iotDevice.result.motion) {
          if (isCaution(iotDevice.result.motion.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.motion.value === "active" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        }
      }

      // 여자 화장실
      if ((iotDevice.labelType === DeviceType.WOMEN_TOILET, iotDevice.result)) {
        if (iotDevice.result.contact) {
          if (isCaution(iotDevice.result.contact.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.contact.value === "open" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        }

        if (iotDevice.result.motion) {
          if (isCaution(iotDevice.result.contact.timestamp)) {
            return DeviceStatus.CAUTION;
          }
          return iotDevice.result.motion.value === "active" ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE;
        }
      }
    }
  };

  return (
    <>
      <section className="table-label">
        <div className="table-label__info flex-center">
          <BaseRichTooltip
            touchIcon="CIRCLE"
            className="mr10"
            title="Title"
            rightLabel={<p className="font13">활성</p>}
            status={"ACTIVE"}
          ></BaseRichTooltip>
          <BaseRichTooltip touchIcon="CIRCLE" className="mr10" rightLabel={<p className="font13">비활성</p>} status={"INACTIVE"} />
          <BaseRichTooltip touchIcon="CIRCLE" className="mr10" rightLabel={<p className="font13">불가</p>} status={"CANNOT_USE"} />
          <BaseRichTooltip touchIcon="CIRCLE" className="mr10" rightLabel={<p className="font13">확인 필요</p>} status={"CAUTION"} />
          <BaseRichTooltip touchIcon={<p className={`ic-man-b cursor-unset`}></p>} className="mr10" rightLabel={<p className="font13">이용 중</p>} />
          <BaseRichTooltip touchIcon={<p className={`ic-man-g cursor-unset`}></p>} className="mr20" rightLabel={<p className="font13">비어있음</p>} />
          <p className="font13">상태를 클릭하면 정보를 볼 수 있습니다</p>
        </div>
        <div className="table-label__refresh">
          <p>업데이트: {date}</p>
          <BaseButton
            title="새로고침"
            className="size-small color-white"
            onClick={() => {
              handleRefresh(buildingId);
            }}
          />
        </div>
      </section>
      <table className="table-monitoring">
        <thead className="table-monitoring__header">
          <tr>
            <th colSpan={2}>
              <p></p>
            </th>
            <th>
              <p className="text-left pl18">공간</p>
            </th>
            <th>
              <p>출입기기</p>
            </th>
            <th>
              <p>재실</p>
            </th>
            <th>
              <div className="flex-center-center">
                <p className="mr4">실내기 </p>
                <p className="font12">(모드/희망온도)</p>
              </div>
            </th>
            <th>
              <p>평균 온도</p>
            </th>
            <th>
              <p>조명</p>
            </th>
            <th>
              <p>남자 화장실</p>
            </th>
            <th>
              <p>여자 화장실</p>
            </th>
          </tr>
        </thead>
        <tbody className="table-monitoring__body">
          {monitoring.map((item, i) => (
            <tr className="table-monitoring__body-floor" key={"floor" + i}>
              <td className="floor-td">
                <div className="floor-cell">
                  <p>{item.floorValue}</p>
                </div>
              </td>
              <td colSpan={9}>
                {/* 첫번째 row는 고정 */}
                <section className="table-monitoring__body-reservation">
                  <div className="cell"></div>

                  {/* 출입기기 */}
                  <div className="cell">
                    {item?.floorDevices?.access?.map((device: Ac2ProductAccessGroupModel & { isLoading?: boolean }, idx: number) => {
                      if (device.isLoading && date) device.isLoading = false;
                      return (
                        <BaseRichTooltip
                          key={`floor-${device.id}-${idx}`}
                          touchIcon={
                            device.isLoading ? (
                              <p style={{ height: 20 }} className="flex-center">
                                -
                              </p>
                            ) : (
                              "CIRCLE"
                            )
                          }
                          leftLabel={
                            <p className="font14 text-center" style={{ width: 20 }}>
                              {idx + 1}
                            </p>
                          }
                          status={deviceStatusCheck("ACCESS", device)}
                          children={monitoringTooltipData(undefined, device)}
                        />
                      );
                    })}
                  </div>

                  {/* 재실 */}
                  <div className="cell">
                    {item.floorDevices.sensor.map((device, idx: number) => {
                      if (device.isLoading && date) device.isLoading = false;
                      return (
                        <div key={`floor-${device.labelType}-${idx}`}>
                          {device.isLoading && <p style={{ height: 20 }}>-</p>}
                          {!device.isLoading && (
                            <BaseRichTooltip
                              touchIcon={
                                <p
                                  className={`${
                                    deviceStatusCheck("IOT", device) === "ACTIVE"
                                      ? "ic-man-b"
                                      : deviceStatusCheck("IOT", device) === "INACTIVE"
                                      ? "ic-man-g"
                                      : deviceStatusCheck("IOT", device) === "CAUTION"
                                      ? "circle__chip --caution"
                                      : "circle__chip --cannot-use"
                                  } `}
                                ></p>
                              }
                              rightLabel={<p className="font14"></p>}
                              children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                              firstActionLabel="상세보기"
                              firstActionFunction={() => {
                                window.open(
                                  PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                    `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                );
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* 실내기 */}
                  <div className="cell">
                    {item.floorDevices.indoorUnit.map((device, idx: number) => {
                      if (device.isLoading && date) device.isLoading = false;
                      return (
                        <div key={`floor-${device.labelType}-${idx}`}>
                          {device.isLoading && <p style={{ height: 20 }}>-</p>}
                          {!device.isLoading && (
                            <BaseRichTooltip
                              touchIcon="CIRCLE"
                              rightLabel={
                                device.platform === "ST" ? (
                                  <p className="font14" onClick={() => console.log(device.result)}>
                                    {device.result?.switch?.value === "on" && (
                                      <>
                                        <span className="mr3">
                                          {(device.result?.airConditionerMode && getUnit(device.result?.airConditionerMode?.value)) || ""}
                                        </span>
                                        <span className="mr3">
                                          {(device.result?.coolingSetpoint && `${roundFloat(device.result?.coolingSetpoint?.value)}°C`) || ""}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p className="font14" onClick={() => console.log(device.result)}>
                                    {device.result?.operation?.power === "On" && (
                                      <>
                                        <span className="mr3">{(device.result?.mode && getUnit(device.result?.mode?.mode)) || ""}</span>
                                        <span className="mr3">
                                          {(device.result?.temperature && `${roundFloat(device.result.temperature?.desired)}°C`) || ""}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                )
                              }
                              status={deviceStatusCheck("IOT", device)}
                              children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                              firstActionLabel="상세보기"
                              firstActionFunction={() => {
                                window.open(
                                  PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                    `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                );
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* 평균 온도 */}
                  {/* 층 평균온도 = 층 + 층의 공용공간 온도의 평균 */}
                  <div className="cell">
                    {temperatureAverage([
                      ...item.floorDevices.temperature,
                      ...item.facilityList.map((facility) => facility.facilityDevices.temperature).flat(),
                    ])}
                  </div>

                  {/* 조명 */}
                  <div className="cell">
                    {item.floorDevices.light.map((device, idx: number) => {
                      if (device.isLoading && date) device.isLoading = false;
                      return (
                        <div key={`floor-${device.labelType}-${idx}`}>
                          {device.isLoading && <p style={{ height: 20 }}>-</p>}
                          {!device.isLoading && (
                            <BaseRichTooltip
                              key={`facility-${device.labelType}-${idx}`}
                              touchIcon="CIRCLE"
                              rightLabel={<p className="font14"></p>}
                              status={deviceStatusCheck("IOT", device)}
                              children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                              firstActionLabel="상세보기"
                              firstActionFunction={() => {
                                window.open(
                                  PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                    `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                );
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* 남자 화장실 */}
                  <div className="cell">
                    {item.floorDevices.mensToilet
                      .sort((a, b) => (a.internalDisplayName > b.internalDisplayName ? 1 : -1))
                      .map((device, idx: number) => {
                        if (device.isLoading && date) device.isLoading = false;
                        return (
                          <div key={`floor-${device.labelType}-${idx}`} className="">
                            <BaseRichTooltip
                              touchIcon={
                                device.isLoading ? (
                                  <p style={{ height: 20 }} className="flex-center">
                                    -
                                  </p>
                                ) : (
                                  <p
                                    className={`flex-center ${
                                      deviceStatusCheck("IOT", device) === "ACTIVE"
                                        ? "ic-man-b"
                                        : deviceStatusCheck("IOT", device) === "INACTIVE"
                                        ? "ic-man-g"
                                        : deviceStatusCheck("IOT", device) === "CAUTION"
                                        ? "circle__chip --caution"
                                        : "circle__chip --cannot-use"
                                    } `}
                                  ></p>
                                )
                              }
                              leftLabel={
                                <div className="flex-center">
                                  <p className="font14 text-center" style={{ width: 20 }}>
                                    {idx + 1}
                                  </p>
                                </div>
                              }
                              status={deviceStatusCheck("IOT", device)}
                              children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                              firstActionLabel="상세보기"
                              firstActionFunction={() => {
                                window.open(
                                  PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                    `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>

                  {/* 여자 화장실 */}
                  <div className="cell">
                    {item.floorDevices.womensToilet
                      .sort((a, b) => (a.internalDisplayName > b.internalDisplayName ? 1 : -1))
                      .map((device, idx: number) => {
                        if (device.isLoading && date) device.isLoading = false;
                        return (
                          <div key={`floor-${device.labelType}-${idx}`} className="">
                            <BaseRichTooltip
                              touchIcon={
                                device.isLoading ? (
                                  <p style={{ height: 20 }} className="flex-center">
                                    -
                                  </p>
                                ) : (
                                  <p
                                    className={`${
                                      deviceStatusCheck("IOT", device) === "ACTIVE"
                                        ? "ic-woman-b"
                                        : deviceStatusCheck("IOT", device) === "INACTIVE"
                                        ? "ic-woman-g"
                                        : deviceStatusCheck("IOT", device) === "CAUTION"
                                        ? "circle__chip --caution"
                                        : "circle__chip --cannot-use"
                                    } `}
                                  ></p>
                                )
                              }
                              leftLabel={
                                <div className="flex-center">
                                  <p className="font14 text-center" style={{ width: 20 }}>
                                    {idx + 1}
                                  </p>
                                </div>
                              }
                              status={deviceStatusCheck("IOT", device)}
                              children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                              firstActionLabel="상세보기"
                              firstActionFunction={() => {
                                window.open(
                                  PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                    `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </section>
                {/* 층 > 회의실 갯수만큼 순회 */}
                {item.facilityList.map((facility, j) => (
                  <section className="table-monitoring__body-reservation" key={"reservation" + j}>
                    {/* 회의실 명 */}
                    <div className="cell">
                      <p className="w-100 text-left">{facility.facilityName}</p>
                    </div>
                    {/* 출입기기 */}
                    <div className="cell">
                      {facility?.facilityDevices.access?.map((device, idx: number) => (
                        <BaseRichTooltip
                          key={`facility-${device.id}-${idx}`}
                          touchIcon="CIRCLE"
                          leftLabel={
                            <p style={{ width: 20 }} className="font14 text-center">
                              {idx + 1}
                            </p>
                          }
                          status={deviceStatusCheck("ACCESS", device)}
                          children={monitoringTooltipData(undefined, device)}
                        />
                      ))}
                    </div>

                    {/* 재실 */}
                    <div className="cell">
                      {facility.facilityDevices.sensor.map((device, idx: number) => {
                        if (device.isLoading && date) device.isLoading = false;
                        return (
                          <div key={`facility-${device.labelType}-${idx}`}>
                            {device.isLoading && <p style={{ height: 20 }}>-</p>}
                            {!device.isLoading && (
                              <BaseRichTooltip
                                touchIcon={
                                  <p
                                    className={`${
                                      deviceStatusCheck("IOT", device) === "ACTIVE"
                                        ? "ic-man-b"
                                        : deviceStatusCheck("IOT", device) === "INACTIVE"
                                        ? "ic-man-g"
                                        : deviceStatusCheck("IOT", device) === "CAUTION"
                                        ? "circle__chip --caution"
                                        : "circle__chip --cannot-use"
                                    } `}
                                  ></p>
                                }
                                status={deviceStatusCheck("IOT", device)}
                                children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                                firstActionLabel="상세보기"
                                firstActionFunction={() => {
                                  window.open(
                                    PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                      `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* 실내기 */}
                    <div className="cell">
                      {facility.facilityDevices.indoorUnit.map((device, idx: number) => {
                        if (device.isLoading && date) device.isLoading = false;
                        return (
                          <div key={`facility-${device.labelType}-${idx}`}>
                            {device.isLoading && <p style={{ height: 20 }}>-</p>}
                            {!device.isLoading && (
                              <BaseRichTooltip
                                touchIcon="CIRCLE"
                                rightLabel={
                                  device.platform === "ST" ? (
                                    <p className="font14" onClick={() => console.log(device.result)}>
                                      {device.result?.switch?.value === "on" && (
                                        <>
                                          <span className="mr3">
                                            {(device.result?.airConditionerMode && getUnit(device.result?.airConditionerMode?.value)) || ""}
                                          </span>
                                          <span className="mr3">
                                            {(device.result?.coolingSetpoint && `${roundFloat(device.result?.coolingSetpoint?.value)}°C`) || ""}
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  ) : (
                                    <p className="font14" onClick={() => console.log(device.result)}>
                                      {device.result?.operation?.power === "On" && (
                                        <>
                                          <span className="mr3">{(device.result?.mode && getUnit(device.result?.mode?.mode)) || ""}</span>
                                          <span className="mr3">
                                            {(device.result?.temperature && `${roundFloat(device.result.temperature?.desired)}°C`) || ""}
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  )
                                }
                                status={deviceStatusCheck("IOT", device)}
                                children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                                firstActionLabel="상세보기"
                                firstActionFunction={() => {
                                  window.open(
                                    PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                      `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {/* 평균온도 */}

                    <div className="cell">{temperatureAverage(facility.facilityDevices.temperature)}</div>

                    {/* 조명 */}
                    <div className="cell">
                      {facility.facilityDevices.light.map((device, idx: number) => {
                        if (device.isLoading && date) device.isLoading = false;
                        return (
                          <div key={`facility-${device.labelType}-${idx}`}>
                            {device.isLoading && <p style={{ height: 20 }}>-</p>}
                            {!device.isLoading && (
                              <BaseRichTooltip
                                touchIcon="CIRCLE"
                                rightLabel={<p className="font14"></p>}
                                status={deviceStatusCheck("IOT", device)}
                                children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                                firstActionLabel="상세보기"
                                firstActionFunction={() => {
                                  window.open(
                                    PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                      `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* 남자 화장실 */}
                    <div className="cell">
                      {facility.facilityDevices.mensToilet
                        .sort((a, b) => (a.internalDisplayName > b.internalDisplayName ? 1 : -1))
                        .map((device, idx: number) => {
                          if (device.isLoading && date) device.isLoading = false;
                          return (
                            <div key={`facility-${device.labelType}-${idx}`} className="">
                              <BaseRichTooltip
                                touchIcon={
                                  device.isLoading ? (
                                    <p style={{ height: 20 }} className="flex-center">
                                      -
                                    </p>
                                  ) : (
                                    <p
                                      className={`flex-center ${
                                        deviceStatusCheck("IOT", device) === "ACTIVE"
                                          ? "ic-man-b"
                                          : deviceStatusCheck("IOT", device) === "INACTIVE"
                                          ? "ic-man-g"
                                          : deviceStatusCheck("IOT", device) === "CAUTION"
                                          ? "circle__chip --caution"
                                          : "circle__chip --cannot-use"
                                      } `}
                                    ></p>
                                  )
                                }
                                leftLabel={
                                  <div className="flex-center">
                                    <p className="font14 text-center" style={{ width: 20 }}>
                                      {idx + 1}
                                    </p>
                                  </div>
                                }
                                status={deviceStatusCheck("IOT", device)}
                                children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                                firstActionLabel="상세보기"
                                firstActionFunction={() => {
                                  window.open(
                                    PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                      `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                    {/* 여자 화장실 */}
                    <div className="cell">
                      {facility.facilityDevices.womensToilet
                        .sort((a, b) => (a.internalDisplayName > b.internalDisplayName ? 1 : -1))
                        .map((device, idx: number) => {
                          if (device.isLoading && date) device.isLoading = false;
                          return (
                            <div key={`facility-${device.labelType}-${idx}`} className="">
                              <BaseRichTooltip
                                touchIcon={
                                  device.isLoading ? (
                                    <p style={{ height: 20 }} className="flex-center">
                                      -
                                    </p>
                                  ) : (
                                    <p
                                      className={`${
                                        deviceStatusCheck("IOT", device) === "ACTIVE"
                                          ? "ic-woman-b"
                                          : deviceStatusCheck("IOT", device) === "INACTIVE"
                                          ? "ic-woman-g"
                                          : deviceStatusCheck("IOT", device) === "CAUTION"
                                          ? "circle__chip --caution"
                                          : "circle__chip --cannot-use"
                                      } `}
                                    ></p>
                                  )
                                }
                                leftLabel={
                                  <div className="flex-center">
                                    <p className="font14 text-center" style={{ width: 20 }}>
                                      {idx + 1}
                                    </p>
                                  </div>
                                }
                                status={deviceStatusCheck("IOT", device)}
                                children={monitoringTooltipData(device, undefined, deviceStatusCheck("IOT", device) === "CAUTION")}
                                firstActionLabel="상세보기"
                                firstActionFunction={() => {
                                  window.open(
                                    PagePath.building.detail.replace(":id", String(buildingId)).replace(":partnerId", String(partner?.id)) +
                                      `?tab=iotDevice&tabType=detail&tabId=${device.buildingDeviceId}`,
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </section>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MonitoringTable;
