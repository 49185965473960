import { useMemo, useState } from "react";
import { useBlockLayout, useRowSelect, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { ContractDeviceControlMember } from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

type Props = {
  openMemberModal: () => void;
  onClose: () => void;
  columns: any;
  data: ContractDeviceControlMember[];
  handleDeleteMember: (id: string) => Promise<void>;
  isAuthority: boolean;
};

const ExecuteMembersModal = (props: Props) => {
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: props.columns,
      data: props.data,
    },
    useBlockLayout,
    useSticky,
    useRowSelect,
    (hooks) => {
      !props.isAuthority &&
        hooks.visibleColumns.push((columns: Array<any>) => [
          ...columns,
          {
            Header: "삭제",
            accessor: "",
            width: 140,
            Cell: ({ row }: any) => {
              return (
                <button
                  className="base-trash-btn color-gray"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmModal({ isOpen: true, title: "삭제하시겠습니까?", payload: row.original.id });
                  }}
                ></button>
              );
            },
          },
        ]);
    },
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={true}>
      <section className="base-abstract-modal__contents">
        <section className="base-abstract-modal__title flex-center-between ">
          <h1> 명령 제어 실행 권한 </h1>
          {!props.isAuthority && <BaseButton title="+ 이용자 추가" onClick={() => props.openMemberModal()} />}
        </section>

        <div {...getTableProps()} className="base-table view-data-table sticky px30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="pa30  flex-center-end w-100">
        <div className="flex-center">
          <button
            className="base-btn minmax80 color-white mr10"
            onClick={() => {
              props.onClose && props.onClose();
            }}
          >
            닫기
          </button>
        </div>
      </section>
      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true} //
            btnRightTitle="확인"
            btnLeftTitle="취소"
            onClick={() => {
              props.handleDeleteMember(confirmModal.payload as string);
              setConfirmModal({ isOpen: false });
            }}
            onClose={() => setConfirmModal({ isOpen: false })}
            title={confirmModal.title}
          ></BaseModal>
        )}
      </>
    </BaseAbstractModal>
  );
};

export default ExecuteMembersModal;
