import { ServiceTypes } from "../adminMemo/adminmemo-types";
import { Sort } from "../public-types";

export type PriceRuleType = {
    PRICE_RULE_UNRECOGNIZED: 'PRICE_RULE_UNRECOGNIZED'; // 정의되지 않은 상태
    PRICE_RULE_PROVIDER:'PRICE_RULE_PROVIDER'; // 프로바이더별 룰
    PRICE_RULE_FACILITY:'PRICE_RULE_FACILITY'; // 프로바이더 공용시설별 요금 룰
    PRICE_RULE_SPECIFIC:'PRICE_RULE_SPECIFIC'; // 세부요금 룰
}

export const PriceRules = {
    PRICE_RULE_UNRECOGNIZED: 'PRICE_RULE_UNRECOGNIZED', // 정의되지 않은 상태
    PRICE_RULE_PROVIDER:'PRICE_RULE_PROVIDER', // 프로바이더별 룰
    PRICE_RULE_FACILITY:'PRICE_RULE_FACILITY', // 프로바이더 공용시설별 요금 룰
    PRICE_RULE_SPECIFIC:'PRICE_RULE_SPECIFIC', // 세부요금 룰
} as const


export type PriceRuleTypeUnion = 'PRICE_RULE_UNRECOGNIZED' | 'PRICE_RULE_PROVIDER' | 'PRICE_RULE_FACILITY' | 'PRICE_RULE_SPECIFIC'

export interface PriceRulesModel{
    id?:number; // pk, 수정시엔 필수이며 없을 경우 등록
    providerId?:string; // 프로바이더 ID
    buildingCommonFacilityId?:string; // 건물 공용시설 ID
    priceRuleType?: PriceRuleTypeUnion; //룰 종류 (요금옵션)
    name?:string; // 룰 이름 (요금정책명)
    startDate?:string; // 시작일자
    endDate?:string; // 종료일자
    startSlot?:number; //시 작슬롯
    endSlot?:number; // 종료슬롯
    price?:number; // 판매가격
    cost?:number; //단가
    isDeleted?: boolean; // 삭제여부
    createdBy?: string; // 등록자 ID
    createdDate?: string; // 등록일시
    modifiedBy?:string; // 수정자 ID
    modifiedDate?: string; // 수정일시
    startTime?:string;
    endTime?:string;

}


export interface PriceListParams{
    page?:number;
    size?:number;
    sort?: Sort;
    providerId?:string; //프로바이더 ID
    priceRuleIdList?:string; //단건만 검색 (ex 상세조회)
 }

 
 export interface BuildingCommonFacilityPriceRulesParam{
    page?:number;
    size?:number;
    sort?:Sort;
    searchValue?:string;
    serviceId?:string;
    serviceType?:any
 }

 export interface AppliedFacilityListParams{
    page?:number;
    size?:number;
    sort?: Sort;
    providerId:string;
    buildingCommonFacilityIdList?:string;
 }
 export interface BuildingCommonFacilityPriceRulesModel{
    id?:number;
    serviceId?:number; //요금을 관리할 서비스의 ID ex) 후불정산 - contractId
    serviceType?:any;
    buildingCommonFacilityId?:number;
    priceRuleId?:number;
    priority?:number;
    cmdType?:'C' | 'U' | 'D';
    createdBy?:string;
    createdDate?:string;
    modifiedBy?:string;
    modifiedDate?:string;
 }
