import React from "react";
import { useBlockLayout, useTable } from "react-table";

interface Props {
  data?: Array<any>;
  columns: Array<any>;
  [key: string]: any;
}

const SpaceBaseTable = ({ data, columns, ...props }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data ?? [],
      ...props,
    },
    useBlockLayout,
  );
  return (
    <section className="inner-tab-table">
      {/* 추가된 공간 테이블 */}
      <div className="my20">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  // if (! && header.id === "isAllowedOverReservation") return null;
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell) => {
                    // if (! && cell.column.id === "isAllowedOverReservation") return null;

                    return (
                      <div {...cell.getCellProps()} className="base-table__td product_table_td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset">
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SpaceBaseTable;
