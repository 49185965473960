import { Address } from "react-daum-postcode";
import { BuildingCommonFacilityDeskGroupModel, CommonFacilityModel, DeskGroupModel } from "src/api/building/building-types";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { numberToStringWithComma } from "src/utils/common-util";

// select box 공통 option
export type SelectOption = {
  value: string;
  label: string;
  index?: number;
  subOptions?: Array<SelectOption>;
};

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// esl 상세 모달
export type EslDatailModal = {
  isOpen: boolean;
  labelId?: string; // 조회할 label id
};

// 건물 기본정보 입력 form data
export type BasicInfoFormData = {
  buildingName: string;
  addressData: Address & { id?: string };
  zonecode: string;
  address: string;
  addressDetail: string;
  locationCode: string;
  description: string;
  feature: string;
  floorNums: number;
  undergroundFloorNums: number;
  completionYmd: string;
  remodelingYmd: string;
  buildingStructureTypes: Array<BuildingStructure>;
  leasableArea: number;
  leasableAreaPyeong?: string;
  leasableAreaNet?: number;
  leasableAreaNetPyeong?: string;
  rateOfUse?: string;
  exitDirection: string;
  entryStartTimeDate: Date;
  entryEndTimeDate: Date;
  entryStartTime: string;
  entryEndTime: string;
  isHoliday: boolean;
  setIsUsedRestoom: boolean;
  isUsedRestroom: boolean;
  restrooms: Array<Restroom>;
  isUsedElevator: boolean;
  passengerElev: string;
  freightElev: string;
  isUsedAirConditioning: boolean;
  heatingType: string;
  heatingTypeDesc: string;
  heatingFacilityType: string;
  isHeating: string;
  isHeatingEquipment: boolean;
  heatingFacilityTypeDesc: string;
  heatingFacilityTypeAddDesc: string;
};

// 건축물 용도
export type BuildingStructure = {
  seq: number;
  id?: number;
  facility?: string; // 시설군
  use?: string | null; // 용도
};

// 화장실
export type Restroom = {
  seq: number;
  id?: number;
  floor: string; // floorOptions 의 value
  type: string; // restroomTypeOptions 의 value
  manCount?: number;
  womanCount?: number;
  count?: number;
};

// 프로퍼티
export type Property = {
  type: "FLOOR" | "HO";
  id?: number;
  floor?: string; // 층
  floorName?: string; // 층 명칭
  hoId?: number; // 호 id
  ho?: number; // 호
  hoName?: string; // 호 명칭
  dedicatedArea?: number; // 전용면적(m2)
  dedicatedAreaPyeong?: string; // 전용면적(평)
  contractArea?: number; // 임대면적(m2)
  contractAreaPyeong?: string; // 임대면적(평)
  rent?: number; // 임대료
  isGray?: boolean; // 테이블 row 색상 회색 여부 (층일 경우 회색)
  ownerId?: string;
  ownerName?: string;
};

// 좌석 공간
export type DeskSpace = {
  type: "GROUP" | "DESK";
  deskGroupId?: number; // 좌석 그룹 id
  deskId?: number; // 좌석 id
  floor?: string; // 위치. 층
  deskGroupName?: string; // 좌석 그룹 명
  deskGroupDescription?: string; // 설명
  deskChartDescription?: string; // 좌석배치도 설명
  deskName?: string; // 좌석 명
  locationCode?: string; // 좌석 location code
  centerX?: string; // 좌석배치도 X 값
  centerY?: string; // 좌석배치도 Y 값
  isGray?: boolean; // 회색 row 여부
  mediaList?: MediaFile[];
};

// 프론트에서 사용할 좌석 공간
export type FrontDeskSpace = {
  type: "GROUP" | "DESK";
  deskGroupId?: number; // 좌석 그룹 id
  deskId?: number; // 좌석 id
  floor?: string; // 위치. 층
  deskGroupName?: string; // 좌석 그룹 명
  deskGroupDescription?: string; // 설명
  deskChartDescription?: string; // 좌석배치도 설명
  deskName?: string; // 좌석 명
  locationCode?: string; // 좌석 location code
  centerX?: string; // 좌석배치도 X 값
  centerY?: string; // 좌석배치도 Y 값
  isGray?: boolean; // 회색 row 여부
  originalGroupId?: number;
};

/*
  건물 회의실/좌석/편의시설
 */
export interface CommonFacility extends CommonFacilityModel {
  eslLabelCode?: string; // ESL 라벨 코드
  eslLabel?: string; // ESL 매핑주소 {id}-{code}
}

// 건축물 용도 시설군
export const buildingStructureOptions: Array<SelectOption> = [
  {
    value: "BUILDING_STRUCTURE_CAR",
    label: "자동차관련시설군",
    // 용도
    subOptions: [{ value: "BUILDING_STRUCTURE_CAR_1", label: "자동차관련시설" }],
  },
  {
    value: "BUILDING_STRUCTURE_INDUSTRY",
    label: "산업등의시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_INDUSTRY_1", label: "운수시설" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_2", label: "창고시설" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_3", label: "공장" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_4", label: "위험물저장및처리시설" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_5", label: "쓰레기처리시설" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_6", label: "묘지관련시설" },
      { value: "BUILDING_STRUCTURE_INDUSTRY_7", label: "장례식장" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_TELECOM",
    label: "전기, 통신 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_TELECOM_1", label: "방송통신시설" },
      { value: "BUILDING_STRUCTURE_TELECOM_2", label: "발전시설" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_CULTURE",
    label: "문화 및 집회 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_CULTURE_1", label: "문화및집회시설" },
      { value: "BUILDING_STRUCTURE_CULTURE_2", label: "종교시설" },
      { value: "BUILDING_STRUCTURE_CULTURE_3", label: "위락시설" },
      { value: "BUILDING_STRUCTURE_CULTURE_4", label: "관광휴게시설" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_BUSINESS",
    label: "영업 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_BUSINESS_1", label: "판매시설" },
      { value: "BUILDING_STRUCTURE_BUSINESS_2", label: "운동시설" },
      { value: "BUILDING_STRUCTURE_BUSINESS_3", label: "숙박시설" },
      { value: "BUILDING_STRUCTURE_BUSINESS_4", label: "고시원" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_EDU_WELFARE",
    label: "교육 및 복지 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_EDU_WELFARE_1", label: "의료시설" },
      { value: "BUILDING_STRUCTURE_EDU_WELFARE_2", label: "노유자시설" },
      { value: "BUILDING_STRUCTURE_EDU_WELFARE_3", label: "교육연구시설" },
      { value: "BUILDING_STRUCTURE_EDU_WELFARE_4", label: "수련시설" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_COMMUNITY",
    label: "근린생활 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_COMUNITY_1", label: "제1종근린생활시설" },
      { value: "BUILDING_STRUCTURE_COMUNITY_2", label: "제2종근린생활시설" },
    ],
  },
  {
    value: "BUILDING_STRUCTURE_OFFICIAL",
    label: "주거업무 시설군",
    subOptions: [
      { value: "BUILDING_STRUCTURE_OFFICIAL_1", label: "단독주택" },
      { value: "BUILDING_STRUCTURE_OFFICIAL_2", label: "공동주택" },
      { value: "BUILDING_STRUCTURE_OFFICIAL_3", label: "업무시설" },
      { value: "BUILDING_STRUCTURE_OFFICIAL_4", label: "교정및군사시설" },
    ],
  },
];

// 주 출입구 방향
export const exitDirectionOptions: Array<SelectOption> = [
  { value: "PRODUCT_DIRECTION_E", label: "동" },
  { value: "PRODUCT_DIRECTION_W", label: "서" },
  { value: "PRODUCT_DIRECTION_S", label: "남" },
  { value: "PRODUCT_DIRECTION_N", label: "북" },
  { value: "PRODUCT_DIRECTION_SE", label: "남동" },
  { value: "PRODUCT_DIRECTION_SW", label: "남서" },
  { value: "PRODUCT_DIRECTION_NE", label: "북동" },
  { value: "PRODUCT_DIRECTION_NW", label: "북서" },
];

// 화장실 층
export const restroomFloorOptions: Array<SelectOption> = [
  { value: "UNDERGROUND_FLOOR_10", label: "지하 10층" },
  { value: "UNDERGROUND_FLOOR_9", label: "지하 9층" },
  { value: "UNDERGROUND_FLOOR_8", label: "지하 8층" },
  { value: "UNDERGROUND_FLOOR_7", label: "지하 7층" },
  { value: "UNDERGROUND_FLOOR_6", label: "지하 6층" },
  { value: "UNDERGROUND_FLOOR_5", label: "지하 5층" },
  { value: "UNDERGROUND_FLOOR_4", label: "지하 4층" },
  { value: "UNDERGROUND_FLOOR_3", label: "지하 3층" },
  { value: "UNDERGROUND_FLOOR_2", label: "지하 2층" },
  { value: "UNDERGROUND_FLOOR_1", label: "지하 1층" },
  { value: "FLOOR_1", label: "지상 1층" },
  { value: "FLOOR_2", label: "지상 2층" },
  { value: "FLOOR_3", label: "지상 3층" },
  { value: "FLOOR_4", label: "지상 4층" },
  { value: "FLOOR_5", label: "지상 5층" },
  { value: "FLOOR_6", label: "지상 6층" },
  { value: "FLOOR_7", label: "지상 7층" },
  { value: "FLOOR_8", label: "지상 8층" },
  { value: "FLOOR_9", label: "지상 9층" },
  { value: "FLOOR_10", label: "지상 10층" },
  { value: "FLOOR_11", label: "지상 11층" },
  { value: "FLOOR_12", label: "지상 12층" },
  { value: "FLOOR_13", label: "지상 13층" },
  { value: "FLOOR_14", label: "지상 14층" },
  { value: "FLOOR_15", label: "지상 15층" },
  { value: "FLOOR_16", label: "지상 16층" },
  { value: "FLOOR_17", label: "지상 17층" },
  { value: "FLOOR_18", label: "지상 18층" },
  { value: "FLOOR_19", label: "지상 19층" },
  { value: "FLOOR_20", label: "지상 20층" },
  { value: "FLOOR_21", label: "지상 21층" },
  { value: "FLOOR_22", label: "지상 22층" },
  { value: "FLOOR_23", label: "지상 23층" },
  { value: "FLOOR_24", label: "지상 24층" },
  { value: "FLOOR_25", label: "지상 25층" },
  { value: "FLOOR_26", label: "지상 26층" },
  { value: "FLOOR_27", label: "지상 27층" },
  { value: "FLOOR_28", label: "지상 28층" },
  { value: "FLOOR_29", label: "지상 29층" },
  { value: "FLOOR_30", label: "지상 30층" },
  { value: "FLOOR_31", label: "지상 31층" },
  { value: "FLOOR_32", label: "지상 32층" },
  { value: "FLOOR_33", label: "지상 33층" },
  { value: "FLOOR_34", label: "지상 34층" },
  { value: "FLOOR_35", label: "지상 35층" },
  { value: "FLOOR_36", label: "지상 36층" },
  { value: "FLOOR_37", label: "지상 37층" },
  { value: "FLOOR_38", label: "지상 38층" },
  { value: "FLOOR_39", label: "지상 39층" },
  { value: "FLOOR_40", label: "지상 40층" },
  { value: "FLOOR_41", label: "지상 41층" },
  { value: "FLOOR_42", label: "지상 42층" },
  { value: "FLOOR_43", label: "지상 43층" },
  { value: "FLOOR_44", label: "지상 44층" },
  { value: "FLOOR_45", label: "지상 45층" },
  { value: "FLOOR_46", label: "지상 46층" },
  { value: "FLOOR_47", label: "지상 47층" },
  { value: "FLOOR_48", label: "지상 48층" },
  { value: "FLOOR_49", label: "지상 49층" },
  { value: "FLOOR_50", label: "지상 50층" },
];

// 화장실 구분
export const restroomTypeOptions: Array<SelectOption> = [
  { value: "PUBLIC", label: "남여 공용" },
  { value: "SEPARATION", label: "남여 분리" },
];

// 화장실 갯수
export const restroomCountOptions: Array<SelectOption> = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

// 엘리베이터 승객용
export const passengerElevOptions: Array<SelectOption> = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

// 엘리베이터 화물용
export const freightElevOptions: Array<SelectOption> = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

// 냉난방 방식 타입
export const heatingTypeOptions: Array<SelectOption> = [
  { value: "CENTRAL_AIR_CONDITIONER", label: "중앙냉난방" },
  { value: "INDIVIDUAL_AIR_CONDITIONER", label: "개별냉난방" },
  { value: "DIRECT_HEATING_AIR_CONDITIONER", label: "직접입력" },
];

// 냉난방 방식 장비 제공 유형
export const isHeatingOptions: Array<SelectOption> = [
  { value: "true", label: "제공" },
  { value: "false", label: "제공안함" },
];

// 냉난방 방식 장비
export const heatingFacilityTypeOptions: Array<SelectOption> = [
  { value: "CEILING_AIR_CONDITIONER", label: "냉난방기(천장형)" },
  { value: "STAND_UP_AIR_CONDITIONER", label: "냉난방기(거치형)" },
  { value: "DIRECT_FACILITY_AIR_CONDITIONER", label: "직접입력" },
];

// 층 호 구분
export const floorHoTypeOptions: Array<SelectOption> = [
  { value: "FLOOR", label: "층" },
  { value: "HO", label: "호" },
];

// 건물 > 규모 > 층수
export const buildingFloorOptions: Array<SelectOption> = Array.from(Array(50).keys()).map((i: number) =>
  Object.assign({ label: i + 1, value: i + 1 }),
);

// 층
export const floorOptions: Array<SelectOption> = [
  { value: "50", label: "지상 50층" },
  { value: "49", label: "지상 49층" },
  { value: "48", label: "지상 48층" },
  { value: "47", label: "지상 47층" },
  { value: "46", label: "지상 46층" },
  { value: "45", label: "지상 45층" },
  { value: "44", label: "지상 44층" },
  { value: "43", label: "지상 43층" },
  { value: "42", label: "지상 42층" },
  { value: "41", label: "지상 41층" },
  { value: "40", label: "지상 40층" },
  { value: "39", label: "지상 39층" },
  { value: "38", label: "지상 38층" },
  { value: "37", label: "지상 37층" },
  { value: "36", label: "지상 36층" },
  { value: "35", label: "지상 35층" },
  { value: "34", label: "지상 34층" },
  { value: "33", label: "지상 33층" },
  { value: "32", label: "지상 32층" },
  { value: "31", label: "지상 31층" },
  { value: "30", label: "지상 30층" },
  { value: "29", label: "지상 29층" },
  { value: "28", label: "지상 28층" },
  { value: "27", label: "지상 27층" },
  { value: "26", label: "지상 26층" },
  { value: "25", label: "지상 25층" },
  { value: "24", label: "지상 24층" },
  { value: "23", label: "지상 23층" },
  { value: "22", label: "지상 22층" },
  { value: "21", label: "지상 21층" },
  { value: "20", label: "지상 20층" },
  { value: "19", label: "지상 19층" },
  { value: "18", label: "지상 18층" },
  { value: "17", label: "지상 17층" },
  { value: "16", label: "지상 16층" },
  { value: "15", label: "지상 15층" },
  { value: "14", label: "지상 14층" },
  { value: "13", label: "지상 13층" },
  { value: "12", label: "지상 12층" },
  { value: "11", label: "지상 11층" },
  { value: "10", label: "지상 10층" },
  { value: "9", label: "지상 9층" },
  { value: "8", label: "지상 8층" },
  { value: "7", label: "지상 7층" },
  { value: "6", label: "지상 6층" },
  { value: "5", label: "지상 5층" },
  { value: "4", label: "지상 4층" },
  { value: "3", label: "지상 3층" },
  { value: "2", label: "지상 2층" },
  { value: "1", label: "지상 1층" },
  { value: "-1", label: "지하 1층" },
  { value: "-2", label: "지하 2층" },
  { value: "-3", label: "지하 3층" },
  { value: "-4", label: "지하 4층" },
  { value: "-5", label: "지하 5층" },
  { value: "-6", label: "지하 6층" },
  { value: "-7", label: "지하 7층" },
  { value: "-8", label: "지하 8층" },
  { value: "-9", label: "지하 9층" },
  { value: "-10", label: "지하 10층" },
];

// 주차 설비 수량
export const parkingLotNumOptions: Array<SelectOption> = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

// 좌석 구분
export const deskSpaceTypeOptions: Array<SelectOption> = [
  { value: "GROUP", label: "좌석 그룹" },
  { value: "DESK", label: "좌석" },
];

// 공간유형
export const spaceStyleOptions: Array<SelectOption> = [
  { value: "SPACE_STYLE_UNRECOGNIZED", label: "선택해주세요." },
  { value: "ROOM", label: "Rooms" },
  { value: "CONTAINER", label: "Container" },
];

// floor 하위 모든 호실의 target 값을 더한값을 리턴한다
export function sumByFloor(floor: string, target: string, data: Array<Property>, format: string = "0,0.00") {
  const hoList = (data || []).filter((d: Property) => d.type === "HO" && d.floor === floor);
  const sum = hoList.reduce((acc: number, cur: any) => acc + Number(cur[target] || 0), 0);
  return numberToStringWithComma(sum, format);
}

// 프로퍼티 정렬. (층 부터 정렬 후 호 정렬)
export function sortingProperties(properties: Array<Property>) {
  return properties.sort((a: Property, b: Property) => {
    const floor1 = Number(a.floor);
    const floor2 = Number(b.floor);
    const ho1 = Math.abs(Number(a.ho || 0));
    const ho2 = Math.abs(Number(b.ho || 0));
    if (floor1 > floor2) return -1;
    if (floor1 < floor2) return 1;
    if (ho1 < ho2) return -1;
    if (ho1 > ho2) return 1;
    return 0;
  });
}

// 회의실 정렬.
export function sortingMeetingRoomList(meetingRoomList: Array<CommonFacilityModel>) {
  return meetingRoomList.sort((a: CommonFacilityModel, b: CommonFacilityModel) => {
    const isGroundA = a.isGround; // 지상여부
    const floorNumA = a.floorNum; // 위치
    const floorA = Number(`${isGroundA ? "" : "-"}${floorNumA}`);
    const isGroundB = b.isGround; // 지상여부
    const floorNumB = b.floorNum; // 위치
    const floorB = Number(`${isGroundB ? "" : "-"}${floorNumB}`);
    if (floorA > floorB) return -1;
    if (floorA < floorB) return 1;
    return 0;
  });
}

// 좌석 그룹 정렬.
export function sortingDeskGroupList(deskGroupList: Array<BuildingCommonFacilityDeskGroupModel>) {
  return deskGroupList.sort((a: BuildingCommonFacilityDeskGroupModel, b: BuildingCommonFacilityDeskGroupModel) => {
    const isGroundA = a.isGround; // 지상여부
    const floorNumA = a.floorNum; // 층
    const floorA = Number(`${isGroundA ? "" : "-"}${floorNumA}`);
    const isGroundB = b.isGround; // 지상여부
    const floorNumB = b.floorNum; // 층
    const floorB = Number(`${isGroundB ? "" : "-"}${floorNumB}`);
    if (floorA > floorB) return -1;
    if (floorA < floorB) return 1;
    if (Number(a.id || 0) < Number(b.id || 0)) return -1;
    if (Number(a.id || 0) > Number(b.id || 0)) return 1;
    return 0;
  });
}

// 좌석 그룹 정렬. (좌석배치도에서 사용)
export function sortingDeskGroupLayoutList(deskGroupList: Array<DeskGroupModel>) {
  return deskGroupList.sort((a: DeskGroupModel, b: DeskGroupModel) => {
    const isGroundA = a.isGround; // 지상여부
    const floorNumA = a.floorNum; // 층
    const floorA = Number(`${isGroundA ? "" : "-"}${floorNumA}`);
    const isGroundB = b.isGround; // 지상여부
    const floorNumB = b.floorNum; // 층
    const floorB = Number(`${isGroundB ? "" : "-"}${floorNumB}`);
    if (floorA > floorB) return -1;
    if (floorA < floorB) return 1;
    if (Number(a.id || 0) < Number(b.id || 0)) return -1;
    if (Number(a.id || 0) > Number(b.id || 0)) return 1;
    return 0;
  });
}

// 편의시설 정렬.
export function sortingRefreshRoomList(refreshRoomList: Array<CommonFacilityModel>) {
  return refreshRoomList.sort((a: CommonFacilityModel, b: CommonFacilityModel) => {
    const isGroundA = a.isGround; // 지상여부
    const floorNumA = a.floorNum; // 위치
    const floorA = Number(`${isGroundA ? "" : "-"}${floorNumA}`);
    const isGroundB = b.isGround; // 지상여부
    const floorNumB = b.floorNum; // 위치
    const floorB = Number(`${isGroundB ? "" : "-"}${floorNumB}`);
    if (floorA < floorB) return -1;
    if (floorA > floorB) return 1;
    return 0;
  });
}

// 건물 이미지 리스트 전체 불러오기

// export const buildingMediaServiceType = "MEDIA_BUILDING,MEDIA_BUILDING_ETC";
export const buildingMediaServiceType: Array<MediaServiceType> = [
  "MEDIA_BUILDING",
  "MEDIA_BUILDING_INSIDE",
  "MEDIA_BUILDING_FLOOR_ROOM",
  "MEDIA_BUILDING_COMMONFACILITY",
  "MEDIA_BUILDING_ETC",
];

// 좌석 아이템
export type DeskItem = {
  id: string;
  name: string;
  left: number;
  top: number;
};

// 관리처 CRUD 처리요청
export const CmdType = {
  C: "C", // 등록
  U: "U", // 수정
  D: "D", // 삭제
} as const;

export type UnionCmdType = (typeof CmdType)[keyof typeof CmdType];

// 관리처 역할 타입
export const RoleType = {
  UNRECOGNIZED: "ROLE_UNRECOGNIZED",
  MANAGER: "ROLE_MANAGER",
  USER: "ROLE_USER",
} as const;

export type UnionRoleType = (typeof RoleType)[keyof typeof RoleType];

// 관리자 업무타입
export const OfficerType = {
  UNRECOGNIZED: "OFFICER_UNRECOGNIZED", // 정의 안된
  MANAGEMENT: "MANAGEMENT", // 관리
  FACILITY: "FACILITY", // 시설
  CLEANER: "CLEANER", // 미화
  SECURITY: "SECURITY", // 보안과 경비
  CONVENIENCE: "CONVENIENCE", // 생활편의
  CS: "CS", // CS
} as const;

export type UnionOfficerType = (typeof OfficerType)[keyof typeof OfficerType];
