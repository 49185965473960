import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import GoToListButton from "src/components/GoToListButton";
import { productMediaServiceType } from "../../product-types";
import ImageCategory from "./components/ImageCategory";
import { BaseButton } from "src/components";
import { getProductDetailPath } from "src/utils/route-util";
import useNavigate from "src/hooks/usePartnerNavigate";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const mediaServiceTypes: MediaServiceType[] = productMediaServiceType;

const ImageForm = () => {
  const location = useLocation();

  //========= Space product ============

  const [images, setImages] = useState<Array<MediaFile>>([]);
  const navigate = useNavigate();

  // 이미지 불러오기
  const { executeAsync: getProductImages } = useApiOperation(getPrImageAsync);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 공간상품 id
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 이미지 불러오기
  const getProductImagesApi = useCallback(async (serviceId: number) => {
    const data = {
      serviceId,
      mediaServiceTypes: productMediaServiceType,
    };

    const response = await getProductImages(data);
    if (response.status >= 200 || response.status < 300) {
      const result = response.data.data.content;

      setImages(result || []);
    }
  }, []);

  useEffect(() => {
    if (productId) {
      getProductImagesApi(productId);
    }
  }, [getProductImagesApi, productId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <BaseSectionTitle title={"이용안내"} noHashLink />
            {mediaServiceTypes.map((type: MediaServiceType) => (
              <ImageCategory key={type} type={type} images={images} productId={productId || 0} getProductImagesApi={getProductImagesApi} />
            ))}
          </div>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title="수정취소"
            className="mr10 color-white size-large"
            onClick={() => {
              navigate(
                getProductDetailPath({
                  productId: productId ? String(productId) + "?tab=image" : "",
                }),
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ImageForm;
