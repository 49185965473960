import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAccountModel, ProviderAddModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { PagePath } from "src/pages/product/details";
import ProviderAccountDetail from "../basicInfoDetail/components/ProviderAccountDetail";
import ProviderPayletterDetail from "../basicInfoDetail/components/ProviderPayletterDetail";
import ProviderPopbillDetail from "../basicInfoDetail/components/ProviderPopbillDetail";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const AccountInfoDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.id;
  const location = useLocation();

  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();

  const [providerAccounts, setProviderAccounts] = useState<Array<ProviderAccountModel>>([]);

  const { isAuthority } = usePartnerAuthority();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const providerDetailApi = useCallback(async (id: string) => {
    const response = await getProviderDetail({ providerId: id });
    if (response.status >= 200 || response.status <= 299) {
      const result = response.data.data;

      // 계좌정보 - 보증금 -> 관
      const accountList = result.providerAccountList?.sort(
        (a: ProviderAccountModel, b: ProviderAccountModel) => Number(a.accountId) - Number(b.accountId),
      );

      setProviderAccounts(accountList || []);
      setProviderDetail(result);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          {providerDetail && (
            <>
              {/*팝빌 - 계좌 입금 확인 / 세금계산서 발행 정보 */}
              <ProviderPopbillDetail providerPopbill={providerDetail?.providerPopbill} />

              {/* 계좌 정보 */}
              <ProviderAccountDetail providerAccount={providerDetail?.providerAccountList} />

              {/* 카드결제 지원 */}

              <ProviderPayletterDetail providerPayletter={providerDetail?.providerPayletter} />
            </>
          )}
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("BASIC", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.provider.form}?id=${providerId}${location.search.replace("?", "&")}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountInfoDetail;
