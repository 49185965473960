import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cell } from "react-table";
import { ProductGuideListType, ProductGuideTypes } from "src/api/product/product-types";
import { BaseButton } from "src/components";
import Link from "src/components/PartnerLink";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "../..";
import GuideDataTable from "./GuideDataTable";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  guideList: Array<ProductGuideListType>;
  buildingId: string;
  productId: number;
};

const ProductGuideTypeList = ({ guideList, productId, buildingId }: Props) => {
  const { isAuthority } = usePartnerAuthority();

  const navigate = useNavigate();

  const columns: Array<any> = [
    {
      Header: "노출여부",
      accessor: "isDisplayed",
      width: 150,
      Cell: ({ value }: Cell<ProductGuideListType>) => {
        return <span>{value === true ? "노출" : "미노출"}</span>;
      },
    },
    {
      Header: "가중치",
      accessor: "orderNums",
      width: 150,
    },
    {
      Header: "항목 명",
      accessor: "subject",
      width: 770,
      Cell: ({ row, value }: Cell<ProductGuideListType>) => {
        const result = row.original;
        const path = `${PagePath.product.detail.replace(":id", String(productId))}?tab=guide&buildingId=${buildingId}&guideId=${
          result.id
        }&productGuideType=${result.productGuideType}`;

        // const path = `${location.pathname}${location.search}&guideId=${result.id}&productGuideType=${result.productGuideType}`;
        return <Link to={path}>{value}</Link>;
      },
    },
  ];

  const [isRegisteredNetwork, setIsRegisteredNetwork] = useState(false);
  const [isRegisteredParking, setIsRegisteredParking] = useState(false);

  // 네트워크 / 주차 항목이 있는지 확인 (각 빌딩 당 네트워크/주차관련 항목은 1개씩만 등록 가능)
  useEffect(() => {
    setIsRegisteredNetwork(false);
    setIsRegisteredParking(false);

    guideList.forEach((item: ProductGuideListType) => {
      if (item.productGuideType === ProductGuideTypes.GUIDE_WIFI) {
        setIsRegisteredNetwork(true);
      }

      if (item.productGuideType === ProductGuideTypes.PARKINGLOT2) {
        setIsRegisteredParking(true);
      }
    }, []);
  }, [guideList]);

  // 항목추가 form path
  const etcGuidePath = useMemo(() => {
    return `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${buildingId}&productGuideType=${ProductGuideTypes.ETC2}`;
  }, [buildingId, productId]);

  // 네트워크 추가 form path
  const networkGuidePath = useMemo(() => {
    return `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${buildingId}&productGuideType=${ProductGuideTypes.GUIDE_WIFI}`;
  }, [buildingId, productId]);

  // 주차관련 추가 form path
  const parkingGuidePath = useMemo(() => {
    return `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${buildingId}&productGuideType=${ProductGuideTypes.PARKINGLOT2}`;
  }, [buildingId, productId]);

  return (
    <div className="contents-container__table">
      <section>
        <div className="contents-container__grid minheight30">
          <div className="contents-container__grid-index">
            <p className="">{`전체 ${guideList.length}`}</p>
          </div>
          {isAuthority("PRODUCT", "w") && (
            <div className="flex-center-end ">
              <BaseButton
                title={"+항목 추가"}
                className="mr8 minmax150 flex-center-center "
                onClick={() => {
                  navigate(etcGuidePath);
                }}
              />
              <BaseButton
                title={"+네트워크 연결 추가"}
                className="mr8 minmax150 flex-center-center "
                onClick={() => {
                  navigate(networkGuidePath);
                }}
                disabled={isRegisteredNetwork}
              />
              <BaseButton
                title={"+주차 관련 안내 추가"}
                className=" minmax150 flex-center-center "
                onClick={() => {
                  navigate(parkingGuidePath);
                }}
                disabled={isRegisteredParking}
              />
            </div>
          )}
        </div>
        <GuideDataTable columns={columns} data={guideList} />
      </section>
    </div>
  );
};

export default ProductGuideTypeList;
