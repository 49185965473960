import { useRecoilValue } from "recoil";
import { BaseButton } from "src/components";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";

import { useParams } from "react-router-dom";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { ContractFacility, ContractOperationDetail, DetailTotalData } from "src/api/contract/contract-types";
import useGetCommonFacilityList from "src/hooks/contract/useGetCommonFacilityList";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import {
  delete_cols,
  desk_cols,
  duplicate_cols,
  edit_cols,
  meeting_cols,
  overCharge_cols,
  price_cols,
  refresh_cols,
} from "../columns/FacilityColumns";
import SpaceAddModal from "../modal/SpaceAddModal";
import UsageLimitView from "./UsageLimitView";

export interface ContractFacilityTableData extends Omit<ContractFacility, "id">, Omit<CommonFacilityListModel, "id"> {
  id?: string | number;
}

type Props = {
  contractOperationDetail: ContractOperationDetail;
  spaceType: SpaceTypeT;
  contractManageId: string;
  detailData: DetailTotalData;
};

/**
 * 공용공간
 * 토글 활성화시
 */
const ContractSpaceContents = ({ contractOperationDetail, spaceType, contractManageId, detailData }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const { isAuthority } = usePartnerAuthority();
  const partner = useRecoilValue(globalPartnerState);
  const params = useParams();

  /** 파트너의 공용공간 예약의 후불정산 기능 활성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  //isPostPaymentReservation 가 false 면 isAllowedOverReservation(overCharge_cols) 제거
  const newDuplicate_cols = isPostPaymentReservation ? price_cols.concat(duplicate_cols, overCharge_cols) : price_cols.concat(duplicate_cols);
  const newEdit_cols = isAuthority("CONTRACT", "w") ? edit_cols : [];
  const newDelete_cols = isAuthority("CONTRACT", "d") ? delete_cols : [];

  /** 공간별 이용한도, 테이블 컬럼 세팅,  */
  const tableColumns = {
    MEETING_ROOM: [...meeting_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
    DESK: [...desk_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
    REFRESH_ROOM: [...refresh_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
  };

  /** 신청계약의 공용공간 id로 빌딩의 공용공간 리스트를 호출*/
  const { data: commonFacilityList } = useGetCommonFacilityList({
    commonFacilityListParams: {
      searchType: "ID",
      commonFacilityTypeList: spaceType,
      keywordList: contractOperationDetail?.productFacility?.map((data) => data.id).join(","),
    },
  });

  /** 신청계약에 추가된 테이블 데이터 생성 함수 */
  const makeSpaceTableData = (data: {
    contractOperationDetail: ContractOperationDetail;
    spaceType: SpaceTypeT;
    commonFacilityList: CommonFacilityListModel[];
  }) => {
    let resultTableData: ContractFacilityTableData[] = [];

    //건물 공용공간에 신청계약 공용공간을 데이터를 오버라이딩해서 리턴한다
    data.commonFacilityList.forEach((commonFacility) => {
      //신청계약 공용공간 id와 건물 공용공간 id가 같으면 리턴
      const finedContractFacility = data.contractOperationDetail.productFacility?.find(
        (contractFacility) => contractFacility.id === commonFacility.id,
      );

      if (finedContractFacility) {
        resultTableData.push({
          ...commonFacility,
          ...finedContractFacility,
        });
      }
    });

    return resultTableData;
  };

  const tableData = commonFacilityList && makeSpaceTableData({ contractOperationDetail, spaceType, commonFacilityList });

  return (
    <>
      <UsageLimitView contractOperationDetail={contractOperationDetail} detailData={detailData} spaceType={spaceType}></UsageLimitView>

      {/* 공간 추가 버튼 */}
      <div className="flex-center-end mt20">
        {isAuthority("CONTRACT", "w") && (
          <BaseButton
            title={SpaceTypeLabel[spaceType] + " 추가 +"}
            className="ml10"
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <SpaceAddModal
                    addedSpaceIds={tableData?.map((data) => data?.id) as any as string[]} //이미 추가된 공용공간 표시용
                    spaceType={spaceType}
                    contractManageId={contractManageId}
                    contractId={params.id!}
                  />
                ),
              });
            }}
          />
        )}
      </div>

      {/* 추가된 공간 테이블 */}
      <SpaceBaseTable data={tableData} columns={tableColumns[spaceType]} props={contractOperationDetail} detailData={detailData} />
    </>
  );
};

export default ContractSpaceContents;
