import { Cell } from "react-table";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { companyRegistrationHypen } from "src/utils";

const ProviderColumns = [
  {
    Header: "id",
    accessor: "providerId",
    width: 80,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      const detailPath = `${PagePath.provider.detail.replace(":id", String(row.original.providerId))}`;

      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
        // <Link to={detailPath} className="text-underline">
        //   {value}
        // </Link>
      );
    },
  },
  {
    Header: "구분",
    accessor: "supplyType",
    width: 110,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      return <div>{value === "RENTAL" ? "프로바이더" : "관리처"}</div>;
    },
  },
  {
    Header: "법인명",
    accessor: "providerName",
    width: 200,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      return <BaseTooltip className="w-100 text-left" contents={value || "-"} />;
    },
  },
  {
    Header: "사업자 등록 번호",
    accessor: "businessRegistrationNumber",
    width: 140,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return companyRegistrationHypen(value);
    },
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 120,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
];

export default ProviderColumns;
