import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { retryPayment } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal, BaseTooltip } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import StorageProcessingModal from "../modal/StorageProcessing/StorageProcessingModal";
import PaymentStatusView from "../components/PaymentStatusView";
const OverdueInfoColumns: any = [
  {
    Header: "회차",
    accessor: "bill.billOrder",
    sticky: "left",
    width: 70,
    Cell: ({ value, row }: any) => {
      if (value) {
        return <p>{value}</p>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "청구서 명",
    accessor: "bill.billTitle",
    sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      return <BaseTooltip contents={value} />
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  {
    Header: "수납확인",
    accessor: "recieve",
    sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, scheduleId, showBtn }: any) => {
      const [isChange, setIsChange] = useState(false);

      const pDatas = useMemo(() => {
        const datas = {
          row,
          rows,
          callList,
          contractBaseInfo,
          scheduleId,
          showBtn,
          isChange,
          setIsChange,
          type: "schedule",
        };
        return datas;
      }, [callList, contractBaseInfo, isChange, row, rows, scheduleId, showBtn]);

      return (
        <div>
          {/* {moment(today).isSameOrAfter(moment(billDate)) ? ( */}
          <div>
            {showBtn && row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ? (
              <BaseButton
                title={row.original.receiptBundle.receiptList.length > 0 ? "수정" : "수납처리"}
                className="color-white size-small"
                disabled={Number(row.original.bill.totalAmount) > 0 || row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true}
                onClick={() => setIsChange(true)}
              />
            ) : (
              <BaseButton
                title={"수납확인"}
                className="color-white size-small"
                disabled={row.original.billReceipt !== null ? false : row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true}
                onClick={() => setIsChange(true)}
              />
            )}
            {isChange && <StorageProcessingModal processData={pDatas} />}
          </div>
          {/* ) : (
            <p>-</p>
          )} */}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.baseAmount",
    width: 120,
    Cell: ({ value, row }: any) => {
      if (value) {
        return <p>{numberToStringWithComma(value)}</p>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.forEach((row: any) => {
        sum = sum + Number(row.original.bill?.baseAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: "청구일자",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부기한",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      // console.log(row);
      return (
        <div>
          {row.original !== null && row.original.bill !== null && row.original.billDueDate !== undefined
            ? moment(row.original.billDueDate).format(ViewYmdFormat.YYYY_MM_DD)
            : "-"}{" "}
        </div>
      );
    },
  },
  // {
  //   Header: "청구번호",
  //   accessor: "billNumber",
  //   // sticky: "left",
  //   width: 120,
  //   Cell: ({ value }: any) => {
  //     return <p>{value ? value : "-"}</p>;
  //   },
  // },
  // {
  //   Header: "거래일시",
  //   accessor: "payment.transactionDate",
  //   width: 100,
  //   Cell: ({ value }: any) => {
  //     return <div>{value ? <BaseTooltip contents={value} type={"date"} /> : "-"}</div>;
  //   },
  // },
  {
    Header: "연체 기간(일)",
    accessor: "receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{value ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
  },

  {
    Header: "tid",
    accessor: "payment.tid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowTId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];

        billShowTId = payment.tid !== "" ? payment.tid : "-";

        if (payment.virtualAccount !== null) {
          billShowTId = payment.virtualAccount.tid;
        }
        if (payment.paymentCancel !== null) {
          billShowTId = payment.paymentCancel.tid;
        }
      }

      return <p>{billShowTId}</p>;
    },
  },
  {
    Header: "cid",
    accessor: "payment.cid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowCId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];

        billShowCId = payment.cid !== "" ? payment.cid : "-";
        if (payment.virtualAccount !== null) {
          billShowCId = payment.virtualAccount.cid;
        }
        if (payment.paymentCancel != null) {
          billShowCId = payment.paymentCancel.cid;
        }
      }

      return <p>{billShowCId}</p>;
    },
  },
  {
    Header: "즉시결제",
    accessor: "instantPayment",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, rows, showBtn, scheduleId, callList, basicInfo }: any) => {
      const [confirmFlag, setConfirmFlag] = useState(false);
      const [prevStatus, setPrevStatus] = useState<any>("");

      const params = useParams();
      const { openToast } = useToast();

      const { executeAsync: rtp } = useApiOperation(retryPayment);

      const retryPay = useCallback(async () => {
        setConfirmFlag(false);
        const askReturn: any = await rtp({ billId: Number(row.original.bill.billId) });
        if (askReturn.status >= 200 && askReturn.status <= 299 && String(askReturn.data.data.content.result) === "true") {
          await callList();
          openToast({ content: "즉시결제가 정상처리 되었습니다.", isSystemToast: true });
        } else {
          openToast({ content: "즉시결제가 실패하였습니다. 관리자에게 문의해주세요.", isSystemToast: true });
        }
      }, [rtp, row.original.bill.billId, callList, openToast]);
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);

      const checkPrevStatus = useMemo(() => {
        let temp = _.cloneDeep(prevStatus);
        const finded = rows.find((r: any) => Number(r.id) === Number(row.id) - 1);

        if (finded) {
          //console.log(finded.original.bill.billPayStatus);
          temp = finded.original.bill.billPayStatus;
          setPrevStatus(finded.original.bill.billPayStatus);
        }
        return temp;
      }, [prevStatus, row.id, rows]);
      const getBillKey = useMemo(() => {
        let billkey = undefined;
        const find = basicInfo.scheduleList.find((obj: any) => obj.supplyType === "RENTAL");

        if (find) {
          return find.billkey;
        }

        return billkey;
      }, [basicInfo]);

      return (
        <>
          {showBtn ? (
            <>
              <BaseButton
                title={"즉시결제"}
                className="color-white size-small"
                disabled={
                  row.original.bill.pgcode !== "creditcard" ||
                  getBillKey === undefined ||
                  getBillKey === "" ||
                  row.original.receiptBundle.receiptList.length > 0 ||
                  row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ||
                  moment(today).isSameOrBefore(moment(billDate)) ||
                  (row.original.bill.billPayStatus === "PAYMENT_READY" && (checkPrevStatus === "" || checkPrevStatus === "PAYMENT_SUCCESS")
                    ? false
                    : true)
                }
                onClick={() => setConfirmFlag(true)}
              />
              <BaseModal
                title={"즉시 결제하시겠습니까?"}
                isOpen={confirmFlag}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => retryPay()}
                onClose={() => setConfirmFlag(false)}
              ></BaseModal>
            </>
          ) : (
            "-"
          )}
        </>
      );
    },
  },

  {
    Header: () => {
      return (
        <p>
          연체금액
          <br />
          거래일시
        </p>
      );
    },
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(ViewYmdFormat.FULL) : "-";
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      let billIssuDay = moment(row.original.bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD);
      let today = moment().format(ViewYmdFormat.YYYY_MM_DD);

      if (row.original.bill.billPayStatus === "PAYMENT_READY" && moment(today).isAfter(moment(billIssuDay))) {
        value = "PAYMENT_DELAY";
      }

      return <PaymentStatusView value={value} />;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
];
export default OverdueInfoColumns;
