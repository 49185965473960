import moment from "moment";
import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { getContractDashboardList } from "src/api/dashboard";
import { useApiOperation } from "src/api/hooks";
import { getQuestionListAsync } from "src/api/question/qeustion-api";
import { QuestionStatusUnionType } from "src/api/question/question-type";
import { getReservationListAsync } from "src/api/reservation/reservation-api";
import { WorkOrderListAsync } from "src/api/work-order/workorder-api";
import { WorkOrderStatus } from "src/api/work-order/workorder-types";
import { ContentsTitle } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import useApiLoading from "src/hooks/useApiLoading";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { YmdFormat } from "src/utils";
import DashboardDatepickerChip from "./DashboardDatepickerChip";
import DashboardSection from "./components/DashboardSection";
import { CONTRACT_MENU_LIST, QUESTION_MENU_LIST, RESERVATION_MENU_LIST } from "./constants";
import { WORKORDER_MENU_LIST } from "./constants/workorder-menu-list";
import { DashboardChip, IMenuDoubleSection, doubleType } from "./types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const Dashboard = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selected, setSelected] = useState<DashboardChip>({
    today: true,
    sevenDays: false,
    sevenDaysAfter: false,
    oneMonth: false,
    oneMonthAfter: false,
  });
  const { isApiLoading, startApiLoading, stopApiLoading } = useApiLoading();

  const selectToday = () => setSelected({ today: true, sevenDays: false, sevenDaysAfter: false, oneMonth: false, oneMonthAfter: false });
  const selectWeek = () => setSelected({ today: false, sevenDays: true, sevenDaysAfter: false, oneMonth: false, oneMonthAfter: false });
  const selectWeekAfter = () => setSelected({ today: false, sevenDays: false, sevenDaysAfter: true, oneMonth: false, oneMonthAfter: false });
  const selectMonth = () => setSelected({ today: false, sevenDays: false, sevenDaysAfter: false, oneMonth: true, oneMonthAfter: false });
  const selectMonthAfter = () => setSelected({ today: false, sevenDays: false, sevenDaysAfter: false, oneMonth: false, oneMonthAfter: true });

  const clearSelected = () => setSelected({ today: false, sevenDays: false, sevenDaysAfter: false, oneMonth: false, oneMonthAfter: false });

  const location = useLocation();
  const navigate = useNavigate();

  const [contractMenuList, setContractMenuList] = useState(CONTRACT_MENU_LIST);
  const [workorderMenuList, setWorkorderMenuList] = useState(WORKORDER_MENU_LIST);
  const [questionMenuList, setQuestionMenuList] = useState(QUESTION_MENU_LIST);
  const [reservationMenuList, setReservationMenuList] = useState(RESERVATION_MENU_LIST);

  const { executeAsync: getContractListSearchTimeType } = useApiOperation(getContractApply, { noLoading: true });
  const { executeAsync: getContractList } = useApiOperation(getContractDashboardList, { noLoading: true });
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync, { noLoading: true });
  const { executeAsync: getQuestionList } = useApiOperation(getQuestionListAsync, { noLoading: true });
  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync, { noLoading: true });

  const { isAuthority } = usePartnerAuthority();

  const handleChangeChip = (selected: DashboardChip) => {
    selected.today && search({ dateRange: [moment().subtract(0, "days").toDate(), new Date()] });
    selected.sevenDays && search({ dateRange: [moment().subtract(7, "days").toDate(), new Date()] });
    selected.sevenDaysAfter && search({ dateRange: [new Date(), moment().add(7, "days").toDate()] });
    selected.oneMonth && search({ dateRange: [moment().subtract(1, "months").toDate(), new Date()] });
    selected.oneMonthAfter && search({ dateRange: [new Date(), moment().add(1, "months").toDate()] });
  };

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;
    setDateRange(dateRange);
    clearSelected();

    if (!startDate || !endDate) return;
    search({ dateRange });
  };

  const getContractListTotalElements = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;
    const { data } = await getContractList({
      searchStartTime: moment(startDate).startOf("day").format(),
      searchEndTime: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
    });
    return data.data.contractCountList;
  };

  const getContractListTotalElementsToBeDone = async ({
    dateRange,
    searchTimeType,
    showDateProperty,
  }: {
    dateRange: [Date | null, Date | null];
    searchTimeType: string;
    showDateProperty: string;
  }) => {
    const [startDate, endDate] = dateRange;
    const { data } = await getContractListSearchTimeType({
      searchStartTime: moment(startDate).startOf("day").format(),
      searchEndTime: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
      page: 0,
      size: 9999,
      sort: {
        orders: [{ property: showDateProperty, direction: "ASC" }],
      },
      searchTimeType,
      contractStep: ContractStep.USE_PROGRESS + "," + ContractStep.TERMINATE_RECEIVED,
    });
    return { count: data.meta.pageMeta?.totalElements, data: data.data.content };
  };

  const getReservationListTotalElements = async ({ dateRange, status }: { dateRange: [Date | null, Date | null]; status: string }) => {
    const [startDate, endDate] = dateRange;
    const { data } = await getReservationList({
      size: 1,
      start: moment(startDate).startOf("day").format(),
      end: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
      statusCode: status,
    });
    return data.meta.pageMeta?.totalElements;
  };
  const getWorkOrderListTotalElements = async ({
    dateRange,
    isIncludesDelayed,
    status,
  }: {
    dateRange: [Date | null, Date | null];
    isIncludesDelayed?: boolean;
    status?: string;
  }) => {
    const [startDate, endDate] = dateRange;
    const payload = {
      size: 3,
      startDate: moment(startDate).startOf("day").format(),
      endDate: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
      sort: {
        orders: [{ property: "startDate", direction: "ASC" }],
      },
      statusList: status,
    };
    const { data } = await getWorkOrderList(
      isIncludesDelayed
        ? { ...payload, isOverdue: "true", statusList: WorkOrderStatus.OPEN + "," + WorkOrderStatus.WAITING + "," + WorkOrderStatus.PROGRESS }
        : payload,
    );
    // isIncludesDelayed :true 면 count도 추가 (totalElements)
    return isIncludesDelayed ? { data: data.data.content, count: data.meta.pageMeta?.totalElements } : { count: data.meta.pageMeta?.totalElements };
  };

  const getWorkOrderListTotalElementsAsync = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;

    const _workorderMenuList = await Promise.all(
      WORKORDER_MENU_LIST.map(async (doubleSection: IMenuDoubleSection) => {
        if (doubleSection.type === doubleType.bar) {
          const barElement = await Promise.all(
            doubleSection.element.map(async (menu) => {
              const { count } = await getWorkOrderListTotalElements({ dateRange, isIncludesDelayed: false, status: menu.status });
              const path = `${menu.path}&startDate=${moment(startDate).startOf("day").format()}&endDate=${
                moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
              }`;
              return { ...menu, count, path };
            }),
          );
          return { type: doubleType.bar, element: barElement };
        } else if (doubleSection.type === doubleType.detail) {
          // 처리지연
          const detailElement = await Promise.all(
            doubleSection.element.map(async (menu) => {
              const beforeOneYear = moment(new Date()).subtract(1, "years").toDate();
              const { data, count } = await getWorkOrderListTotalElements({ dateRange: [beforeOneYear, new Date()], isIncludesDelayed: true });
              const path = `${menu.path}&startDate=${moment(beforeOneYear).startOf("day").format()}&endDate=${
                moment(new Date()).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
              }`;
              return { ...menu, count, data: data?.slice(0, 3), path };
            }),
          );
          return { type: doubleType.detail, element: detailElement };
        }
      }),
    );
    return _workorderMenuList;
  };

  const getQuestionListTotalElements = async ({
    dateRange,
    status,
    showDateProperty,
  }: {
    dateRange: [Date | null, Date | null];
    status?: QuestionStatusUnionType;
    showDateProperty: string;
  }) => {
    const [startDate, endDate] = dateRange;
    const { data } = await getQuestionList({
      page: 0,
      size: 3,
      sort: {
        orders: [{ property: showDateProperty, direction: "DESC" }],
      },
      statusList: status,
      startDate: moment(startDate).startOf("day").format(),
      endDate: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
    });
    return { count: data.meta.pageMeta?.totalElements ?? -1, data: data.data?.content };
  };

  const getContractListTotalElementsAsync = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;
    const countData = await getContractListTotalElements({ dateRange });
    const _contractMenuList = await Promise.all(
      CONTRACT_MENU_LIST.map(async (doubleSection: IMenuDoubleSection) => {
        if (doubleSection.type === doubleType.bar) {
          const barElement = doubleSection.element.map((menu) => {
            const path = `${menu.path}&searchStartTime=${moment(startDate).startOf("day").format()}&searchEndTime=${
              moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
            }`;
            const findStatus = countData.find((contract) => contract.contractStep === menu.status);
            const count = findStatus?.contractCount || 0;
            return { ...menu, count, path };
          });
          return { type: doubleType.bar, element: barElement };
        } else if (doubleSection.type === doubleType.detail) {
          const detailElement = await Promise.all(
            doubleSection.element.map(async (menu) => {
              if (menu.title === "완료예정") {
                const withInThreeMonths = moment().add(3, "months").toDate();
                const path = `${menu.path}&searchStartTime=${moment(new Date()).startOf("day").format()}&searchEndTime=${
                  moment(withInThreeMonths).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
                }&searchTimeType=CONTRACT_END_TIME`;
                const { count: toBeDoneCount, data: toBeDoneData } = await getContractListTotalElementsToBeDone({
                  dateRange: [new Date(), withInThreeMonths],
                  searchTimeType: "CONTRACT_END_TIME",
                  showDateProperty: menu.showDateProperty || "",
                });

                const threeData = toBeDoneData.slice(0, 3);
                return { ...menu, count: toBeDoneCount, data: threeData, path };
              } else {
                const { data } = await getContractListSearchTimeType({
                  searchStartTime: moment(startDate).startOf("day").format(),
                  searchEndTime: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
                  page: 0,
                  size: 3,
                  contractStep: menu.status,
                  sort: {
                    orders: [{ property: menu.showDateProperty || "", direction: "DESC" }],
                  },
                });
                const path = `${menu.path}&searchStartTime=${moment(startDate).startOf("day").format()}&searchEndTime=${
                  moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
                }`;
                const findStatus = countData.find((contract) => contract.contractStep === menu.status);
                const count = findStatus?.contractCount || -1;
                return { ...menu, count, data: data.data.content, path };
              }
            }),
          );
          return { type: doubleType.detail, element: detailElement };
        }
      }),
    );

    return _contractMenuList;
  };

  const getReservationListTotalElementsAsync = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;
    const reservationMenuListStatus = await Promise.all(
      RESERVATION_MENU_LIST.map(async (doubleSection: IMenuDoubleSection) => {
        let result;
        if (doubleSection.type === doubleType.bar) {
          const barElement = await Promise.all(
            doubleSection.element.map(async (menu) => {
              const count = await getReservationListTotalElements({ dateRange, status: menu.status });
              const path = `${menu.path}&start=${moment(startDate).startOf("day").format()}&end=${
                moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
              }`;
              return { ...menu, count, path };
            }),
          );
          result = { type: doubleType.bar, element: barElement };
        } else if (doubleSection.type === doubleType.detail) {
          return null;
        }
        return result;
      }),
    );

    return reservationMenuListStatus;
  };

  const getQuestionListTotalElementsAsync = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;
    const questionMenuListStatus = await Promise.all(
      QUESTION_MENU_LIST.map(async (doubleSection: IMenuDoubleSection) => {
        let result;
        if (doubleSection.type === doubleType.bar) {
          const barElement = await Promise.all(
            doubleSection.element.map(async (menu) => {
              const { count } = await getQuestionListTotalElements({
                dateRange,
                status: menu.status,
                showDateProperty: menu.showDateProperty ?? "createdDate",
              });
              const path = `${menu.path}&startDate=${moment(startDate).startOf("day").format()}&endDate=${
                moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
              }`;

              return { ...menu, count, path };
            }),
          );
          result = { type: doubleType.bar, element: barElement };
        } else if (doubleSection.type === doubleType.detail) {
          const element = await Promise.all(
            doubleSection.element.map(async (menu) => {
              const { count, data } = await getQuestionListTotalElements({
                dateRange,
                status: menu.status,
                showDateProperty: menu.showDateProperty!,
              });
              const path = `${menu.path}&startDate=${moment(startDate).startOf("day").format()}&endDate=${
                moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
              }`;
              return { ...menu, count, path, data };
            }),
          );
          result = { type: doubleType.detail, element };
        }
        return result;
      }),
    );
    return questionMenuListStatus;
  };

  const search = async ({ dateRange }: { dateRange: [Date | null, Date | null] }) => {
    const [startDate, endDate] = dateRange;

    if (!startDate || !endDate) return;

    navigate({
      search: qs.stringify({
        searchStartTime: moment(startDate).startOf("day").format(),
        searchEndTime: moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
      }),
    });
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as {
      searchStartTime: string;
      searchEndTime: string;
    };

    const _dateRange = [queryParams.searchStartTime, queryParams.searchEndTime].map((date) => (date ? moment(date).toDate() : null)) as [
      Date | null,
      Date | null,
    ];

    const dateRange = _dateRange[0] && _dateRange[1] ? _dateRange : ([moment().subtract(0, "days").toDate(), new Date()] as [Date, Date]);
    const [startDate, endDate] = dateRange;
    const today = new Date();

    moment(today).subtract(0, "days").isSame(startDate, "day") && moment(today).isSame(endDate, "day") && selectToday();
    moment(today).subtract(7, "days").isSame(startDate, "day") && moment(today).isSame(endDate, "day") && selectWeek();
    moment(today).isSame(startDate, "day") && moment(today).add(7, "days").isSame(endDate, "day") && selectWeekAfter();
    moment(today).subtract(1, "months").isSame(startDate, "day") && moment(today).isSame(endDate, "day") && selectMonth();
    moment(today).isSame(startDate, "day") && moment(today).add(1, "months").isSame(endDate, "day") && selectMonthAfter();

    setDateRange(dateRange);

    (async () => {
      try {
        startApiLoading();
        const contractMenuListStatus: any = await getContractListTotalElementsAsync({ dateRange });
        setContractMenuList(contractMenuListStatus);

        const reservationMenuListStatus: any = await getReservationListTotalElementsAsync({ dateRange });

        setReservationMenuList(reservationMenuListStatus);

        const questionMenuListStatus: any = await getQuestionListTotalElementsAsync({ dateRange });
        setQuestionMenuList(questionMenuListStatus);

        const workorderMenuListStatus: any = await getWorkOrderListTotalElementsAsync({ dateRange });
        setWorkorderMenuList(workorderMenuListStatus);
        stopApiLoading();
      } catch (error) {
        console.log(error);
        stopApiLoading();
      }
    })();
  }, [location]);

  const findTotalPath = (menuName: "contract" | "workorder" | "reservation" | "question") => {
    const [startDate, endDate] = dateRange;
    switch (menuName) {
      case "contract":
        const contractBasePath = PagePath.contract.list + "?&contractStep=";
        return `${contractBasePath}&searchStartTime=${moment(startDate).startOf("day").format()}&searchEndTime=${
          moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
        }`;
      case "reservation":
        const reservationBasePath = PagePath.reservation.list + "?statusCode=";
        return `${reservationBasePath}&start=${moment(startDate).startOf("day").format()}&end=${
          moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
        }`;
      case "question":
        const questionBasePath = PagePath.question.list + "?&statusList=";
        return `${questionBasePath}&startDate=${moment(startDate).startOf("day").format()}&endDate=${
          moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
        }`;
      case "workorder":
        const workorderBasePath = PagePath.workOrder.authenticated.list + "?&statusList=";
        return `${workorderBasePath}&startDate=${moment(startDate).startOf("day").format()}&endDate=${
          moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00"
        }`;
      default:
        return "";
    }
  };

  return (
    <div>
      <ContentsTitle title="대시보드">
        <div className="flex-center-start">
          <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
          <DashboardDatepickerChip onSelected={handleChangeChip} selected={selected} />
        </div>
      </ContentsTitle>
      <div className="contents-container__scroll">
        <article className="page-dashboard">
          {isAuthority("CONTRACT", "r") && (
            <DashboardSection title={"신청/계약"} doubleSection={contractMenuList} totalPath={findTotalPath("contract")} sectionName={"contract"} />
          )}
          {isAuthority("WORKORDER", "r") && (
            <DashboardSection title={"워크오더"} doubleSection={workorderMenuList} totalPath={findTotalPath("workorder")} sectionName={"workorder"} />
          )}

          {isAuthority("CS", "r") && (
            <DashboardSection title={"CS"} doubleSection={questionMenuList} totalPath={findTotalPath("question")} sectionName={"question"} />
          )}

          {isAuthority("RESERVATION", "r") && (
            <DashboardSection
              title={"공용공간 예약"}
              doubleSection={reservationMenuList}
              totalPath={findTotalPath("reservation")}
              sectionName={"reservation"}
            />
          )}
        </article>
      </div>
    </div>
  );
};

export default Dashboard;
