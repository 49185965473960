import { useEffect, useState } from "react";

import { Column } from "react-table";

import { CommonFacilityModel } from "src/api/building/building-types";
import { ProductModel } from "src/api/product/product-types";

import { BaseButton } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";

import { useSetRecoilState } from "recoil";
import { baseAbstractModalStateZ1 } from "src/recoil/modalState/atom";
import { desk_cols, meeting_cols, refresh_cols, time_court_delete_cols, time_court_edit_cols, time_court_price_cols } from "../columns/SpaceColumns";
import { makeUsedSpaceTableData } from "../libs";
import SpaceAddModal from "../modal/SpaceAddModal";
import SpaceBaseTable from "./SpaceBaseTable";

type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
  isEditable?: boolean;
  isDeletable?: boolean;
};

/**
 * 공용공간
 * 토글 활성화시
 */
const TimeCourtSpaceContents = ({ spaceType, product, isEditable, isDeletable }: Props) => {
  const setAbstractModalZ1 = useSetRecoilState(baseAbstractModalStateZ1);

  // 테이블 컬럼 : 빈 배열에 공간별 컬럼을 추가하기 위한 state
  const [cols, setCols] = useState<Column<CommonFacilityModel>[]>([]);

  /** 공간별 이용한도, 테이블 컬럼 세팅,  */
  useEffect(() => {
    switch (spaceType) {
      case "MEETING_ROOM":
        setCols(
          meeting_cols
            .concat(time_court_price_cols)
            .concat(isEditable ? time_court_edit_cols : [])
            .concat(isDeletable ? time_court_delete_cols : []),
        );
        break;
      case "DESK":
        setCols(
          desk_cols
            .concat(time_court_price_cols)
            .concat(isEditable ? time_court_edit_cols : [])
            .concat(isDeletable ? time_court_delete_cols : []),
        );
        break;
      case "REFRESH_ROOM":
        setCols(
          refresh_cols
            .concat(time_court_price_cols)
            .concat(isEditable ? time_court_edit_cols : [])
            .concat(isDeletable ? time_court_delete_cols : []),
        );
        break;
    }
  }, [spaceType, isEditable, isDeletable]);

  return (
    <>
      {/* 공간 추가 버튼 */}
      <div className="flex-center-end mt20">
        {isEditable && (
          <BaseButton
            title={SpaceTypeLabel[spaceType] + " 추가 +"}
            className="ml10"
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <SpaceAddModal
                    addedSpaceIds={makeUsedSpaceTableData(product, spaceType)?.map((data) => data?.id) as any as string[]}
                    spaceType={spaceType}
                  />
                ),
              });
            }}
          />
        )}
      </div>

      {/* 추가된 공간 테이블 */}
      <SpaceBaseTable data={makeUsedSpaceTableData(product, spaceType)} columns={cols} />

      {spaceType === "DESK" && (
        <div>
          <div className="contents-container__grid mt20">
            <div className="contents-container__grid-index">
              <p className="">배치도</p>
            </div>
          </div>
          <div className="mt20">
            <ImagesDetail
              images={product.buildingList?.map((building) => building?.buildingCommonFacility?.deskSpace?.mediaList ?? []).flat() ?? []}
              isUsedDescription={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TimeCourtSpaceContents;
