import { useRecoilValue } from "recoil";
import { ProductModel } from "src/api/product/product-types";
import { BaseButton } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { delete_cols, desk_cols, duplicate_cols, edit_cols, meeting_cols, refresh_cols } from "../columns/SpaceColumns";
import { makeUsedSpaceTableData } from "../libs";
import SpaceAddModal from "../modal/SpaceAddModal";
import SpaceBaseTable from "./SpaceBaseTable";
import UsageLimitView from "./UsageLimitView";
import { useMemo } from "react";

type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
  isEditable?: boolean;
  isDeletable?: boolean;
};

/**
 * 공용공간
 * 토글 활성화시
 */
const SpaceContents = ({ product, spaceType, isEditable, isDeletable }: Props) => {
  const { setAbstractModalZ1 } = useModal();

  const partner = useRecoilValue(globalPartnerState);

  /** 파트너의 공용공간 예약의 후불정산 기능 활성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  //isPostPaymentReservation 가 false 면 duplicate_cols컬럼에서 isAllowedOverReservation 제거
  const newDuplicate_cols = isPostPaymentReservation ? duplicate_cols : duplicate_cols.filter((data) => data.accessor !== "isAllowedOverReservation");
  const newEdit_cols = useMemo(() => (isEditable ? edit_cols : []), [isEditable]);
  const newDelete_cols = useMemo(() => (isDeletable ? delete_cols : []), [isDeletable]);

  /** 공간별 이용한도, 테이블 컬럼 세팅,  */
  const tableColumns = {
    MEETING_ROOM: [...meeting_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
    DESK: [...desk_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
    REFRESH_ROOM: [...refresh_cols, ...newDuplicate_cols, ...newEdit_cols, ...newDelete_cols],
  };

  return (
    <>
      <UsageLimitView product={product} spaceType={spaceType}></UsageLimitView>

      {/* 공간 추가 버튼 */}
      <div className="flex-center-end mt20">
        {isEditable && (
          <BaseButton
            title={SpaceTypeLabel[spaceType] + " 추가 +"}
            className="ml10"
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <SpaceAddModal
                    addedSpaceIds={makeUsedSpaceTableData(product, spaceType)?.map((data) => data?.id) as any as string[]}
                    spaceType={spaceType}
                  />
                ),
              });
            }}
          />
        )}
      </div>

      {/* 추가된 공간 테이블 */}
      <SpaceBaseTable data={makeUsedSpaceTableData(product, spaceType)} columns={tableColumns[spaceType]} />

      {spaceType === "DESK" && (
        <div>
          <div className="contents-container__grid mt20">
            <div className="contents-container__grid-index">
              <p className="">배치도</p>
            </div>
          </div>
          <div className="mt20">
            <ImagesDetail
              images={product.buildingList?.map((building) => building?.buildingCommonFacility?.deskSpace?.mediaList ?? []).flat() ?? []}
              isUsedDescription={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SpaceContents;
