import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { ContractFacility, DetailTotalData } from "src/api/contract/contract-types";
import LimitFrom from "src/pages/product/forms/commonSpaceForm/modal/LimitFrom";
import Price from "src/pages/product/forms/commonSpaceForm/modal/Price";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { ContractFacilityTableData } from "../components/ContractSpaceContents";
import usePostOperationFacility from "src/hooks/contract/usePostOperationFacility";
import { decommaizeNumber } from "@toss/utils";

interface Props {
  space: ContractFacilityTableData;
  isEditable: boolean;
  detailData: DetailTotalData;
}

/** 신청계약 요금,이용한도 설정 모달 */
const SettingModal = ({ space, isEditable, detailData }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const partner = useRecoilValue(globalPartnerState);
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;
  const spaceName = space.facilityName;
  const spaceType = space.commonFacilityType as SpaceTypeT;

  const productId = detailData?.product?.id;

  const { mutate } = usePostOperationFacility();

  const defaultTabs = useMemo(() => {
    if (isPostPaymentReservation) {
      return [
        { value: "price", label: "요금", isActive: true },
        { value: "usage", label: "이용한도", isActive: false },
      ];
    } else {
      return [{ value: "price", label: "요금", isActive: true }];
    }
  }, [isPostPaymentReservation]);

  const [tabs, setTabs] = useState<{ value: string; label: string; isActive: boolean }[]>(defaultTabs);
  const onChangeTab = (tabValue: string) => {
    setTabs(tabs.map((tab) => ({ ...tab, isActive: tab.value === tabValue ? true : false })));
  };

  console.log("신청계약 요금,이용한도 설정 모달 - SettingModal - space :>> ", space);

  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: space.isUnlimitedReservation,
    //월 이용한도 체크여부
    isMonthlyUnLimit: space.isLimitedMonthlyReservation,
    //일 이용한도 체크여부
    isDailyUnLimit: space.isLimitedDailyReservation,
    //시간
    monthlyTime: space.monthlyServiceMinutes,
    dailyTime: space.dailyServiceMinutes,
    //초과 예약 여부
    isAllowOverReservation: space.isAllowedOverReservation,
  });

  const handleSubmit = () => {
    //LimitFrom 내부에서 수정된 editObj 로 수정 데이터를 생성
    const commonFacility: ContractFacility = {
      contractFacilityId: space.contractFacilityId, // string - 계약 공용시설 ID (신규 공용시설 추가 시: 계약 공용시설 ID 0 입력)
      id: String(space.id), // string - 건물 공용시설 ID (공간상품 관리)
      // resourceEmail: "example@example.com", // string - 리소스 캘린더 ID
      isUnlimitedReservation: editObj.isUnLimitReservation, // boolean - 예약 무제한 여부
      isAllowedOverReservation: editObj.isAllowOverReservation, // boolean - 초과예약 허용 여부
      isLimitedMonthlyReservation: editObj.isMonthlyUnLimit, // boolean - 월별 예약제한 사용 여부
      monthlyServiceMinutes: editObj.monthlyTime?.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime?.toString()!), // integer - 월별 제공시간(분)
      isLimitedDailyReservation: editObj.isDailyUnLimit, // boolean - 일별 예약제한 사용 여부
      dailyServiceMinutes: editObj.dailyTime?.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime?.toString()!), // integer - 일별 예약제한 시간(분)
    };

    //공용공간 생성/수정 함수 RCA37. 계약 - 운영정보 공용시설 수정 API 사용
    mutate(
      { contractId: Number(detailData.contract?.contractId), productFacilityList: [commonFacility] },
      { onSuccess: () => setAbstractModalZ1({ isOpen: false }) },
    );
  };

  return (
    <div key={spaceName} style={{ overflowX: "scroll" }}>
      {isEditable && (
        <>
          <section className="base-abstract-modal__title px30 pb20">
            <div className="w-100 flex-row">
              <h1>
                {SpaceTypeLabel[spaceType]} {spaceName} 설정
              </h1>
            </div>
          </section>
          <div className="contents-title__tab border-bottom-gray px30">
            {tabs?.map((tab) => {
              return (
                <div key={tab.value} className={`${tab?.isActive ? "active" : ""}`} onClick={() => onChangeTab(tab.value)}>
                  <span>{tab.label}</span>
                </div>
              );
            })}
          </div>
        </>
      )}

      {tabs.map((tab) => {
        if (tab.value === "price" && tab.isActive) {
          return (
            <Price
              space={space as any}
              isEditable={true}
              productId={productId}
              serviceId={String(detailData.contract?.contractId)}
              serviceType="SERVICE_CONTRACT"
            />
          );
        }

        if (tab.value === "usage" && tab.isActive) {
          return (
            <LimitFrom
              spaceType={spaceType}
              editObj={editObj as any}
              setEditObj={setEditObj as any}
              onClose={() => setAbstractModalZ1({ isOpen: false })}
              handleSubmit={() => handleSubmit()}
            />
          );
        }
      })}
    </div>
  );
};
export default SettingModal;
