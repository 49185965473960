import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getContractOperationInfo } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import {
  addUsers,
  calendarStatus,
  delUser,
  getUser,
  resolveWatchCalendar,
  retryWatchCalendar,
  unWatchCalendar,
  watchCalendar,
} from "src/api/user/user-api";
import { MemberStatus, UserDetailInvietee, UserDetailModel } from "src/api/user/user-types";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import useContractApi from "src/pages/contract/hooks/useContractApi";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat, formatPhoneNumber } from "src/utils/common-util";

const UserDetail = () => {
  const { isAuthority } = usePartnerAuthority();
  const { openErrorModal } = useErrorModal();
  const { callDetails } = useContractApi();
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = [
    {
      label: "기본정보",
      value: "basic",
      disabled: false,
    },
  ];
  const query = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { memberId: contractMemberId } = useParams();
  const { openToast } = useToast();

  const [isDelete, setIsDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdateCheck, setIsUpdateCheck] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [isNotMaster, setIsNotMaster] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showIssue, setShowIssue] = useState("-");
  const [isRetryInvite, setIsRetryInvite] = useState(false);

  const [user, setUser] = useState<UserDetailModel>();

  const [mId, setMId] = useState<Number>();
  const [viewStatus, setViewStatus] = useState("");
  const [operation, setOperation] = useState<any>();
  const partner = useRecoilValue(globalPartnerState);
  const [contractDetail, setContractDetail] = useState<any>();
  const [cnumber, setCNumber] = useState("");

  const { executeAsync: detail } = useApiOperation(getUser, { noHandleError: true });
  const { executeAsync: dUser } = useApiOperation(delUser, { noHandleError: true });
  const { executeAsync: changeCalendar } = useApiOperation(watchCalendar, {
    noHandleError: true,
  });

  const { executeAsync: changeCalendar2 } = useApiOperation(unWatchCalendar, {
    noHandleError: true,
  });
  const { executeAsync: getCalStatue } = useApiOperation(calendarStatus, {
    noHandleError: true,
  });

  const { executeAsync: retryRelationCalendar } = useApiOperation(retryWatchCalendar, {
    noHandleError: true,
  });
  const { executeAsync: resolveRelationCalendar } = useApiOperation(resolveWatchCalendar, {
    noHandleError: true,
  });
  const { executeAsync: memberDetail } = useApiOperation(getMemberDetail, {
    noHandleError: true,
  });

  // const { executeAsync: retryInviteU } = useApiOperation(retryInviteUser, {
  //   noHandleError: true,
  // });

  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    noHandleError: true,
  });

  const { executeAsync: reInviteUser } = useApiOperation(addUsers, {
    noHandleError: true,
  });

  const callOperation = useCallback(
    async (id: number) => {
      const contractDetails = await callDetails(String(id));

      setContractDetail(contractDetails);

      const res: any = await getOperation({ id: Number(id) });
      if (res.status >= 200 && res.status <= 299) {
        console.log("res :>> ", res.data.data.content);
        const length = res.data.data.content.productManage.clientSecretValue.length;

        res.data.data.content.productManage.clientSecretValueBlocked = "*".repeat(length);
        setOperation(res.data.data.content);
      } else {
        console.log(`res`, res);
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },
    [getOperation, openErrorModal],
  );

  const callMemberDetail = useCallback(
    async (id: string) => {
      const response: any = await memberDetail({ id });
      if (response.status >= 200 && response.status <= 299) {
        setMId(response.data.data.member.id);
      }
    },
    [memberDetail],
  );

  const callDetail = useCallback(
    async (contractMemberId: number) => {
      const res: any = await detail({ contractMemberId });
      res.data.data.contractManageId = query.contractManageId;
      if (res.status >= 200 && res.status <= 299) {
        if (res.data.data?.hrUser?.isExistsIssues !== undefined && String(res.data.data?.hrUser?.isExistsIssues) === "true") {
          let text = "실패 (";

          if (res.data.data?.hrUser?.isResolvable === true) {
            text = text + "재 연동 필요) ";
          } else {
            text = text + "관리자 문의) ";
          }
          text += moment(res.data.data?.hrUser?.issueOccurredDate).format(YmdFormat.FULL);
          setShowIssue(text);
        }

        //
        setUser(res.data.data);
        if (res.data.data.content.contractId > 0) callOperation(res.data.data.content.contractId);
        setViewStatus(res.data.data.content.memberStatus);
      } else {
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },

    [callMemberDetail, callOperation, detail, openErrorModal, query.contractManageId],
  );

  const retryCalendar = useCallback(async () => {
    const response: any = await retryRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });

    if (response.status >= 200 && response.status <= 299) {
      openToast({
        type: "SUCCESS",
        content: "캘린더 동기화 요청에 성공하였습니다.",
      });
      callDetail(Number(contractMemberId));
    } else {
      openToast({
        type: "FAIL",
        content: "캘린더 동기화 요청에 실패하였습니다.",
      });
    }
  }, [callDetail, contractMemberId, retryRelationCalendar, user]);

  const resolveCalendar = useCallback(async () => {
    const response: any = await resolveRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (response.status >= 200 && response.status <= 299) {
      // setSuccess("캘린더 연동에 성공하였습니다.");
      openToast({
        type: "SUCCESS",
        content: "캘린더 동기화 요청에 성공하였습니다.",
      });
      callDetail(Number(contractMemberId));
    } else {
      openToast({
        type: "FAIL",
        content: "캘린더 동기화 요청에 실패하였습니다.",
      });
    }
  }, [callDetail, contractMemberId, resolveRelationCalendar, user?.content.memberNo, user?.hrUser.contractId, user?.hrUser.email]);

  const retryInvite = useCallback(async () => {
    setIsRetryInvite(false);

    const res: any = await reInviteUser({
      contractManageId: user?.contractManageId,
      contractMemberList: [{ inviteMobileNumber: user?.content.inviteMobileNumber }],
      partnerId: partner?.id,
    });

    if (res.status >= 200 && res.status <= 299) {
      callDetail(Number(contractMemberId));
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [callDetail, contractMemberId, openErrorModal, user?.content.contractMemberId]);

  const onClickUpdate = useCallback(async () => {
    const res: any = await changeCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("캘린더 연동에 성공하였습니다.");
      setIsDeleted(true);
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [changeCalendar, openErrorModal, user]);

  const onClickFalseUpdate = useCallback(async () => {
    const res: any = await changeCalendar2({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("캘린더 미연동에 성공하였습니다.");
      setIsDeleted(true);
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [changeCalendar2, openErrorModal, user?.content.memberNo, user?.hrUser.contractId, user?.hrUser.email]);

  const onMasterToggle = useCallback(
    async (isAdmin: boolean | undefined) => {
      setIsMaster(false);
      setIsNotMaster(false);
      const res: any = await dUser({
        contractMemberId: Number(contractMemberId),
        contractManageId: Number(contractDetail?.contractManage?.contractManageId),
        isAdmin: isAdmin,
        delete: false,
      });
      if (res.status >= 200 && res.status <= 299) {
        setSuccess("권한이 변경되었습니다.");
        setIsDeleted(true);
      } else {
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },
    [contractMemberId, dUser, openErrorModal, contractDetail?.contractManage?.contractManageId],
  );

  const onDelete = useCallback(async () => {
    const res: any = await dUser({
      contractMemberId: Number(contractMemberId),
      contractManageId: Number(contractDetail?.contractManage?.contractManageId),
      delete: true,
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("삭제 되었습니다.");
      setIsDeleted(true);
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [contractMemberId, dUser, openErrorModal, contractDetail?.contractManage?.contractManageId]);

  const onDeleted = useCallback(() => {
    // const path = PagePath.user.list + "?contractApplyNumber=" + query.contractApplyNumber;
    // navigate(path);
    setIsDeleted(false);
    setIsEditable(false);
    callDetail(Number(contractMemberId));
  }, []);

  const sendMemberDetail = useCallback((inviteeInfo?: UserDetailInvietee) => {
    let rtn = "";
    if (inviteeInfo && inviteeInfo?.inviteeMemberId) {
      const path = PagePath.member.detail.replace(":id", String(inviteeInfo?.inviteeMemberId));
      rtn = path;
    }
    return rtn;
  }, []);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (query?.tab) {
      return tabs.find((tab) => tab.value === query?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...query };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, query],
  );

  useEffect(() => {
    if (contractMemberId) {
      callDetail(Number(contractMemberId));
    }
  }, [contractMemberId, location]);

  return (
    <>
      <MetaTag subTitle={String(user?.content.memberNo)} />
      <div>
        <ContentsTitle
          title="이용자 초대"
          tabs={tabs}
          activeTab={activeTab}
          clickTab={clickTab}
          detailInfo={[
            { title: "id", value: contractMemberId! },
            { title: "신청번호", value: String(query?.contractApplyNumber!) },
          ]}
        />
        <div className="contents-container__scroll">
          <div className="contents-container__wrap page-user-form">
            {activeTab!.value === "basic" && (
              <>
                <article className="contents-container__wrap-article">
                  <BaseSectionTitle title={"기본정보"} />
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>회원번호</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>{user?.content.memberNo ? <p>{user?.content.memberNo}</p> : "-"}</div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>휴대폰 번호</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{formatPhoneNumber(user?.content.inviteMobileNumber!)}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>이메일</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p>{user?.hrUser.email ? user?.hrUser.email : "-"}</p>
                      </div>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>이메일 인증</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>{user?.hrUser.isEmailVerified ? <p>{user?.hrUser.isEmailVerified ? "인증" : "미인증"}</p> : <p>{"-"}</p>}</div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>닉네임</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p>{user?.hrUser.nickname || "-"}</p>
                      </div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>상태</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-center-start flex-row">
                        <p
                          className={`${
                            (viewStatus === "MEMBER_INVITE" && "status A") ||
                            (viewStatus === "MEMBER_USE" && "status B") ||
                            (viewStatus === "MEMBER_DELETE" && "status D") ||
                            (viewStatus === "MEMBER_CLOSE" && "status D") ||
                            (viewStatus === "MEMBER_EXPIRE" && "status D")
                          }`}
                        >
                          {viewStatus === MemberStatus.MEMBER_INVITE && "초대"}
                          {viewStatus === MemberStatus.MEMBER_USE && "사용"}
                          {viewStatus === MemberStatus.MEMBER_DELETE && "삭제"}
                          {viewStatus === MemberStatus.MEMBER_CLOSE && "종료"}
                          {viewStatus === MemberStatus.MEMBER_EXPIRE && "만료"}
                        </p>
                        {isAuthority("CONTRACT", "w") && viewStatus === MemberStatus.MEMBER_EXPIRE && (
                          <BaseButton title="재 초대" onClick={() => setIsRetryInvite(true)} className="color-white ml10" />
                        )}
                      </div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>초대일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{user?.content?.inviteDate ? moment(user?.content?.inviteDate).format(ViewYmdFormat.FULL) : "-"}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>가입일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{user?.hrUser?.signUpTime ? moment(user?.hrUser?.signUpTime).format(ViewYmdFormat.FULL) : "-"}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>초대자</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <span>
                          {user?.inviteeInfo?.inviteeMemberId
                            ? user?.inviteeInfo?.inviteeMemberId
                            : user?.inviteeInfo?.inviteeMemberNo
                            ? user?.inviteeInfo?.inviteeMemberNo
                            : "-"}
                        </span>
                        <span className="ml10">
                          (
                          {user?.inviteeInfo?.inviteeMemberId
                            ? "Central"
                            : formatPhoneNumber(user?.inviteeInfo?.inviteePhoneNumber!)
                            ? user?.inviteeInfo?.inviteePhoneNumber
                            : "-"}
                          )
                        </span>
                      </div>
                    </div>
                  </section>
                  {operation && operation?.productManage?.isUseCalendar === true && (
                    <div>
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>캘린더 연동</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          {isEditable === false ? (
                            <div>
                              <p>{user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}</p>
                            </div>
                          ) : (
                            <div className="flex-center-start flex-row">
                              <span className="mr10">{user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}</span>

                              <BaseButton
                                title={user?.hrUser?.isWatchingCalendar ? "미연동" : "연동"}
                                onClick={() => (user?.hrUser?.isWatchingCalendar === false ? onClickUpdate() : onClickFalseUpdate())}
                                className="color-white"
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      {user?.hrUser?.isWatchingCalendar && (
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>캘린더 동기화</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            {isEditable === false ? (
                              <div>
                                <p>{showIssue}</p>
                              </div>
                            ) : (
                              <div className="flex-center-start flex-row">
                                <span className="mr10">
                                  <p>{showIssue}</p>
                                </span>

                                {user?.hrUser?.isExistsIssues !== undefined && user?.hrUser?.isExistsIssues === false && (
                                  <BaseButton title="재시도" onClick={() => retryCalendar()} className="color-white" />
                                )}
                                {user?.hrUser?.isExistsIssues !== undefined &&
                                  user?.hrUser?.isExistsIssues === true &&
                                  user?.hrUser?.isResolvable === true && (
                                    <BaseButton title="재시도" onClick={() => resolveCalendar()} className="color-white" />
                                  )}
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </div>
                  )}
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>권한</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      {isEditable === false ? (
                        <div>
                          <p>
                            {user?.content?.isAdmin === false && user?.content?.isContractor === false && "없음"}
                            {user?.content?.isAdmin === false && user?.content?.isContractor === true && "계약자"}
                            {user?.content?.isAdmin === true && user?.content?.isContractor === true && "계약자, 마스터"}
                            {user?.content?.isAdmin === true && user?.content?.isContractor === false && "마스터"}
                          </p>
                        </div>
                      ) : (
                        <div className="flex-center-start flex-row">
                          <span className="mr10">
                            <div className="flex-center-start flex-row">
                              <div>{user?.content?.isAdmin === false && user?.content?.isContractor === false && "없음"}</div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === false && user?.content?.isContractor === true && "계약자"}
                              </div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === true && user?.content?.isContractor === true && "계약자, 마스터"}
                              </div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === true && user?.content?.isContractor === false && "마스터"}
                              </div>
                              {user?.content?.isContractor === false && (
                                <BaseButton
                                  title={user?.content?.isAdmin === false ? "마스터 지정" : "마스터 해제"}
                                  onClick={() => {
                                    if (user?.content?.isAdmin === true) {
                                      setIsNotMaster(true);
                                    } else {
                                      setIsMaster(true);
                                    }
                                  }}
                                  className="ml10 color-white"
                                />
                              )}
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                  </section>
                </article>
                <RegistrationInformation data={user?.content} />

                {isEditable === false && <AdminMemoV3 serviceId={Number(contractMemberId)} serviceType={ServiceTypes.SERVICE_INVITED_USER} />}
              </>
            )}
          </div>
        </div>
        <BaseModal
          title="해당 정보를 삭제하시겠습니까?"
          isOpen={isDelete}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsDelete(false)}
          onClick={onDelete}
        />

        <BaseModal title={success} isOpen={isDeleted} btnRightTitle={"확인"} onClick={onDeleted} />
        <BaseModal title={message} isOpen={isUpdateCheck} btnRightTitle={"확인"} onClick={() => setIsUpdateCheck(false)} />
        <BaseModal
          title="마스터 지정하시겠습니까?"
          isOpen={isMaster}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsMaster(false)}
          onClick={() => onMasterToggle(true)}
        >
          <div>
            <p>해당 이용자는 마스터가 되면, 다른 이용자를 초대/삭제할 수 있는 권한을 가지게 됩니다.</p>
          </div>
        </BaseModal>
        <BaseModal
          title="마스터 해제하시겠습니까?"
          isOpen={isNotMaster}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsNotMaster(false)}
          onClick={() => onMasterToggle(false)}
        >
          <div>
            <p>해당 이용자는 즉시 다른 이용자를 초대/삭제할 수 있는 권한이 없어지게 됩니다.</p>
          </div>
        </BaseModal>
        <BaseModal
          title="재 초대를 하시겠습니까?"
          isOpen={isRetryInvite}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsRetryInvite(false)}
          onClick={() => retryInvite()}
        />
        <div className="contents-container__btn-wrap">
          <div className="left-area d-flex">
            {isEditable ? null : <GoToListButton title={"목록으로"} />}

            <div className="right-area">
              {isAuthority("CONTRACT", "d") &&
                (((user?.content?.isContractor !== true && user?.content?.memberStatus === MemberStatus.MEMBER_USE) ||
                  user?.content?.memberStatus === MemberStatus.MEMBER_INVITE) &&
                !isEditable ? (
                  <BaseButton title="삭제" onClick={() => setIsDelete(true)} className="no-outline color-white size-large text-red900 ml10" />
                ) : (
                  <div className="ml40">
                    <p className="contents-container__sub-title-info">{"계약자는 이용자 삭제가 되지 않습니다."}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className="right-area">
            {isAuthority("CONTRACT", "w") && user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === false && (
              <BaseButton title="수정" onClick={() => setIsEditable(true)} className="size-large" />
            )}
            {user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === true && (
              <>
                <BaseButton title={"수정취소"} className="color-white size-large mr10" onClick={() => setIsEditable(false)} />
                <BaseButton title="저장" className="size-large ml10" onClick={() => setIsEditable(false)} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
