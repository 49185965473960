import { useState } from "react";
import { ProductModel } from "src/api/product/product-types";
import { SpaceType as ConstSpaceType, SpaceTypeT } from "src/pages/product/product-types";

import { decommaizeNumber } from "@toss/utils";
import { resData } from "../CommonSpace";
import LimitFrom from "./LimitFrom";
import useUpdateProduct from "src/hooks/product/useUpdateProduct";

type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
  onClose: () => void;
};

export const SpaceTypeReqDataMap = {
  [ConstSpaceType.MEETING_ROOM]: {
    isUnLimitReservation: "isUnlimitedMeetingRoom",
    isMonthlyUnLimit: "isLimitedMeetingRoomMonthlyReservation",
    monthlyTime: "meetingRoomMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedMeetingRoomDailyReservation",
    dailyTime: "meetingRoomMaxReservTimeDay",
    isAllowOverReservation: "isAllowedMeetingRoomOverReservation",
    contractTotalTime: "meetingRoomTotalTime", // TotalTime을 월별 예약 가능 시간으로 사용
    contractDailyTime: "meetingRoomDailyTime",
  },
  [ConstSpaceType.DESK]: {
    isUnLimitReservation: "isUnlimitedDesk",
    isMonthlyUnLimit: "isLimitedDeskMonthlyReservation",
    monthlyTime: "deskMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedDeskDailyReservation",
    dailyTime: "deskMaxReservTimeDay",
    isAllowOverReservation: "isAllowedDeskOverReservation",
    contractTotalTime: "deskTotalTime",
    contractDailyTime: "deskDailyTime",
  },
  [ConstSpaceType.REFRESH_ROOM]: {
    isUnLimitReservation: "isUnlimitedRefreshRoom",
    isMonthlyUnLimit: "isLimitedRefreshRoomMonthlyReservation",
    monthlyTime: "refreshRoomMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedRefreshRoomDailyReservation",
    dailyTime: "refreshRoomMaxReservTimeDay",
    isAllowOverReservation: "isAllowedRefreshRoomOverReservation",
    contractTotalTime: "refreshRoomTotalTime",
    contractDailyTime: "refreshRoomDailyTime",
  },
} as const;

const AllSpaceUsageLimitModal = ({ product, spaceType, onClose }: Props) => {
  const { mutate } = useUpdateProduct();
  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: product[SpaceTypeReqDataMap[spaceType].isUnLimitReservation],
    //월 이용한도 체크여부
    isMonthlyUnLimit: product[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit],
    monthlyTime: product[SpaceTypeReqDataMap[spaceType].monthlyTime] === -1 ? "" : product[SpaceTypeReqDataMap[spaceType].monthlyTime] ?? "",
    //일 이용한도 체크여부
    isDailyUnLimit: product[SpaceTypeReqDataMap[spaceType].isDailyUnLimit],
    dailyTime: product[SpaceTypeReqDataMap[spaceType].dailyTime] === -1 ? "" : product[SpaceTypeReqDataMap[spaceType].dailyTime] ?? "",
    //초과 예약 여부
    isAllowOverReservation: product[SpaceTypeReqDataMap[spaceType].isAllowOverReservation],
  });

  const handleSubmit = async () => {
    const data = {
      [SpaceTypeReqDataMap[spaceType].isUnLimitReservation]: editObj.isUnLimitReservation,
      [SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]: editObj.isMonthlyUnLimit,
      [SpaceTypeReqDataMap[spaceType].monthlyTime]: editObj.monthlyTime.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime.toString()),
      [SpaceTypeReqDataMap[spaceType].isDailyUnLimit]: editObj.isDailyUnLimit,
      [SpaceTypeReqDataMap[spaceType].dailyTime]: editObj.dailyTime.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime.toString()),
      [SpaceTypeReqDataMap[spaceType].isAllowOverReservation]: editObj.isAllowOverReservation,
    };

    mutate({ id: product.id, ...data }, { onSuccess: () => onClose() });
  };

  return (
    <>
      <LimitFrom spaceType={spaceType} editObj={editObj} setEditObj={setEditObj} onClose={onClose} handleSubmit={handleSubmit} />
    </>
  );
};

export default AllSpaceUsageLimitModal;
