import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import {
  deleteClosedPeriodRuleAsync,
  getClosedPeriodRuleListAsync,
  getClosedPeriodRuleMappingListAsync,
} from "src/api/closedPeriodRule/closedPeriodRule-api";
import { ClosedPeriodRuleModel } from "src/api/closedPeriodRule/closedPeriodRule-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { ViewYmdFormat } from "src/utils";
import { DayOfWeekTypeLabel } from "../../closedPeriod-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { split } from "lodash";
import DetailLink from "src/components/DetailLink";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const BasicInfoDetail = () => {
  const { id } = useParams();
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriodRuleModel>();
  const [provider, setProvider] = useState<ProviderModel>();
  const navigate = useNavigate();
  const [usedProductIds, setUsedProductsIds] = useState<string>();
  const [usedContractIds, setUsedContractIds] = useState<string>();
  const { setBaseModal } = useModal();
  const { openToast } = useToast();
  const { executeAsync: getClosedPeriodRuleDetail } = useApiOperation(getClosedPeriodRuleListAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getClosedPeriodRuleMappingDetail } = useApiOperation(getClosedPeriodRuleMappingListAsync); // 사용중인 상품과 신청계약 정보 호출
  const { executeAsync: deleteClosedPeriodRule } = useApiOperation(deleteClosedPeriodRuleAsync);

  useEffect(() => {
    const fetchApi = async (closedPeriodRuleId: string) => {
      const response = await getClosedPeriodRuleDetail({ id: closedPeriodRuleId, size: 1, page: 0 });
      const _closedPeriod: ClosedPeriodRuleModel | undefined = response?.data?.data?.content?.[0];

      if (!_closedPeriod) {
        return;
      }

      setClosedPeriod(response?.data?.data?.content?.[0]);

      getProvider({ providerId: _closedPeriod.providerId }).then((data) => setProvider(data?.data?.data?.content?.[0]));

      // 사용중인 상품과 신청계약 리스트
      getClosedPeriodRuleMappingDetail({ closedPeriodRuleId: id }).then((data) => {
        const productIdArr: string[] = [];
        const contractIdArr: string[] = [];

        data.data?.data?.content.forEach((closedPeriodRuleMapping) => {
          if (closedPeriodRuleMapping.serviceType === "SERVICE_PRODUCT") {
            return productIdArr.push(closedPeriodRuleMapping.serviceId ?? "");
          }
          if (closedPeriodRuleMapping.serviceType === "SERVICE_CONTRACT") {
            return contractIdArr.push(closedPeriodRuleMapping.serviceId ?? "");
          }
        });

        setUsedProductsIds(productIdArr.join(","));
        setUsedContractIds(contractIdArr.join(","));
      });
    };

    if (id) {
      fetchApi(id);
    }
  }, [id, getClosedPeriodRuleDetail, getProvider, setUsedProductsIds, getClosedPeriodRuleMappingDetail]);

  return (
    <>
      <MetaTag subTitle={closedPeriod?.subject || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap price-page">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"기본정보"} />

            {/* 기본정보 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  {provider?.providerId ? (
                    <BaseNewTabLink
                      path={`${PagePath.provider.detail.replace(":id", provider.providerId.toString())}`}
                      value={provider.providerName || "-"}
                      ellipsis
                      className="font14"
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>정책명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{closedPeriod?.subject ?? "-"}</p>
              </div>
            </section>
            <div className="flex-start">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">적용기간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {closedPeriod?.startDate && closedPeriod.endDate
                      ? `${moment(closedPeriod.startDate).format(ViewYmdFormat.YYYY_MM_DD)} ~ ${moment(closedPeriod.endDate).format(
                          ViewYmdFormat.YYYY_MM_DD,
                        )}`
                      : "-"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-contents">
                  <p>
                    {closedPeriod?.days
                      ?.split(",")
                      .map((day) => DayOfWeekTypeLabel[day as keyof typeof DayOfWeekTypeLabel])
                      .join(",")}
                  </p>
                </div>
              </section>
            </div>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">적용시간</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{closedPeriod?.startTime ? `${closedPeriod?.startTime} ~ ${closedPeriod?.endTime}` : "-"}</p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">사용중인 상품</p>
              </div>
              <div className="contents-container__grid-contents flex-start gap5">
                {usedProductIds ? (
                  usedProductIds?.split(",")?.map((id) => {
                    const detailPath = `${PagePath.product.detail.replace(":id", `${id}`)}`;
                    return (
                      <p>
                        <BaseNewTabLink className="text-underline" path={`${detailPath}`} value={id} />
                      </p>
                    );
                  })
                ) : (
                  <p>{"-"}</p>
                )}
              </div>
            </section>
            <section className="contents-container__grid flex-start gap5">
              <div className="contents-container__grid-index">
                <p className="">사용중인 신청계약</p>
              </div>
              <div className="contents-container__grid-contents">
                {usedContractIds ? (
                  usedContractIds?.split(",")?.map((id) => {
                    const detailPath = `${PagePath.contract.detail.replace(":id", `${id}`)}`;
                    return (
                      <p>
                        <BaseNewTabLink className="text-underline" path={`${detailPath}`} value={id} />
                      </p>
                    );
                  })
                ) : (
                  <p>{"-"}</p>
                )}
              </div>
            </section>
          </article>
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"등록 정보"} />

            <div className="flex-center-between">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최초 등록일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{moment(closedPeriod?.createdDate).format("YYYY.MM.DD HH:mm:ss") || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최초 등록자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{closedPeriod?.createdBy || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center-between">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{moment(closedPeriod?.modifiedDate).format("YYYY.MM.DD HH:mm:ss") || "-"}</span>{" "}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 수정자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{closedPeriod?.modifiedBy || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
          </article>

          <AdminMemoV3 serviceId={Number(id)} serviceType={ServiceTypes.SERVICE_CLOSED_PERIOD_RULE} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="left-area d-flex">
          <BaseButton
            title="삭제"
            className="color-white size-large mr10"
            onClick={() => {
              if (usedProductIds) {
                return setBaseModal({ isOpen: true, title: "사용중인 상품이 있습니다.", btnRightTitle: "확인" });
              }
              if (usedContractIds) {
                return setBaseModal({ isOpen: true, title: "사용중인 신청/계약이 있습니다.", btnRightTitle: "확인" });
              }

              deleteClosedPeriodRule({ id: id! }).then((data) => {
                if (data.data.data.content) {
                  openToast({ content: "정상적으로 삭제 되었습니다." });
                  navigate(PagePath.closedPeriod.list);
                } else {
                  openToast({ content: "정상적으로 삭제 되지 않았습니다.", type: "FAIL" });
                }
              });
            }}
          />
          <BaseButton
            title="수정"
            className="size-large"
            onClick={() => {
              const formPath = `${PagePath.closedPeriod.form}?id=${id}`;
              navigate(formPath);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
