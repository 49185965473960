import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, Sort } from "../public-types";
import qs from "qs";
import { ApiPath } from "..";
import {
  ClosedPeriodRuleListParams,
  ClosedPeriodRuleMappingListParams,
  ClosedPeriodRuleMappingModel,
  ClosedPeriodRuleModel,
} from "./closedPeriodRule-types";

/* 
  RIC01 . 휴무기간 룰 목록(상세)
*/
export async function getClosedPeriodRuleListAsync(
  axios: AxiosInstance,
  params?: ClosedPeriodRuleListParams,
): Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodRuleModel[] }>>> {
  return await axios.get(ApiPath.closedPeriod.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RIC02. 휴무기간 룰 등록
*/
export async function postClosedPeriodRuleAsync(
  axios: AxiosInstance,
  params?: ClosedPeriodRuleModel,
): Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodRuleModel }>>> {
  return await axios.post(ApiPath.closedPeriod.add, {
    closedPeriodRule: params,
  });
}
/* 
  RIC03. 휴무기간 룰 수정
*/
export async function editClosedPeriodRuleAsync(
  axios: AxiosInstance,
  params?: ClosedPeriodRuleModel,
): Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodRuleModel }>>> {
  if (!params?.id) {
    throw Error("id is not found");
  }
  return await axios.put(ApiPath.closedPeriod.edit.replace("{id}", params.id.toString()), {
    closedPeriodRule: params,
  });
}
/* 
  RIC04. 휴무기간 룰 삭제
*/
export async function deleteClosedPeriodRuleAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: string }>>> {
  if (!params?.id) {
    throw Error("id is not found");
  }
  return await axios.delete(ApiPath.closedPeriod.delete.replace("{id}", params.id.toString()));
}

/* 
  RIC05 . 휴무기간 룰 매핑 목록(상세)
*/
export async function getClosedPeriodRuleMappingListAsync(
  axios: AxiosInstance,
  params?: ClosedPeriodRuleMappingListParams,
): Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodRuleMappingModel[] }>>> {
  return await axios.get(ApiPath.closedPeriod.mapping, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RIC06. 휴무기간 룰 매핑 저장(등록/삭제)
*/
export async function postClosedPeriodRuleMappingAsync(
  axios: AxiosInstance,
  params?: ClosedPeriodRuleMappingModel[],
): Promise<AxiosResponse<ApiResponse<{ content: ClosedPeriodRuleMappingModel[] }>>> {
  return await axios.post(ApiPath.closedPeriod.add, {
    closedPeriodRuleMappingList: params,
  });
}
