import React, { useState } from "react";

import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import Price from "./Price";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useParams } from "react-router-dom";

interface Props {
  // spaceType: SpaceTypeT;
  // spaceName: string; // 공용공간 name
  space: CommonFacilityListModel;
  isEditable: boolean;
}

const SettingModal = ({ space, isEditable }: Props) => {
  const spaceName = space.facilityName;
  const spaceType = space.commonFacilityType as SpaceTypeT;

  const { queryParams } = useQueryParams<{ id?: string }>();
  const params = useParams();
  const productId = queryParams.id ? queryParams.id : params.id;

  const [tabs, setTabs] = useState<{ value: string; label: string; isActive: boolean }[]>([{ value: "price", label: "요금", isActive: true }]);

  const onChangeTab = (tabValue: string) => {
    setTabs(tabs.map((tab) => ({ ...tab, isActive: tab.value === tabValue ? true : false })));
  };

  return (
    <div key={spaceName} style={{ overflowX: "scroll" }}>
      {isEditable && (
        <>
          <section className="base-abstract-modal__title px30 pb20">
            <div className="w-100 flex-row">
              <h1>
                {SpaceTypeLabel[spaceType]} {spaceName} 설정
              </h1>
            </div>
          </section>
          <div className="contents-title__tab border-bottom-gray px30">
            {tabs?.map((tab) => {
              return (
                <div key={tab.value} className={`${tab?.isActive ? "active" : ""}`} onClick={() => onChangeTab(tab.value)}>
                  <span>{tab.label}</span>
                </div>
              );
            })}
          </div>
        </>
      )}

      {tabs.map(
        (tab) =>
          tab.value === "price" &&
          tab.isActive && <Price space={space} isEditable={isEditable} productId={productId} serviceId={productId!} serviceType="SERVICE_PRODUCT" />,
      )}
    </div>
  );
};

export default SettingModal;
