import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { getBuildingsAsync } from "src/api/building/building-api";
import { CommonFacilityType } from "src/api/building/building-types";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { getNoticeListAsync } from "src/api/notice/notice-api";
import { NoticeListParams, NoticeModel } from "src/api/notice/notice-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { qsParse } from "src/utils";
import { Modal } from "../notice-types";
import noticeColumns from "./columns/noticeColumns";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchType?: string;
  search001?: string;
  isDisplayed?: string;
  containsTypes?: string;
  partnerId?: string;
  partnerName?: string;
};

// selectOption :: 공개여부
const isDisplayed = [
  { value: "", label: "전체" },
  { value: "true", label: "공개" },
  { value: "false", label: "비공개" },
];

/**
 * 공지사항 > 목록 화면
 */
const NoticeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthority } = usePartnerAuthority();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    // qs.parse에 decoder때문에 검색값 입력 검색시 한번더 decoding
    if (_queryParams.search001) {
      _queryParams.search001 = decodeURIComponent(_queryParams.search001);
    }

    return _queryParams;
  }, [location.search]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [noticeList, setNoticeList] = useState<Array<NoticeModel>>(); // table column에 맞게 가공한 목록
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const { executeAsync: getNoticeList } = useApiOperation(getNoticeListAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  // 쿼리파람과 함께 navigate
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );
  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화
    const fetchApi = async (noticeParam: NoticeListParams) => {
      // 공지사항 목록 조회
      const response = await getNoticeList(noticeParam);
      if (response.status >= 200 && response.status <= 299) {
        const noticeList = response.data.data.rows;
        let tempAllContracts: any = [];
        let tempAllBuildings: any = [];
        noticeList.forEach((notice) => {
          if (notice.contracts) {
            tempAllContracts = [...tempAllContracts, ...notice.contracts];
          }
          if (notice.buildings) {
            tempAllBuildings = [...tempAllBuildings, ...notice.buildings];
          }
        });
        const allContracts = [...new Set(tempAllContracts.map((item: any) => item.contractId))];
        const allBuildings = [...new Set(tempAllBuildings.map((item: any) => item.buildingId))];
        let dataContractList: any = [];
        let dataBuildingList: any = [];

        if (allContracts.length > 0) {
          const { data: contractList } = await getContractList({ page: 0, size: 99, contractIds: allContracts.join(",") }); // 계약목록 조회
          if (contractList.data.content) {
            dataContractList = contractList.data.content;
          }
        }

        if (allBuildings.length > 0) {
          const { data: buildingList } = await getBuildingList({ page: 0, size: 99, id: allBuildings.join(",") }); // 건물목록 조회
          if (buildingList.data.content) {
            dataBuildingList = buildingList.data.content;
          }
        }

        //신청계약 상품명, 건물명 추가
        const newNoticeList = noticeList.map((notice, length) => {
          const contractProductNames = dataContractList
            ?.filter((contract: any) => notice.contracts?.map((item) => item.contractId)?.includes(Number(contract.contractId)))
            .map((contract: any) => contract.spaceProductName);
          const buildingNames = dataBuildingList
            ?.filter((building: any) => notice.buildings?.map((item) => item.buildingId)?.includes(Number(building.id)))
            .map((building: any) => building.buildingName);
          const newNotice = { ...notice, contractProductNames, buildingNames };
          return newNotice;
        });

        setNoticeList(newNoticeList);
        setPageMeta(response.data.meta.pageMeta);
      }
    };

    fetchApi(queryParams);
  }, [getNoticeList, queryParams, getContractList, getBuildingList, location.search]);
  return (
    <div className="">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseSelect
                    placeholder="공개여부"
                    value={params.isDisplayed}
                    className="pre-wrap"
                    stateOptions={isDisplayed}
                    setStateValue={(isDisplayed: CommonFacilityType) => {
                      setParams({ ...params, isDisplayed });
                      navigateWithQueryParams({ ...params, page: 0, isDisplayed }, "search");
                    }}
                  />
                </div>
                <div>
                  <BaseInput
                    value={params.search001 ? params.search001 : ""}
                    type="text"
                    placeholder="검색어를 입력해주세요."
                    onChange={(search001: string) => {
                      setParams({ ...params, search001 });
                    }}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onClearClick={() => navigateWithQueryParams({ page: 0, search001: "" }, "search")}
                  />
                </div>
              </div>
            </section>
          </div>
          {isAuthority("NOTICE", "w") && (
            <div className="right-area">
              <BaseButton
                title="+ 공지사항 등록"
                onClick={() => {
                  navigate(PagePath.notice.form);
                }}
              />
            </div>
          )}
        </div>

        {noticeList && (
          <BaseTable
            data={noticeList}
            columns={noticeColumns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams?.sort?.orders}
            disabledSortHeaders={["contractProductNames", "buildingNames"]}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(params.size)}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.title}
        />
      )}
    </div>
  );
};
export default NoticeList;
