import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { editClosedPeriodRuleAsync, getClosedPeriodRuleListAsync, postClosedPeriodRuleAsync } from "src/api/closedPeriodRule/closedPeriodRule-api";
import { ClosedPeriodRuleModel } from "src/api/closedPeriodRule/closedPeriodRule-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseDatePicker, BaseInput } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import RangeDatepicker from "src/components/RangeDatepicker";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import { PagePath } from "src/pages/product/details";
import { UnionDayOfWeekType, dayOptions } from "src/pages/workOrder/workOrder-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { YmdFormat } from "src/utils";

import { DAYS } from "../../closedPeriod-types";
import ProviderSelectModal from "../../components/ProviderSelectModal";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

/**
 * feature:: rangeDataPicker에서 1개의 react-hook-form 컨트롤러를 사용하므로
 * 상세 데이터 호출 시 displayDate를 setValue 해줌. 타입은 dateRange state와 같다.
 */
type SelectedProvider = {
  providerId: string;
  providerName: string;
};

const BasicInfoForm = () => {
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const { setBaseModal } = useModal();
  const id: string | undefined = queryParams?.id ? String(queryParams.id) : undefined;

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriodRuleModel>();
  const [selectedProvider, setSelectedProvider] = useState<SelectedProvider[]>([]);

  const [isProviderSelectModalOpen, setIsProviderSelectModalOpen] = useState<Modal>({ isOpen: false });

  const { executeAsync: postClosedPeriodRule } = useApiOperation(postClosedPeriodRuleAsync);
  const { executeAsync: editClosedPeriodRule } = useApiOperation(editClosedPeriodRuleAsync);
  const { executeAsync: getClosedPeriodRuleDetail } = useApiOperation(getClosedPeriodRuleListAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);

  const { openToast } = useToast();

  const defaultValues: any = useMemo(() => {
    return {
      providerId: "",
      subject: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      days: DAYS,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    unregister,
    trigger,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });

  // register
  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";

    register("startDate", {
      required: requiredMessage,
    });
    register("endDate", {
      required: requiredMessage,
    });
    register("startTime", {
      required: requiredMessage,
    });
    register("endTime", {
      required: requiredMessage,
    });
    register("days", {
      required: requiredMessage,
    });

    register("providerId", {
      required: requiredMessage,
    });
  }, [register, unregister, getValues, setValue]);

  // fetch
  useEffect(() => {
    if (id) {
      //id 가 있으면 수정모드
      const fetchApi = async () => {
        const response = await getClosedPeriodRuleDetail({ id: id, size: 1, page: 0 });
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.data.content.length > 0) {
            let closedPeriodData = response.data.data.content[0];
            let providerData: ProviderModel = {};

            const providerResponse = await getProvider({ providerId: closedPeriodData?.providerId?.toString() });
            if (providerResponse.status >= 200 && providerResponse.status <= 299) {
              if (providerResponse.data.data.content.length > 0) {
                providerData = providerResponse?.data?.data?.content[0];
                let providerState = { providerId: String(providerData.providerId), providerName: String(providerData.providerName) };
                setSelectedProvider([providerState]);
              }
            }

            const closedPeriodDetail = {
              ...closedPeriodData,
              providerName: providerData?.providerName,
            };

            setClosedPeriod(closedPeriodDetail);

            if (closedPeriodDetail.startDate && closedPeriodDetail.endDate) {
              const start = closedPeriodDetail.startDate ? moment(closedPeriodDetail.startDate).toDate() : null;
              const end = closedPeriodDetail.endDate ? moment(closedPeriodDetail.endDate).toDate() : null;
              setDateRange([start, end]);
            }

            reset({
              providerId: closedPeriodDetail.providerId,
              subject: closedPeriodDetail.subject,

              startDate: closedPeriodDetail.startDate ? moment(closedPeriodDetail.startDate).format(YmdFormat.WITH_TIME_ZONE) : "",
              endDate: closedPeriodDetail.endDate ? moment(closedPeriodDetail.endDate).format(YmdFormat.WITH_TIME_ZONE) : "",
              startTime: closedPeriodDetail.startTime ? moment(closedPeriodDetail.startTime, "hh:mm").toDate() : "",
              endTime: closedPeriodDetail.endTime ? moment(closedPeriodDetail.endTime, "hh:mm").toDate() : "",

              days: closedPeriodDetail.days?.split(","),
            });
          }
        }
      };
      fetchApi();
    }
  }, [id, getProvider, getClosedPeriodRuleDetail, setValue, reset]);

  // 저장 / 수정
  const mutationClosedPeriodRule = async (data: any) => {
    const startTime = data.startTime ? moment(data.startTime).format("HH:mm") : "";
    const endTime = data.endTime ? moment(data.endTime).format("HH:mm") : "";

    const basicInfoData: ClosedPeriodRuleModel = {
      ...data,
      days: data.days?.join(","),
      startTime: startTime,
      endTime: endTime,
      ...(id && { id: +id }),
    };

    const response = id ? await editClosedPeriodRule(basicInfoData) : await postClosedPeriodRule(basicInfoData);

    const result = response.data?.data?.content;
    setBaseModal({ isOpen: false });

    if (result) {
      openToast({
        content: `정상적으로 ${id ? "수정" : "등록"}되었습니다.`,
      });
      navigate(PagePath.closedPeriod.detail.replace(":id", `${result.id}`));
    }
  };

  const onSubmit = (data: any) => {
    setBaseModal({
      isOpen: true,
      btnLeftTitle: "취소",
      btnRightTitle: "확인",
      title: "저장 하시겠습니까?",
      onClick: () => mutationClosedPeriodRule(data),
    });
  };

  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    // validation 걸렸을때
    console.log("getValues() :>> ", getValues());
    console.log("errors :>> ", errors);
    return false;
  };

  const onAddedProviderSelectModal = (provider: SelectedProvider[]) => {
    if (!provider) return;
    setSelectedProvider(provider);
    setValue("providerId", provider[0].providerId, { shouldDirty: true });
    setIsProviderSelectModalOpen({ isOpen: false });
    trigger("providerId");
  };

  const handleDeleteProvider = (id: string) => {
    const filteredDeleted = selectedProvider.filter((item) => item.providerId !== id);
    setValue("providerId", "");
    setSelectedProvider(filteredDeleted);
    trigger("providerId");
  };

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;
    setDateRange(dateRange);
    if (!startDate || !endDate) {
      setValue("startDate", undefined, { shouldDirty: true, shouldValidate: true });
      setValue("endDate", undefined, { shouldDirty: true, shouldValidate: true });
      return;
    }

    let start = moment(startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
    let end = moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

    setValue("startDate", start, { shouldDirty: true });
    setValue("endDate", end, { shouldDirty: true });
    trigger("startDate");
    trigger("endDate");
  };

  return (
    <>
      {id ? <MetaTag subTitle={closedPeriod?.subject || ""} /> : <MetaTag isCreatePage />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap">
            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"기본정보"} noHashLink />
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p className="required">프로바이더</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <BaseButton
                      type="button"
                      className="color-white size-medium"
                      title="선택"
                      disabled={id ? true : false}
                      onClick={() => setIsProviderSelectModalOpen({ isOpen: true })}
                    />
                    <div className="flex-files ml10">
                      {selectedProvider?.map((item) => (
                        <div className="flex-files__wrap" key={item.providerId}>
                          <BaseNewTabLink
                            path={`${PagePath.provider.detail.replace(":id", `${item.providerId}`)}`}
                            value={item.providerName || "-"}
                          />
                          {!id && <div className="delete-btn-x" onClick={() => handleDeleteProvider(item.providerId)}></div>}
                        </div>
                      ))}
                    </div>
                  </div>
                  {errors && errors.providerId && <p className="validation-text">{errors.providerId.message?.toString()}</p>}
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>정책명</p>
                </div>

                <div className="contents-container__grid-contents">
                  <div className="minmax240">
                    <Controller
                      control={control}
                      name="subject"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <BaseInput
                          inputRef={ref}
                          placeholder="정책명을 입력하세요"
                          className="mr8"
                          type="text"
                          onChange={onChange}
                          value={value}
                          name={name}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">적용기간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax240">
                    <RangeDatepicker minDate={new Date()} dateRange={dateRange} onChange={handleOnDateRangeChange} />
                  </div>
                  <div className="flex-center-start gap8 mt10">
                    {dayOptions.map((day, idx: number) => (
                      <div key={day.value}>
                        <Controller
                          control={control}
                          name={"days"}
                          render={({ field: { onChange, value, name } }) => (
                            <BaseCheckbox
                              id={`${day.value}`}
                              name={`${day.value}`}
                              className="mr10 chip-case"
                              label={day.label}
                              checked={value?.some((item: UnionDayOfWeekType) => item === day.value) || false}
                              onChange={(checked: boolean) => {
                                let weeks: UnionDayOfWeekType[] = watch("days") || [];

                                if (checked) {
                                  weeks.push(day.value);
                                } else {
                                  weeks = weeks.filter((week) => week !== day.value);
                                }
                                onChange(weeks);
                              }}
                            />
                          )}
                        ></Controller>
                      </div>
                    ))}
                    <BaseCheckbox
                      label="전체"
                      id="allCheck"
                      name="allCheck"
                      checked={watch("days").length === 7}
                      onChange={(checked) => {
                        if (checked) {
                          setValue("days", DAYS);
                        } else {
                          setValue("days", []);
                        }
                      }}
                    />
                  </div>
                  {errors && errors.startDate && <p className="validation-text">{errors.startDate.message?.toString()}</p>}
                  {errors && errors.days && <p className="validation-text">{errors.days.message?.toString()}</p>}
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">적용시간</p>
                </div>

                <div className="contents-container__grid-contents">
                  <div className="flex-center flex-row minmax240">
                    <div className="w-100">
                      <Controller
                        control={control}
                        name="startTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseDatePicker
                              timeIntervals={30}
                              type="time"
                              placeholder="시간설정"
                              errorText={error?.message}
                              selectedDate={value}
                              setDate={(date: Date) => {
                                onChange(date);
                                setValue("endTime", "");
                              }}
                              name={name}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                    <span className="mx10">{`~`}</span>
                    <div className="w-100">
                      <Controller
                        control={control}
                        name="endTime"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseDatePicker
                              timeIntervals={30}
                              type="time"
                              placeholder="시간설정"
                              errorText={error?.message}
                              selectedDate={value}
                              setDate={onChange}
                              name={name}
                              filterTime={(time) => {
                                const currentDate = new Date(watch("startTime"));
                                const selectedDate = new Date(time);

                                //startTime 이후의 시간만 true 리턴
                                return currentDate < selectedDate;
                              }}
                              injectTimes={[moment().hours(23).minutes(59).toDate()]}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {Number(id) > 0 ? (
              <BaseButton
                title="수정 취소"
                className="size-large color-white"
                onClick={() => {
                  navigate(PagePath.closedPeriod.detail.replace(":id", String(id)));
                }}
              />
            ) : (
              <BaseButton
                title="취소"
                className="size-large color-white"
                onClick={() => {
                  navigate(PagePath.closedPeriod.list);
                }}
              />
            )}
            <BaseButton title="저장" type="submit" className="size-large ml10" />
          </div>
        </div>
      </form>
      {isProviderSelectModalOpen.isOpen && (
        <ProviderSelectModal
          onCanceled={() => setIsProviderSelectModalOpen({ isOpen: false })}
          onAdded={onAddedProviderSelectModal}
          defaultChecked={selectedProvider ? selectedProvider : []}
        />
      )}
    </>
  );
};

export default BasicInfoForm;
