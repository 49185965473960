import { useCallback, useState } from "react";
import { editGroupItem } from "src/api/chargeItems/chargeItems-api";
import { ChargeItem } from "src/api/chargeItems/chargeItems-type";
import { useApiOperation } from "src/api/hooks";
import { BaseModal } from "src/components";

import { useToast } from "src/recoil/toast/hook";

export const columns: any = [
  {
    Header: "id",
    // sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemId",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    width: 60,
  },
  {
    Header: "항목",
    // sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemName",
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && ""}
          {value && value}
        </>
      );
    },
    width: 80,
  },
  {
    Header: "고지서 표기명",
    columns: [
      {
        Header: "",
        accessor: "itemLabel",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            <>
              <span className="ellipsis2">{value}</span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "부과기준",
    columns: [
      {
        Header: "부과방식",
        accessor: "level1",
        width: 80,
        Cell: ({ value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value === "MNT_AREA" && "면적단가"}
                {value === "MNT_USAGE" && "사용량"}
                {value === "MNT_SEPARATE" && "별도금액"}
              </span>
            </>
          );
        },
      },
      {
        Header: "산정기준",
        accessor: "level2",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {row.original.level1 === "MNT_AREA" && value === "CONTRACT" && "계약면적"}
                {row.original.level1 === "MNT_AREA" && value === "EXCLUSIVE" && "전용면적"}
                {row.original.level1 === "MNT_USAGE" && value === "ELECTRICITY" && "전기"}
                {row.original.level1 === "MNT_USAGE" && value === "WATER_SUPPLY" && "수도"}
                {row.original.level1 === "MNT_USAGE" && value === "HOT_WATER" && "급탕"}
                {row.original.level1 === "MNT_USAGE" && value === "HEATING" && "난방"}
                {row.original.level1 === "MNT_USAGE" && value === "GAS" && "가스"}
                {row.original.level1 === "MNT_USAGE" && value === "PURIFY_WATER" && "정수"}
                {row.original.level1 === "MNT_USAGE" && value === "WASTE_WATER" && "하수"}
                {row.original.level1 === "MNT_SEPARATE" && "-"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "과세기준",
    columns: [
      {
        Header: "",
        accessor: "isApplyTax",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "과세"}
                {String(value) === "false" && "비과세"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "연체료",
    columns: [
      {
        Header: "",
        accessor: "isApplyOverdue",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "적용"}
                {String(value) === "false" && "미적용"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "중간정산",
    columns: [
      {
        Header: "",
        accessor: "isApplyAdjust",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "적용"}
                {String(value) === "false" && "미적용"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "부과계산방법",
    columns: [
      {
        Header: "소숫점",
        accessor: "priceUnit",
        width: 100,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value === "MNT_ORIGIN" && "원금 그대로"}
                {value === "MNT_THOUSAND" && "1000원 단위"}
                {value === "MNT_HUNDRED" && "100원 단위"}
                {value === "MNT_TEN" && "10원 단위"}
                {value === "MNT_ONE" && "1원 단위"}
              </span>
            </>
          );
        },
      },
      {
        Header: "반올림",
        accessor: "roundingMode",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {row.original.priceUnit === "MNT_ORIGIN" && value === "UNNECESSARY" && "-"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "UP" && "절상"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "HALF_UP" && "반올림"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "DOWN" && "절하"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "표기순서",
    columns: [
      {
        Header: "",
        accessor: "orderNum",
        width: 80,
        Cell: ({ row, rows, value, tableData, setTableData, itemList, clearForm }: any) => {
          const [isOpen3, setIsOpen3] = useState(false);
          const { openToast } = useToast();

          const { executeAsync: editItem } = useApiOperation(editGroupItem);
          const updateAll = useCallback(
            async (ctMntItems: Array<ChargeItem>) => {
              const response = await editItem({
                groupId: row.original.groupId,
                ctMntItemList: ctMntItems,
              });
              if (response.status > 199 && response.status < 300) {
                await itemList();
                openToast({ content: "정상적으로 저장 되었습니다." });
                clearForm();
              } else {
              }
            },
            [clearForm, editItem, row, itemList, openToast],
          );
          const saveItems = useCallback(
            async (tableData: any) => {
              console.log("tableData", tableData);
              const send: any = [];
              tableData.map((row: any) => {
                send.push(row.original);
              });
              setIsOpen3(false);

              const response: any = await updateAll(send);
              if (response.status > 199 && response.status < 300) {
                await itemList();
                openToast({ content: "정상적으로 저장 되었습니다." });
                clearForm();
              } else {
              }
            },
            [clearForm, itemList, openToast, updateAll],
          );
          const onClickUp = () => {
            let idx = 0;

            rows.map((r: any, index: number) => {
              if (row.id === r.id) {
                idx = index;
              }
            });

            const item = rows.splice(idx, 1);
            rows.splice(idx - 1, 0, item[0]);

            const originals: any = [];
            rows.map((r: any) => {
              originals.push(r.original);
            });

            const numb = 10;
            let temp = (originals.length + 1) * 10;
            const r = originals.map((d: any) => {
              temp -= numb;
              d.orderNum = temp;
              return d;
            });
            setTableData(r);
            setIsOpen3(true);
          };
          const onClickDown = () => {
            let idx = 0;

            rows.map((r: any, index: number) => {
              if (row.id === r.id) {
                idx = index;
              }
            });

            const item = rows.splice(idx, 1);
            rows.splice(idx + 1, 0, item[0]);

            const originals: any = [];
            rows.map((r: any) => {
              originals.push(r.original);
            });

            const numb = 10;
            let temp = (originals.length + 1) * 10;
            const r = originals.map((d: any) => {
              temp -= numb;
              d.orderNum = temp;
              return d;
            });
            setTableData(r);
            setIsOpen3(true);
          };
          return (
            <div className="w-100 d-flex justify-contents-end">
              <span className="mr4 no-wrap text-gray500">{value === 0 ? "" : value}</span>
              {rows.map((r: any, index: number) => {
                if (row.id === r.id) {
                  return (
                    <button
                      key={index}
                      className={`base-up-btn ${index === 0 ? "not-available" : ""}`}
                      onClick={() => (index !== 0 ? onClickUp() : {})}
                    ></button>
                  );
                }
              })}

              {rows.map((r: any, index: number) => {
                if (row.id === r.id) {
                  return (
                    <button
                      key={index}
                      className={`base-down-btn ${index === 0 ? "not-available" : ""}`}
                      onClick={() => (index !== rows.length - 1 ? onClickDown() : {})}
                    ></button>
                  );
                }
              })}
              <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => saveItems(rows)} onClose={() => setIsOpen3(false)}>
                <p>표기순서 변경 내역을 저장 하시겠습니까?</p>
              </BaseModal>
            </div>
          );
        },
      },
    ],
  },

  {
    Header: "사용여부",
    columns: [
      {
        Header: "",
        accessor: "isUse",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span>{value ? "사용" : "미사용"}</span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "수정/삭제",
    columns: [
      {
        Header: "",
        accessor: "sample11",
        width: 80,
        Cell: (i: any) => {
          const { executeAsync: editItem } = useApiOperation(editGroupItem);
          const [isOpen2, setIsOpen2] = useState(false);
          const clickDetail = useCallback(() => {
            i.clearForm();
            i.setFormData(i.row.original);
            i.setSelectOption();
          }, [i]);
          const deleteItem = useCallback(
            async (delData: ChargeItem) => {
              delData.isDeleted = true;
              setIsOpen2(false);

              const response: any = await editItem({
                groupId: i.row.original.groupId,
                ctMntItemList: [delData],
              });

              if (response.status > 199 && response.status < 300) {
                await i.itemList();
              } else {
              }
            },
            [editItem, i],
          );
          return (
            <div className="minmax50 d-flex justify-contents-end">
              {/* {!i.row.values.floor && <button className="base-edit-btn mr4"></button>} */}
              <button
                className="base-edit-btn mr4"
                // disabled={i.row.values.floor}
                onClick={() => clickDetail()}
              ></button>
              <button className="base-trash-btn" onClick={() => setIsOpen2(true)}></button>
              <BaseModal
                isOpen={isOpen2}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => deleteItem(i.row.original)}
                onClose={() => setIsOpen2(false)}
              >
                <p>삭제 하시겠습니까?</p>
              </BaseModal>
            </div>
          );
        },
      },
    ],
  },
];
