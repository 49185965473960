import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useApiOperation } from "src/api/hooks";
import { getProductListAsync } from "src/api/product/product-api";
import { ProductCategory, ProductModel, ProductStatus } from "src/api/product/product-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInputWithSearch, BaseSelect, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { ProductColumns } from "./columns";
import { ProductTypeLabels, ProductTypeT, ProductTypes } from "./product-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

// 검색대상
const searchTypes = [
  { value: "ALL", label: "전체" },
  { value: "PRODUCT_NAME", label: "상품명" },
  { value: "ADDRESS", label: "주소" },
  { value: "BUILDING_NAME", label: "건물명" },
];

// 상태
const statuses = [
  { value: "", label: "전체" },
  { value: ProductStatus.ENABLED, label: "공개" },
  { value: ProductStatus.DISABLED, label: "비공개" },
  { value: ProductStatus.LINK_ENABLED, label: "링크공개" },
];

// 이용여부
const isProductsUsed = [
  { value: "", label: "전체" },
  { value: "true", label: "이용중" },
  { value: "false", label: "미이용" },
];

const ProductType = [
  { value: "", label: "전체" },
  { value: ProductTypes["FULL_COURT"], label: ProductTypeLabels["FULL_COURT"] },
  { value: ProductTypes["TIME_COURT"], label: ProductTypeLabels["TIME_COURT"] },
  { value: ProductTypes["MAINTENANCE_FEE"], label: ProductTypeLabels["MAINTENANCE_FEE"] },
  { value: ProductTypes["NON_RESIDENT_OFFICE"], label: ProductTypeLabels["NON_RESIDENT_OFFICE"] },
  { value: ProductTypes["DIGITAL_PRODUCT"], label: ProductTypeLabels["DIGITAL_PRODUCT"] },
];

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  status?: string;
  productType?: ProductTypeT;
  keyword?: string;
  isDeleted?: string;
  isUsed?: string;
  partnerId?: string;
  sort?: Sort;
  productCategory?: ProductCategory;
};

interface FormData {
  isUsed: string;
  status: string;
  productCategory: string;
  productType: string;
  search: {
    type: string;
    keyword: string;
  };
}

/* 
  공간상품 > 목록 화면
 */
const ProductList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 공간상품 목록 조회
  const [products, setProducts] = useState<ProductModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [orders, setOrders] = useState<Order[]>([]);
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync);

  const { control, handleSubmit, setValue, getValues } = useForm<FormData>();
  const formRef = useRef<HTMLFormElement>(null);

  const search = ({ data, page = 0, size = 20, orders = [] }: { data: FormData; page?: number; size?: number; orders?: Order[] }) => {
    navigate({ search: qs.stringify({ ...data, page, size, orders }) });
  };

  const handleSearch = (data: FormData) => search({ data, page: 0, size: Number(params.size) || 20 });
  const handleGoPage = (page: number) => search({ data: getValues(), page, orders, size: Number(params.size) || 20 });
  const handleSetOreders = (orders: Order[]) => search({ data: getValues(), page: pageMeta?.pageRequest.page, orders });

  const handleClickSearch = () => formRef.current?.dispatchEvent(new Event("submit", { bubbles: true }));

  const params = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location]);
  const { isAuthority } = usePartnerAuthority();

  useEffect(() => {
    const page = params.page ? Number(params.page) : 0;
    const size = params.size ? Number(params.size) : 20;
    const searchObj = params.search
      ? (params.search as {
          type: string;
          keyword: string;
        })
      : undefined;

    const orders = (params.orders as unknown as Order[]) || [
      {
        direction: "DESC",
        property: "id",
      },
    ];

    (async () => {
      const result = await getProductList({
        ...params,
        page,
        size,
        keyword: searchObj?.keyword,
        searchType: searchObj?.type,
        sort: {
          orders: orders,
        },
      });

      if (result.status === 200) {
        setProducts(result.data.data.content);
        setPageMeta(result.data.meta.pageMeta);
        setOrders(orders);
        setValue("isUsed", params.isUsed as string);
        setValue("status", params.status as ProductStatus);
        setValue("productCategory", params.productCategory as ProductCategory);
        setValue("productType", params.productType as string);
        setValue("search", {
          type: searchObj?.type || "",
          keyword: searchObj?.keyword || "",
        });
      }
    })();
  }, [location]);

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <form className="left-area" ref={formRef} onSubmit={handleSubmit(handleSearch)}>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <Controller
                    control={control}
                    name="isUsed"
                    render={({ field }) => (
                      <BaseSelect
                        value={field.value}
                        stateOptions={isProductsUsed}
                        setStateValue={(value: string) => {
                          search({ data: { ...getValues(), isUsed: value }, size: Number(params.size) || 20 });
                        }}
                        placeholder="이용여부"
                      />
                    )}
                  />
                </div>
                <div className="minmax120 mr8">
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <BaseSelect
                        value={field.value}
                        stateOptions={statuses}
                        setStateValue={(value: string) => {
                          search({ data: { ...getValues(), status: value }, size: Number(params.size) || 20 });
                        }}
                        placeholder="공개상태"
                      />
                    )}
                  />
                </div>
                <div className="minmax170 mr8">
                  <Controller
                    control={control}
                    name="productType"
                    render={({ field }) => (
                      <BaseSelect
                        value={field.value}
                        stateOptions={ProductType}
                        setStateValue={(value: string) => {
                          search({ data: { ...getValues(), productType: value }, size: Number(params.size) || 20 });
                        }}
                        placeholder="상품 유형"
                      />
                    )}
                  />
                </div>
                <div className="minmax320 mr8">
                  <Controller
                    control={control}
                    render={({ field }) => {
                      return (
                        <BaseInputWithSearch
                          type="text"
                          selectValue={field.value?.type}
                          inputValue={field.value?.keyword}
                          stateOptions={searchTypes}
                          setStateValue={(searchType: string) => {
                            setValue("search", {
                              ...field.value,
                              type: searchType,
                            });
                          }}
                          onChange={(searchKeyword: string, e: React.ChangeEvent<HTMLInputElement>) =>
                            setValue("search", {
                              ...field.value,
                              keyword: searchKeyword,
                            })
                          }
                          onKeyUp={handleClickSearch}
                          onSearchClick={handleClickSearch}
                          onClearClick={() => {
                            search({
                              data: {
                                ...getValues(),
                                search: {
                                  type: "",
                                  keyword: "",
                                },
                              },
                            });
                          }}
                        />
                      );
                    }}
                    name={"search"}
                  />
                </div>
              </div>
            </section>
          </form>
          {isAuthority("PRODUCT", "w") && (
            <div className="right-area">{<BaseButton title="+ 상품 등록" onClick={() => navigate(PagePath.product.form)} />}</div>
          )}
        </div>
        {products && (
          <BaseTable
            data={products}
            columns={ProductColumns}
            currentSize={Number(params.size) || 20}
            sizeOption={(sizeValue) => {
              search({ data: { ...getValues() }, size: sizeValue });
            }}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            totalElements={pageMeta?.totalElements}
            goPage={handleGoPage}
            orders={orders}
            disabledSortHeaders={["rentalCost", "image"]}
            setOrders={handleSetOreders}
          />
        )}
      </div>
    </div>
  );
};
export default ProductList;
