// 건물 Table Columns
import moment from "moment";
import { useEffect, useState } from "react";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import { BaseContractStatus } from "src/components/BaseContractStatus";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import DetailLink from "src/components/DetailLink";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import PagePath from "src/pagePath.json";
import { calculateDate, ViewYmdFormat } from "src/utils/common-util";

export const columns = [
  {
    Header: "id",
    accessor: "contractId",
    sticky: "left",
    width: 80,
    Cell: (props: any) => {
      const path = PagePath.contract.detail.replace(":id", props.row.original.contractId);
      return (
        <>
          <DetailLink to={path} className="text-underline">
            {props.value}
          </DetailLink>
        </>
      );
    },
  },

  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
    sticky: "left",
    Cell: (props: any) => {
      const path = PagePath.contract.detail.replace(":id", props.row.original.contractId);
      return (
        <>
          <DetailLink to={path} className="text-underline">
            {props.value}
          </DetailLink>
          {/* <Link to={path} className="text-underline">
            {props.value}
          </Link> */}
        </>
      );
    },
  },
  {
    Header: "상태",
    accessor: "contractStep",
    sticky: "left",
    width: 80,
    Cell: ({ value }: any) => {
      return <BaseContractStatus status={value} />;
    },
  },
   
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
{
    Header: "계약명",
    accessor: "contractName",
    width: 120,
    sticky: "left",
    Cell: ({value}: any) => {
      
      return (
        <BaseTooltip contents={value} />
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },

  {
    Header: "상품유형",
    accessor: "spaceProductType",
    width: 100,
    Cell: (props: any) => {
      return <ProductTypeChangeLabel productType={props.value} />;
    },
  },
  {
    Header: "상품id",
    accessor: "spaceProductId",
    width: 80,
    Cell: (props: any) => {
      // console.log("rops.row.original", props.row.original);
      const path = PagePath.product.detail.replace(":id", props.row.original.spaceProductId);
      return (
        <>
          <BaseNewTabLink path={path} value={props.value || "-"} ellipsis />
        </>
      );
    },
  },
  {
    Header: `이용자(현재/최대)`,
    accessor: "useNums",
    width: 140,
    Cell: ({ value, row }: any) => {
      const now = row.original.memberTotCount;
      const total = row.original.memberMaxNums;
      const path = PagePath.user.list + "?contractApplyNumber=" + row.original?.contractApplyNumber + "&contractId=" + row.original?.contractId;
      const text = now + "명 / " + total + "명";
      return <BaseNewTabLink path={path} value={text || "-"} ellipsis />;
    },
  },

  {
    Header: "이용시작",
    accessor: "contractStartTime",
    width: 120,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "이용종료",
    accessor: "contractEndTime",
    width: 145,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "기간",
    accessor: "contractTerm",
    width: 80,
    Cell: ({ row }: any) => {
      const [calDate, setCalDate] = useState("");
      // console.log(row);
      useEffect(() => {
        let text = calculateDate(row.original.contractStartTime!, row.original.contractEndTime!, row.original.spaceProductType!);
        if (text !== "") {
          setCalDate(text);
        }
      }, [row.original.contractEndTime, row.original.contractStartTime, row.original.spaceProductType]);
      return (
        <div className="w-100 text-left">
          <p>{calDate !== "" ? calDate : "-"}</p>
        </div>
      );
    },
  },

  // {
  //   Header: "이름",
  //   accessor: "applicantName",
  //   width: 100,
  //   Cell: ({ value }: any) => {
  //     return <BaseTooltip contents={value} />;
  //   },
  // },
  {
    Header: "최초 등록일",
    accessor: "createdDate",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
];
