import { decommaizeNumber } from "@toss/utils";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ContractOperationDetail, DetailTotalData, ProductFacilityUsedTime } from "src/api/contract/contract-types";
import { BaseButton } from "src/components";
import useEditContractOperation from "src/hooks/contract/useEditContractOperation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { renderTime } from "src/pages/product/forms/commonSpaceForm/libs";
import { SpaceTypeReqDataMap } from "src/pages/product/forms/commonSpaceForm/modal/AllSpaceUsageLimitModal";
import LimitFrom from "src/pages/product/forms/commonSpaceForm/modal/LimitFrom";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import UsageMonthlyTimeModal from "../modal/UsageMonthlyTimeModal";

const MaxReservTimeMonthMap = {
  MEETING_ROOM: "meetingRoomTotalTime",
  DESK: "deskTotalTime",
  REFRESH_ROOM: "refreshRoomTotalTime",
} as const;

const MaxReservTimeDayMap = {
  MEETING_ROOM: "meetingRoomDailyTime",
  DESK: "deskDailyTime",
  REFRESH_ROOM: "refreshRoomDailyTime",
} as const;

type Props = {
  detailData: DetailTotalData;
  spaceType: SpaceTypeT;
  contractOperationDetail: ContractOperationDetail;
};

const spaceTypeMap = {
  MEETING_ROOM: "meetingRoom",
  DESK: "desk",
  REFRESH_ROOM: "refreshRoom",
} as const;

//신청계약 이용한도 view
const UsageLimitView = ({ detailData, spaceType, contractOperationDetail }: Props) => {
  const { setAbstractModalZ1, abstractModalZ1 } = useModal();
  const { isAuthority } = usePartnerAuthority();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { mutate } = useEditContractOperation();
  const partner = useRecoilValue(globalPartnerState);
  /** 파트너의 공용공간 예약의 후불정산 기능 활성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isUnLimitReservation],
    //월 이용한도 체크여부
    isMonthlyUnLimit: contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit],
    //일 이용한도 체크여부
    isDailyUnLimit: contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isDailyUnLimit],
    //월 시간
    monthlyTime:
      contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].contractTotalTime] === -1
        ? ""
        : decommaizeNumber(contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].contractTotalTime]?.toString()!), //contractTotalTime 사용
    //일 시간
    dailyTime:
      contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].contractDailyTime] === -1
        ? ""
        : decommaizeNumber(contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].contractDailyTime]?.toString()!), //contractTotalTime 사용
    //초과 예약 여부
    isAllowOverReservation: contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isAllowOverReservation],
  });

  const handleSubmit = () => {
    const productManage = {
      //무제한 여부
      [SpaceTypeReqDataMap[spaceType].isUnLimitReservation]: editObj.isUnLimitReservation,
      //월 이용한도
      [SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]: editObj.isMonthlyUnLimit,
      //일 이용한도
      [SpaceTypeReqDataMap[spaceType].isDailyUnLimit]: editObj.isDailyUnLimit,
      //월시간
      [SpaceTypeReqDataMap[spaceType].contractTotalTime]:
        editObj.monthlyTime.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime.toString()),
      //일시간
      [SpaceTypeReqDataMap[spaceType].contractDailyTime]: editObj.dailyTime.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime.toString()),
      //초과 예약 여부
      [SpaceTypeReqDataMap[spaceType].isAllowOverReservation]: editObj.isAllowOverReservation,
    };

    mutate(
      { contractId: String(detailData.contract?.contractId), productManage: productManage },
      { onSuccess: () => setAbstractModalZ1({ isOpen: false }) },
    );
  };

  const LimitFormContainer = (
    <>
      <section className="base-abstract-modal__title border-bottom">
        <div>
          <h1>{SpaceTypeLabel[spaceType]}전체 이용한도 설정</h1>
          <ul className="base-list mt20">
            <li className="font14">전체 {SpaceTypeLabel[spaceType]}의 예약 이용한도를 설정해 주세요.</li>
          </ul>
        </div>
      </section>
      <LimitFrom
        spaceType={spaceType}
        editObj={editObj as any}
        setEditObj={setEditObj as any}
        onClose={() => setAbstractModalZ1({ isOpen: false })}
        handleSubmit={handleSubmit}
      />
    </>
  );

  useEffect(() => {
    if (isAuthority("CONTRACT", "r") && isAuthority("CONTRACT", "w")) {
      setAbstractModalZ1((prev) => ({ ...prev, children: LimitFormContainer }));
    }
  }, [editObj, isAuthority]);

  return (
    <div>
      <div className="flex-center-between mt20">
        <p className="font15 font-weight-semibold">{SpaceTypeLabel[spaceType]} 전체 이용한도</p>
        <div className="flex-center">
          <BaseButton
            title={"월 사용 시간 확인"}
            className="color-white"
            onClick={() => {
              setIsPopupOpen(true);
            }}
          />

          {isAuthority("CONTRACT", "w") && (
            <BaseButton
              title={"전체 이용한도 설정"}
              className="color-white ml10"
              disabled={
                detailData?.contract?.contractStep === "APPLY_CANCEL" ||
                detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
                detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
                detailData?.contract?.contractStep === "USE_COMPLETE"
              }
              onClick={() =>
                setAbstractModalZ1(() => ({
                  size: "medium",
                  isOpen: true,
                  children: LimitFormContainer,
                }))
              }
            />
          )}
        </div>
      </div>
      <section className="reservation-wrap__gray-board">
        <div>
          <p className="index">월 이용한도</p>
          <div className="flex-center value">
            {renderTime(
              "TOTAL",
              contractOperationDetail.productManage?.[MaxReservTimeMonthMap[spaceType]],
              contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]!,
              contractOperationDetail.productFacilityUsedTime?.[(spaceTypeMap[spaceType] + "MonthlyUsedTime") as keyof ProductFacilityUsedTime], //월
            )}
          </div>
        </div>
        <div>
          <p className="index">일 이용한도</p>
          <div className="flex-center value">
            {renderTime(
              "TOTAL",
              contractOperationDetail.productManage?.[MaxReservTimeDayMap[spaceType]], // ex :meetingRoomDailyTime
              contractOperationDetail.productManage?.[SpaceTypeReqDataMap[spaceType].isDailyUnLimit]!, // ex :isLimitedMeetingRoomDailyReservation
              contractOperationDetail.productFacilityUsedTime?.[(spaceTypeMap[spaceType] + "DailyUsedTime") as keyof ProductFacilityUsedTime], // 일 ex : meetingRoomDailyUsedTime
            )}
          </div>
        </div>
      </section>

      {isPopupOpen && (
        <UsageMonthlyTimeModal
          isOpen={isPopupOpen}
          type={spaceType}
          totalOverCharge={isPostPaymentReservation!}
          onClick={() => {}}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
};

// const TypeMap = {
//   MEETING_ROOM: {
//     monthly: "isLimitedMeetingRoomMonthlyReservation",
//     daily: "isLimitedMeetingRoomDailyReservation",
//   },
//   DESK: {
//     monthly: "isLimitedRefreshRoomMonthlyReservation",
//     daily: "isLimitedRefreshRoomDailyReservation",
//   },
//   REFRESH_ROOM: {
//     monthly: "isLimitedRefreshRoomMonthlyReservation",
//     daily: "isLimitedRefreshRoomDailyReservation",
//   },
// } as const;

export default UsageLimitView;
