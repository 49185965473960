import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getPartnerAuthorityAsync } from "src/api/partner";
import { BaseButton } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { UnionPartnerPermission, UnionPartnerPrivilegeCode } from "src/types/partner";
import { axiosInstance } from "src/utils";
// isAuthorityReadonly :: 파트너 회원의 readOnly값 리턴.
// const { isAuthorityReadonly } = usePartnerAuthority();

// fetchAuthorityReadonly :: true 값을 함께 보내면 readOnly값이 true일때 알럿을 띄웁니다. 보내지 않을경우 권한조회만 다시 합니다.
// const { isAuthorityReadonly, fetchAuthorityReadonly } = usePartnerAuthority();
// fetchAuthorityReadonly(true);

// checkPageAccess는 해당 페이지에 권한이 있는지 확인하고 알럿을 띄웁니다.
// usePartnerAuthority({ checkPageAccess: true });

/** 
   * 전체 페이지 url 접근하는 것을 막는 로직은 SideBar.tsx 에서 구현함
   * usePartnerAuthority 를 부모 / 자식 컴포넌트에서 여러번 선언해야 할 수 있는데
    react-query 는 api 를 한번만 호출하지만, effect는 디펜던시로 인해 더 많은 effect가 실행되기 때문 
  */

type Props = {
  checkPageAccess?: boolean;
};
export const usePartnerAuthority = (props?: Props) => {
  const navigate = useNavigate();
  const historyStack = window.history.length;
  const partner = useRecoilValue(globalPartnerState);

  const { setAbstractModalZ1: setAbstractError } = useModal();
  const renderModal = (onClickHandler: Function) => {
    return (
      <>
        <div className="base-modal__title">
          <p>
            해당 기능에 대해
            <br /> 접근이 제한 되었습니다
          </p>
        </div>
        <div className="base-modal__contents pt20">
          <p>관리자에게 문의해 주세요</p>
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton title={"확인"} onClick={onClickHandler} />
        </div>
      </>
    );
  };

  const { data } = useQuery({
    queryKey: ["getPartnerAuthority"],
    queryFn: async () => await getPartnerAuthorityAsync(axiosInstance, String(partner?.id)),
    retryOnMount: false,
    staleTime: 5000,
    refetchOnMount: false,
    enabled: !!partner?.id,
    meta: {
      onError(error, query) {
        if (error) {
          console.log("error", error);
          // api 에러시 커스텀 모달 오픈
          setAbstractError({
            isOpen: true,
            size: "small",
            children: renderModal(() => setAbstractError({ isOpen: false })),
            className: "base-modal",
          });
        }
      },
    },
  });

  // 파트너 readOnly 권한 적용

  // 파트너 메뉴별 권한여부 확인
  const partnerPrivileges = useMemo(() => {
    const result = data?.data?.data?.authority?.privileges;
    return result || [];
  }, [data?.data.data.authority.privileges]);

  // Harry 수정 >
  const fetchAuthorityReadonly = (checkReadonly?: boolean, global: boolean = false) => {
    // 권한 확인시 true면 알럿
    if (checkReadonly) {
      setAbstractError({
        isOpen: true,
        size: "small",
        children: renderModal(() => {
          setAbstractError({ isOpen: false });
          //얼럿 후 뒤로가기 적용해야 할 때 실행 > sideBar.tsx 에 적용해둠
          if (global) {
            historyStack > 1 ? navigate(-1) : navigate(pagePath.main);
          }
        }),
        className: "base-modal",
      });
    }
  };

  // ==========================
  // 이전 코드 (neo)
  // const fetchAuthorityReadonly = (checkReadonly?: boolean) => {
  //   // 권한 확인시 true면 알럿
  //   if (isAuthorityReadonly && checkReadonly) {
  //     setAbstractError({
  //       isOpen: true,
  //       size: "small",
  //       children: renderModal(() => setAbstractError({ isOpen: false })),
  //       className: "base-modal",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   //페이지 권한 확인 알럿
  //   if (props?.checkPageAccess && isAuthorityReadonly) {
  //     setAbstractError({
  //       isOpen: true,
  //       size: "small",
  //       children: renderModal(() => {
  //         setAbstractError({ isOpen: false });
  //         historyStack > 1 ? navigate(-1) : navigate(pagePath.main);
  //       }),
  //       className: "base-modal",
  //     });
  //   }
  // }, [isAuthorityReadonly, props?.checkPageAccess]);

  // ==========================

  /**
  - 각 페이지에 대해 read, write, delete 권한이 있는지 확인
  - code - "BASIC", "BUILDING", "CS" 와 같은 code 전달
  - permission - "r" | "d" | "w" 전달
   */
  const isAuthority = useCallback(
    (code: UnionPartnerPrivilegeCode, permission: UnionPartnerPermission) => {
      // 페이지에 대해 유요한 권한이 있는지 찾기

      const checkValidPrivilege = partnerPrivileges.find((privilege) => privilege.code === code);

      // 찾은 Privilege > permission 에 인자로 전달받은 데이터가 있는지 boolean 리턴
      const isValidPermission = checkValidPrivilege?.permissions.some((value) => value === permission) || false;

      return isValidPermission;
    },
    [partnerPrivileges],
  );

  return { fetchAuthorityReadonly, partnerPrivileges, isAuthority };
};
