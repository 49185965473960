import { Dispatch, SetStateAction } from "react";
import { useRecoilValue } from "recoil";
import { BaseButton, BaseCheckbox, BaseRadio, BaseTooltip } from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { commaizePrice, deleteCommas, showHourMinute } from "src/utils";
import { EditObj } from "./SpaceUsageLimitModal";

const LimitFrom = ({
  spaceType,
  editObj,
  setEditObj,
  onClose,
  handleSubmit,
}: {
  spaceType: SpaceTypeT;
  editObj: EditObj;
  setEditObj: Dispatch<SetStateAction<EditObj>>;
  onClose: () => void;
  handleSubmit: () => void;
}) => {
  const partner = useRecoilValue(globalPartnerState);

  /** 파트너의 공용공간 예약의 후불정산 기능 화성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  const { setBaseModal } = useModal();
  const renderText = (isLimit: boolean, time: string) => {
    if (isLimit) {
      return time === "" || time === "0" ? "분 (0시간 0분)" : `분 (${showHourMinute(deleteCommas(time))})`;
    } else {
      return;
    }
  };
  return (
    <>
      <section className="base-abstract-modal__contents px30">
        <section className="base-abstract-modal__contents-subtitle">
          <h2 className="required">무제한 여부</h2>
        </section>
        <div className="flex-center pb30">
          <div className="flex-center minmax220">
            <BaseRadio
              id={spaceType + "_isLimited_True"}
              name={spaceType + "_isLimited_True"}
              checked={editObj.isUnLimitReservation === true}
              label="제한 없음" // 무제한
              onChange={() => {
                setEditObj((prev) => ({
                  ...prev,
                  isUnLimitReservation: true,
                  isMonthlyUnLimit: false,
                  isDailyUnLimit: false,
                }));
              }}
              className={"mr10"}
            />
            <BaseRadio
              id={spaceType + "_isLimited_False"}
              name={spaceType + "_isLimited_False"}
              checked={!editObj.isUnLimitReservation}
              label="시간제(이용한도 설정함)"
              onChange={() => {
                setEditObj((prev) => ({ ...prev, isUnLimitReservation: false, isMonthlyUnLimit: true, isDailyUnLimit: true }));
              }}
            />
          </div>
        </div>

        <section className="base-abstract-modal__contents-subtitle mt0">
          <h2>월 이용한도</h2>
        </section>
        <div className="flex-center pb30">
          <div className="flex-center">
            <BaseCheckbox
              className="w-fit mr10"
              id={spaceType + "month"}
              name={spaceType + "month"}
              checked={editObj.isMonthlyUnLimit}
              disabled={editObj.isUnLimitReservation}
              onChange={(value) => {
                setEditObj((prev) => ({
                  ...prev,
                  isMonthlyUnLimit: value,
                }));
              }}
              label="사용함"
            />
            <div className="minmax240">
              <BaseInputNumberFormat
                //2,147,483,647
                type="text"
                value={commaizePrice(editObj.monthlyTime)}
                onChange={(text: string) => {
                  setEditObj((prev) => ({ ...prev, monthlyTime: commaizePrice(text) }));
                }}
                disable={editObj.isUnLimitReservation || !editObj.isMonthlyUnLimit}
              />
            </div>
          </div>
          <span className="font18 ml10">{renderText(!editObj.isUnLimitReservation, editObj.monthlyTime.toString()) ?? ""}</span>
        </div>

        <section className="base-abstract-modal__contents-subtitle mt0">
          <h2>일 이용한도</h2>
        </section>
        <div className="flex-center pb30">
          <div className="flex-center">
            <BaseCheckbox
              className="w-fit mr10"
              id={spaceType + "daily"}
              name={spaceType + "daily"}
              checked={editObj.isDailyUnLimit}
              disabled={editObj.isUnLimitReservation}
              onChange={(value) => {
                setEditObj((prev) => ({
                  ...prev,
                  isDailyUnLimit: value,
                }));
              }}
              label="사용함"
            />
            <div className="minmax240">
              <BaseInputNumberFormat
                type="text"
                value={commaizePrice(editObj.dailyTime)}
                onChange={(text: string) => setEditObj((prev) => ({ ...prev, dailyTime: commaizePrice(text) }))}
                disable={editObj.isUnLimitReservation || !editObj.isDailyUnLimit}
              />
            </div>
          </div>
          <span className="font18 ml10">{renderText(!editObj.isUnLimitReservation, editObj.dailyTime.toString()) ?? ""}</span>
        </div>

        {isPostPaymentReservation && (
          <>
            <section className="base-abstract-modal__contents-subtitle mt0">
              <div className="contents-container__grid-index">
                <div className="flex-center font14">
                  이용한도 초과되는 예약 허용 여부
                  <BaseTooltip
                    contents={""}
                    tooltip={
                      "이용한도 초과된 이용시간의 집계는 월 사용시간 확인 팝업에서 확인하실 수 있으며, 공용공간 요금 설정 시 초과 요금도 집계됩니다."
                    }
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </div>
            </section>
            <div className="flex-center pb30">
              <div className="flex-center minmax220">
                <BaseRadio
                  className="mr10"
                  id={spaceType + "_overReservation_false"}
                  checked={!editObj.isAllowOverReservation}
                  onChange={() => {
                    setEditObj((prev) => ({ ...prev, isAllowOverReservation: false }));
                  }}
                  name={spaceType + "_overReservation_false"}
                  label="비허용"
                  disabled={editObj.isUnLimitReservation}
                />
                <BaseRadio
                  id={spaceType + "_overReservation_true"}
                  checked={editObj.isAllowOverReservation}
                  onChange={() => {
                    setEditObj((prev) => ({ ...prev, isAllowOverReservation: true }));
                  }}
                  name={spaceType + "_overReservation_true"}
                  label="허용하고 후불정산"
                  disabled={editObj.isUnLimitReservation}
                />
              </div>
            </div>
          </>
        )}
      </section>
      <section className="base-abstract-modal__btn-wrap">
        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}
        <BaseButton title={"취소"} className="color-white" onClick={onClose} />

        <BaseButton
          title={"저장"}
          onClick={() => {
            if (editObj.isMonthlyUnLimit && Number(editObj.monthlyTime) <= 0) {
              return setBaseModal({
                isOpen: true,
                btnRightTitle: "확인",
                title: "월 이용한도를 설정하고자 한다면, 이용한도 시간값도 분단위로 입력해 주세요.",
              });
            }

            if (editObj.isDailyUnLimit && Number(editObj.dailyTime) <= 0) {
              return setBaseModal({
                isOpen: true,
                btnRightTitle: "확인",
                title: "일 이용한도를 설정하고자 한다면, 이용한도 시간값도 분단위로 입력해 주세요.",
              });
            }

            if (!editObj.isUnLimitReservation) {
              if (!editObj.isDailyUnLimit && !editObj.isMonthlyUnLimit) {
                return setBaseModal({
                  isOpen: true,
                  btnRightTitle: "확인",
                  title: "월 이용한도 또는 일 이용한도를 설정해주세요",
                });
              }
            }

            setBaseModal({
              isOpen: true,
              btnRightTitle: "확인",
              btnLeftTitle: "취소",
              title: "저장하시겠습니까?",
              onClick: () => {
                handleSubmit();
                setBaseModal({ isOpen: false });
              },
            });
          }}
        />
      </section>
    </>
  );
};

export default LimitFrom;
