import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getScheduleDetail_RCB01, getScheduleRoundNOverdueList, mntBillAdd, mntBillUpdate } from "src/api/billingSchedule/billingSchedule-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList, providerDetailAsync } from "src/api/provider/provider-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, BaseSelect, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import useApiLoading from "src/hooks/useApiLoading";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import MakeBillPopup from "src/pages/billing/components/MakeBillPopup";
import OverdueFeePopup from "src/pages/contract/commonComponents/modal/OverdueFeePopup";
import useContactApi from "src/pages/contract/hooks/useContractApi";
import { useToast } from "src/recoil/toast/hook";
import { YmdFormat } from "src/utils";
import MntEditColumns, { deleteCol } from "../../../columns/MntColumns";
import OverdueInfo from "./OverdueInfo";
interface Bill {
  billId: number;
  billStartTime: string;
  billEndTime: string;
  billIssueDate: string;
  billDueDate: string;
  isConfirmed: boolean;
  billDescription: string;
  amountDescription: string;
  buildingId: number;
  billDetailList: Array<any>;
  [key: string]: any;
}
const initBill = {
  billId: 0,
  billStartTime: "",
  billEndTime: "",
  billIssueDate: "",
  billDueDate: "",
  isConfirmed: false,
  billDescription: "",
  amountDescription: "",
  isDeleted: false,
  buildingId: 0,
  billDetailList: [],
};
const MntBillingForm = ({ basicInfo, callList, contractBaseInfo, stopObj }: any) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { isApiLoading } = useApiLoading();
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);
  const { executeAsync: getScheduleList } = useApiOperation(getScheduleDetail_RCB01);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  //  프로바이더 목록 api
  const { executeAsync: getProviders } = useApiOperation(getProviderList);
  // 청구서 저장
  const { executeAsync: addBill } = useApiOperation(mntBillAdd);
  // 청구서 수정
  const { executeAsync: updateBill } = useApiOperation(mntBillUpdate);
  const { saveContractBillData } = useContactApi();
  const [cols, setCols] = useState<any>([]);
  const [selectedBuildingId, setSelectedBuildingId] = useState<any>("" || null);
  const [selectedScheduleId, setSelectedScheduleId] = useState<any>("" || null);
  const [firstSelector, setFirstSelector] = useState<any>([]);
  const [secondSelector, setSecondSelector] = useState<any>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<any>({});
  const [buildingScheduleList, setBuildingScheduleList] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [overdueList, setOverdueList] = useState<any>([]);
  const [showMakeBill, setShowMakeBill] = useState(false);
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);
  const [provider, setProvider] = useState<any>();
  const [bill, setBill] = useState<Bill>({
    billId: 0,
    billStartTime: "",
    billEndTime: "",
    billIssueDate: "",
    billDueDate: "",
    isConfirmed: false,
    billDescription: "",
    amountDescription: "",
    buildingId: 0,
    taxInvoiceIssueDate: null,
    billDetailList: [],
  });
  const [messageModal, setMessageModal] = useState<Modal>({
    isOpen: false,
  });

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (value) => value,
      }),
    [location],
  );

  /** 수정가능 여부 */
  const showBtn = useMemo(() => {
    let flag = true;

    if (location.pathname.indexOf("/detail/") > -1) {
      flag = false;
    }

    return flag;
  }, [location]);
  /**
   * 선택한 건물의 스케줄에 등록 된 프로바이더 목록 조회
   * ,로 구분된 스트링 값
   */
  const getProvidersName = useCallback(
    async (providerIds: string) => {
      const getPds = await getProviders({ providerId: String(providerIds) });
      // console.log("getProviders", getPds);
      return getPds.data.data.content;
    },
    [getProviders],
  );

  const getMntProvider = useCallback(
    async (providerId: string) => {
      const mntPd: any = await getProviderDetail({ providerId });
      if (mntPd.data.data) {
        // console.log("mntPd.data.data", mntPd.data.data);
        setProvider(mntPd.data.data);
      }
    },
    [getProviderDetail],
  );

  /**
   * 선택한 건물 스케줄의 청구서 목록을 조회
   */
  const getRounds = useCallback(
    async (buildingId: string, scheduleId: string) => {
      setTableData([]);
      setOverdueList([]);
      const rounds = await getRoundList({ buildingId: buildingId, scheduleId: Number(scheduleId) });
      console.log("rounds", rounds);
      if (rounds && rounds.status >= 200 && rounds.status <= 299) {
        const sorted: any = rounds.data.data.content;

        const normal = sorted.filter((sort: any) => {
          if (sort.bill.isOverdueBill === false) {
            return sort;
          }
        });
        const overdues = sorted.filter((sort: any) => {
          if (sort.bill.isOverdueBill) {
            return sort;
          }
        });
        await setTableData(normal);
        await setOverdueList(overdues);
      }
    },
    [getRoundList],
  );
  const getBuildingsSchedules = useCallback(
    async (buildingId: string) => {
      const params = {
        contractId: Number(contractBaseInfo!.contract!.contractId),
        buildingId: Number(buildingId),
        supplyType: "MNT",
      };
      setBuildingScheduleList([]);
      const list = await getScheduleList(params);
      console.log("find buildings schedules", list);
      setBuildingScheduleList(list.data.data.scheduleList);
      return list;
    },
    [contractBaseInfo, getScheduleList],
  );
  /**
   * 선택한 건물의 관리비 스케줄 목록 조회
   */
  const findMntSchedule = useCallback(
    async (buildingId: string) => {
      if (contractBaseInfo) {
        const list = await getBuildingsSchedules(buildingId);
        // setTableData(list.data.data.scheduleList.content);
        // console.log("list", list.data.data.scheduleList);
        if (list.data.data.scheduleList && list.data.data.scheduleList.length > 0) {
          let pvIds = list.data.data.scheduleList.map((schedule: any) => schedule.providerId).join(",");

          //받아온 목록에서 프로바이더 명 매핑처리
          const providers = await getProvidersName(pvIds);
          if (providers.length > 0) {
            let mntScheduleList = list.data.data.scheduleList;
            const second: any = [];
            mntScheduleList.forEach((msl: any) => {
              const pd = providers.find((pv: any) => msl.providerId === pv.providerId);
              const opt = {
                label: pd?.providerName + "(" + pd?.providerId + ")",
                value: msl.scheduleId,
                providerId: pd?.providerId,
              };
              second.push(opt);
            });
            setSecondSelector(second);
            let providerId = "";
            let scheduleId = "";
            //0번째로 조회
            if (queryParams.scheduleId) {
              scheduleId = String(queryParams.scheduleId);
              providerId = second.find((opt: any) => opt.value === String(queryParams.scheduleId)).providerId;
            } else {
              providerId = second[0]!.providerId;
              scheduleId = second[0]!.value;
            }

            await getMntProvider(providerId);
            setSelectedScheduleId(scheduleId);
            const findSchedule = list.data.data.scheduleList.find((schedule: any) => schedule.scheduleId === scheduleId);
            setSelectedSchedule(findSchedule);
            console.log("list", list.data.data);
            await getRounds(buildingId, findSchedule.scheduleId);
          }
        }
      }
    },
    [contractBaseInfo, getBuildingsSchedules, getProvidersName, queryParams, getMntProvider, getRounds],
  );

  useEffect(() => {
    let list = _.cloneDeep(MntEditColumns);

    if (basicInfo?.spaceProductType === "TIME_COURT") {
      list = list.filter((head: any) => head.accessor !== "instantPayment");
    }
    setCols([...list, ...deleteCol]);

    if (contractBaseInfo.product) {
      const buildingSelectData: any = [];

      //상품 ID로 상품안의 건물 데이터
      const snapshotProduct = contractBaseInfo.product;

      snapshotProduct!.buildings!.forEach((building: any) => {
        // console.log("building", building);
        const option = {
          label: building.initValue + "(" + building.domainId + ")",
          value: building.domainId,
          providerId: 0,
        };
        buildingSelectData.push(option);
      });

      if (firstSelector.length === 0) {
        setFirstSelector(buildingSelectData);
      }
      if (!selectedBuildingId && queryParams.buildingId === undefined) {
        setSelectedBuildingId(buildingSelectData[0]!.value!);
        findMntSchedule(buildingSelectData[0]!.value!);
      }
      console.log("queryParams", queryParams);
      if (queryParams.buildingId && queryParams.scheduleId) {
        setSelectedBuildingId(queryParams.buildingId);
        findMntSchedule(String(queryParams.buildingId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractBaseInfo, findMntSchedule]);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, page, prepareRow } = useTable(
    {
      columns: cols,
      data: tableData,
      initialState: { pageSize: 1000 },
      contractBaseInfo,
      callList,
      basicInfo,
      stopObj,
      buildingId: selectedBuildingId,
      scheduleId: selectedScheduleId,
      showBtn,
      getRounds,
      messageModal,
      setMessageModal,
      selectedScheduleId,
      openToast,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  // columns width 의 총 합 (table width 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const onClosePopup = useCallback(async () => {
    setShowMakeBill(false);
    // setSelectedBuildingId(selectedBuildingId);
    // setSelectedScheduleId(selectedScheduleId);
    await getRounds(selectedBuildingId, selectedScheduleId);
    setBill(initBill);
  }, [getRounds, selectedBuildingId, selectedScheduleId]);

  /**
   * 저장 버튼 클릭
   */
  const saveBill = useCallback(
    async (detailList: any) => {
      // console.log("detailList", detailList);
      const temp = _.cloneDeep(bill);
      if (temp.buildingId === 0) {
        temp.buildingId = selectedBuildingId;
      }
      if (temp.scheduleId === 0) {
        temp.scheduleId = selectedScheduleId;
      }
      if (temp.billStartTime !== "") {
        temp.billStartTime = moment(temp.billStartTime).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billEndTime !== "") {
        temp.billEndTime = moment(temp.billEndTime).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billIssueDate !== "") {
        temp.billIssueDate = moment(temp.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billDueDate !== "") {
        temp.billDueDate = moment(temp.billDueDate).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.taxInvoiceIssueDate !== null) {
        temp.taxInvoiceIssueDate = moment(temp.taxInvoiceIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      }
      temp.billDetailList = detailList;
      setBill(temp);
      // console.log('temp',temp);
      //저장 기능 처리
      return await addBill({ scheduleId: selectedScheduleId, body: temp });
    },
    [addBill, bill, selectedBuildingId, selectedScheduleId],
  );

  const onConfirmBill = useCallback(async () => {
    const temp = _.cloneDeep(bill);
    const send: any = { billId: temp.billId };
    send.isConfirmed = !temp.isConfirmed;

    const rnt = await updateBill({ scheduleId: selectedScheduleId, body: send });
    if (rnt.status >= 200 && rnt.status < 400) {
      setBill({ ...temp, ...send });
    }
    return rnt;
  }, [bill, selectedScheduleId, updateBill]);

  const checkProviderData = useCallback(() => {
    let check = true;
    if (contractBaseInfo!.contractManage!.mbOrganizationId === "0") {
      openToast({ content: "해당 계약에 사업자 정보를 등록해 주세요.", isSystemToast: true });
      check = false;
      return check;
    }
    console.log("provider", provider.providerAccountList);
    if (
      Array.isArray(provider!!.providerAccountList) &&
      (provider!.providerAccountList!.length === 0 ||
        provider!.providerAccountList!.filter((data: any) => data.accountType === "MANAGEMENT_ACCOUNT")!.length === 0)
    ) {
      openToast({ content: "해당 정산정보의 연동정보에 계좌정보를 등록해 주세요.", isSystemToast: true });
      check = false;
      return check;
    }
    return check;
  }, [contractBaseInfo, openToast, provider]);

  const handleAddBill = useCallback(() => {
    const flag = checkProviderData();
    console.log("flag", flag);
    if (flag) {
      setShowMakeBill(true);
    }
  }, [checkProviderData]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article" style={{ maxWidth: "unset" }}>
            <div>
              <div className="contents-container__sub-title">
                <BaseSectionTitle title={"관리비 빌링 현황"} />
              </div>
              <div>
                <div className="flex-center-between mb10">
                  <div className="left-area">
                    <div className="flex-center">
                      <BaseSelect
                        value={selectedBuildingId || null}
                        setStateValue={async (buildingId: string) => {
                          setBill(initBill);
                          setSelectedBuildingId(buildingId);
                          const temp = _.cloneDeep(bill);
                          temp.buildingId = Number(buildingId);
                          setBill(temp);
                          await findMntSchedule(buildingId);
                        }}
                        stateOptions={firstSelector}
                        className="mr8 minmax260"
                      />
                      <BaseSelect
                        value={selectedScheduleId || null}
                        setStateValue={async (scheduleId: string) => {
                          setSelectedScheduleId(String(scheduleId));
                          const find: any = secondSelector.find((mnt: any) => String(mnt.value) === String(scheduleId));
                          const findSchedule = buildingScheduleList.find((schedule: any) => schedule.scheduleId === scheduleId);
                          setSelectedSchedule(findSchedule);
                          await getMntProvider(find.providerId);
                          await getRounds(selectedBuildingId, scheduleId);
                        }}
                        stateOptions={secondSelector}
                        className="minmax260"
                      />
                      <BaseButton
                        title="연체 요율 설정"
                        className="ml8 color-white"
                        onClick={() => {
                          const check = checkProviderData();
                          if (check) {
                            setIsOverdueFeeOpen(true);
                          }
                        }}
                      />
                      <BaseTooltip
                        contents={""}
                        tooltip={"관리처 별로 연체 요율을 설정해 주세요."}
                        type={"normal"}
                        className={"ml8 tooltip__trigger-icon"}
                      />
                      {isOverdueFeeOpen && (
                        <>
                          <OverdueFeePopup
                            isOpen={isOverdueFeeOpen}
                            overdueChargeStandard={String(selectedSchedule?.overdueChargeStandard)}
                            overdueList={selectedSchedule?.scheduleOverdueList!}
                            onClick={async (editedData: any) => {
                              setIsOverdueFeeOpen(false);
                              const temp = _.cloneDeep(selectedSchedule!);
                              temp.overdueChargeStandard = editedData.overdueChargeStandard;
                              console.log("editedData", editedData);
                              editedData.overdueList.forEach((overdue: any) =>
                                overdue.overdueId ? (overdue.cmdType = "U") : (overdue.cmdType = "C"),
                              );
                              temp.scheduleOverdueList = [...editedData.overdueList];
                              console.log("temp", temp);
                              const result: any = await saveContractBillData(selectedSchedule, temp);
                              console.log("result", result);
                              if (result.response.status > 199 && result.response.status < 400) {
                                const temp = _.cloneDeep(messageModal);
                                temp.message = "연체 요율이 저장되었습니다.";
                                temp.isOpen = true;
                                setMessageModal(temp);
                                const list: any = await getBuildingsSchedules(selectedBuildingId);
                                const findSchedule = list.data.data.scheduleList.find(
                                  (schedule: any) => schedule.scheduleId === selectedSchedule.scheduleId,
                                );
                                setSelectedSchedule(findSchedule || {});
                              }
                            }}
                            onClose={() => setIsOverdueFeeOpen(false)}
                            viewOnly={!showBtn}
                            scheduleType={"mnt"}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="right-area">
                    <BaseButton
                      title="청구서 생성"
                      onClick={handleAddBill}
                      disabled={contractBaseInfo?.contract?.contractStep === ContractStep.APPLY_CANCEL}
                    />
                  </div>
                </div>
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                              {/* <div className="ic_sort"></div> */}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {page.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    {page.length === 0 && (
                      <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="footer">
                    {footerGroups.map((footerGroup) => (
                      <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {footerGroup.headers.map((column) => (
                          <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                            {column.render("Footer")}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        {basicInfo?.spaceProductType !== "TIME_COURT" && (
          <OverdueInfo
            basicInfo={basicInfo}
            callList={getRounds}
            contractBaseInfo={contractBaseInfo}
            roundList={tableData}
            overdueList={overdueList}
            stopObj={stopObj}
            editable={showBtn}
            scheduleId={selectedScheduleId}
            buildingId={selectedBuildingId}
            showBtn={showBtn}
          />
        )}
        {showMakeBill && (
          <MakeBillPopup
            isChange={showMakeBill}
            contractDetail={contractBaseInfo!}
            showBtn={showBtn}
            bill={_.cloneDeep(bill)}
            setBill={setBill}
            onClose={onClosePopup}
            onConfirm={onConfirmBill}
            onClick={saveBill}
            messageModal={messageModal}
            setMessageModal={setMessageModal}
            findMntSchedule={findMntSchedule}
            allBill={null}
            buildingId={selectedBuildingId}
            scheduleId={selectedScheduleId}
          />
        )}
        {messageModal.isOpen && (
          <BaseModal
            isOpen={messageModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setMessageModal({ ...messageModal, isOpen: false });
            }}
            title={messageModal.message}
          ></BaseModal>
        )}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton
            title="수정 취소"
            className="color-white size-large ml8"
            onClick={() => {
              navigate(
                pagePath.billing.detail.replace(
                  ":id",
                  String(params.id) + "?tab=mnt&buildingId=" + selectedBuildingId + "&scheduleId=" + selectedScheduleId,
                ),
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MntBillingForm;
