import _ from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { retryPayment } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import StorageProcessingModal from "../modal/StorageProcessing/StorageProcessingModal";
import PaymentStatusView from "../components/PaymentStatusView";
const MntOverdueInfoColumns: any = [
  {
    Header: "산출기간",
    accessor: "bill.billStartTime",
    sticky: "left",
    width: 190,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const start = moment(row.original.bill.billStartTime).format(ViewYmdFormat.YYYY_MM_DD);
      const end = moment(row.original.bill.billEndTime).format(ViewYmdFormat.YYYY_MM_DD);
      return <p>{start + " - " + end}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.baseAmount",
    width: 120,
    Cell: ({ value, row }: any) => {
      if (value) {
        return <p>{numberToStringWithComma(value)}</p>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "청구일자",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부 종료일",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      // console.log(row);
      return (
        <div>
          {row.original !== null && row.original.bill !== null && row.original.billDueDate !== undefined
            ? moment(row.original.billDueDate).format(ViewYmdFormat.YYYY_MM_DD)
            : "-"}{" "}
        </div>
      );
    },
  },
  {
    Header: "연체 기간(일)",
    accessor: "bill.receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{value ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "bill.receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.forEach((row: any) => {
        if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
          sum += Number(row.original.receiptBundle.realtimeOverdueAmount);
        }

        if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
          sum += Number(row.original.receiptBundle.accruedOverdueAmount);
        }
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },
  {
    Header: "수납확인",
    accessor: "recieve",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, scheduleId, buildingId, showBtn }: any) => {
      const [isChange, setIsChange] = useState(false);

      const pDatas = useMemo(() => {
        const datas = {
          row,
          rows,
          callList,
          contractBaseInfo,
          buildingId,
          scheduleId,
          showBtn,
          isChange,
          setIsChange,
          type: "mnt",
        };
        return datas;
      }, [callList, contractBaseInfo, isChange, row, rows, scheduleId, buildingId, showBtn]);

      return (
        <div>
          {/* {moment(today).isSameOrAfter(moment(billDate)) ? ( */}
          <div>
            {showBtn && row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ? (
              <BaseButton
                title={row.original.receiptBundle.receiptList.length > 0 ? "수정" : "수납처리"}
                className="color-white size-small"
                disabled={Number(row.original.bill.totalAmount) > 0 || row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true}
                onClick={() => setIsChange(true)}
              />
            ) : (
              <BaseButton
                title={"수납확인"}
                className="color-white size-small"
                disabled={row.original.billReceipt !== null ? false : row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true}
                onClick={() => setIsChange(true)}
              />
            )}
            {isChange && <StorageProcessingModal processData={pDatas} />}
          </div>
          {/* ) : (
            <p>-</p>
          )} */}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          연체금액
          <br />
          거래일시
        </p>
      );
    },
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(ViewYmdFormat.FULL) : "-";
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      let billIssuDay = moment(row.original.bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD);
      let today = moment().format(ViewYmdFormat.YYYY_MM_DD);

      if (row.original.bill.billPayStatus === "PAYMENT_READY" && moment(today).isAfter(moment(billIssuDay))) {
        value = "PAYMENT_DELAY";
      }

      return <PaymentStatusView value={value} />;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
];
export default MntOverdueInfoColumns;
