/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { editPriceRulesAsync, getPriceRulesListAsync, postPriceRulesAsync } from "src/api/price/price-api";
import { PriceRules, PriceRulesModel } from "src/api/price/price-types";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseConfirmModal, BaseDatePicker, BaseInput, BaseModal, BaseRadio } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import RangeDatepicker from "src/components/RangeDatepicker";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { YmdFormat, onlyNumber } from "src/utils";
import FacilitySelectModal from "../../components/FacilitySelectModal";
import ProviderSelectModal from "../../components/ProviderSelectModal";
import useDynamicHeight from "src/hooks/useDynamicHeight";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

/**
 * feature:: rangeDataPicker에서 1개의 react-hook-form 컨트롤러를 사용하므로
 * 상세 데이터 호출 시 displayDate를 setValue 해줌. 타입은 dateRange state와 같다.
 */
type SelectedProvider = {
  providerId: string;
  providerName: string;
};
type SelectedFacility = {
  buildingCommonFacilityId: string;
  facilityName: string;
  buildingName: string;
  buildingId: string;
};

const BasicInfoForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const id: string | undefined = queryParams?.id ? String(queryParams.id) : undefined;

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [price, setPrice] = useState<PriceRulesModel>();
  const [selectedProvider, setSelectedProvider] = useState<SelectedProvider[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<SelectedFacility[]>([]);
  const [selectedRulesRadio, setSelectedRulesRadio] = useState<string>("BASE");
  const [selectedRulesOption, setSelectedRulesOption] = useState<string>(PriceRules.PRICE_RULE_PROVIDER);
  const [isProviderSelectModalOpen, setIsProviderSelectModalOpen] = useState<Modal>({ isOpen: false });
  const [isFacilitySelectModalOpen, setIsFacilitySelectModalOpen] = useState<Modal>({ isOpen: false });
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [errorModal, setErrorModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: postPriceRules } = useApiOperation(postPriceRulesAsync, { noHandleError: true });
  const { executeAsync: editPriceRules } = useApiOperation(editPriceRulesAsync);
  const { executeAsync: getPriceRulesDetail } = useApiOperation(getPriceRulesListAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);

  const { openToast } = useToast();
  const checkHeightRef = useRef<HTMLDivElement | null>(null);
  const maxHeight = useDynamicHeight(checkHeightRef);
  const defaultValues: any = useMemo(() => {
    return {
      providerId: "",
      name: "",
      priceRuleType: PriceRules.PRICE_RULE_PROVIDER,
      buildingCommonFacilityId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      cost: "",
      price: "",
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    unregister,
    trigger,
    clearErrors,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<any>({
    defaultValues,
  });

  // register
  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    if (selectedRulesRadio === "BASE" && selectedRulesOption === PriceRules.PRICE_RULE_PROVIDER) {
      const nameValue = getValues("name");
      unregister(["buildingCommonFacilityId", "startDate", "endDate", "startTime", "endTime", "name"]);
      setValue("name", nameValue);
    } else if (selectedRulesRadio === "BASE" && selectedRulesOption === PriceRules.PRICE_RULE_FACILITY) {
      const nameValue = getValues("name");
      unregister(["startDate", "endDate", "startTime", "endTime", "name"]);
      setValue("name", nameValue);
      register("buildingCommonFacilityId", {
        required: requiredMessage,
      });
    } else {
      register("name", {
        required: requiredMessage,
      });
      register("startDate", {
        required: requiredMessage,
      });
      register("endDate", {
        required: requiredMessage,
      });
      register("startTime", {
        required: requiredMessage,
      });
      register("endTime", {
        required: requiredMessage,
      });
    }
    register("providerId", {
      required: requiredMessage,
    });
    register("cost", {
      required: requiredMessage,
    });
    register("price", {
      required: requiredMessage,
    });
  }, [register, selectedRulesRadio, selectedRulesOption, unregister, getValues, setValue]);

  // fetch
  useEffect(() => {
    if (id) {
      const fetchApi = async () => {
        const response = await getPriceRulesDetail({ priceRuleIdList: id, page: 0, size: 1 });
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.data.content.length > 0) {
            let priceData = response.data.data.content[0];
            let providerData: ProviderModel = {};
            let facilityData: CommonFacilityModel = {};

            const providerResponse = await getProvider({ providerId: priceData?.providerId?.toString() });
            if (providerResponse.status >= 200 && providerResponse.status <= 299) {
              if (providerResponse.data.data.content.length > 0) {
                providerData = providerResponse?.data?.data?.content[0];
                let providerState = { providerId: String(providerData.providerId), providerName: String(providerData.providerName) };
                setSelectedProvider([providerState]);
              }
            }
            if (priceData.buildingCommonFacilityId) {
              const buildingFacilityResponse = await getCommonFacilityList({
                searchType: "ID",
                keywordList: priceData?.buildingCommonFacilityId?.toString(),
              });
              if (buildingFacilityResponse.status >= 200 && buildingFacilityResponse.status <= 299) {
                if (buildingFacilityResponse.data.data.content.length > 0) {
                  facilityData = buildingFacilityResponse?.data?.data?.content[0];

                  let facilityState = {
                    buildingCommonFacilityId: String(facilityData.id) || "",
                    facilityName: String(facilityData.facilityName) || "",
                    buildingName: String(facilityData.buildingName) || "",
                    buildingId: String(facilityData.buildingId) || "",
                  };

                  setSelectedFacility([facilityState]);
                }
              }
            }

            const priceDetail = {
              ...priceData,
              providerName: providerData?.providerName,
              buildingId: facilityData?.buildingId,
              buildingName: facilityData?.buildingName,
              facilityName: facilityData?.facilityName,
            };

            setPrice(priceDetail);

            if (priceDetail.priceRuleType === PriceRules.PRICE_RULE_SPECIFIC) {
              setSelectedRulesRadio("SPECIFIC");
            }
            if (priceDetail.startDate && priceDetail.endDate) {
              const start = priceDetail.startDate ? moment(priceDetail.startDate).toDate() : null;
              const end = priceDetail.endDate ? moment(priceDetail.endDate).toDate() : null;
              setDateRange([start, end]);
            }

            reset({
              providerId: priceDetail.providerId,
              name: priceDetail.name,
              priceRuleType: priceDetail.priceRuleType,
              buildingCommonFacilityId: priceDetail.buildingCommonFacilityId,
              startDate: priceDetail.startDate ? moment(priceDetail.startDate).format(YmdFormat.WITH_TIME_ZONE) : "",
              endDate: priceDetail.endDate ? moment(priceDetail.endDate).format(YmdFormat.WITH_TIME_ZONE) : "",
              startTime: priceDetail.startTime ? moment(priceDetail.startTime, "hh:mm").toDate() : "",
              endTime: priceDetail.endTime ? moment(priceDetail.endTime, "hh:mm").toDate() : "",
              cost: priceDetail.cost,
              price: priceDetail.price,
            });

            // 프로바이더, 공용공간 팝업 선택되어있으면 추가
          }
        }
      };
      fetchApi();
    }
  }, [id, getCommonFacilityList, getProvider, getPriceRulesDetail, setValue, reset]);

  const onSubmit = useCallback(
    (data: Partial<PriceRulesModel>, e?: any) => {
      e.preventDefault();
      let payload: Partial<PriceRulesModel> = {};
      const startTime = data.startTime ? moment(data.startTime).format("HH:mm") : "";
      const endTime = data.endTime ? moment(data.endTime).format("HH:mm") : "";

      payload = { ...data, startTime, endTime };

      if (id) {
        let newPayload: any = {};
        for (const key in payload) {
          if (key !== "name") {
            if (payload[key as keyof PriceRulesModel]) {
              newPayload[key] = payload[key as keyof PriceRulesModel];
            }
          }
          if (price?.name !== payload["name"]) {
            newPayload = { ...newPayload, name: payload["name"] };
          }
        }
        setConfirmModal({ isOpen: true, message: "수정하시겠습니까?", type: "editPrice", payload: newPayload });
      } else {
        let newPayload: any = {};
        for (const key in payload) {
          if (payload[key as keyof PriceRulesModel]) {
            newPayload[key] = payload[key as keyof PriceRulesModel];
          }
        }
        setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", type: "addPrice", payload: newPayload });
      }

      e.target.reset();
    },
    [id, price],
  );

  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    // validation 걸렸을때
    return false;
  };

  const onAddedProviderSelectModal = (provider: SelectedProvider[]) => {
    if (!provider) return;
    setSelectedProvider(provider);
    setValue("providerId", provider[0].providerId, { shouldDirty: true });
    setIsProviderSelectModalOpen({ isOpen: false });
    trigger("providerId");
  };

  const onAddedFacilitySelectModal = (facility: SelectedFacility[]) => {
    if (!facility) return;
    setSelectedFacility(facility);
    setValue("buildingCommonFacilityId", facility[0].buildingCommonFacilityId, { shouldDirty: true });
    setIsFacilitySelectModalOpen({ isOpen: false });
    trigger("buildingCommonFacilityId");
  };
  const handleDeleteProvider = (id: string) => {
    const filteredDeleted = selectedProvider.filter((item) => item.providerId !== id);
    setValue("providerId", "");
    setSelectedProvider(filteredDeleted);
    trigger("providerId");
  };
  const handleDeleteFacility = (id: string) => {
    const filteredDeleted = selectedFacility.filter((item) => item.buildingCommonFacilityId !== id);
    setValue("buildingCommonFacilityId", "");
    setSelectedFacility(filteredDeleted);
    trigger("buildingCommonFacilityId");
  };

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;
    setDateRange(dateRange);
    if (!startDate || !endDate) return;

    let start = moment(startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
    let end = moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

    setValue("startDate", start, { shouldDirty: true });
    setValue("endDate", end, { shouldDirty: true });
    trigger("startDate");
    trigger("endDate");
  };

  const isAnythingChanged = () => {
    if (id) {
      // TODO:: 변경사항 비교 수정
    } else {
      let changed: boolean = false;
      for (let key in defaultValues) {
        if (key !== "priceRuleType") {
          if (getValues(key)) {
            changed = true;
          }
        }
      }
      return changed;
    }
  };
  return (
    <>
      {id ? <MetaTag subTitle={price?.name || ""} /> : <MetaTag isCreatePage />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap price-page">
            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"기본정보"} noHashLink />
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p className="required">프로바이더</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <BaseButton
                      type="button"
                      className="color-white size-medium"
                      title="선택"
                      disabled={id ? true : false}
                      onClick={() => setIsProviderSelectModalOpen({ isOpen: true })}
                    />
                    <div className="flex-files ml10">
                      {selectedProvider?.map((item) => (
                        <div className="flex-files__wrap" key={item.providerId}>
                          <BaseNewTabLink
                            path={`${PagePath.provider.detail.replace(":id", `${item.providerId}`)}`}
                            value={item.providerName || "-"}
                          />
                          {!id && <div className="delete-btn-x" onClick={() => handleDeleteProvider(item.providerId)}></div>}
                        </div>
                      ))}
                    </div>
                  </div>
                  {errors && errors.providerId && <p className="validation-text">{errors.providerId.message?.toString()}</p>}
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">요금구분</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax740 flex-row flex-center-start">
                    <BaseRadio
                      name={"BASE"}
                      id={"BASE"}
                      value={"BASE"}
                      disabled={id ? true : false}
                      checked={selectedRulesRadio === "BASE" ? true : false}
                      onChange={(_, e) => {
                        setSelectedRulesRadio(e.target.name);
                        setValue("priceRuleType", PriceRules.PRICE_RULE_PROVIDER);
                        setValue("startTime", "");
                        setValue("endTime", "");
                        setValue("startDate", "");
                        setValue("endDate", "");
                        setSelectedRulesOption(PriceRules.PRICE_RULE_PROVIDER);
                        setDateRange([null, null]);
                        clearErrors();
                      }}
                      label={"기준요금"}
                      className="mr10"
                    />
                    <BaseRadio
                      name={"SPECIFIC"}
                      id={"SPECIFIC"}
                      value={"SPECIFIC"}
                      disabled={id ? true : false}
                      checked={selectedRulesRadio === "SPECIFIC" ? true : false}
                      onChange={(_, e) => {
                        setSelectedRulesRadio(e.target.name);
                        setValue("priceRuleType", PriceRules.PRICE_RULE_SPECIFIC);
                        setValue("buildingCommonFacilityId", "");
                        setSelectedFacility([]);
                        clearErrors();
                      }}
                      label={"개별요금"}
                    />
                  </div>
                  {selectedRulesRadio === "BASE" && (
                    <div className="gray-box mt10">
                      <div className="gray-box__grid mb20">
                        <div className="gray-box__grid-index">
                          <p>기준요금 유형</p>
                        </div>
                        <div className="gray-box__grid-contents">
                          <Controller
                            control={control}
                            name="priceRuleType"
                            render={({ field: { onChange, value, name } }) => (
                              <div className="d-flex">
                                <BaseRadio
                                  name={name}
                                  id={PriceRules.PRICE_RULE_PROVIDER}
                                  value={PriceRules.PRICE_RULE_PROVIDER}
                                  checked={value === PriceRules.PRICE_RULE_PROVIDER ? true : false}
                                  disabled={id ? true : false}
                                  onChange={(_, e) => {
                                    onChange(PriceRules.PRICE_RULE_PROVIDER);
                                    setSelectedRulesOption(PriceRules.PRICE_RULE_PROVIDER);
                                    setValue("buildingCommonFacilityId", "");
                                    setSelectedFacility([]);
                                  }}
                                  label={"프로바이더"}
                                  className="mr10"
                                />
                                <BaseRadio
                                  name={name}
                                  id={PriceRules.PRICE_RULE_FACILITY}
                                  value={PriceRules.PRICE_RULE_FACILITY}
                                  checked={value === PriceRules.PRICE_RULE_FACILITY ? true : false}
                                  disabled={id ? true : false}
                                  onChange={(_, e) => {
                                    onChange(PriceRules.PRICE_RULE_FACILITY);
                                    setSelectedRulesOption(PriceRules.PRICE_RULE_FACILITY);
                                  }}
                                  label={"공용공간"}
                                />
                              </div>
                            )}
                          ></Controller>
                        </div>
                      </div>
                      <div className="gray-box__grid">
                        <div className={`${maxHeight > 40 ? "index-top-padding flex-start gray-box__grid-index" : "gray-box__grid-index"}`}>
                          <p>공용공간</p>
                        </div>
                        <div className="gray-box__grid-contents ">
                          <div className={` ${maxHeight > 40 ? "flex-start" : "flex-center"}`}>
                            <BaseButton
                              type="button"
                              className="color-white size-medium"
                              title="선택"
                              disabled={watch("priceRuleType") !== PriceRules.PRICE_RULE_FACILITY}
                              onClick={() => setIsFacilitySelectModalOpen({ isOpen: true })}
                            />
                            <div className={`flex-files ml10`} ref={checkHeightRef}>
                              {selectedFacility?.map((item) => (
                                <div className="two-depth-link" key={item.buildingCommonFacilityId}>
                                  <BaseNewTabLink
                                    path={`${PagePath.building.detail.replace(":id", `${item.buildingId}`)}`}
                                    value={item.buildingName || "-"}
                                    isAddChevronPositionRight
                                  />
                                  <BaseNewTabLink
                                    path={`${PagePath.building.detail.replace(":id", `${item.buildingId}`)}?tab=facility`}
                                    value={item.facilityName || "-"}
                                  />
                                  <div className="delete-x-btn" onClick={() => handleDeleteFacility(item.buildingCommonFacilityId)}></div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gray-box__grid">
                        <div className="gray-box__grid-index">
                          <p></p>
                        </div>
                        <div className="gray-box__grid-contents ">
                          {errors && errors.buildingCommonFacilityId && (
                            <p className="validation-text">{errors.buildingCommonFacilityId.message?.toString()}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className={`${watch("priceRuleType") === PriceRules.PRICE_RULE_SPECIFIC ? "required" : ""} `}>요금정책명</p>
                </div>

                <div className="contents-container__grid-contents">
                  <div className="minmax240">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <BaseInput
                          inputRef={ref}
                          placeholder="요금정책명을 입력하세요"
                          className="mr8"
                          type="text"
                          onChange={onChange}
                          value={value}
                          name={name}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
              <div className="flex-center">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className={`${watch("priceRuleType") === PriceRules.PRICE_RULE_SPECIFIC ? "required" : ""} `}>적용일자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <RangeDatepicker disabled={selectedRulesRadio === "BASE"} dateRange={dateRange} onChange={handleOnDateRangeChange} />
                    </div>
                    {errors && errors.startDate && <p className="validation-text">{errors.startDate.message?.toString()}</p>}
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className={`${watch("priceRuleType") === PriceRules.PRICE_RULE_SPECIFIC ? "required" : ""} `}>적용시간</p>
                  </div>

                  <div className="contents-container__grid-contents">
                    <div className="flex-center flex-row minmax240">
                      <div className="w-100">
                        <Controller
                          control={control}
                          name="startTime"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseDatePicker
                                timeIntervals={30}
                                type="time"
                                placeholder="시간설정"
                                errorText={error?.message}
                                selectedDate={value}
                                setDate={(date: Date) => {
                                  onChange(date);
                                  setValue("endTime", "");
                                }}
                                name={name}
                                disabled={selectedRulesRadio === "BASE"}
                              />
                            );
                          }}
                        ></Controller>
                      </div>
                      <span className="mx10">{`~`}</span>
                      <div className="w-100">
                        <Controller
                          control={control}
                          name="endTime"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseDatePicker
                                timeIntervals={30}
                                type="time"
                                placeholder="시간설정"
                                errorText={error?.message}
                                selectedDate={value}
                                setDate={onChange}
                                name={name}
                                disabled={selectedRulesRadio === "BASE"}
                                filterTime={(time) => {
                                  const currentDate = new Date(watch("startTime"));
                                  const selectedDate = new Date(time);

                                  //startTime 이후의 시간만 true 리턴
                                  return currentDate < selectedDate;
                                }}
                                injectTimes={[moment().hours(23).minutes(59).toDate()]}
                              />
                            );
                          }}
                        ></Controller>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="flex-center">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className={`required`}>원가 (원/30분)</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="cost"
                        render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder="원가를 입력하세요"
                            maxLength={9}
                            errorText={error?.message}
                            type="text"
                            name={name}
                            onChange={onChange}
                            className="mr8"
                            value={onlyNumber(String(value))}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className={`required`}>판매가 (원/30분)</p>
                  </div>

                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="price"
                        render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder="판매가를 입력하세요"
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={onlyNumber(String(value))}
                            name={name}
                            maxLength={9}
                            errorText={error?.message}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
              </div>
            </article>
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {Number(id) > 0 ? (
              <BaseButton
                title="수정 취소"
                className="size-large color-white"
                onClick={() => {
                  if (isAnythingChanged()) {
                    setConfirmModal({ isOpen: true, type: "checkChanged", message: "현재 페이지에서 나가시겠습니까?" });
                  } else {
                    navigate(PagePath.price.detail.replace(":id", String(id)));
                  }
                }}
              />
            ) : (
              <BaseButton
                title="취소"
                className="size-large color-white"
                onClick={() => {
                  if (isAnythingChanged()) {
                    setConfirmModal({ isOpen: true, type: "checkChanged", message: "현재 페이지에서 나가시겠습니까?" });
                  } else {
                    navigate(PagePath.price.list);
                  }
                }}
              />
            )}
            <BaseButton title="저장" type={"submit"} className="size-large ml10" />
          </div>
        </div>
      </form>
      {isProviderSelectModalOpen.isOpen && (
        <ProviderSelectModal
          onCanceled={() => setIsProviderSelectModalOpen({ isOpen: false })}
          onAdded={onAddedProviderSelectModal}
          defaultChecked={selectedProvider ? selectedProvider : []}
        />
      )}
      {isFacilitySelectModalOpen.isOpen && (
        <FacilitySelectModal
          onCanceled={() => setIsFacilitySelectModalOpen({ isOpen: false })}
          onAdded={onAddedFacilitySelectModal}
          defaultChecked={selectedFacility ? selectedFacility : []}
        />
      )}
      {confirmModal.isOpen && (
        <BaseModal
          title={confirmModal.message}
          isOpen={confirmModal.isOpen}
          btnLeftTitle={confirmModal.type === "addPrice" || confirmModal.type === "editPrice" ? "취소" : "머무르기"}
          btnRightTitle={confirmModal.type === "addPrice" || confirmModal.type === "editPrice" ? "확인" : "나가기"}
          onClick={async () => {
            if (confirmModal.type === "addPrice") {
              setConfirmModal({ isOpen: false });
              const responsePostPrice = await postPriceRules(confirmModal.payload);
              if (responsePostPrice.status >= 200 && responsePostPrice.status <= 299) {
                openToast({ content: "정상적으로 등록되었습니다." });
                navigate(PagePath.price.list);
              } else {
                const errorCode = responsePostPrice.data.meta.errorCode;
                if (errorCode === "ePC0513") {
                  setErrorModal({ isOpen: true, title: "이미 등록된 프로바이더의 기준요금입니다" });
                } else if (errorCode === "ePC0516") {
                  setErrorModal({
                    isOpen: true,
                    title: "프로바이더를 다시 확인해주세요",
                    message: "프로바이더 목록에서 [관리처]는 공용공간 요금을 설정할 수 없습니다",
                  });
                } else {
                  setErrorModal({ isOpen: true, title: "잘못된 요청입니다", type: errorCode });
                }
              }
            } else if (confirmModal.type === "editPrice") {
              setConfirmModal({ isOpen: false });
              const { data } = await editPriceRules({ id: Number(id), payload: confirmModal.payload || {} });
              if (data.data) {
                openToast({ content: "정상적으로 수정되었습니다." });
                navigate(`${PagePath.price.detail.replace(":id", String(id))}`);
              }
            } else if (confirmModal.type === "checkChanged") {
              if (id) {
                setConfirmModal({ isOpen: false });
                navigate(`${PagePath.price.detail.replace(":id", String(id))}`);
              } else {
                setConfirmModal({ isOpen: false });
                navigate(`${PagePath.price.list}`);
              }
            }
          }}
          onClose={() => setConfirmModal({ isOpen: false })}
        >
          {confirmModal.type === "checkChanged" && (
            <div>
              <span>
                입력 중인 내용이 있는 경우,
                <br /> 저장을 하지 않은 정보는 사라집니다.
              </span>
            </div>
          )}
        </BaseModal>
      )}
      {errorModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={errorModal.title}
          btnRightTitle={"확인"}
          onClick={() => {
            setErrorModal({ isOpen: false });
          }}
        >
          {errorModal.type ? (
            <div>
              <span>{`[${confirmModal.type}]`}</span>
            </div>
          ) : errorModal.message ? (
            <div>
              <span>{errorModal.message}</span>
            </div>
          ) : null}
        </BaseModal>
      )}
    </>
  );
};

export default BasicInfoForm;
