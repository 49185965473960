import moment from "moment";
import { Cell } from "react-table";
import { NoticeModel } from "src/api/notice/notice-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat } from "src/utils";
import { noticeCategoryToText } from "../../notice-types";
/* 
  공용공간 예약 목록 columns
*/
const noticeColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ value }: Cell<NoticeModel>) => {
      const detailPath = `${PagePath.notice.detail.replace(":id", `${value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {value}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: () => {
      return <span className="no-wrap">공개 여부</span>;
    },
    accessor: "isDisplayed",
    width: 80,
    Cell: (props: Cell<NoticeModel>) => {
      return <div>{props.value ? <div className="status B">공개</div> : <div className="status D">비공개</div>}</div>;
    },
  },
  {
    Header: "카테고리",
    accessor: "noticeCategory",
    width: 80,
    Cell: ({ value }: Cell<NoticeModel>) => {
      return <p>{noticeCategoryToText(value)}</p>;
    },
  },
  {
    Header: "제목",
    accessor: "title",
    width: 280,
    Cell: (props: Cell<NoticeModel>) => {
      const detailPath = `${PagePath.notice.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div className="w-100 text-left ellipsis">
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {props.value}
          </DetailLink>
        </div>
      );
    },
  },

  {
    Header: "공개 기간",
    accessor: "displayStartDate",
    width: 288,
    Cell: (props: Cell<NoticeModel>) => {
      return (
        <div>
          {props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : ""} -{" "}
          {props.row.original.displayStartDate ? moment(props.row.original.displayEndDate).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : ""}
        </div>
      );
    },
  },
  {
    Header: "건물",
    accessor: "buildingNames",
    width: 200,
    Cell: (props: Cell<NoticeModel>) => {
      return <>{<div className="w-100 text-left">{props.value && props.value.length > 0 ? props.value.join(", ") : "-"}</div>}</>;
    },
  },
  {
    Header: "신청/계약",
    accessor: "contractProductNames",
    width: 200,
    Cell: (props: Cell<NoticeModel>) => {
      return <>{<div className="w-100 text-left ellipsis">{props.value && props.value.length > 0 ? props.value.join(", ") : "-"}</div>}</>;
    },
  },
  {
    Header: () => {
      return <span className="no-wrap">등록일</span>;
    },
    accessor: "createdDate",
    width: 96,
    Cell: ({ value }: Cell<NoticeModel>) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
];

export default noticeColumns;
