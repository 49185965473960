import { useEffect, useState } from "react";
import { Cell, Row } from "react-table";
import { useSetRecoilState } from "recoil";
import { CommonFacilityModel } from "src/api/building/building-types";
import NoImage from "src/assets/images/NoImage.png";
import { BaseButton, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import Link from "src/components/PartnerLink";
import usePostCommonFacility from "src/hooks/product/usePostCommonFacility";
import { useQueryParams } from "src/hooks/useQueryParams";
import { PagePath } from "src/pages/product/details";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { baseAbstractModalStateZ1 } from "src/recoil/modalState/atom";
import { useModal } from "src/recoil/modalState/hook";
import { makeTimeString } from "src/utils";
import SettingModal from "../modal/SettingModal";
import SpaceUsageLimitModal from "../modal/SpaceUsageLimitModal";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import BaseNewTabLink from "src/components/BaseNewTabLink";

export const meeting_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "이미지",
    accessor: "img",
    width: 80,
    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center">
          <img src={row.original?.mediaList?.[0]?.url ?? NoImage} alt=""></img>
        </div>
      );
    },
  },
  {
    Header: "회의실 명",
    accessor: "facilityName",
    width: 140,
    Cell: ({ row, value }: any) => {
      return (
        <div className="flex-center-center w100">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "정원",
    accessor: "facilityMaxPeopleNums",
    width: 70,
    Cell: ({ row, value }: Cell<any>) => {
      console.log("row :>> ", row);
      return <p className="text-underline">{value ? value : "-"}</p>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 96,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 148,
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];

export const refresh_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "이미지",
    accessor: "img",
    width: 80,
    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center">
          <img src={row.original?.mediaList?.[0]?.url ?? NoImage} alt=""></img>
        </div>
      );
    },
  },
  {
    Header: "편의시설 명",
    accessor: "facilityName",
    width: 190,
    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center w100">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 96,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 163,
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];

export const desk_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },

  {
    Header: "좌석그룹 명",
    accessor: "groupName",
    width: 160,
    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center w100">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "좌석 명",
    accessor: "facilityName",
    width: 80,
    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center w100">
          <BaseTooltip contents={value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 100,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 194,
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
];

export const duplicate_cols: any[] = [
  {
    Header: () => (
      <div className="no-wrap">
        <p>무제한 여부</p>
      </div>
    ),
    accessor: "isUnlimitedReservation",
    className: "required",
    width: 72,
    Cell: ({ row }: any) => {
      const [priceType, setPriceType] = useState(false);
      useEffect(() => {
        setPriceType(row.original.isUnlimitedReservation);
      }, [row.original]);
      return (
        <>
          <div className="w-100 text-center">
            <p>{priceType ? "무제한" : "시간제"}</p>
          </div>
        </>
      );
    },
  },

  {
    Header: "월 이용한도",
    accessor: "monthlyServiceMinutes",
    className: "required",
    width: 120,
    Cell: ({ value, row }: { value: number | undefined; row: any }) => {
      if (value === undefined || value === -1 || !row.original.isLimitedMonthlyReservation || row.original.isUnlimitedReservation) {
        //무제한이거나 사용안함이거나
        return "-";
      }

      return (
        <>
          <div className="w-100 text-center">
            <BaseTooltip isSingleLine contents={makeTimeString(value)} />
          </div>
        </>
      );
    },
  },
  {
    Header: "일 이용한도",
    accessor: "dailyServiceMinutes",
    width: 120,
    Cell: ({ value, row }: { value: number | undefined; row: any }) => {
      if (value === undefined || value === -1 || !row.original.isLimitedDailyReservation || row.original.isUnlimitedReservation) {
        return "-";
      }

      return (
        <>
          <div className="w-100 text-center">
            <BaseTooltip isSingleLine contents={makeTimeString(value)} />
          </div>
        </>
      );
    },
  },
  {
    Header: () => (
      <div className="no-wrap">
        <p>초과 예약</p>
      </div>
    ),
    accessor: "isAllowedOverReservation",
    className: "required",
    width: 64,
    Cell: ({ row }: any) => {
      if (row.original.isUnlimitedReservation) {
        return "-";
      }
      return (
        <>
          <div className="w-100 text-center">
            <p>{row.original.isAllowedOverReservation ? "허용" : "비허용"}</p>
          </div>
        </>
      );
    },
  },
];

export const time_court_price_cols = [
  {
    Header: "30분당 요금",
    accessor: "priceForThirty",
    width: 300,
    Cell: ({ row }: { row: Row<CommonFacilityModel> }) => {
      const setAbstractModalZ1 = useSetRecoilState(baseAbstractModalStateZ1);

      return (
        <div className="w-100 flex-center-center">
          <BaseButton
            title={"요금 확인"}
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "xlarge",
                children: <SettingModal isEditable={false} space={row.original} />,
              })
            }
          />
        </div>
      );
    },
  },
];

export const time_court_edit_cols = [
  {
    Header: "설정",
    accessor: "edit",
    width: 80,
    Cell: ({ row }: any) => {
      const setAbstractModalZ1 = useSetRecoilState(baseAbstractModalStateZ1);

      return (
        <div className="flex-row flex-center">
          <BaseButton
            title={"설정"}
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "xlarge",
                children: <SettingModal isEditable={true} space={row.original} />,
              })
            }
          />
        </div>
      );
    },
  },
];

export const time_court_delete_cols = [
  {
    Header: "삭제",
    accessor: "btn",
    width: 48,
    Cell: ({ row }: { row: Row<CommonFacilityModel> }) => {
      const { setBaseModal } = useModal();
      const { mutate } = usePostCommonFacility();
      const { queryParams } = useQueryParams<{ id?: string }>();
      const params = useParams();
      const productId = queryParams.id ? queryParams.id : params.id;

      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      return (
        <button
          className="base-trash-btn color-red"
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnLeftTitle: "취소",
              btnRightTitle: "확인",
              title: `해당 ${SpaceTypeLabel[spaceType]}을 삭제합니다`,
              children: `삭제한 즉시 해당 ${SpaceTypeLabel[spaceType]}에 엑세스 할 수 없습니다`,
              onClick: () => {
                mutate({
                  productBuildingCommonFacilitySaveList: [
                    {
                      id: row.original.productBuildingCommonFacilityId,
                      cmdType: "D",
                      buildingCommonFacilityId: row.original?.id?.toString(),
                    },
                  ],
                  productId: productId!,
                });
                setBaseModal({ isOpen: false });
              },
            });
          }}
        ></button>
      );
    },
  },
];

export const edit_cols: any[] = [
  {
    Header: "설정",
    accessor: "edit",
    width: 80,
    Cell: ({ row }: Cell<CommonFacilityModel>) => {
      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      const [isOpen, setIsOpen] = useState(false);
      return (
        <div className="flex-row flex-center">
          <BaseButton title={"설정"} className="color-white" onClick={() => setIsOpen(true)} />

          <BaseAbstractModal isOpen={isOpen} size="xlarge" className="overflow-x-hidden">
            <section className="base-abstract-modal__title px30 pb20">
              <div className="w-100 flex-row">
                <h1>{SpaceTypeLabel[spaceType]} 설정</h1>
                <ul className="base-list mt20">
                  <li className="font14">{SpaceTypeLabel[spaceType]}의 예약 이용한도를 설정해 주세요.</li>
                </ul>
                <SpaceUsageLimitModal spaceType={spaceType} space={row.original} onClose={() => setIsOpen(false)} />
              </div>
            </section>
          </BaseAbstractModal>
        </div>
      );
    },
  },
];

export const delete_cols: any[] = [
  {
    Header: "삭제",
    accessor: "btn",
    width: 48,
    Cell: ({ row }: { row: Row<CommonFacilityModel> }) => {
      const { setBaseModal } = useModal();
      const { mutate } = usePostCommonFacility();
      const { queryParams } = useQueryParams<{ id?: string }>();
      const params = useParams();
      const productId = queryParams.id ? queryParams.id : params.id;

      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      return (
        <button
          className="base-trash-btn color-red"
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnLeftTitle: "취소",
              btnRightTitle: "확인",
              title: `해당 ${SpaceTypeLabel[spaceType]}을 삭제합니다`,
              children: `삭제한 즉시 해당 ${SpaceTypeLabel[spaceType]}에 엑세스 할 수 없습니다`,
              onClick: () => {
                mutate({
                  productBuildingCommonFacilitySaveList: [
                    {
                      id: row.original.productBuildingCommonFacilityId,
                      cmdType: "D",
                      buildingCommonFacilityId: row.original?.id?.toString(),
                    },
                  ],
                  productId: productId!,
                });

                setBaseModal({ isOpen: false });
              },
            });
          }}
        ></button>
      );
    },
  },
];
