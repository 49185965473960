import moment from "moment";
import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { getClosedPeriodRuleMappingListAsync } from "src/api/closedPeriodRule/closedPeriodRule-api";
import { useApiOperation } from "src/api/hooks";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import DetailLink from "src/components/DetailLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat } from "src/utils";
import { DayOfWeekTypeLabel } from "../../closedPeriod-types";

/* 
  공용공간 휴무목록 columns
*/
const closedPeriodColumn = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.closedPeriod.detail.replace(":id", `${props.value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {props.value}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "프로바이더",
    accessor: "providerName",
    width: 270,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.provider.detail.replace(":id", `${props.row.original.providerId}`)}`;

      return <BaseNewTabLink path={detailPath} value={props.value || "-"} ellipsis className="w-100 text-left" />;
    },
  },
  {
    Header: "적용기간",
    accessor: "startDate",
    width: 300,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD) : ""} -{" "}
          {props.row.original.endDate ? moment(props.row.original.endDate).format(ViewYmdFormat.YYYY_MM_DD) : ""}
          <br />
          {props.row.original?.days
            ?.split(",")
            .map((day: any) => DayOfWeekTypeLabel[day as keyof typeof DayOfWeekTypeLabel])
            .join(",")}
        </div>
      );
    },
  },
  {
    Header: "적용시간",
    accessor: "endTime",
    width: 300,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.row.original.startTime ? props.row.original.startTime : ""} -{props.value ? props.value : ""}
        </div>
      );
    },
  },
  {
    Header: "정책명",
    accessor: "subject",
    width: 300,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
];

export default closedPeriodColumn;
