import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";
import { DeviceType, ExecuteResultType, IndoorUnitKeyTypes, IndoorUnitMode } from "./monitoring-types";
import { roundFloat } from "src/utils/common-util";
import { alertIcon } from "./components/MonitoringTable";

export const getUnit = (type: IndoorUnitKeyTypes) => {
  return IndoorUnitMode[type];
};

const cautionMessage = "최신 정보가 아닐 수 있습니다. 상태를 확인하세요.";
const BULLET = "•";

export const monitoringTooltipData = (iotData?: ExecuteResultType, accessData?: Ac2ProductAccessGroupModel, isCaution?: boolean) => {
  const unknownMessage = "알수없음";
  const tooltipChildren = [
    {
      type: DeviceType.ACCESS,
      value: (
        <div>
          <p className="popover-gray__title">{accessData?.deviceName}</p>

          <p>{`${BULLET} 상태 : ${accessData?.deviceStatusInfo?.deviceStatus}`}</p>
          <p>{`${BULLET} 장치 id : ${accessData?.externalDeviceId}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.SENSOR,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          <div className="flex-center">
            <p>{`${BULLET} 상태 : ${(iotData?.result?.motion && iotData?.result?.motion.value) || unknownMessage}`}</p>
            {isCaution && <div className="ml4">{alertIcon}</div>}
          </div>
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.INDOOR_UNIT,
      value: (
        <div>
          {iotData?.platform === "ST" && (
            <>
              <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
              <div className="flex-center">
                <p>{`${BULLET} 상태 : ${(iotData?.result?.switch && iotData?.result?.switch.value) || unknownMessage}`}</p>
                {isCaution && <div className="ml4">{alertIcon}</div>}
              </div>
              <p>{`${BULLET} 모드 : ${
                (iotData?.result?.airConditionerMode && getUnit(iotData?.result?.airConditionerMode.value)) || unknownMessage
              }`}</p>
              <p>{`${BULLET} 희망 온도 : ${
                (iotData?.result?.error
                  ? unknownMessage
                  : iotData?.result?.coolingSetpoint && roundFloat(iotData?.result?.coolingSetpoint.value) + "°C") || unknownMessage
              }`}</p>
              <p>{`${BULLET} 측정 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.value) + "°C") ||
                unknownMessage
              }`}</p>
              {isCaution && (
                <div className="flex-center">
                  <div className="mr3">{alertIcon}</div>
                  <p className="font12">{cautionMessage}</p>
                </div>
              )}
            </>
          )}
          {iotData?.platform === "B_IOT" && (
            <>
              <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
              <div className="flex-center">
                <p>{`${BULLET} 상태 : ${(iotData?.result?.operation && iotData?.result?.operation.power) || unknownMessage}`}</p>
                {isCaution && <div className="ml4">{alertIcon}</div>}
              </div>
              <p>{`${BULLET} 모드 : ${(iotData?.result?.mode && getUnit(iotData?.result?.mode.mode)) || unknownMessage}`}</p>
              <p>{`${BULLET} 희망 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.desired) + "°C") ||
                unknownMessage
              }`}</p>
              <p>{`${BULLET} 측정 온도 : ${
                (iotData?.result?.error ? unknownMessage : iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.current) + "°C") ||
                unknownMessage
              }`}</p>
              {isCaution && (
                <div className="flex-center">
                  <div className="mr3">{alertIcon}</div>
                  <p className="font12">{cautionMessage}</p>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
    // // 온도 caoution 확인 필요
    // {
    //   type: DeviceType.TEMPERATURE,
    //   value: (
    //     <div>
    //       <p className="popover-gray__title">평균 온도</p>
    //       {iotData?.platform === "ST" && (
    //         <p>{`${BULLET} ${(iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.value)) + "°C" || unknownMessage} ${
    //           iotData?.internalDisplayName
    //         }`}</p>
    //       )}
    //       {iotData?.platform === "B_IOT" && (
    //         <p>{`${BULLET} ${(iotData?.result?.temperature && roundFloat(iotData?.result?.temperature.current)) + "°C" || unknownMessage} ${
    //           iotData?.internalDisplayName
    //         }`}</p>
    //       )}
    //     </div>
    //   ),
    // },
    {
      type: DeviceType.LIGHT,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          <div className="flex-center">
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${(iotData?.result?.switch && iotData?.result?.switch.value) || unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
            {isCaution && <div className="ml4">{alertIcon}</div>}
          </div>
          <p>{`${BULLET} 밝기 : ${(iotData?.result?.level && iotData?.result.level.value) || unknownMessage}`}</p>
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.MENS_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          {!iotData?.result && <p>{`${BULLET} 상태 : ${unknownMessage}`}</p>}
          {iotData?.result?.contact && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iotData?.result?.contact.value ? iotData?.result?.contact.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {iotData?.result?.motion && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iotData?.result?.motion.value ? iotData?.result?.motion.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}

          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.WOMEN_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iotData?.internalDisplayName}</p>
          {!iotData?.result && <p>{`${BULLET} 상태 : ${unknownMessage}`}</p>}
          {iotData?.result?.contact && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iotData?.result?.contact.value ? iotData?.result?.contact.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {iotData?.result?.motion && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iotData?.result?.motion.value ? iotData?.result?.motion.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const iotChildern =
    tooltipChildren.find((item) => item.type === iotData?.labelType) || tooltipChildren.find((item) => item.type === DeviceType.ACCESS);

  return iotChildern?.value;
};
