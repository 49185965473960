import { useMemo, useState } from "react";
import { BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useGetProduct from "src/hooks/product/useGetProduct";
import { useQueryParams } from "src/hooks/useQueryParams";
import { BasicInfoDetail, ProductInfoDetail } from "./details";
import ChargeDetail from "./details/chargeManagement/ChargeDetail";
import GuideDetail from "./details/guide/GuideDetail";
import ProductImagesDetail from "./details/productImages/ProductImagesDetail";
import { CommonSpace } from "./forms";
import { ProductAccessGroup } from "./forms/accessLevelForm";
import ProductIotDevice from "./forms/iotDevice/ProductIotDevice";
import { ProductTypes, tabs } from "./product-types";

/* 
  공간상품 > 상세
 */
const ProductDetail = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { queryParams } = useQueryParams();

  const productId = queryParams.id;
  // 공간상품 상세 get Api
  const { data: product } = useGetProduct({ productId: productId });

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0], [queryParams]);

  /** V타입에서 공용공간 탭을 제거하기 위한 필터
   *  S2타입에서 요금관리 탭 제거
   */
  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => {
      if (product?.productType === ProductTypes.NON_RESIDENT_OFFICE && tab.value === "commonSpace") {
        return false;
      }
      if (product?.productType === ProductTypes.TIME_COURT && tab.value === "charge") {
        return false;
      }
      return true;
    });
  }, [product]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={product?.productName} isCreatePage={productId === undefined} />
      {modalOpen && (
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)} title="기본정보가 저장되지 않았습니다"></BaseModal>
      )}
      <ContentsTitle title="공간상품" tabs={filteredTabs} activeTab={activeTab} detailInfo={[{ title: "id", value: productId ?? "" }]} />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab.value === "basicInfo" && product && productId && <BasicInfoDetail product={product} productId={productId} />}
      {/* 전시정보 */}
      {activeTab.value === "productInfo" && product && productId && <ProductInfoDetail product={product!} productId={productId} />}
      {/* 이미지 */}
      {activeTab.value === "image" && <ProductImagesDetail />}
      {/* 요금관리 */}
      {activeTab.value === "charge" && product && productId && <ChargeDetail product={product} productId={productId} />}
      {/* 시설/서비스 */}
      {/* {activeTab.value === "facility" && <FacilityDetail product={product!} />} */}
      {/* 공용공간 */}
      {activeTab.value === "commonSpace" && product && productId && <CommonSpace product={product} productId={productId} />}

      {/* 출입그룹 */}
      {activeTab.value === "accessLevel" && <ProductAccessGroup product={product!} />}

      {/* 엑세스그룹 */}
      {/* {activeTab.value === "accessGroup" && <AccessGroupDetail />} */}
      {/* 이용안내 */}
      {activeTab?.value === "guide" && <GuideDetail productDetail={product!} />}
      {/* IoT */}
      {activeTab?.value === "iotDevice" && <ProductIotDevice product={product!} />}
    </div>
  );
};
export default ProductDetail;
