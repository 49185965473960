import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { changeRecieve, getPaymentNCancelList } from "src/api/billingSchedule/billingSchedule-api";
import { getProviderAccountCredits } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import RangeDatepicker from "src/components/RangeDatepicker";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils/common-util";
import DepositHistoryTable from "./DepositHistoryTable";
import PriceInfo from "./PriceInfo";
import StorageProcessingTable from "./StorageProcessingTable";
import PaymentTable from "./PaymentTable";
import { useParams } from "react-router-dom";

interface ProcessDatas {
  row: any;
  rows: any;
  callList: any;
  contractBaseInfo: any;
  buildingId?: any;
  scheduleId: any;
  showBtn: boolean;
  isChange: boolean;
  setIsChange: any;
  type: string;
}
interface Props {
  processData: ProcessDatas;
}

const StorageProcessingModal = ({ processData }: Props) => {
  const { contractBaseInfo, row, callList, scheduleId, showBtn, isChange, setIsChange, buildingId, type } = processData;
  const { id } = useParams();
  const { executeAsync: changeSuccess } = useApiOperation(changeRecieve);
  const { executeAsync: getAccountCredits } = useApiOperation(getProviderAccountCredits);
  const { executeAsync: getPaymentList } = useApiOperation(getPaymentNCancelList);

  const { openToast } = useToast();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const [search, setSearch] = useState({
    billId: Number(row.original.bill.billId),
    scheduleId: scheduleId,
    startDateTime: "",
    endDateTime: "",
  });
  // const sorted = _.sortBy(sample, "trDateTime").reverse();
  const [receiveData, setReceiveData] = useState<any>([]);

  const [receiveList, setReceiveList] = useState<any>([]);
  const [delReceiveList, setDelReceiveList] = useState<any>([]);
  const [paymentArray, setPaymentArray] = useState<any>([]);

  const [isSave, setIsSave] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [message, setMessage] = useState("");

  const [maxLimit, setMaxLimit] = useState<Date | null>(null);

  const [sum] = useState(0);
  const [ovSum] = useState(0);

  const fetchAccounts = useCallback(
    async (search: any) => {
      // console.log("search", search);
      const response: any = await getAccountCredits(search);
      //console.log("getAccount", response);
      if (response.status >= 200 && response.status <= 299) {
        response.data.data.content.forEach((accData: any) => (accData.isOpen = false));
        setReceiveData(response.data.data.content);
      }
    },
    [getAccountCredits, setReceiveData],
  );

  const fetchPaymentList = useCallback(
    async (billId: string, pgcode: string) => {
      let params = {
        billId,
        pgcode,
        isSuccessOnly: true,
      };
      if (pgcode === "virtualaccount") {
        params.isSuccessOnly = false;
      }
      const paymentList: any = await getPaymentList(params);
      if (paymentList.data.data.content && paymentList.data.data.content.length > 0) {
        // console.log("paymentList.data.data.content", paymentList.data.data.content);
        setPaymentArray(paymentList.data.data.content);
      }
    },
    [getPaymentList],
  );

  const validation = (List: any) => {
    const temp = _.cloneDeep(List);

    let checkPrice = true;
    let checkDate = true;
    let checkPgcode = true;

    if (Number(makeSum) > Number(row.original.receiptBundle.billTotalAmount)) {
      openToast({ content: "입금 총액은 수납처리 전 잔액보다 클 수 업습니다.", isSystemToast: true });
      return;
    }

    const saveList = temp.filter((save: any) => Number(save.receiptId) === 0);

    saveList.forEach((save: any) => {
      if (save.pgcode === "" || save.pgcode === undefined || save.pgcode === null) {
        checkPgcode = false;
      }
      if (save.trDateTime === undefined || save.trDateTime === null) {
        checkDate = false;
      }
      if (Number(save.receiptAmount) === 0) {
        checkPrice = false;
      }
    });

    if (!checkPgcode) {
      openToast({ content: "결제수단을 선택해 주세요.", isSystemToast: true });
      return;
    }

    if (!checkPrice) {
      openToast({ content: "수납 추가 시 수납 금액은 필수 입력 정보입니다.", isSystemToast: true });
      return;
    }
    if (!checkDate) {
      openToast({ content: "수납 추가 시 거래일시는 필수 입력 정보입니다.", isSystemToast: true });
      return;
    }

    const etc = temp.filter((save: any) => Number(save.acctCreditId) === 0 && Number(save.paymentId) === 0 && Number(save.receiptId) === 0);
    const accounts = temp.filter((save: any) => Number(save.acctCreditId) !== 0 || (Number(save.paymentId) !== 0 && Number(save.receiptId) === 0));

    if (row.original.bill.pgcode === "creaditcard" && Number(row.original.receiptBundle.billTotalAmount) !== Number(etc.receiptAmount)) {
      openToast({ content: "카드결제형은 부분수납을 허용하지 않습니다.", isSystemToast: true });
      return;
    }
    accounts.forEach((save: any) => {
      save.trDateTime = moment(save.trDateTime).format(YmdFormat.WITH_TIME_ZONE);
    });
    let sendData: any = [];
    let etcs: any = [];
    etc.forEach((addItem: any) => {
      if (addItem && addItem.trDateTime !== null && addItem.receiptAmount !== 0) {
        const insertReceipt: any = {
          receiptId: 0,
          billId: row.original.bill.billId,
          acctCreditId: 0,
          paymentId: 0,
          pgcode: addItem.pgcode,
          receiptAmount: Number(etc.receiptAmount),
          trDateTime: moment(etc.trDateTime).format(YmdFormat.WITH_TIME_ZONE),
        };
        etcs.push(insertReceipt);
      }
    });
    sendData = [...accounts, ...etcs, ...delReceiveList];

    if (sendData.length === 0) {
      openToast({
        content: "저장 내용이 없습니다. 수납 내역을 입력해주세요",
        isSystemToast: true,
      });
      return;
    }

    if (checkPrice) {
      setMessage("저장 하시겠습니까?");
      setIsSave(true);
    }
  };

  const firstSettings = useCallback(async () => {
    row.original.receiptBundle.receiptList.forEach((sort: any, index: number) => (sort.viewCount = index + 1));
    setReceiveList(row.original.receiptBundle.receiptList);
  }, [row]);

  const makeSuccess = useCallback(
    async (List: any, del: any) => {
      const temp = _.cloneDeep(List);

      const etcs = temp.filter((save: any) => Number(save.receiptId) === 0);

      let etc: any = [];
      etcs.forEach((addItem: any) => {
        if (addItem && addItem.trDateTime !== null && addItem.receiptAmount !== 0) {
          const insertReceipt: any = {
            receiptId: 0,
            billId: row.original.bill.billId,
            acctCreditId: addItem.acctCreditId !== 0 ? addItem.acctCreditId : 0,
            paymentId: 0,
            pgcode: addItem.pgcode,
            receiptAmount: Number(addItem.receiptAmount),
            trDateTime: moment(addItem.trDateTime).format(YmdFormat.WITH_TIME_ZONE),
          };
          etc.push(insertReceipt);
        }
      });
      etc = etc.concat(del);

      if (etc.length > 0) {
        console.log("send", etc);
        const send: any = {
          scheduleId: Number(scheduleId),
          body: etc,
        };
        setIsSave(false);
        setIsChange(false);
        const response: any = await changeSuccess(send);
        console.log(response);

        if (response.status >= 200 && response.status <= 299) {
          openToast({ content: "저장 되었습니다.", isSystemToast: true });

          if (type === "schedule") {
            await callList();
          } else {
            await callList(buildingId, scheduleId);
          }

          firstSettings();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row.original.bill.billId, scheduleId, changeSuccess, setIsChange, callList],
  );

  const sortedRecevieList = useMemo(() => {
    const temp = _.cloneDeep(receiveList);

    let sorted: any = [];
    let sorted1: any = [];
    let sorted2: any = [];

    if (temp.length > 0) {
      const isReceiptId = temp.filter((temp2: any) => Number(temp2.receiptId) === 0);
      const isReceiptId2 = temp.filter((temp2: any) => Number(temp2.receiptId) > 0);
      sorted1 = _.orderBy(isReceiptId, [(receipt: any) => moment(receipt.trDateTime).toDate()], ["desc"]);
      sorted2 = _.orderBy(isReceiptId2, [(receipt: any) => moment(receipt.trDateTime).toDate()], ["desc"]);
      sorted = sorted1.concat(sorted2);
    }

    return sorted;
  }, [receiveList]);

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let startTime = "";

    let endTime = "";
    if (dateRange[0]) {
      let change1 = moment(dateRange[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000Z";

      startTime = moment(change1).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      setSearch({ ...search, ...{ startDateTime: startTime } });

      if (moment().isAfter(moment(dateRange[0]).add(3, "months"))) {
        setMaxLimit(moment(dateRange[0]).add(3, "months").toDate());
      } else {
        setMaxLimit(moment().toDate());
      }
    }
    if (dateRange[1]) {
      endTime = moment(moment(dateRange[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999Z").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      setSearch({ ...search, ...{ endDateTime: endTime } });
    }

    if (dateRange[0] !== null && dateRange[1] !== null) {
      fetchAccounts({ ...search, ...{ startDateTime: startTime }, ...{ endDateTime: endTime } });
    }
  };
  const callPaymentList = useCallback(async () => {
    await firstSettings();
    await fetchPaymentList(row.original.bill.billId, row.original.bill.pgcode);
  }, [fetchPaymentList, firstSettings, row.original.bill.billId, row.original.bill.pgcode]);

  useEffect(() => {
    const dump = _.cloneDeep(search);
    const dr = _.cloneDeep(dateRange);
    if (dump.startDateTime === "") {
      let startT = moment().format(ViewYmdFormat.YYYY_MM_DD);
      // let startT = moment("2023-01-08").format(ViewYmdFormat.YYYY_MM_DD);

      let addTime = startT + " 00:00:00";
      dump.startDateTime = moment(addTime).subtract("7", "days").format(YmdFormat.WITH_TIME_ZONE);
      dr[0] = moment(dump.startDateTime).toDate();
    }
    if (dump.endDateTime === "") {
      let endT = moment().format(ViewYmdFormat.YYYY_MM_DD);
      // let endT = moment("2023-03-31").format(ViewYmdFormat.YYYY_MM_DD);

      let addTime = endT + " 23:59:59";

      dump.endDateTime = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
      dr[1] = moment(dump.endDateTime).toDate();
    }
    setMaxLimit(moment().toDate());
    setSearch(dump);
    setDateRange(dr);
    fetchAccounts(dump);

    setDelReceiveList([]);

    callPaymentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPaymentList]);

  const makeSum = useMemo(() => {
    let s = _.cloneDeep(sum);
    if (receiveList.length > 0) {
      receiveList.forEach((rp: any) => {
        s += Number(rp.receiptAmount);
      });
    }
    return s;
  }, [receiveList, sum]);

  const makeOvSum = useMemo(() => {
    let s = _.cloneDeep(ovSum);
    if (receiveList.length > 0) {
      receiveList.forEach((rp: any) => {
        s += Number(rp.overdueAmount);
      });
    }

    return s;
  }, [receiveList, ovSum]);
  const totalSum = useMemo(() => {
    let realtimeOverdueAmount = 0;

    if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
      realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
    }

    if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
      realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
    }

    return realtimeOverdueAmount;
  }, [row.original.receiptBundle]);

  const nowSum = useMemo(() => {
    let sum = 0;

    sum = Number(row.original.receiptBundle.billTotalAmount) - makeSum;
    return sum;
  }, [row, makeSum]);
  // if (dateRange[0] !== null) {
  // console.log('{moment(dateRange[0]).add(3, "months").toDate()}', moment(dateRange[0]).add(3, "months").format(ViewYmdFormat.YYYY_MM_DD));
  // }

  return (
    <BaseAbstractModal isOpen={isChange} className="billing-schedule-info-modal">
      <section className="base-abstract-modal__title border-bottom">
        <h1>빌링/수납 처리</h1>
      </section>
      <article className="base-abstract-modal__contents px30">
        <div className="overflow-container">
          {/* 요약 */}
          <PriceInfo spaceProductType={contractBaseInfo.product.productType} row={row} nowSum={nowSum} makeSum={makeSum} totalSum={totalSum} />
          {/* 입금 내역 */}
          {type !== "mnt" && row.original.bill.pgcode === "banktransfer" && (
            <section>
              <div className="base-abstract-modal__contents-subtitle">
                <div className="flex-center">
                  <h2>입금내역</h2>
                  <p className="font14 pl10">총 {receiveData.length} 건</p>
                </div>

                <div className="minmax240">
                  <RangeDatepicker disabled={!showBtn} dateRange={dateRange} onChange={handleOnDateRangeChange} maxDate={maxLimit} />
                </div>
              </div>
              <DepositHistoryTable
                dataBundle={{
                  tdData: receiveData,
                  setReceiveData,
                  receiveList: sortedRecevieList,
                  row,
                  showBtn,
                  delReceiveList,
                  setDelReceiveList,
                  setReceiveList,
                  contractBaseInfo,
                }}
              />
            </section>
          )}
          {(row.original.bill.pgcode === "creditcard" || row.original.bill.pgcode === "virtualaccount") && (
            <section>
              <div className="base-abstract-modal__contents-subtitle">
                <div className="flex-center">
                  <h2>{row.original.bill.pgcode === "creditcard" ? "카드결제 내역" : "간편 입금 계좌 내역"}</h2>
                  <p className="font14 pl10">총 {paymentArray.length} 건</p>
                </div>
              </div>
              <PaymentTable
                dataBundle={{
                  paymentData: paymentArray,
                  pgcode: row.original.bill.pgcode,
                  editable: showBtn,
                  callList,
                  callPaymentList,
                  firstSettings,
                  scheduleId,
                }}
              />
            </section>
          )}
          {/* 수납 처리 */}
          <section>
            <div className="base-abstract-modal__contents-subtitle">
              <div className="flex-center">
                <h2>수납 처리</h2>
              </div>
              {row.original.bill.pgcode === "banktransfer" && (
                <ul>
                  <li>입금내역 선택 후 수납내역의 입금액을 해당 회차의 청구금액으로 수정가능합니다.</li>
                </ul>
              )}
            </div>

            <StorageProcessingTable
              dataBundle={{
                sortedRecevieList,
                showBtn,
                receiveData,
                setReceiveData,
                makeSum,
                makeOvSum,
                row,
                setReceiveList,
                paymentArray,
                delReceiveList,
                setDelReceiveList,
                contractBaseInfo,
              }}
            />
          </section>
        </div>
      </article>

      <BaseModal
        isOpen={isSave}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setIsSave(false)}
        onClick={() => makeSuccess(sortedRecevieList, delReceiveList)}
        title={message}
      ></BaseModal>
      <BaseModal isOpen={isSaved} btnRightTitle="확인" onClick={() => setIsSaved(false)} title={message}></BaseModal>

      <section className="base-abstract-modal__btn-wrap">
        {showBtn && row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ? (
          <>
            <BaseButton
              title={"취소"}
              className="color-white"
              onClick={() => {
                setIsChange(false);
              }}
            />
            <BaseButton
              title={"저장"}
              onClick={() => {
                validation(sortedRecevieList);
              }}
            />
          </>
        ) : (
          <BaseButton
            title={"확인"}
            onClick={() => {
              setIsChange(false);
            }}
          />
        )}
      </section>
    </BaseAbstractModal>
  );
};

export default StorageProcessingModal;
