import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/work-order/workorder-api";
import {
  WorkOrderListModel,
  WorkOrderQueryParams,
  WorkOrderStatus,
  WorkOrderType,
  searchTypes,
  workOrderPriorityOptions,
} from "src/api/work-order/workorder-types";
import { BaseButton, BaseCheckbox, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import { BaseTooltip } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import ExcelDownloadButton from "src/pages/visitor/list/ExcelDownloadButton";
import { YmdFormat, downloadExcel, numberToStringWithComma, onChangeKoreanDays, qsParse } from "src/utils/common-util";
import WorkOrderListColumn from "./columns/WorkOrderListColumn";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { workOrderStatus } from "../../workOrder-types";

const WorkOrderListAuthenticated = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 로딩바

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const queryParams: WorkOrderQueryParams = useMemo(() => {
    const _queryParams: WorkOrderQueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.startDate !== undefined && _queryParams?.startDate !== "") {
      dump[0] = moment(_queryParams?.startDate).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.endDate !== undefined && _queryParams?.endDate !== "") {
      dump[1] = moment(_queryParams?.endDate).toDate();
    } else {
      dump[1] = null;
    }

    if (_queryParams?.keywordList) {
      _queryParams.keywordList = decodeURIComponent(_queryParams.keywordList);
    }

    if (_queryParams.statusList && typeof _queryParams.statusList === "string") {
      _queryParams.statusList = _queryParams.statusList.split(",");
    }

    setDateRange(dump);
    return _queryParams;
  }, [location.search]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [workOrders, setWorkOrders] = useState<Array<WorkOrderListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<WorkOrderQueryParams>({ ...queryParams });

  const { isAuthority } = usePartnerAuthority();

  // 워크오더 목록 api
  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync);

  // 워크오더 목록
  const getWorkOrderListApi = useCallback(
    async (workOrderParams: WorkOrderQueryParams) => {
      const response = await getWorkOrderList(workOrderParams);

      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setWorkOrders(result);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getWorkOrderList, location.search],
  );

  useEffect(() => {
    setParams({ ...queryParams });
    getWorkOrderListApi(queryParams);
  }, [getWorkOrderListApi, queryParams]);
  // 쿼리파라미터 정보와 함께 네비게이션

  const navigateWithQueryParams = useCallback(
    (passParams?: WorkOrderQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  // 날짜 검색
  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let start = "";
    let end = "";

    if (dateRange[0] !== null) {
      start = moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
      setParams({ ...params, ...{ startDate: start } });
    }
    if (dateRange[1] !== null) {
      end = moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59.999+09:00";
      setParams({ ...params, ...{ startDate: start, endDate: end } });

      navigateWithQueryParams({ ...params, ...{ startDate: start, endDate: end } });
    }
  };

  // 엑셀 다운로드
  const onDownloadWorkOrderExcel = async () => {
    const newParams = { ...params };
    delete newParams.size;

    const response = await getWorkOrderList(newParams);
    if (response.status >= 200 && response.status <= 299) {
      const data = response.data.data.content;

      const downloadData = data.map((item) => {
        // 상태
        let status = "";
        switch (item.status) {
          case WorkOrderStatus.OPEN:
            status = "접수";
            break;

          case WorkOrderStatus.CLOSED:
            status = "종료";
            break;
          case WorkOrderStatus.PROGRESS:
            status = "처리중";
            break;

          case WorkOrderStatus.COMPLETED:
            status = "처리 완료";
            break;

          case WorkOrderStatus.WAITING:
            status = "확인";
            break;

          case WorkOrderStatus.CANCELED:
            status = "취소";
            break;
        }

        // 지연여부
        const isDelay =
          (item.status === WorkOrderStatus.OPEN || item.status === WorkOrderStatus.WAITING || item.status === WorkOrderStatus.PROGRESS) &&
          item.isOverdue;

        const formatData = {
          workOrderSheetNo: `${item?.workOrderSheetNo?.slice(0, 8)}-${item.workOrderSheetNo?.slice(8, 13)}` || "-",
          workOrderSheetGroupId: item.workOrderSheetGroupId ? "정기" : "비정기",
          status: status,
          summary: item.summary || "-",
          delay: isDelay ? "지연" : "-",
          mgmtOfficerName: item.mgmtOfficerName || "-",

          startDate: `${moment(item.startDate).format(YmdFormat.YYYY_MM_DD)} (${onChangeKoreanDays(item.startDate)})` || "-",
          endDate: `${moment(item.endDate).format(YmdFormat.YYYY_MM_DD)} (${onChangeKoreanDays(item.endDate)})` || "-",
          costSum:
            `${Number(item.costCount) > 1 ? `(${item.costCount}건)` : ""} ${
              numberToStringWithComma(Number(item.costSum)) !== "0" ? numberToStringWithComma(Number(item.costSum)) : "-"
            }` || "-",
          modifiedDate: moment(item.modifiedDate).format(YmdFormat.YYYY_MM_DD_HH_MM),
          createdBy: item.createdBy,
        };

        return formatData;
      });
      try {
        const fileName =
          params.startDate && params.endDate
            ? `ctrl.room_워크오더목록_${moment(params.startDate).format("YYYYMMDD")}~${moment(params.endDate).format("YYYYMMDD")}_${moment().format(
                "YYYYMMDDHHmmss",
              )}`
            : `ctrl.room-워크오더목록-전체_${moment().format("YYYYMMDDHHmmss")}`;

        await downloadExcel({
          data: downloadData,
          fileName,
          header: WorkOrderListColumn.map((column) => column.Header),
        });

        setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
      }
    }
  };
  return (
    <div className="page-product-list">
      {/* 로딩바 */}
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>상태검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <BaseMultiSelect
                    stateOptions={workOrderStatus}
                    value={(params.statusList as string[]) || []}
                    setStateValue={(statusList: Array<{ value: string; label: string }>) => {
                      navigateWithQueryParams({ ...params, page: 0, statusList: statusList.map((item) => item.value) });
                    }}
                    placeholder="상태"
                    checkBoxDisabled={params.isOverdue ? [WorkOrderStatus.COMPLETED, WorkOrderStatus.CLOSED, WorkOrderStatus.CANCELED] : []}
                  />
                </div>
                <div className="flex-center">
                  <BaseCheckbox
                    id={"isOverdue"}
                    name={"isOverdue"}
                    label="지연된 업무"
                    className="mr4"
                    checked={params.isOverdue ? true : false}
                    onChange={(checked) => {
                      const newParams = { ...params };

                      if (checked) {
                        navigateWithQueryParams({
                          ...newParams,
                          page: 0,
                          isOverdue: checked ? "true" : "",
                          statusList: ["WORK_ORDER_OPEN", "WORK_ORDER_WAITING", "WORK_ORDER_PROGRESS"],
                        });
                      } else {
                        delete newParams.isOverdue;
                        delete newParams.statusList;
                        navigateWithQueryParams({ ...newParams, page: 0 });
                      }
                    }}
                  />
                  <BaseTooltip
                    touchIcon={"QUESTION"}
                    size={16}
                    tooltip="선택 시 접수/확인/처리중 상태의 업무만 볼 수 있습니다. 다른 상태의 업무를 보시려면 선택을 해제해 주세요."
                  ></BaseTooltip>
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                {/* <div className="minmax120 mr8">
                  <BaseSelect
                    placeholder="우선순위"
                    stateOptions={workOrderPriorityOptions}
                    setStateValue={(priority: number) => {
                      navigateWithQueryParams({ ...params, page: 0, priority: priority });
                    }}
                    value={params.priority}
                  />
                </div> */}

                <div className="minmax205 ">
                  <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                </div>

                <BaseInputWithSearch
                  type="text"
                  selectValue={params.searchType}
                  inputValue={params?.keywordList ? decodeURIComponent(String(params?.keywordList)) : ""}
                  stateOptions={searchTypes}
                  setStateValue={(searchType: string) => {
                    setParams({ ...params, searchType });
                  }}
                  onChange={(keywordList: string) => {
                    setParams({ ...params, keywordList });
                  }}
                  onClearClick={() => {
                    const editParams = { ...params };
                    delete editParams.keywordList;
                    setParams(editParams);
                    navigateWithQueryParams({ ...params, page: 0, keywordList: "" });
                  }}
                  onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                  onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                />
              </div>
            </section>
          </div>
          <div className="right-area">
            {isAuthority("WORKORDER", "w") && (
              <BaseButton className="ml20" title="+ 워크시트 생성" onClick={() => navigate(PagePath.workOrder.authenticated.form)} />
            )}
          </div>
        </div>
        <BaseTable
          data={workOrders}
          currentSize={Number(queryParams.size) || 20}
          sizeOption={(sizeValue) => {
            navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
          }}
          pageIndex={Number(params?.page || 0)}
          totalElements={pageMeta?.totalElements}
          totalPages={pageMeta?.totalPages || 0}
          goPage={(page: number) => {
            navigateWithQueryParams({ page }, "pagination");
          }}
          orders={params?.sort?.orders}
          setOrders={(orders?: Array<Order>) => {
            if (orders) {
              navigateWithQueryParams({ sort: { orders } }, "search");
            }
          }}
          disabledSortHeaders={[
            "workOrderType",
            "summary",
            "endDate",
            "workOrderPriority",
            "mgmtOfficerName",
            "createdBy",
            "delay",
            "buildingName",
            "workOrderSheetGroupId",
            "mgmtOfficeName",
            "costSum",
          ]}
          columns={WorkOrderListColumn}
          children={
            <ExcelDownloadButton
              onClick={() => {
                onDownloadWorkOrderExcel();
              }}
            >
              엑셀받기
            </ExcelDownloadButton>
          }
        />{" "}
      </div>
      {alertModal.isOpen && (
        <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
          {alertModal.message}
        </BaseModal>
      )}
    </div>
  );
};

export default WorkOrderListAuthenticated;
