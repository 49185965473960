import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ContractManagaDetail, Contractor, DetailTotalData } from "src/api/contract/contract-types";
import { BaseButton, BaseInput, BaseRadio, BaseTooltip } from "src/components";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import Link from "src/components/PartnerLink";
import pagePath from "src/pagePath.json";
import SearchContractMemberPopup from "src/pages/commonPopup/SearchContractMemberPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { default as useContactApi, default as useContractApi } from "../../hooks/useContractApi";
import BaseSection from "../../../../components/layout/BaseSection";
import { formatPhoneNumber } from "src/utils/common-util";
import ContractImageUpload from "../ContractImageUpload";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: Function;
  setEditable: any;
  saveFlags: boolean;
  setSaveFlags: any;
  popupState: boolean;
  setPopupState: any;
  setCommonModals: any;
  setEditConfrim: any;
}

const ContractorNOrganizationEdit = ({
  detailData,
  setData,
  setEditable,
  saveFlags,
  setSaveFlags,
  setCommonModals,
  setPopupState,
  setEditConfrim,
}: ContractBasicProp) => {
  const { editContractManageData } = useContactApi();
  const { getOrganizationData } = useContractApi();
  const { contract, contractManage, contractOrganization } = detailData;
  // const [textGrey, setTextGrey] = useState("contents-container__grid ");
  const [, setContractor] = useState<Contractor>();
  const [editContractor, setEditContractor] = useState<Contractor>();
  const [isChangeContractor, setIsChangeContractor] = useState(false);
  const [isOrgPop, setIsOrgPop] = useState(false);

  const [organization, setOrganization] = useState<any>();
  const [editContractManage, setEditContractManage] = useState<ContractManagaDetail>();
  const [proof, setProof] = useState<any>();
  const [bi, setBi] = useState<any>();
  const [orgShow, setOrgShow] = useState(false);

  const infomation = `세금계산서 발행 대상은 사업자 정보 필수 입력입니다. 사업자 메뉴에서 사업자등록증의 정보를 정확히 입력해 주세요.`;

  const onChangeContractor = useCallback(
    async (member: any) => {
      if (member) {
        setIsChangeContractor(false);
        const temp = _.cloneDeep(contractManage!);
        // 기 등록된 계약자를 해제 처리
        const changeContractor = temp?.contractorList!.map((user: Contractor) => {
          user.isContractor = false;
          user.cmdType = "U";
          return user;
        });

        //화면에 보여주기 위한 데이터 작업
        const changeUser: Contractor = {
          cmdType: "U",
          isContractor: true,
          isAdmin: true,
          memberEmail: member.email,
          memberPhoneNumber: member.inviteMobileNumber,
          memberNo: member.memberNo,
        };

        //전송할 멤버 정보를 위한 데이터 작업
        member.cmdType = "U";
        member.isContractor = true;
        member.isAdmin = true;
        changeContractor.push(member);
        setEditContractor(changeUser);
        temp.contractorList = changeContractor;
        setEditContractManage(temp);

        // console.log("member", member, editContractor);
      }
    },
    [contractManage],
  );

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);
  const orgViewDataSetting = useCallback((orgData: any, proofs: any, bis: any) => {
    setOrganization(orgData);
    setProof(proofs);
    setBi(bis);
  }, []);

  const onChangeOrganization = useCallback(
    async (org: any) => {
      setIsOrgPop(false);
      const { organization: orgData, proofs, bis }: any = await getOrganizationData(Number(org.id));
      const temp = _.cloneDeep(contractManage!);
      temp.mbOrganizationId = Number(orgData.id);
      setEditContractManage(temp);
      orgViewDataSetting(orgData, proofs, bis);
    },
    [contractManage, getOrganizationData, orgViewDataSetting],
  );
  //데이터 화면용 초기화
  const initDatas = useCallback(() => {
    if (contractManage && contractManage?.contractorList) {
      setEditContractManage(contractManage!);
      const findedContractor = contractManage?.contractorList!.find((user: Contractor) => user.isContractor);
      if (findedContractor) {
        setContractor(findedContractor);
        setEditContractor(findedContractor);
      }

      if (contractManage?.mbOrganizationId === "0") {
        setOrgShow(false);
      } else {
        setOrgShow(true);
      }

      const proofs = contractOrganization?.mediaList!.filter((media: any) => media.type === "PROOF");
      const bi = contractOrganization?.mediaList!.filter((media: any) => media.type !== "PROOF");
      orgViewDataSetting(contractOrganization?.organization, proofs, bi);
    }
  }, [contractManage, contractOrganization?.mediaList, contractOrganization?.organization, orgViewDataSetting]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);

  //저장 버튼 클릭 시
  const onSaveContractManageData = useCallback(async () => {
    if (editContractManage?.mbOrganizationId !== 99999) {
      const result: any = await editContractManageData(contractManage!, editContractManage!);
      console.log("result", result);
      if (result.response.status! > 199 && result.response.status! < 300) {
        setCommonModals({
          message: "저장 되었습니다.",
          title: "계약자 정보 변경",
        });
        setPopupState(true);
        setEditConfrim(false);
        setSaveFlags(false);
        setEditable(false);
        setData();
        return;
      }
      if (result.validationPass === false) {
        setCommonModals({
          message: result.message,
          title: "계약자 정보 변경",
        });
        setPopupState(true);
        setSaveFlags(false);
        // setEditable(false);
        setEditConfrim(false);
        return;
      }
    } else {
      setCommonModals({
        message: "연결할 사업자의 법인명/상호를 조회하여 선택해 주세요.",
        title: "사업자 정보를 입력해 주세요",
      });
      setPopupState(true);
      setSaveFlags(false);
      // setEditable(false);
      setEditConfrim(false);
    }
  }, [
    editContractManageData,
    contractManage,
    editContractManage,
    setCommonModals,
    setPopupState,
    setSaveFlags,
    setEditable,
    setData,
    setEditConfrim,
  ]);

  //저장 플래그 변경 시에만 반응 하도록
  useEffect(() => {
    // 저장 클릭 시
    if (saveFlags === true) {
      // console.log("clicked!!!!");
      onSaveContractManageData();
    } else {
      //취소 클릭 시
      initDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFlags, setSaveFlags]);

  return (
    <>
      <div className="flex-center">
        <BaseSection
          title={"이메일"}
          children={
            <div className="flex-row flex-center-start">
              <div className="minmax200 mr8">
                {/* <Link to={goMemberDetail(viewContractMember?.memberNo)} className="text-underline" target="_blank" rel="noopener noreferrer"> */}
                <p>{editContractor?.memberEmail}</p>
                {/* </Link> */}
              </div>

              <div className="minmax90">
                <BaseButton
                  disabled={
                    contract?.contractStep === "APPLY_RECEIVED" ||
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "USE_COMPLETE" ||
                    contract?.contractStep === "TERMINATE_COMPLETE"
                  }
                  title="변경"
                  onClick={() => {
                    setIsChangeContractor(true);
                  }}
                  className="color-white"
                />
                <SearchContractMemberPopup
                  contractManageId={Number(contractManage?.contractManageId!)}
                  isOpen={isChangeContractor}
                  onClose={() => setIsChangeContractor(false)}
                  onClick={onChangeContractor}
                />
              </div>
            </div>
          }
        />
        <BaseSection title={"회원 번호"} value={editContractor?.memberNo} />
      </div>
      <div className="flex-center">
        <BaseSection title={"휴대폰"} value={formatPhoneNumber(String(editContractor?.memberPhoneNumber))} />
        <BaseSection
          titleChildren={
            <div className="flex-center font14">
              사업자 여부
              <BaseTooltip children={infomation} contents={""} tooltip={infomation} type={"normal"} className={"tooltip__trigger-icon"} />
            </div>
          }
          children={
            <div className="flex-row flex-center-start">
              <BaseRadio
                id="mbOrgId"
                name="mbOrganizationIdUse"
                className="mr10"
                value={"false"}
                label="해당없음"
                checked={String(orgShow) === "false" ? true : false}
                onChange={() => {
                  setOrgShow(false);
                  console.log("click not use");
                  const temp = _.cloneDeep(editContractManage!);
                  temp.mbOrganizationId = 0;
                  setEditContractManage(temp);
                }}
              ></BaseRadio>
              <BaseRadio
                id="mbOrgId2"
                name="mbOrganizationIdUse"
                value={"true"}
                label="개인/법인 사업자"
                checked={String(orgShow) === "true" ? true : false}
                onChange={() => {
                  setOrgShow(true);

                  console.log("click use");
                  const temp = _.cloneDeep(editContractManage!);
                  temp.mbOrganizationId = 99999;
                  setEditContractManage(temp);
                }}
              ></BaseRadio>
            </div>
          }
        />
      </div>

      {orgShow && (
        <>
          <div className="flex-center">
            <BaseSection
              title={"법인명/상호"}
              children={
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className={organization?.name ? "mr5" : ""}>{organization?.name ? organization?.name : ""}</span>
                    <div className="flex-row flex-center-start">
                      <span>
                        {organization?.id ? (
                          <Link className="text-underline" to={moveOrg(organization?.id)} target="_blank">
                            {organization?.id ? "id:" + organization?.id : "-"}
                          </Link>
                        ) : (
                          ""
                        )}
                      </span>
                      <div className="minmax90 ml8">
                        <BaseButton
                          disabled={
                            contract?.contractStep === "APPLY_RECEIVED" ||
                            contract?.contractStep === "APPLY_CANCEL" ||
                            contract?.contractStep === "USE_COMPLETE" ||
                            contract?.contractStep === "TERMINATE_COMPLETE"
                          }
                          title={organization?.id ? "변경" : "조회"}
                          onClick={() => {
                            setIsOrgPop(true);
                          }}
                          className="color-white"
                        />
                        <>
                          <div className="minmax180 mr8">
                            <BaseInput
                              readonly={
                                contract?.contractStep === "APPLY_RECEIVED" ||
                                contract?.contractStep === "APPLY_CANCEL" ||
                                contract?.contractStep === "USE_COMPLETE" ||
                                contract?.contractStep === "TERMINATE_COMPLETE"
                              }
                              name={"mbOrganizationId"}
                              type="hidden"
                              value={contractManage?.mbOrganizationId}
                            />
                          </div>
                        </>

                        <SearchOrgPopup isOpen={isOrgPop} onClose={() => setIsOrgPop(false)} onClick={onChangeOrganization} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <BaseSection
              title={"사업자 유형"}
              value={organization?.bizType ? (organization?.bizType === "CORP" ? "법인사업자" : "일반사업자") : "-"}
            />
          </div>
          <div className="flex-center">
            <BaseSection title={"대표자/성명"} value={organization?.ceoName ? organization?.ceoName : "-"} />
            <BaseSection title={"사업자 등록번호"} value={organization?.bizRegNo ? organization?.bizRegNo : "-"} />
          </div>
          <div className="flex-center">
            <BaseSection title={"사업장 주소"} value={organization?.address ? organization?.address : "-"} />
            <BaseSection title={"사업자 등록증 첨부"} children={<ContractImageUpload isOnlyView images={proof} type={"PROOF"} />} />
          </div>
        </>
      )}
    </>
  );
};

export default ContractorNOrganizationEdit;
