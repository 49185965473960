import moment from "moment";
import { Cell } from "react-table";
import { WorkOrderListModel, WorkOrderStatus } from "src/api/work-order/workorder-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { workOrderStatus } from "src/pages/workOrder/workOrder-types";
import { numberToStringWithComma, onChangeKoreanDays } from "src/utils";

const WorkOrderListColumn = [
  // {
  //   Header: "No",
  //   accessor: "id",
  //   width: 80,
  //   Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
  //     const detailPath = `${PagePath.workOrder.authenticated.detail.replace(":id", value)}?worksheetNo=${row.original.workOrderSheetNo}`;

  //     return (
  //       <DetailLink to={detailPath} className="text-underline">
  //         {value}
  //       </DetailLink>
  //     );
  //   },
  // },
  {
    Header: "업무 번호",
    accessor: "workOrderSheetNo",
    width: 140,
    Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
      const detailPath = `${PagePath.workOrder.authenticated.detail.replace(":id", String(row.original.id))}?worksheetNo=${
        row.original.workOrderSheetNo
      }`;
      return (
        <DetailLink to={detailPath} className="text-underline">
          {`${value.slice(0, 8)}-${value.slice(8, 13)}`}
        </DetailLink>
      );
    },
  },
  {
    Header: "업무유형",
    accessor: "workOrderSheetGroupId",
    width: 70,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <span>{value ? "정기" : "비정기"}</span>;
    },
  },
  {
    Header: "상태",
    accessor: "status",
    width: 90,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let status = workOrderStatus.find((option) => option.value === value)?.label;
      let color = workOrderStatus.find((option) => option.value === value)?.tagColor;
      return <span className={color}>{status}</span>;
    },
  },
  {
    Header: "제목",
    accessor: "summary",
    width: 270,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <BaseTooltip contents={value} className="w-100 d-flex justfy-content-start" />;
    },
  },
  // {
  //   Header: "건물",
  //   accessor: "buildingName",
  //   width: 160,
  //   Cell: ({ row }: Cell<WorkOrderListModel>) => {
  //     const buildingName = row.original.buildingName;

  //     if (buildingName) {
  //       return <BaseTooltip contents={`${buildingName}`} isSingleLine={true} className="w-100 d-flex justfy-content-start" />;
  //     } else {
  //       return <span>-</span>;
  //     }
  //   },
  // },

  {
    Header: "지연 여부",
    accessor: "delay",
    width: 80,
    Cell: ({ row }: Cell<WorkOrderListModel>) => {
      const status = row.original.status;
      const isOverdue = row.original.isOverdue; // 종료일자 < 오늘날짜 여부 boolean

      if (status === WorkOrderStatus.OPEN || status === WorkOrderStatus.WAITING || status === WorkOrderStatus.PROGRESS) {
        return <span className={isOverdue ? "status G" : ""}>{isOverdue ? "지연" : "-"}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },

  {
    Header: "작업자",
    accessor: "mgmtOfficerName",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => (
      <div className="w-100 d-flex justfy-content-start">
        <span>{value}</span>
      </div>
    ),
  },
  {
    Header: "업무 시작일",
    accessor: "startDate",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format("YYYY.MM.DD")}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "처리기한",
    accessor: "endDate",
    width: 120,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format("YYYY.MM.DD")}</span>
          <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
        </div>
      );
    },
  },
  {
    Header: "처리비용",
    accessor: "costSum",
    width: 120,
    Cell: ({ value, row }: Cell<WorkOrderListModel>) => {
      const costCount = row.original.costCount;
      const costSum = numberToStringWithComma(value);
      return (
        <div className="d-flex justify-contents-end w-100">
          <p className="pr5">{`${Number(costCount) > 1 ? `(${costCount}건)` : ""} ${costSum !== "0" ? `${costSum}원` : "-"}` || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <BaseTooltip contents={moment(value).format("YYYY.MM.DD")} />;
    },
  },
  {
    Header: "등록자",
    accessor: "createdBy",
    width: 150,
    Cell: ({ value }: Cell<WorkOrderListModel>) => <BaseTooltip contents={value} isSingleLine={true} className="w-100 justfy-content-start" />,
  },
  // {
  //   Header: "재처리 요청",
  //   accessor: "isReopened",
  //   width: 100,
  //   Cell: ({ value }: Cell<WorkOrderListModel>) => {
  //     return <span>{value === true ? "재처리 요청" : "-"}</span>;
  //   },
  // },

  // {
  //   Header: "우선순위",
  //   accessor: "workOrderPriority",
  //   width: 90,
  //   Cell: ({ value }: Cell<WorkOrderListModel>) => {
  //     let workOrderPriority = "";

  //     switch (value) {
  //       case "1":
  //         workOrderPriority = "낮음";
  //         break;

  //       case "2":
  //         workOrderPriority = "보통";
  //         break;

  //       case "3":
  //         workOrderPriority = "높음";
  //         break;

  //       case "4":
  //         workOrderPriority = "긴급";
  //         break;
  //     }

  //     return <span>{workOrderPriority}</span>;
  //   },
  // },
  // {
  //   Header: "업무구분",
  //   accessor: "workOrderType",
  //   width: 100,
  //   Cell: ({ value }: Cell<WorkOrderListModel>) => {
  //     let workOrderType = "";

  //     if (value === WorkOrderType.SPACE) {
  //       workOrderType = "공간 관리";
  //     } else {
  //       workOrderType = "서비스 관리";
  //     }

  //     return <span>{workOrderType}</span>;
  //   },
  // },
];

export default WorkOrderListColumn;
