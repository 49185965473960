import { useState } from "react";
import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import Link from "src/components/PartnerLink";
import ProductMemberModal from "./modals/ProductMemberModal";
import { numberToStringWithComma } from "src/utils";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  product: any;
  setData: (data: ContractOperationProductManage) => void;
};

const UserAccessSection = ({ product, setData }: Props) => {
  const { isAuthority } = usePartnerAuthority();

  const [memberModal, setMemberModal] = useState<Modal>({ isOpen: false });

  return (
    <div>
      <BaseSectionTitle title={"이용자 관리"} />
      <div className="contents-contract-user product-w-full">
        <div className="text-center py30">
          <div className="mb8">
            <span className="font14 text-primary3">최대 이용자</span>
          </div>
          <div className="mb20">
            <span className="font22 mr5">
              <b>{product ? numberToStringWithComma(product.maxNums) : 0}</b>
            </span>
            <span className="font18">명</span>
          </div>
          <div className="flex-center-center">
            {isAuthority("PRODUCT", "w") && (
              <BaseButton title="최대 인원 설정" className="color-white" onClick={() => setMemberModal({ isOpen: true })} />
            )}
          </div>
        </div>
      </div>
      {memberModal.isOpen && (
        <ProductMemberModal
          currentMembers={Number(product.maxNums)}
          onSubmit={(maxNums: number) => {
            if (maxNums > 0) {
              const newProductManage = { ...product, maxNums: maxNums };
              setData(newProductManage);
              setMemberModal({ isOpen: false });
            }
          }}
          onClose={() => setMemberModal({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default UserAccessSection;
