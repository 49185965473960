import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import ContractApplication from "./components/application/ContractApplication";

const tabs = [
  { value: "basicInfo", label: "기본정보", disabled: false },
  { value: "contract", label: "계약", disabled: true },
  { value: "accessLevel", label: "출입 관리", disabled: true },
  { value: "reservation", label: "공용공간 예약현황", disabled: true },
  { value: "iotDevice", label: "IoT 기기", disabled: true },
];

const ContractFormMain = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    async (tab: { value: string; label: string; disabled: boolean }) => {
      if (tab.disabled === false) {
        const newQueryParams = { ...queryParams };

        if (newQueryParams?.tab) {
          delete newQueryParams.tab;
        }
        if (newQueryParams?.memoId) {
          delete newQueryParams?.memoId;
        }
        if (newQueryParams?.memoType) {
          delete newQueryParams?.memoType;
        }
        // query parameter 에 tab 추가
        const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
        navigate(location.search + "?" + decodeURIComponent(newQueryParamStr));
      } else {
        setModalOpen(true);
      }
    },
    [queryParams, navigate, location.search],
  );

  // breadCrumb 클릭시 callback 함수
  // const clickBreadCrumb = useCallback(
  //   (crumb: { value: string; label: string }) => {
  //     if (crumb.value === "contract") {
  //       navigate(PagePath.contract.list);
  //     }
  //   },
  //   [navigate],
  // );

  const breadCrumbs = [{ value: "contract", label: "신청/계약" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  return (
    <>
      <MetaTag />
      <div className="page-contract-detail">
        <ContentsTitle title="신청/계약" tabs={tabs} activeTab={activeTab} clickTab={clickTab} colorType={"contract"} />
        {activeTab?.value === "basicInfo" && <ContractApplication />}
        {modalOpen && (
          <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)} title="기본정보가 저장되지 않았습니다"></BaseModal>
        )}
      </div>
    </>
  );
};

export default ContractFormMain;
