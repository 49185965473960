import _ from "lodash";
import moment from "moment";
import { BaseContractStatus } from "src/components/BaseContractStatus";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import PaymentStatusView from "../../components/PaymentStatusView";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import { BaseTooltip } from "src/components";

const BillIngScheduleColumns: any = [
  {
    Header: "id",
    accessor: "contractId",
    width: 80,
    sticky: "left",
    Cell: ({ value, row }: any) => {
      // console.log('contractId:',value);
      const path = PagePath.billing.detail.replace(":id", row.original.contractId);
      return (
        <>
          <DetailLink to={path} className={"text-underline"}>
            {value}
          </DetailLink>
        </>
      );
    },
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
    sticky: "left",
    Cell: ({ value, row }: any) => {
      // console.log('contractId:',value);
      const path = PagePath.contract.detail.replace(":id", row.original.contractId);
      return (
        <>
          <BaseNewTabLink path={path} value={value || "-"} ellipsis />
        </>
      );
    },
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    sticky: "left",
    Cell: ({ value, row }: any) => {
      return <BaseContractStatus status={value} />;
    },
  },
  {
    Header: "사업자",
    accessor: "mbOrganizationName",
    sticky: "left",
    width: 180,
    Cell: ({ value, row }: any) => {
      const path = PagePath.organization.detail.replace(":id", row.original.mbOrganizationId);
      // tooltip={true}
      return <div className="w-100">{value ? <BaseNewTabLink className="text-left" path={path} value={value || "-"} tooltip={true} /> : "-"}</div>;
    },
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 132,
    sticky: "left",
    Cell: ({ value, row }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "계약명",
    accessor: "contractName",
    width: 132,
    sticky: "left",
    Cell: ({ value, row }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "상품유형",
    accessor: "spaceProductType",
    width: 100,
    Cell: (props: any) => {
      return <ProductTypeChangeLabel productType={props.value} />;
    },
  },
  {
    Header: "관리비 청구",
    accessor: "isMntContain",
    width: 100,
    Cell: (props: any) => {
      return <p>{props.value ? "-" : "별도"}</p>;
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 200,
    Cell: ({ value, row }: any) => {
      const path = PagePath.product.detail.replace(":id", row.original.spaceProductId);
      return (
        <div className="w-100 text-left">
          <BaseNewTabLink path={path} value={value || "-"} ellipsis />
        </div>
      );
    },
  },
  {
    Header: "최근 청구금액",
    width: 140,
    accessor: "billAmount",
    Cell: ({ value, row }: any) => {
      return <p className="w-100 text-right">{Number(row.original.billAmount) > 0 ? numberToStringWithComma(value) + " 원" : "-"}</p>;
    },
  },
  {
    Header: "최근 결제상태",
    width: 80,
    accessor: "billPayStatus",
    Cell: ({ value, row }: any) => {
      return <PaymentStatusView value={value} contractStep={row.original.contractStep} />;
    },
  },
  {
    Header: "최근 납부기한",
    width: 80,
    accessor: "billDueDate",
    Cell: ({ value, row }: any) => {
      return <p>{row.original.billDueDate ? moment(row.original.billDueDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: "빌링기간 내 연체여부",
    accessor: "isOverdue",
    width: 100,
    Cell: ({ value, row }: any) => {
      // console.log("contractStep", row.original.contractStep !== "APPLY_CANCEL");
      return (
        <div
          className={`${
            Number(row.original.billAmount) > 0 &&
            ((value === undefined && "status F") || (String(value) === "false" && "status F") || (String(value) === "true" && "status G"))
          }`}
        >
          {Number(row.original.billAmount) > 0 ? (String(value) === "true" ? "연체" : "정상") : "-"}
        </div>
      );
    },
  },
  {
    Header: "빌링 기간",
    accessor: "scheduleStartTime",
    width: 180,
    Cell: ({ value, row }: any) => {
      const start = moment(row.original.scheduleStartTime).format(ViewYmdFormat.YYYY_MM_DD);
      const end = moment(row.original.scheduleEndTime).format(ViewYmdFormat.YYYY_MM_DD);
      if (_.isEqual(start, end)) {
        return <p className="w-100 text-left">{row.original.scheduleStartTime ? start : "-"}</p>;
      } else {
        return <p className="w-100 text-left">{row.original.scheduleStartTime ? start + " - " + end : "-"}</p>;
      }
    },
  },
];

export default BillIngScheduleColumns;
