import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getScheduleDetail_RCB01, getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { disabledEdit } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import useContractApi from "src/pages/contract/hooks/useContractApi";
import ScheduleBasicInfo from "../components/basicInfo/ScheduleBasicInfo";
import ScheduleInfo from "./components/billingScheduleInfo/ScheduleInfo";
import MntBillingForm from "./components/mntBillingForm/MntBillingForm";

const tabs = [
  { value: "basicInfo", label: "빌링스케줄 정보" },
  { value: "schedule", label: "이용료 빌링/연체 현황" },
  { value: "mnt", label: "관리비 빌링 현황" },
];

const BillingScheduleDetail = () => {
  const { callDetails } = useContractApi();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [scheduleId, setScheduleId] = useState(0);
  const [basicInfo, setBasicInfo] = useState<any>();

  const [roundList, setRoundList] = useState<any>([]);
  const [overdueList, setOverdueList] = useState<any>([]);

  const [contractDetail, setContractDetail] = useState<any>({});

  const [schedule, setSchedule] = useState<any>();

  const [spaceProductType, setSpaceProductType] = useState("");

  const [editable, setEditable] = useState(false);

  // const { executeAsync: getProviderDetail } = useApiOperation(, { noHandleError: true });
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  const { executeAsync: getScheduleList } = useApiOperation(getScheduleDetail_RCB01);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      let path = location.pathname;
      newQueryParams.tab = tab.value;
      if (tab.value === "basicInfo") {
        path = PagePath.billing.detail.replace(":id", String(id));
      }
      // console.log("location.pathname", location, id, newQueryParams);
      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(path + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const breadCrumbs = [{ value: "billing", label: "빌링스케줄 상세" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const [stopObj, setStopObj] = useState<any>();

  const callList = useCallback(async () => {
    if (id && Number(id) !== 0) {
      setRoundList([]);
      setOverdueList([]);
      //
      const res: any = await getScheduleList({ contractId: Number(id), supplyType: "RENTAL" });

      const response: any = await getRoundList({ scheduleId: Number(res!.data!.data!.scheduleList![0]!.scheduleId!) });

      if (response && response.status >= 200 && response.status <= 299) {
        const sorted = _.sortBy(response.data.data.content, ["bill.billOrder"]);

        const normal = sorted.filter((sort) => {
          if (sort.bill.isOverdueBill === false) {
            return sort;
          }
        });
        const overdues = sorted.filter((sort) => {
          if (sort.bill.isOverdueBill === true) {
            return sort;
          }
        });

        setRoundList(normal);
        setOverdueList(overdues);
      }
      //
    }
  }, [getRoundList, getScheduleList, id]);

  const fetchBillDate = useCallback(
    async (id: number) => {
      //call contractDetail
      if (contractDetail.contract === undefined) {
        const contractDetailResponse = await callDetails(String(id));

        setContractDetail(contractDetailResponse);
        if (disabledEdit.includes(contractDetailResponse?.contract?.contractStep!)) {
          setEditable(true);
        }
        setSpaceProductType(contractDetailResponse.product.productName);

        const sd: any = contractDetailResponse.scheduleList!.find((schedule: any) => schedule.supplyType === "RENTAL");

        setBasicInfo(contractDetailResponse.contract!);
        setScheduleId(sd.scheduleId);
        setStopObj(sd);
        await callList();
      }

      // }
    },
    [callDetails, callList, contractDetail],
  );

  useEffect(() => {
    if (id && !schedule) {
      fetchBillDate(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // title 에 건물명 바인딩
  let contractApplyNumber;
  if (contractDetail.contractManage?.contractApplyNumber) {
    contractApplyNumber = contractDetail.contractManage?.contractApplyNumber;
  }

  const checkTabs = useMemo(() => {
    if (
      contractDetail?.contract?.isMntContain! ||
      (contractDetail.product && contractDetail.product.productType === "FULL_COURT" && contractDetail.product.productType === "OPEN_COURT")
    ) {
      return tabs.filter((tab: any) => tab.value !== "mnt");
    }
    return tabs;
  }, [contractDetail]);

  return (
    <>
      <MetaTag subTitle={contractApplyNumber} />

      <div className="page-contract-detail">
        <ContentsTitle
          title="빌링스케줄 상세"
          tabs={checkTabs}
          activeTab={activeTab}
          clickTab={clickTab}
          detailInfo={[
            { title: "id", value: String(id) },
            { title: "신청번호", value: contractDetail.contractManage?.contractApplyNumber },
          ]}
        />

        {activeTab?.value === "basicInfo" && <ScheduleBasicInfo contractDetail={contractDetail} />}
        {activeTab?.value === "schedule" && (
          <ScheduleInfo
            basicInfo={contractDetail}
            callList={callList}
            contractBaseInfo={contractDetail}
            roundList={roundList}
            overdueList={overdueList}
            spaceProductType={spaceProductType}
            stopObj={stopObj}
            setStopObj={setStopObj}
            editable={editable}
            scheduleId={scheduleId}
          />
        )}

        {activeTab?.value === "mnt" && (
          <MntBillingForm
            basicInfo={contractDetail}
            callList={callList}
            contractBaseInfo={contractDetail}
            spaceProductType={spaceProductType}
            stopObj={stopObj}
            editable={editable}
          />
        )}
      </div>
    </>
  );
};

export default BillingScheduleDetail;
