import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { BaseDatePicker, BaseInput, BaseTooltip } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import BaseSection from "src/components/layout/BaseSection";
import { YmdFormat, numberToStringWithComma } from "src/utils";

const BillBasicInfo = ({ bill, setBill, showBtn, priceTotal }: any) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateRange2, setDateRange2] = useState<[Date | null, Date | null]>([null, null]);
  // const todayNext = moment().add(1, "days").toDate();
  // const todayBefore = moment().subtract(1, "days").toDate();
  const handleOnDateRangeChange = (dr: [Date | null, Date | null]) => {
    setDateRange(dr);
    const temp = _.cloneDeep(bill);
    if (dr[0] !== null) {
      temp.billStartTime = moment(dr[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000";
    }
    if (dr[1] !== null) {
      temp.billEndTime = moment(dr[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";
      setBill(temp);
    }
  };
  const handleOnDateRangeChange2 = (dr2: [Date | null, Date | null]) => {
    setDateRange2(dr2);
    const temp = _.cloneDeep(bill);
    if (dr2[0] !== null) {
      temp.billIssueDate = moment(dr2[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000";
    }
    if (dr2[1] !== null) {
      temp.billDueDate = moment(dr2[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";
      setBill(temp);
    }
  };
  const handleEtc1 = (value: string) => {
    const temp = _.cloneDeep(bill);
    temp.billDescription = value;
    setBill(temp);
  };
  const handleEtc2 = (value: string) => {
    const temp = _.cloneDeep(bill);
    temp.amountDescription = value;
    setBill(temp);
  };

  useEffect(() => {
    if (bill.billId > 0) {
      setDateRange([moment(bill.billStartTime).toDate(), moment(bill.billEndTime).toDate()]);
      setDateRange2([moment(bill.billIssueDate).toDate(), moment(bill.billDueDate).toDate()]);
    }
  }, [bill, setDateRange, setDateRange2]);
  return (
    <div>
      <section className="base-abstract-modal__title">
        <h1>기본정보</h1>
      </section>
      <div className="px30">
        <div className="flex-center-between">
          {bill.billId !== 0 && (
            <BaseSection
              title="청구서명"
              children={
                <>
                  {/* placeholder={"청구서명 입력 예)YYYY년 MM월"} */}
                  <BaseInput type="text" value={bill?.billTitle} className="minmax280" readonly />
                </>
              }
            />
          )}
        </div>
        <div className="flex-center-between">
          <BaseSection
            title="산출 기간"
            required={true}
            children={
              <div className="flext-start-center minmax280">
                <RangeDatepicker
                  dateRange={dateRange}
                  onChange={handleOnDateRangeChange}
                  disabled={bill?.isConfirmed || !showBtn}
                  // maxDate={todayBefore}
                />
              </div>
            }
          />
          <BaseSection
            title="산출 기간 비고"
            children={
              <>
                <BaseInput
                  type="text"
                  value={bill?.billDescription}
                  className="minmax280"
                  placeholder={"산출 기간 관련 안내사항 입력(선택)"}
                  onChange={handleEtc1}
                  disabled={bill.isConfirmed || !showBtn}
                />
              </>
            }
          />
        </div>
        <div className="flex-center-between">
          <BaseSection
            titleChildren={
              <>
                <div className="ml8 flex-center font14 required">
                  납부 기간
                  <BaseTooltip
                    contents={""}
                    tooltip={"필요 시, 납부 종료일이 공휴일인 경우 다음 영업일을 확인하여 납부기한을 변경해 주세요."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </>
            }
            required={true}
            children={
              <div className="minmax280">
                <RangeDatepicker
                  dateRange={dateRange2}
                  onChange={handleOnDateRangeChange2}
                  disabled={bill?.isConfirmed || !showBtn}
                  // minDate={moment(moment(bill.billEndTime).add(1, "days")).toDate()}
                  // maxDate={todayNext}
                />
              </div>
            }
          />

          <BaseSection
            title="납부 기간 비고"
            children={
              <>
                <BaseInput
                  type="text"
                  value={bill?.amountDescription}
                  className="minmax280"
                  placeholder={"납부 기간 관련 안내사항 입력(선택)"}
                  onChange={handleEtc2}
                  disabled={bill.isConfirmed || !showBtn}
                />
              </>
            }
          />
        </div>
        <div className="flex-center-between">
          <BaseSection
            titleChildren={
              <>
                <div className="flex-center font14">
                  납부 금액
                  <BaseTooltip
                    contents={""}
                    tooltip={"납부 금액은 세부 내역의 합계를 자동으로 계산하여 출력됩니다."}
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </>
            }
            children={
              <div className="flex-row flex-center-start w-100">
                <BaseInput type="text" value={numberToStringWithComma(priceTotal)} className="minmax280 mr8 text-right" disabled />
                <p>원</p>
              </div>
            }
          />
        </div>
        <div className="flex-center-between">
          <BaseSection
            required={true}
            titleChildren={
              <>
                <div className="ml8 flex-center font14 required">
                  계산서 작성일
                  <BaseTooltip
                    contents={""}
                    tooltip={
                      "선택한 계산서 작성일로 전자세금계산서/전자계산서에 작성일자로 표기됩니다.• 선택한 계산서 작성일로 예약 발행 하시려면, 관리비 빌링 현황 화면에서 [발행] 버튼을 클릭해 주세요.• 현재일이 작성일보다 뒤인 경우, [발행] 버튼을 클릭하시면 즉시 발행됩니다."
                    }
                    type={"normal"}
                    className={"tooltip__trigger-icon"}
                  />
                </div>
              </>
            }
            children={
              <div className="flex-row flex-center-start w-100">
                <BaseDatePicker
                  name={"bill.taxInvoiceIssueDate"}
                  type={"date"}
                  className="minmax280 mr8"
                  selectedDate={bill.taxInvoiceIssueDate ? moment(bill.taxInvoiceIssueDate).toDate() : null}
                  setDate={(value: Date) => {
                    
                    const temp = _.cloneDeep(bill);
                    if(value !==null){
                      temp.taxInvoiceIssueDate = moment(value).format(YmdFormat.WITH_TIME_ZONE);
                    }else{
                      temp.taxInvoiceIssueDate = null
                    }
                    setBill(temp);
                    
                  }}
                  placeholder={"계산서 작성일 입력(필수)"}
                  disabled={bill.isConfirmed || !showBtn}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default BillBasicInfo;
